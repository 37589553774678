import React, { useEffect, useState } from 'react';
import { FormGroup, Card, CardBody } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import * as ownerAction from '../actions';
import { isEmpty } from 'lodash';
import PageTitle from '../../../container/components/PageTitle';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { stateToHTML } from 'draft-js-export-html';
import { SUCCESS, WARNING } from '../../../../../constants';

export default function AboutUs(props) {
  const dispatch = useDispatch();

  const ownerDetail = useSelector((state) => state.Owner.ownerDetail);
  const updateAboutUsSuccess = useSelector(
    (state) => state.Owner.updateAboutUsSuccess
  );

  const [aboutUsData, setAboutUsData] = useState('');
  const [editorContentHtml, setEditorContentHtml] = useState('');
  const [stateUpdate, setStateUpdate] = useState(0);

  useEffect(() => {
    dispatch(ownerAction.getDetailOwner());
  }, []);

  useEffect(() => {
    const { aboutUs } = ownerDetail;
    if (!isEmpty(ownerDetail) && aboutUs) {
      setDataStoreToState();
    }
  }, [ownerDetail]);

  useEffect(() => {
    const state = updateAboutUsSuccess > stateUpdate;
    if (!!updateAboutUsSuccess || !!stateUpdate) {
      props.addNotification(
        state ? SUCCESS : WARNING,
        `Update content ${state ? 'successful' : 'failed'}.`
      );
      setStateUpdate(updateAboutUsSuccess);
    }
  }, [updateAboutUsSuccess]);

  function onEditorStateChange(data) {
    console.log(data);
    setEditorContentHtml(stateToHTML(data.getCurrentContent()));
    setAboutUsData(data);
  }

  function setDataStoreToState() {
    const { aboutUs } = ownerDetail;
    if (aboutUs) {
      const data = EditorState.createWithContent(
        ContentState.createFromBlockArray(convertFromHTML(aboutUs))
      );
      setEditorContentHtml(aboutUs);
      setAboutUsData(data);
    }
  }

  function onUpdateAbout() {
    const payload = {
      aboutUs: editorContentHtml,
    };
    dispatch(ownerAction.onUpdateAboutUs(payload));
  }

  const imageUploadCallBack = (file) =>
    new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      let img = new Image();
      // let url = ''
      reader.onload = function (e) {
        img.src = this.result;
      };
      img.onload = function () {
        // console.log(img.src.length)
        // Zoom the canvas needed for the image (you can also define the canvas tag directly in the DOM, so that the compressed image can be directly displayed without going to base64)
        var canvas = document.createElement('canvas');
        var context = canvas.getContext('2d');

        // image original size
        var originWidth = this.width;
        var originHeight = this.height;

        // Maximum size limit, you can achieve image compression by setting the width and height
        var maxWidth = 400,
          maxHeight = 500;
        // target size
        var targetWidth = originWidth,
          targetHeight = originHeight;
        // Image size exceeds 300x300 limit
        if (originWidth > maxWidth || originHeight > maxHeight) {
          if (originWidth / originHeight > maxWidth / maxHeight) {
            // wider, size limited by width
            targetWidth = maxWidth;
            targetHeight = Math.round(maxWidth * (originHeight / originWidth));
          } else {
            targetHeight = maxHeight;
            targetWidth = Math.round(maxHeight * (originWidth / originHeight));
          }
        }
        // canvas scales the image
        canvas.width = targetWidth;
        canvas.height = targetHeight;
        // clear the canvas
        context.clearRect(0, 0, targetWidth, targetHeight);
        // Image Compression
        context.drawImage(img, 0, 0, targetWidth, targetHeight);
        /* The first parameter is the created img object; the second three parameters are the upper left corner coordinates, and the second two are the canvas area width and height */

        // compressed image to base64 url
        /*canvas.toDataURL(mimeType, qualityArgument), mimeType The default value is 'image/png';
         * qualityArgument indicates the quality of the exported image. This parameter is valid only when exported to jpeg and webp formats. The default value is 0.92*/
        var newUrl = canvas.toDataURL('image/jpeg', 0.92); //base64 format
        // console.log(newUrl.length)

        resolve({
          data: {
            link: newUrl,
          },
        });
        // You can also convert the compressed image to blob format for uploading
        // canvas.toBlob((blob)=>{
        //     console.log(blob)
        // // Pass the blob as a parameter to the backend
        // }, 'image/jpeg', 0.92)
      };
    });

  return (
    <Card className="animated card-home fadeIn">
      <PageTitle
        heading="About Us"
        // subheading="Wide selection of buttons that feature different styles for backgrounds, borders and hover options!"
        icon="pe-7s-pen icon-gradient bg-tempting-azure"
      />
      <CardBody className="card-body-box d-flex flex-column">
        <FormGroup>
          {
            <Editor
              editorState={aboutUsData || EditorState.createEmpty()}
              onEditorStateChange={onEditorStateChange}
              toolbar={{
                options: [
                  'image',
                  'inline',
                  'blockType',
                  'fontSize',
                  'fontFamily',
                  'list',
                  'textAlign',
                  'colorPicker',
                  'link',
                  'embedded',
                  'emoji',
                  'remove',
                  'history',
                ],
                history: { inDropdown: true },
                inline: { inDropdown: false },
                list: { inDropdown: true },
                textAlign: { inDropdown: true },
                image: {
                  urlEnabled: false,
                  uploadEnabled: true,
                  alignmentEnabled: true, // Whether to display the arrange button is equivalent to text-align
                  uploadCallback: imageUploadCallBack,
                  previewImage: true,
                  inputAccept: 'image/*',
                  alt: { present: false, mandatory: false },
                },
              }}
            />
          }
        </FormGroup>
        <FormGroup className="text-center mt-auto">
          <button onClick={setDataStoreToState} className="btn-delete mx-2">
            Reset
          </button>
          <button onClick={onUpdateAbout} className="btn-active mx-2">
            Save
          </button>
        </FormGroup>
      </CardBody>
    </Card>
  );
}
