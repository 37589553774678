import React, { Component } from 'react';
import {
    FormGroup, Card, CardHeader, CardBody, Col, Row, Container, Table, CardFooter, Input,Button
} from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as customerAction from '../actions';
import moment from 'moment'
import PaginationFooter from '../../../../../libs/components/PaginationFooter'
import { isEqual } from 'lodash'
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from 'react-datepicker';
import PageTitle from '../../../container/components/PageTitle'
import {formatPhone} from '../../../../../libs/utils/utils'

class AppoitmentList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            marketingType: '1',
            page: 1,
            pageSize: 10,
            isOpen: false,
            keyword: '',
            date: ''
        };
    }

    componentDidMount() {
        this.onInit()
    }

    componentDidUpdate(prevProps, prevState) {
        const { keyword, date } = this.state
        const { isCreateCampainSuccess, isOpenDownload, dataProps } = this.props;
        if (isCreateCampainSuccess > prevProps.isCreateCampainSuccess)
            this.setState({ isOpen: false })
        if (isOpenDownload > prevProps.isOpenDownload) {
            this.setState({ isOpen: true })
        }
        if (!isEqual(keyword, prevState.keyword) || !isEqual(date, prevState.date)) {
            this.onInit()
        }
    }

    toggle = tab => {
        const { marketingType } = this.state;
        if (marketingType != tab)
            this.setState({ marketingType: tab }, () => this.onInit());
    }

    onInit = () => {
        const { page, pageSize, keyword, date } = this.state;
        const { actions } = this.props;
        const payload = { page, pageSize, keyword, date: date ? moment(date).format('YYYY-MM-DD') : '' }
        actions.getDataAppointment(payload)
    }

    onChangePagination = (page) => () => this.setState({ page }, () => this.onInit())

    getTotalByType = (type = 0) => {
        const { reportList } = this.props;
        return reportList.reduce((sum, report) => sum + (type ? (report.clientType == type ? report.billNumber : 0) : report.billNumber), 0)
    }

    openDownloadPopUp = (id) => () => {
        const { actions } = this.props;
        actions.getListSignInCoVid({ id })
    }

    onChange = ({ target: { name, value } }) => this.setState({ [name]: value })

    render() {
        const { keyword, page, date } = this.state;
        const { history, appoitmentist, total } = this.props;
        return (
            <>
                <PageTitle heading="Search by: " icon="fa fa-search icon-gradient bg-tempting-azure">
                    <FormGroup className="form-search-reviews justify-content-between m-0">
                        <div className="d-flex align-items-center">
                            <div className="form-search">

                                <Input className="input-keyword mr-2" value={keyword} name="keyword" onChange={this.onChange} placeholder="Keyword" autoComplete="off" />

                                <DatePicker
                                    className="form-control text-center input-search-staff"
                                    name="startTime"
                                    selected={date ? moment(date).toDate() : ''}
                                    onChange={date => this.setState({ date })}
                                    showYearDropdown
                                    dateFormatCalendar="MMMM"
                                    scrollableYearDropdown
                                    yearDropdownItemNumber={15}
                                    dateFormat="MM/dd"
                                    autoComplete="off"
                                    placeholderText="Date"
                                />
                            </div>
                        </div>

                        <Button className="mr-3"
                            onClick={() => history.push('/appointment-detail')}
                            color="primary"
                        >
                            Appointment Scheduler
                        </Button>
                    </FormGroup>

                    
                </PageTitle>
                <FormGroup className="table-marketting mt-0 w-100 d-flex">
                    <Card className="table-list-marketting w-100">
                        <CardHeader><h4><span>Appointment</span></h4></CardHeader>
                        <CardBody>
                            <div className="table-marketting-content">
                                <Table borderless striped>
                                    <thead>
                                        <tr>
                                            <th>Staff Name</th>
                                            <th>Customer Name</th>
                                            <th>Customer Phone</th>
                                            <th>Service Name</th>
                                            <th className="text-center">Status</th>
                                            <th>Start time</th>
                                            <th>End time</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            appoitmentist && appoitmentist.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{item.staffName}</td>
                                                    <td>{item.customerName}</td>
                                                    <td>{formatPhone(item.customerPhone)}</td>
                                                    <td>{item.serviceNames.join(', ')}</td>
                                                    <td className="text-center">{item.strStatus}</td>
                                                    <td>{item.strStartTime}</td>
                                                    <td>{item.strEndTime}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </CardBody>
                        <CardFooter className="pt-0 justify-content-center">
                            <PaginationFooter
                                currentPage={page}
                                totalSearch={total}
                                onChangePagination={this.onChangePagination} />
                        </CardFooter>
                    </Card>
                </FormGroup>
            </>
        );
    }
}


function mapStateToProps(state) {
    return {
        appoitmentist: state.Appoitment.appoitmentist,
        total: state.Appoitment.total,
    };
}

function mapDispatchToProps(dispatch) {
    const actions = {
        ...customerAction
    };
    return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(AppoitmentList);
