const ACTION_NAME = 'FEEDBACK/COUPON';
export const GET_DATA_COUPON = `${ACTION_NAME}/GET_DATA_COUPON`;
export const GET_DATA_COUPON_SUCCESS = `${ACTION_NAME}/GET_DATA_COUPON_SUCCESS`;
export const ON_CREATE_COUPON = `${ACTION_NAME}/ON_CREATE_COUPON`;
export const ON_CREATE_COUPON_SUCCESS = `${ACTION_NAME}/ON_CREATE_COUPON_SUCCESS`;
export const GET_DETAIL_COUPON = `${ACTION_NAME}/GET_DETAIL_COUPON`;
export const GET_DETAIL_COUPON_SUCCESS = `${ACTION_NAME}/GET_DETAIL_COUPON_SUCCESS`;
export const ON_UPDATE_COUPON = `${ACTION_NAME}/ON_UPDATE_COUPON`;
export const ON_UPDATE_COUPON_SUCCESS = `${ACTION_NAME}/ON_UPDATE_COUPON_SUCCESS`;
export const ON_DELETE_COUPON = `${ACTION_NAME}/ON_DELETE_COUPON`;
export const ON_DELETE_COUPON_SUCCESS = `${ACTION_NAME}/ON_DELETE_COUPON_SUCCESS`;

export const GET_GALLERY_COUPON = `${ACTION_NAME}/GET_GALLERY_COUPON`;
export const GET_GALLERY_COUPON_SUCCESS = `${ACTION_NAME}/GET_GALLERY_COUPON_SUCCESS`;

export const GET_CATEGORY_COUPON = `${ACTION_NAME}/GET_CATEGORY_COUPON`;
export const GET_CATEGORY_COUPON_SUCCESS = `${ACTION_NAME}/GET_CATEGORY_COUPON_SUCCESS`;
export const ON_CREATE_CATEGORY_COUPON = `${ACTION_NAME}/ON_CREATE_CATEGORY_COUPON`;
export const ON_CREATE_CATEGORY_COUPON_SUCCESS = `${ACTION_NAME}/ON_CREATE_CATEGORY_COUPON_SUCCESS`;
export const ON_UPDATE_CATEGORY_COUPON = `${ACTION_NAME}/ON_UPDATE_CATEGORY_COUPON`;
export const ON_UPDATE_CATEGORY_COUPON_SUCCESS = `${ACTION_NAME}/ON_UPDATE_CATEGORY_COUPON_SUCCESS`;
export const ON_DELETE_CATEGORY_COUPON = `${ACTION_NAME}/ON_DELETE_CATEGORY_COUPON`;
export const ON_DELETE_CATEGORY_COUPON_SUCCESS = `${ACTION_NAME}/ON_DELETE_CATEGORY_COUPON_SUCCESS`;

export const GET_COUPON_BY_CATEGORY_ID = `${ACTION_NAME}/GET_COUPON_BY_CATEGORY_ID`;
export const GET_COUPON_BY_CATEGORY_ID_SUCCESS = `${ACTION_NAME}/GET_COUPON_BY_CATEGORY_ID_SUCCESS`;

export const EXPIRE_TYPE = {
    FROM_TO: 0,
    A_WEEK: 1,
    A_MONTH: 2,
    THREE_MONTHS: 3,
    SIX_MONTHS: 4,
}

export const SEARCH_TYPE = {
    ALL: 0,
    EXPIRE: 1,
    NAME: 2,
    STAR: 3
}