import React from 'react';
import {
    ModalFooter, ModalHeader, Modal, FormGroup, Textarea, Col, Label, Input, ModalBody, Toast, ToastBody, ToastHeader
} from 'reactstrap';
import Rating from 'react-rating';
import Star from '../../../../../assets/img/star-review.svg';
import StarFull from '../../../../../assets/img/star-full.svg';


export default function ModalReview(props) {
    const { isOpen, staffReviewList } = props;

    return (
        <Modal className="modal-create" isOpen={!!isOpen} centered>
            <ModalHeader className="border-0">Review</ModalHeader>
            <ModalBody className="border-0" style={{
                                            maxHeight: 'calc(100vh - 210px)',
                                            overflowY: 'auto'}}>
                {staffReviewList && staffReviewList.map((review, index) => {
                    return (
                        <div key={index} className="p-2 bg-secondary my-2 rounded ">
                            <Toast className="review-staff">
                                <div className= "ml-1 mt-2">
                                    <div className="row ml-1">
                                        <strong className="col-sm-4">{review.customerName}</strong>
                                        <small className="col-sm-7 text-right">{review.strCreateDate}</small>
                                    </div>

                                    <p className= "ml-1 mt-2">
                                        <span>
                                            <Rating
                                                initialRating={review.star}
                                                readonly
                                                emptySymbol={
                                                    <span className="star-rating-symbol">
                                                        <img src={Star} />
                                                    </span>
                                                }
                                                fullSymbol={
                                                    <span className="star-rating-symbol">
                                                        <img src={StarFull} />
                                                    </span>
                                                }
                                            />
                                        </span>
                                    </p>
                                </div>
                                <hr></hr>
                                <ToastBody>{review.content}</ToastBody>
                            </Toast>
                            < br></br>
                        </div>
                    )
                })}
            </ModalBody>
            <ModalFooter className="border-0">
                <button onClick={props.toggleModal(false)}>Cancel</button>
            </ModalFooter>
        </Modal>
    )
}