import React from 'react';
import { MARKETING_TYPE } from '../constants';

export default function HeaderTable(props) {
  const { marketingType } = props;
    let value = "Register Date"
    if (marketingType == MARKETING_TYPE.APPOINTMENT) {
        value = "Appointment Date / Time"
    }else if (marketingType == MARKETING_TYPE.USED_COUPON) {
        value = "Coupon Used Date"
    }else if (marketingType == MARKETING_TYPE.GREATE_THAN_3_STAR) {
        value = "Review Date/Time"
    }else if (marketingType == MARKETING_TYPE.LESS_THAN_3_STAR) {
        value = "Review Date/Time"
    }else if (marketingType == MARKETING_TYPE.GIFT_CARD_PURCHASE) {
        value = "Gift Purchase Date"
    }else if (marketingType == MARKETING_TYPE.LOYALTY_POINT) {
        value = "Total Point"
    }
  return (
    <>
        {value}
    </>
  );
}
