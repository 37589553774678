import { apiBaseURL } from '../config';
import { request } from '../services/api';

const baseEndpoint = `${apiBaseURL}`;

function getSocialChannelList() {
    const endpoint = `${baseEndpoint}/socialChannel/list`;
    return request(endpoint, {
        method: 'GET',
    });
}

function onCreateChannel(data) {
    const endpoint = `${baseEndpoint}/socialChannel/save`;
    return request(endpoint, {
        method: 'POST',
        data
    });
}

function onUpdateChannelList(data) {
    const endpoint = `${baseEndpoint}/socialChannel/update`;
    return request(endpoint, {
        method: 'PUT',
        data
    });
}

function onDeleteChannel({ payload }) {
    const endpoint = `${baseEndpoint}/socialChannel/delete/${payload}`;
    return request(endpoint, {
        method: 'GET'
    });
}

function getCategoryFeedbacks() {
    const endpoint = `${baseEndpoint}/reviews/category/getAll`;
    return request(endpoint, {
        method: 'GET',
    });
}

function getQuestionFeedbacks() {
    const endpoint = `${baseEndpoint}/question/getAll`;
    return request(endpoint, {
        method: 'GET',
    });
}

function onCreateQuestionFeedbacks(data) {
    const endpoint = `${baseEndpoint}/question/create`;
    return request(endpoint, {
        method: 'POST',
        data
    });
}

function onDeleteQuestionFeedbacks(id) {
    const endpoint = `${baseEndpoint}/question/delete/${id}`;
    return request(endpoint, {
        method: 'DELETE',
    });
}

function onCreateCategoryFeedbacks(data) {
    const endpoint = `${baseEndpoint}/reviews/category/save`;
    return request(endpoint, {
        method: 'POST',
        data
    });
}

function onDeleteCategoryFeedbacks(id) {
    const endpoint = `${baseEndpoint}/reviews/category/delete/${id}`;
    return request(endpoint, {
        method: 'DELETE',
    });
}

function getDataNotification() {
    const endpoint = `${baseEndpoint}/notification/feedback/getByShop`;
    return request(endpoint, {
        method: 'GET',
    });
}

function onUpdateNotification(data) {
    const endpoint = `${baseEndpoint}/notification/feedback/saveOrUpdate`;
    return request(endpoint, {
        method: 'POST',
        data
    });
}

function getAllSettingMemberApi() {
    console.log(22222222222)
    const endpoint = `${baseEndpoint}/shop/getAllSettingMember`;
    return request(endpoint, {
        method: 'GET',
    });
}

function updateSettingMemberApi(data) {
    const endpoint = `${baseEndpoint}/shop/updateSettingMember`;
    return request(endpoint, {
        method: 'PUT',
        data
    });
}

function getSettingSmsApi() {
    const endpoint = `${baseEndpoint}/shop/getAllSettingSms`;
    return request(endpoint, {
        method: 'GET',
    });
}

function updateSettingSmsApi(data) {
    const endpoint = `${baseEndpoint}/shop/updateSettingSms`;
    return request(endpoint, {
        method: 'PUT',
        data
    });
}

function getAutoSettingSentSmsApi() {
    const endpoint = `${baseEndpoint}/shop/getAutoSettingSentSms`;
    return request(endpoint, {
        method: 'GET',
    });
}

function updateAutoSettingSentSmsApi(data) {
  const endpoint = `${baseEndpoint}/shop/updateAutoSettingSentSms`;
  return request(endpoint, {
    method: 'PUT',
    data,
  });
}

function getExpressServices() {
    const endpoint = `${baseEndpoint}/shop/setting-express-service`;
    return request(endpoint, {
        method: 'GET',
    });
}

function onAddExpressServices(data) {
    const endpoint = `${baseEndpoint}/shop/setting-express-service`;
    return request(endpoint, {
        method: 'POST',
        data
    });
}

function onUpdateExpressServices(data) {
    const endpoint = `${baseEndpoint}/shop/setting-express-service`;
    return request(endpoint, {
        method: 'PUT',
        data
    });
}

function onDeleteExpressServices(id) {
    const endpoint = `${baseEndpoint}/shop/setting-express-service/${id}`;
    return request(endpoint, {
        method: 'DELETE',
    });
}

export default {
    getSocialChannelList,
    onCreateChannel,
    onUpdateChannelList,
    onDeleteChannel,
    getCategoryFeedbacks,
    getQuestionFeedbacks,
    onCreateQuestionFeedbacks,
    onDeleteQuestionFeedbacks,
    getDataNotification,
    onUpdateNotification,
    onCreateCategoryFeedbacks,
    onDeleteCategoryFeedbacks,
    getAllSettingMemberApi,
    updateSettingMemberApi,
    getSettingSmsApi,
    updateSettingSmsApi,
    getAutoSettingSentSmsApi,
    updateAutoSettingSentSmsApi,
	getExpressServices,
	onAddExpressServices,
	onUpdateExpressServices,
	onDeleteExpressServices,
};
