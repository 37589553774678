import React from 'react';
import {
  ModalFooter, ModalHeader, Modal, FormGroup, Textarea, Col, Label, Input, ModalBody,
} from 'reactstrap';


export default function ModalHandleCategory(props) {
  const { isOpen, category } = props;
  return (
    <Modal className="modal-create" isOpen={!!isOpen} centered>
      <ModalHeader className="border-0">{isOpen == 1 ? 'Create ' : 'Edit '}Category</ModalHeader>
      <ModalBody className="border-0">
        <FormGroup row>
          <Label sm={3}>Category Name</Label>
          <Col sm={9}>
            <Input name="categoryName" value={category.categoryName} onChange={props.onChange} />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>Description</Label>
          <Col sm={9}>
            <Input type="textarea" rows="3" name="description" value={category.description} onChange={props.onChange} />
          </Col>
        </FormGroup>
      </ModalBody>
      <ModalFooter className="border-0">
        <button onClick={props.toggleModal(false)}>Cancel</button>
        <button disabled={!category.categoryName}  onClick={(e) => {e.target.setAttribute("disabled", true);props.onSave();
        }} >Save</button> 
      </ModalFooter>
    </Modal>
  )
}

