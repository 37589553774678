import React from 'react';
import {
  ModalFooter, ModalHeader, Modal,
} from 'reactstrap';


export default function ModalWarning(props) {
  const { isOpen, onConfirm } = props;
  return (
    <Modal className="modal-delete" isOpen={isOpen} centered>
      <ModalHeader className="border-0">Your account does not have enough money, please contact the administrator</ModalHeader>
      <ModalFooter className="border-0">
        <button type="button" onClick={onConfirm}>OK</button>
      </ModalFooter>
    </Modal>
  );
}
