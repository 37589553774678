import { all, put, call, takeEvery } from 'redux-saga/effects';
import * as action from '../actions';
import API from '../../../../../api/dashboard'
import * as actionDefault from '../../../container/actions'

export function* handleGetDataDashboard(payload) {
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.getReportDashboard, payload);
    yield put(action.getDataDashboardSuccess(response.data.payload));
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    console.log(error);
    yield put(actionDefault.isLoadingSuccess(true));
  }
}

export function* handleGetReview(payload) {
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.getReview, payload);
    if (response && response.data.code && response.data.code === 200) {
      yield put(action.getReviewSuccess(response.data.payload));
    }
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    console.log(error);
    yield put(actionDefault.isLoadingSuccess(true));
  }
}

export function* handleGetReviewSocial(payload) {
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.getReviewSocial, payload);
    if (response && response.data.code && response.data.code === 200) {
      yield put(action.getReviewSuccess(response.data.payload));
    }
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    console.log(error);
    yield put(actionDefault.isLoadingSuccess(true));
  }
}

export function* handleGetReviewCoupon(payload) {
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.getReviewCoupon, payload);
    if (response && response.data.code && response.data.code === 200) {
      yield put(action.getReviewSuccess(response.data.payload));
    }
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    console.log(error);
    yield put(actionDefault.isLoadingSuccess(true));
  }
}

export default function* root() {
  yield all([
    takeEvery(action.getDataDashboard, handleGetDataDashboard),
    takeEvery(action.getReview, handleGetReview),
    takeEvery(action.getReviewSocial, handleGetReviewSocial),
    takeEvery(action.getReviewCoupon, handleGetReviewCoupon)
  ]);
}
