import React, { Component } from 'react';
import {
  ModalFooter, ModalHeader, Modal
} from 'reactstrap';
import ModalCustom from './Modal'

export default function ModalDeleteAppoitment(props) {

  const { isOpen } = props;
  return (
    <Modal className="modal-delete" centered isOpen={isOpen}>
      <ModalHeader className="border-0">{props.message || 'Uncontrollable error'}</ModalHeader>
      <ModalFooter className="border-0">
        {
          props.toggleDelete &&
          <button onClick={() => props.toggleDelete(false)}>Cancel</button>
        }
        <button onClick={props.onClose}>{props.btnClose || 'OK'}</button>
      </ModalFooter>
    </Modal>
  )
}

