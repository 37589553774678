const ACTION_NAME = 'FEEDBACK/CUSTOMER';
export const GET_DATA_CUSTOMER = `${ACTION_NAME}/GET_DATA_CUSTOMER`;
export const GET_DATA_CUSTOMER_SUCCESS = `${ACTION_NAME}/GET_DATA_CUSTOMER_SUCCESS`;

export const GET_DATA_LAST_SIGN_IN = `${ACTION_NAME}/GET_DATA_LAST_SIGN_IN`;
export const GET_DATA_LAST_SIGN_IN_SUCCESS = `${ACTION_NAME}/GET_DATA_LAST_SIGN_IN_SUCCESS`;

export const GET_LIST_SIGNIN_BY_CUSTOMER = `${ACTION_NAME}/GET_LIST_SIGNIN_BY_CUSTOMER`;
export const GET_LIST_SIGNIN_BY_CUSTOMER_SUCCESS = `${ACTION_NAME}/GET_LIST_SIGNIN_BY_CUSTOMER_SUCCESS`;
export const GET_HISTORY_TRANSACTION = `${ACTION_NAME}/GET_HISTORY_TRANSACTION`;
export const GET_HISTORY_TRANSACTION_SUCCESS = `${ACTION_NAME}/GET_HISTORY_TRANSACTION_SUCCESS`;

export const GET_DETAIL_CUSTOMER = `${ACTION_NAME}/GET_DETAIL_CUSTOMER`;
export const GET_DETAIL_CUSTOMER_SUCCESS = `${ACTION_NAME}/GET_DETAIL_CUSTOMER_SUCCESS`;

export const UPDATE_DETAIL_CUSTOMER = `${ACTION_NAME}/UPDATE_DETAIL_CUSTOMER`;
export const UPDATE_DETAIL_CUSTOMER_SUCCESS = `${ACTION_NAME}/UPDATE_DETAIL_CUSTOMER_SUCCESS`;
