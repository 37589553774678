import React, { Component } from 'react';
import {
  FormGroup, Card, CardHeader, CardBody, UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle,
  Col, Label, Input, CardFooter, Modal, Row
} from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as couponAction from '../actions';
import moment from 'moment'
import { SEARCH_TYPE } from '../constants'
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from 'react-datepicker';
import Picture from '../../../../../assets/img/picture.png';
import PaginationFooter from '../../../../../libs/components/PaginationFooter';
import PageTitle from '../../../container/components/PageTitle'

class Coupon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      typeSearch: SEARCH_TYPE.ALL,
      startTime: '',
      endTime: '',
      keyWord: '',
      starSearch: 0,
      page: 1,
      pageSize: 12,
    };
  }

  componentDidMount() {
    this.onInit()
    this.onInitCategoryCoupon()
  }

  componentDidUpdate(prevProps, prevState) {
    const { typeSearch, startTime, endTime, keyWord, starSearch } = this.state;
    if (typeSearch != prevState.typeSearch && typeSearch == SEARCH_TYPE.ALL) {
      this.setState({
        startTime: '',
        endTime: '',
        keyWord: '',
        starSearch: 0,
        page: 1
      }, () => this.onInit())
    }

    if (typeSearch != prevState.typeSearch) {
      this.setState({
        startTime: typeSearch != SEARCH_TYPE.EXPIRE ? '' : startTime,
        endTime: typeSearch != SEARCH_TYPE.EXPIRE ? '' : endTime,
        keyWord: typeSearch != SEARCH_TYPE.NAME ? '' : keyWord,
        starSearch: typeSearch != SEARCH_TYPE.STAR ? 0 : starSearch,
      })
    }
    if (startTime && endTime) {
      if (startTime != prevState.startTime || endTime != prevState.endTime) {
        this.onInit()
      }
    }
    if (keyWord != prevState.keyWord || starSearch != prevState.starSearch)
      this.onInit()
  }

  onChange = ({ target: { name, value } }) => this.setState({ [name]: value })

  handleChangeDate = (name, date) => {
    this.setState({
      [name]: date
    })
  }

  onInit = () => {
    const { actions } = this.props;
    const { typeSearch, startTime, endTime, keyWord, starSearch, page, pageSize } = this.state;
    let payload = {}
    if (typeSearch == SEARCH_TYPE.ALL) {
      payload = {
        page,
        pageSize
      }
    }
    else if (typeSearch == SEARCH_TYPE.EXPIRE) {
      payload = {
        page,
        pageSize,
        startTime: moment(startTime).format('YYYY-MM-DD hh:mm:ss'), endTime: moment(endTime).format('YYYY-MM-DD hh:mm:ss')
      }
    }
    else if (typeSearch == SEARCH_TYPE.NAME) {
      payload = {
        page,
        pageSize,
        keyWord
      }
    }
    else if (typeSearch == SEARCH_TYPE.STAR) {
      payload = {
        page,
        pageSize,
        star: starSearch
      }
    }
    actions.getDataCoupon(payload)
  }

  onInitCategoryCoupon = () => {
    const { actions } = this.props;
    actions.getCategoryCoupon()
  }

  changeTypeSearch = (typeSearch) => () => this.setState({ typeSearch })

  changeStarSearch = (starSearch) => () => this.setState({ starSearch })

  onChangePagination = (page) => () => {
    this.setState({
      page
    }, () => this.onInit())
  }

  render() {
    const { typeSearch, startTime, endTime, keyWord, starSearch, page, isOpenHandleCategory, category, isShowDeleteCate } = this.state;
    const { couponList, history, total, categoryCouponList } = this.props;
    return (
      <Card className="animated fadeIn card-home flex-column">
        <PageTitle>
          <div className="d-flex align-items-center">
            <UncontrolledButtonDropdown>
              <DropdownToggle caret color="warning">
                {
                  typeSearch == SEARCH_TYPE.EXPIRE ? 'Search by Expire Date' :
                    typeSearch == SEARCH_TYPE.NAME ? 'Search by Name' :
                      typeSearch == SEARCH_TYPE.STAR ? 'Search by Star' : 'All Coupon'
                }
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={this.changeTypeSearch(SEARCH_TYPE.ALL)}>All Coupon</DropdownItem>
                <DropdownItem onClick={this.changeTypeSearch(SEARCH_TYPE.EXPIRE)}>Expire Date</DropdownItem>
                <DropdownItem onClick={this.changeTypeSearch(SEARCH_TYPE.NAME)}>Name</DropdownItem>
                <DropdownItem onClick={this.changeTypeSearch(SEARCH_TYPE.STAR)}>Star</DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
            <div className="form-search">
              {
                typeSearch == SEARCH_TYPE.EXPIRE &&
                <>
                  <DatePicker
                    className="form-control text-center"
                    name="startTime"
                    selected={startTime ? moment(startTime).toDate() : ''}
                    maxDate={endTime ? moment(endTime).toDate() : ''}
                    onChange={date => this.handleChangeDate('startTime', date)}
                    showYearDropdown
                    dateFormatCalendar="MMMM"
                    scrollableYearDropdown
                    yearDropdownItemNumber={15}
                    dateFormat="MM/dd"
                    autoComplete="off"
                  />
                  <span>-</span>
                  <DatePicker
                    className="form-control text-center"
                    name="endTime"
                    selected={endTime ? moment(endTime).toDate() : ''}
                    minDate={startTime ? moment(startTime).toDate() : ''}
                    onChange={date => this.handleChangeDate('endTime', date)}
                    showYearDropdown
                    dateFormatCalendar="MMMM"
                    scrollableYearDropdown
                    yearDropdownItemNumber={15}
                    dateFormat="MM/dd"
                    autoComplete="off"
                  />
                </>
              }
              {
                typeSearch == SEARCH_TYPE.NAME &&
                <Input className="input-keyword" value={keyWord} name="keyWord" onChange={this.onChange} placeholder="Enter your keyword" />
              }
              {
                typeSearch == SEARCH_TYPE.STAR &&
                <UncontrolledButtonDropdown>
                  <DropdownToggle caret color="warning">
                    {
                      starSearch == 1 ? '1 Star' :
                        starSearch == 2 ? '2 Star' :
                          starSearch == 3 ? '3 Star' :
                            starSearch == 4 ? '4 Star' :
                              starSearch == 5 ? '5 Star' : 'All Star'
                    }
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={this.changeStarSearch(0)}>All Star</DropdownItem>
                    <DropdownItem onClick={this.changeStarSearch(1)}>1 Star</DropdownItem>
                    <DropdownItem onClick={this.changeStarSearch(2)}>2 Star</DropdownItem>
                    <DropdownItem onClick={this.changeStarSearch(3)}>3 Star</DropdownItem>
                    <DropdownItem onClick={this.changeStarSearch(4)}>4 Star</DropdownItem>
                    <DropdownItem onClick={this.changeStarSearch(5)}>5 Star</DropdownItem>
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
              }
            </div>
          </div>
          <button className="btn-add ml-auto" onClick={() => history.push('/coupon/create')}>+ Coupon</button>
        </PageTitle>
        <CardBody className="card-body-box d-flex flex-column">
          <FormGroup row className="body-category-coupon">
            {
              couponList && couponList.map(coupon => {
                return (
                  <Col sm={6} md={4} xl={3} key={coupon.id}>
                    <div className="coupon-item">
                      <div onClick={() => history.push(`/coupon/edit/${coupon.id}`)} className="coupon-item-meta">
                        <img src={coupon.imageUrl ? coupon.imageUrl : Picture} />
                      </div>
                      <div className="coupon-item-content">
                        <h5 onClick={() => history.push(`/coupon/edit/${coupon.id}`)}>{coupon.couponName}</h5>
                      </div>
                    </div>
                  </Col>
                )
              })
            }
          </FormGroup>
          <CardFooter className="mt-auto">
            <PaginationFooter
              currentPage={page}
              totalSearch={total}
              onChangePagination={this.onChangePagination} />
          </CardFooter>
        </CardBody>
      </Card>
    );
  }
}

function mapStateToProps(state) {
  return {
    couponList: state.Coupon.couponList,
    total: state.Coupon.total,
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {
    ...couponAction,
  };
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(Coupon);
