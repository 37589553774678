import React from 'react';
import { Input, FormGroup, Label, Col } from 'reactstrap'

export default function GroupInput(props) {
    const { label, value, name, onChange, classInput, placeholder } = props;
    return (
        <FormGroup row className="row-coupon">
            <Label sm={3} md={2}>{label}</Label>
            <Col sm={6} md={6}>
                <Input value={value} name={name} onChange={onChange} className={'input-radius' + (classInput ? ' ' + classInput : '')} placeholder={placeholder} />
            </Col>
        </FormGroup>
    )
}