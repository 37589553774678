import React, { Component } from 'react';
import {
  FormGroup, Card, CardHeader, CardBody, Col, Row, Container, Table, CardFooter, UncontrolledTooltip, Input
} from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as customerAction from './actions';
import moment from 'moment'
import PaginationFooter from '../../../../libs/components/PaginationFooter'
import ModalDelete from './components/ModalDelete'
import { isEqual } from 'lodash'
import DatePicker from 'react-datepicker'
import * as defaultAction from '../../container/actions'
import { SUCCESS, WARNING } from '../../../../constants'
import Api from '../../../../api/customer'
import { formatPhone } from '../../../../libs/utils/utils'

class Customer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      marketingType: '1',
      page: 1,
      pageSize: 10,
      isOpen: false,
      keyWord: '',
      startTime: '',
      endTime: '',

    };
    this.timeout = 0
  }

  componentDidMount() {
    this.onInit()
  }

  componentDidUpdate(prevProps, prevState) {
    const { keyWord, startTime, endTime } = this.state
    if (!isEqual(keyWord, prevState.keyWord))
      this.onChangeSearch()
  }

  // toggle = tab => {
  //   const { marketingType } = this.state;
  //   if (marketingType != tab)
  //     this.setState({ marketingType: tab }, () => this.onInit());
  // }

  onInit = () => {
    const { page, pageSize, keyWord, startTime, endTime } = this.state;
    const { actions } = this.props;
    const payload = {
      page, pageSize, keyWord,
      startTime: startTime ? moment(startTime).format('YYYY-MM-DD') : '',
      endTime: endTime ? moment(endTime).format('YYYY-MM-DD') : '',
    }
    actions.getDataCustomer(payload)
  }

  onChangePagination = (page) => () => this.setState({ page }, () => this.onInit())

  onChangeSearch = () => {
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.onInit()
    }, 500);
  }

  getTotalByType = (type = 0) => {
    const { reportList } = this.props;
    return reportList.reduce((sum, report) => sum + (type ? (report.clientType == type ? report.billNumber : 0) : report.billNumber), 0)
  }

  openDownloadPopUp = (id) => () => {
    const { actions } = this.props;
    actions.getListSignInCoVid({ id })
  }

  onChange = ({ target: { name, value } }) => this.setState({ [name]: value })

  handleChangeDate = (name, date) => {
    this.setState({
      [name]: date
    })
  }

  onSearch = () => this.setState({ page: 1 }, () => this.onInit())

  openDeleteCustomer = (id) => () => {
    this.setState({ isOpen: id })
  }

  onDelete = async () => {
    const { isOpen } = this.state
    console.log(isOpen)
    const { actions } = this.props
    actions.isLoadingSuccess(false)
    const response = await Api.deleteCustomerApi(isOpen);
    const status = response && response.data && response.data.code && response.data.code === 200
    this.setState({
      isOpen: 0
    }, () => {
      this.props.addNotification(
        status ? SUCCESS : WARNING,
        `Delete customer ${status ? 'successful' : 'failed'}.`
      );
      this.onInit()
    })

    actions.isLoadingSuccess(true)
  }

  render() {
    const { isOpen, page, keyWord, startTime, endTime } = this.state;
    const { history, customerList, total } = this.props;
    const { location: { pathname } } = history;
    return (
      <>
        <Card className="animated fadeIn card-home card-home--marketting">
          {/* <PageTitle heading="Search by: " icon="fa fa-search icon-gradient bg-tempting-azure">
            <FormGroup className="form-search-reviews justify-content-between m-0">
              <div className="d-flex align-items-center">
                <div className="form-search">
                  <Input className="input-keyword mr-2" value={keyWord} name="keyWord" onChange={this.onChange} placeholder="Name or Phone" autoComplete="off" />
                </div>
              </div>
            </FormGroup>
          </PageTitle> */}
          <CardBody className="d-flex p-0">
            <div className="d-flex w-100">
              <FormGroup className="table-marketting mt-0 w-100 d-flex">
                <Card className="table-list-marketting w-100">
                  <CardHeader><h4><span>Customer</span></h4></CardHeader>
                  <CardBody>
                    <FormGroup className="d-flex flex-wrap mb-4">
                      <div className="form-search-reviews">
                        <Input className="input-keyword mr-2" value={keyWord} name="keyWord" onChange={this.onChange} placeholder="Name or Phone" autoComplete="off" />
                        <DatePicker
                          className="input-reviews-time"
                          name="startTime"
                          selected={startTime ? moment(startTime).toDate() : ''}
                          maxDate={endTime ? moment(endTime).toDate() : ''}
                          onChange={date => this.handleChangeDate('startTime', date)}
                          showYearDropdown
                          dateFormatCalendar="MMMM"
                          scrollableYearDropdown
                          yearDropdownItemNumber={15}
                          placeholderText="From"
                          dateFormat="MM/dd/yyyy"
                          autoComplete="off"
                        />
                        <span className="mx-3">-</span>
                        <DatePicker
                          className="input-reviews-time"
                          name="endTime"
                          selected={endTime ? moment(endTime).toDate() : ''}
                          minDate={startTime ? moment(startTime).toDate() : ''}
                          onChange={date => this.handleChangeDate('endTime', date)}
                          showYearDropdown
                          dateFormatCalendar="MMMM"
                          scrollableYearDropdown
                          yearDropdownItemNumber={15}
                          placeholderText="To"
                          dateFormat="MM/dd/yyyy"
                          autoComplete="off"
                        />
                        <button onClick={this.onSearch}>Search</button>
                      </div>
                    </FormGroup>
                    {/* <div className="table-marketting-content"> */}
                    <Table className="mb-0">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Phone</th>
                          <th>Point</th>
                          <th>Register Date</th>
                          <th>Source Customer</th>
                          <th>Status</th>
                          <th className="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          customerList && customerList.map((item, index) => (
                            <tr key={index}>
                              <td>{item.name}</td>
                              <td>{formatPhone(item.phone)}</td>
                              <td>{item.point || 0}</td>
                              <td>{item.registerDate && moment(new Date(item.registerDate)).format('MM-DD-YYYY')}</td>
                              <td>{item.customerType || 'WALK-IN'}</td>
                              <td>
                                {
                                  item.status == 1 ? <div class="badge badge-success">Active</div> : item.status == 0 ? <div class="badge badge-danger">InActive</div> : ''
                                }
                              </td>
                              <td className="text-center">
                                {/* <span className={cx('download-customer', { disabled: !item.countFileCoVid })} onClick={!!item.countFileCoVid ? this.openDownloadPopUp(item.customerId) : null} id={`dowload-${item.customerId}`}>
                                    <i className="fa fa-eye" aria-hidden="true"></i>
                                  </span>
                                  {
                                    !!item.countFileCoVid &&
                                    <UncontrolledTooltip placement="top" target={`dowload-${item.customerId}`}>Download from covid sign in</UncontrolledTooltip>
                                  } */}
                                <span className={'btn btn-primary'} onClick={() => history.push(`/customer/detail/${item.id}`)}>
                                  <i className="fa fa-eye" aria-hidden="true"></i>
                                </span>

                                <span className="btn btn-danger ml-2" onClick={this.openDeleteCustomer(item.id)}>
                                  <i className="fa fa-trash" aria-hidden="true"></i>
                                </span>
                              </td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </Table>
                    {/* </div> */}
                  </CardBody>
                  <CardFooter className="pt-0">
                    <PaginationFooter
                      currentPage={page}
                      totalSearch={total}
                      onChangePagination={this.onChangePagination} />
                  </CardFooter>
                </Card>
              </FormGroup>
            </div>
          </CardBody>
        </Card>
        <ModalDelete
          isOpen={!!isOpen}
          toggleDelete={() => this.setState({ isOpen: 0 })}
          onDelete={this.onDelete}
        />
      </>
    );
  }
}


function mapStateToProps(state) {
  return {
    customerList: state.Customer.customerList,
    total: state.Customer.total,
    total: state.Customer.total,
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {
    ...customerAction,
    ...defaultAction
  };
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(Customer);
