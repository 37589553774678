import React, { Component } from 'react';

import domtoimage from 'dom-to-image';
import htmlToImage from 'html-to-image';
import html2canvas from 'html2canvas';

import Logo from '../../../../assets/img/logo-icheck1.png'
import Icon from '../../../../assets/img/icon-render.png'
import Banner from '../../../../assets/img/banner-QR.jpg'

export default class Coupon extends Component {

    constructor(props) {
        super(props);
        this.myRef = React.createRef();
    }

    renderDomtoimage = () => {
        const node = this.myRef.current;
        if (node) {
            domtoimage.toPng(node)
                .then((dataUrl) => {
                    this.downloadImage(dataUrl)
                })
                .catch(function (error) {
                    console.error('oops, something went wrong!', error);
                });
        }
    }
    renderDomtoimage1 = () => {
        var node = document.getElementById('qr-banner-share');
        if (node) {
            domtoimage.toPng(node)
                .then((dataUrl) => {
                    this.downloadImage(dataUrl)
                })
                .catch(function (error) {
                    console.error('oops, something went wrong!', error);
                });
        }
    }

    renderHtml2Canvas = () => {
        const node = this.myRef.current;
        if (node) {
            html2canvas(node)
                .then((canvas) => {
                    const imgData = canvas.toDataURL('image/png');
                    this.downloadImage(imgData)
                });
        }
    }

    renderHtml2Canvas1 = () => {
        var node = document.getElementById('qr-banner-share');
        if (node) {
            html2canvas(node)
                .then((canvas) => {
                    const imgData = canvas.toDataURL('image/png');
                    this.downloadImage(imgData)
                });
        }
    }

    renderHtmltoImage = () => {
        const node = this.myRef.current;
        if (node) {
            htmlToImage.toPng(node)
                .then((dataUrl) => {
                    this.downloadImage(dataUrl)
                })
                .catch(function (error) {
                    console.error('oops, something went wrong!', error);
                });
        }
    }

    renderHtmltoImage1 = () => {
        var node = document.getElementById('qr-banner-share');
        if (node) {
            htmlToImage.toPng(node)
                .then((dataUrl) => {
                    this.downloadImage(dataUrl)
                })
                .catch(function (error) {
                    console.error('oops, something went wrong!', error);
                });
        }
    }

    downloadImage = (base64) => {
        var a = document.createElement("a");
        a.href = base64;
        a.download = "Image.png";
        a.click();
    }

    render() {
        return (
            <div>
                <div>
                    <button onClick={this.renderDomtoimage}>domtoimage</button>
                    <button onClick={this.renderDomtoimage1}>domtoimage1</button>
                    <button onClick={this.renderHtml2Canvas}>html2canvas</button>
                    <button onClick={this.renderHtml2Canvas1}>html2canvas1</button>
                    <button onClick={this.renderHtmltoImage}>htmltoimage1</button>
                    <button onClick={this.renderHtmltoImage1}>htmltoimage1</button>
                </div>
                <div id="qr-banner-share" ref={this.myRef} style={{ background: `url(${Banner})` }}>
                    <div className="qr-banner-share-top">
                        <h4>Escape Nails & Spa</h4>
                        <div className="qr-description">
                            <p>Your Vital Review <br /> is helping to imporve my <br /> performance for your next visit!</p>
                            <span><img src={Logo} /></span>
                        </div>
                        <div className="icon-middle"><img src={Icon} /></div>
                        <p className="qr-scan-text"><span><i className="fa fa-mobile" aria-hidden="true"></i></span> Scan Me</p>
                        {/* <div className="qr-banner"><img src={`data:image/jpeg;base64,${imageQrCodeReviewBase64}`} /></div> */}
                        <p className="qr-content">We apprreciate your time and look forward to serving you again!</p>
                    </div>
                    <div className="qr-banner-share-bottom">
                        <div className="qr-info"><p>(234) 222 333</p></div>
                        <div className="qr-end"><span>Thank You</span></div>
                    </div>
                </div>
            </div>
        );
    }
}
