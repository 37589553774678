import { apiBaseURL } from '../config';
import { request } from '../services/api';
import { isEmpty } from 'lodash'

const baseEndpoint = `${apiBaseURL}`;

function getReportDashboard(data) {
  let searchParams = ''
  if (!isEmpty(data.payload)) {
    searchParams = new URLSearchParams();
    Object.keys(data.payload).forEach(key => searchParams.append(key, data.payload[key]))
    searchParams = '?' + searchParams.toString()
  }
  const endpoint = `${baseEndpoint}/reviews/report_dashboard${searchParams}`;
  return request(endpoint, {
    method: 'GET'
  });
}

function getReview(data) {
  let searchParams = ''
  if (!isEmpty(data.payload)) {
    searchParams = new URLSearchParams();
    Object.keys(data.payload).forEach(key => searchParams.append(key, data.payload[key]))
    searchParams = '?' + searchParams.toString()
  }
  const endpoint = `${baseEndpoint}/reviews/getAllBadReviews${searchParams}`;
  return request(endpoint, {
    method: 'GET'
  });
}

function getReviewSocial(data) {
  let searchParams = ''
  if (!isEmpty(data.payload)) {
    searchParams = new URLSearchParams();
    Object.keys(data.payload).forEach(key => searchParams.append(key, data.payload[key]))
    searchParams = '?' + searchParams.toString()
  }
  const endpoint = `${baseEndpoint}/reviews/getAllReviewsBySocialChannel${searchParams}`;
  return request(endpoint, {
    method: 'GET'
  });
}

function getReviewCoupon(data) {
  let searchParams = ''
  if (!isEmpty(data.payload)) {
    searchParams = new URLSearchParams();
    Object.keys(data.payload).forEach(key => searchParams.append(key, data.payload[key]))
    searchParams = '?' + searchParams.toString()
  }
  const endpoint = `${baseEndpoint}/coupon/getReportCouponByFilter${searchParams}`;
  return request(endpoint, {
    method: 'GET'
  });
}

export default {
  getReportDashboard,
  getReview,
  getReviewSocial,
  getReviewCoupon
};
