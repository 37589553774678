import { createAction } from 'redux-actions';
import * as CONST from './constants';

export const getOwnerList = createAction(CONST.GET_GET_OWNER_LIST);
export const getOwnerListSuccess = createAction(CONST.GET_GET_OWNER_LIST_SUCCESS);
export const getDetailOwner = createAction(CONST.GET_DETAIL_OWNER);
export const getDetailOwnerSuccess = createAction(CONST.GET_DETAIL_OWNER_SUCCESS);
export const onUpdateOwner = createAction(CONST.ON_UPDATE_OWNER);
export const onUpdateOwnerSuccess = createAction(CONST.ON_UPDATE_OWNER_SUCCESS);
export const onGetOwnerSchedule = createAction(CONST.ON_GET_OWNER_SCHEDULE);
export const onGetOwnerScheduleSuccess = createAction(CONST.ON_GET_OWNER_SCHEDULE_SUCCESS);

export const onUpdateOwnerSchedule = createAction(CONST.ON_UPDATE_OWNER_SCHEDULE);
export const onUpdateOwnerScheduleSuccess = createAction(CONST.ON_UPDATE_OWNER_SCHEDULE_SUCCESS);

export const onUpdateAboutUs = createAction(CONST.ON_UPDATE_ABOUT_US);
export const onUpdateAboutUsSuccess = createAction(CONST.ON_UPDATE_ABOUT_US_SUCCESS);