import { createAction } from 'redux-actions';
import * as CONST from './constants';

export const getServiceList = createAction(CONST.GET_SERVICE_LIST);
export const getServiceListSuccess = createAction(
  CONST.GET_SERVICE_LIST_SUCCESS
);
export const onCreateService = createAction(CONST.ON_CREATE_SERVICE);
export const onCreateServiceSuccess = createAction(
  CONST.ON_CREATE_SERVICE_SUCCESS
);
export const getDetailService = createAction(CONST.GET_DETAIL_SERVICE);
export const getDetailServiceSuccess = createAction(
  CONST.GET_DETAIL_SERVICE_SUCCESS
);
export const onUpdateService = createAction(CONST.ON_UPDATE_SERVICE);
export const onUpdateServiceSuccess = createAction(
  CONST.ON_UPDATE_SERVICE_SUCCESS
);
export const onDeleteService = createAction(CONST.ON_DELETE_SERVICE);
export const onDeleteServiceSuccess = createAction(
  CONST.ON_DELETE_SERVICE_SUCCESS
);

export const getCategoryList = createAction(CONST.GET_CATEGORY_LIST);
export const getCategoryListSuccess = createAction(
  CONST.GET_CATEGORY_LIST_SUCCESS
);
export const onCreateCategory = createAction(CONST.ON_CREATE_CATEGORY);
export const onCreateCategorySuccess = createAction(
  CONST.ON_CREATE_CATEGORY_SUCCESS
);
export const getDetailCategory = createAction(CONST.GET_DETAIL_CATEGORY);
export const getDetailCategorySuccess = createAction(
  CONST.GET_DETAIL_CATEGORY_SUCCESS
);
export const onUpdateCategory = createAction(CONST.ON_UPDATE_CATEGORY);
export const onUpdateCategorySuccess = createAction(
  CONST.ON_UPDATE_CATEGORY_SUCCESS
);
export const onDeleteCategory = createAction(CONST.ON_DELETE_CATEGORY);
export const onDeleteCategorySuccess = createAction(
  CONST.ON_DELETE_CATEGORY_SUCCESS
);

export const getImageCategoryService = createAction(
  CONST.GET_IMAGE_CATEGORY_SERVICE
);
export const getImageCategoryServiceSuccess = createAction(
  CONST.GET_IMAGE_CATEGORY_SERVICE_SUCCESS
);

export const getImageByCategoryID = createAction(
  CONST.GET_IMAGE_BY_CATEGORY_ID
);
export const getImageByCategoryIDSuccess = createAction(
  CONST.GET_IMAGE_BY_CATEGORY_ID_SUCCESS
);
