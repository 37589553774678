import React, { Component } from 'react';
import {
  FormGroup,
  Card,
  CardHeader,
  CardBody,
  UncontrolledButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Col,
  Label,
  Input,
  Row,
  CustomInput,
  CardImg,
  Button,
} from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as couponAction from '../actions';
import * as serviceAction from '../../Service/actions';
import { EXPIRE_TYPE } from '../constants';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import Picture from '../../../../../assets/img/picture.png';
import Spinner from '../../../../../assets/Spinner.gif';
import { isEqual, isEmpty } from 'lodash';
import ModalDelete from './ModalDelete';
import ModaGallery from './ModaGallery';
import moment from 'moment';
import domtoimage from 'dom-to-image';
import ReeValidate from 'ree-validate';
import htmlToImage from 'html-to-image';
import PageTitle from '../../../container/components/PageTitle';
import { ROUTER_URL } from '../../../../../constants';
import Api from '../../../../../api/coupon';
const dataValidate = {
  'start date': 'required',
  'end date': 'required',
  'coupon name': 'required',
};

class Coupon extends Component {
  constructor(props) {
    super(props);

    this.validator = new ReeValidate(dataValidate);

    this.state = {
      isCreate: true,
      couponName: '',
      expireType: EXPIRE_TYPE.FROM_TO,
      startDate: '',
      endDate: '',
      ratingApply: [],
      imagePath: '',
      discountType: 0,
      percentageValue: 0,
      amountValue: 0,
      isOpenDelete: false,
      isOpenGallery: false,
      gallerySelected: 0,
      imageGallery: '',
      isLoadImage: false,
      errors: this.validator.errors,
      serviceId: 0,
      countImage: 0,
      categoryId: 0,
      categoryType: 0,
    };

    this.validateAndSubmit = this.validateAndSubmit.bind(this);
  }

  componentDidMount() {
    const {
      actions,
      history: {
        location: { pathname, state },
      },
      galleryCoupon,
      serviceList,
      couponDetail,
      categoryCouponList,
    } = this.props;
    if (state && !isEmpty(state)) {
      this.setState({ categoryType: 1 });
    }
    if (pathname.indexOf('edit') > -1) {
      if (!isEmpty(couponDetail)) {
        this.setState({
          ...this.state,
          ...couponDetail,
          ratingApply: couponDetail.ratingApply ? couponDetail.ratingApply : [],
          image: couponDetail.imageUrl,
          percentageValue:
            couponDetail.discountType == 0 ? couponDetail.discountValue : 0,
          amountValue:
            couponDetail.discountType == 1 ? couponDetail.discountValue : 0,
        });
      }
      if (couponDetail.libraryImageId) {
        this.setState({
          gallerySelected: couponDetail.libraryImageId,
          imageGallery: galleryCoupon
            .filter((gallery) => gallery.id == couponDetail.libraryImageId)
            .map((gallery) => `${gallery.urlImage}`),
        });
      }
      const id = pathname.split('/').slice(-1).pop();
      actions.getDetailCoupon(id);
    }
    if (!galleryCoupon.length) {
      actions.getGalleryCoupon();
    }
    if (!serviceList.length) {
      actions.getServiceList({ page: 1, pageSize: 10 });
    }
    this.setState({
      isCreate: pathname.indexOf('edit') === -1,
    });

    if (!categoryCouponList || !categoryCouponList.length) {
      this.onInitCategoryCoupon();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      gallerySelected,
      imageGallery,
      endDate,
      discountType,
      percentageValue,
      amountValue,
      subTitle,
    } = this.state;
    const {
      couponDetail,
      createCouponSuccess,
      updateCouponSuccess,
      deleteCouponSuccess,
      history,
      galleryCoupon,
    } = this.props;
    if (
      gallerySelected == prevState.gallerySelected &&
      gallerySelected &&
      imageGallery == prevState.imageGallery &&
      imageGallery
    ) {
      if (gallerySelected && imageGallery) {
        if (
          !isEqual(endDate, prevState.endDate) ||
          !isEqual(percentageValue, prevState.percentageValue) ||
          !isEqual(subTitle, prevState.subTitle) ||
          !isEqual(amountValue, prevState.amountValue)
        ) {
          this.onSelect(gallerySelected)();
        }
        if (!isEqual(discountType, prevState.discountType)) {
          if (
            (discountType == 0 && !percentageValue) ||
            (discountType == 1 && !amountValue)
          ) {
            this.setState({
              gallerySelected: 0,
              imageGallery: '',
              imagePath: '',
              image: '',
              percentageValue: discountType == 1 ? 0 : percentageValue,
              amountValue: discountType == 0 ? 0 : amountValue,
            });
          }
        }
      }
    }

    if (!isEqual(couponDetail, prevProps.couponDetail)) {
      this.setState({
        ...this.state,
        ...couponDetail,
        ratingApply: couponDetail.ratingApply ? couponDetail.ratingApply : [],
        image: couponDetail.imageUrl,
        percentageValue:
          couponDetail.discountType == 0 ? couponDetail.discountValue : 0,
        amountValue:
          couponDetail.discountType == 1 ? couponDetail.discountValue : 0,
      });
      if (couponDetail.libraryImageId) {
        this.setState({
          gallerySelected: couponDetail.libraryImageId,
          imageGallery: galleryCoupon
            .filter((gallery) => gallery.id == couponDetail.libraryImageId)
            .map((gallery) => `${gallery.urlImage}`),
        });
      }
    }

    if (createCouponSuccess > prevProps.createCouponSuccess) {
      history.push(ROUTER_URL.COUPON_LIST);
    }
    if (updateCouponSuccess > prevProps.updateCouponSuccess) {
      history.push(ROUTER_URL.COUPON_LIST);
      this.props.addNotification('success', 'Update coupon successfully');
    } else if (updateCouponSuccess < prevProps.updateCouponSuccess) {
      this.props.addNotification('warning', 'Update coupon failure');
    }
    if (deleteCouponSuccess > prevProps.deleteCouponSuccess) {
      history.push(ROUTER_URL.COUPON_LIST);
    }
  }

  onChange = ({ target: { name, value } }) => {
    this.setState({
      [name]: value,
    });
  };

  onInitCategoryCoupon = () => {
    const { actions } = this.props;
    actions.getCategoryCoupon();
  };

  changeTypeExpire = (type) => () => {
    let endDate = '';
    switch (type) {
      case EXPIRE_TYPE.A_WEEK:
        endDate = moment().add(7, 'days').toDate();
        break;
      case EXPIRE_TYPE.A_MONTH:
        endDate = moment().add(1, 'months').toDate();
        break;
      case EXPIRE_TYPE.THREE_MONTHS:
        endDate = moment().add(3, 'months').toDate();
        break;
      case EXPIRE_TYPE.SIX_MONTHS:
        endDate = moment().add(6, 'months').toDate();
        break;
      case EXPIRE_TYPE.A_YEAR:
        endDate = moment().add(1, 'years').toDate();
        break;
      default:
        endDate = '';
        break;
    }
    this.setState({
      expireType: type,
      startDate: type !== EXPIRE_TYPE.FROM_TO ? moment().toDate() : '',
      endDate,
    });
  };

  handleChangeDate = (name, date) => this.setState({ [name]: date });

  handleRating = (value) => () => {
    const { ratingApply } = this.state;
    this.setState({
      ratingApply:
        ratingApply.indexOf(value) > -1
          ? [...ratingApply].filter((r) => r != value)
          : [...ratingApply, value],
    });
  };

  onChangeImage = ({ target: { files, name } }) => {
    if (files && !!files.length) {
      const reader = new FileReader();
      const file = files[0];

      reader.onload = (upload) => {
        this.setState({
          [name]: upload.target.result,
          [`${name}File`]: files,
        });
      };
      reader.readAsDataURL(file);
    }
  };

  deleteImage = (name) => () => {
    this.setState({
      [name]: '',
      [`${name}File`]: '',
    });
  };

  changeDiscountType = ({ target: { name, checked } }) => {
    const { discountType } = this.state;
    let type = discountType;
    if (name == 'percentage' && discountType != 0 && checked) {
      type = 0;
    }

    if (name == 'amount' && discountType != 1 && checked) {
      type = 1;
    }

    this.setState({
      discountType: type,
    });
  };

  onHandleCoupon = () => {
    const {
      id,
      isCreate,
      couponName,
      discountType,
      startDate,
      endDate,
      imagePath,
      ratingApply,
      percentageValue,
      amountValue,
      image,
      serviceId,
      categoryId,
      gallerySelected,
      categoryType,
      expireType,
    } = this.state;
    const { actions } = this.props;
    const param = {
      couponId: isCreate ? 0 : id,
      couponName,
      discountType,
      discountValue: discountType == 0 ? percentageValue : amountValue,
      startDate,
      endDate,
      image: imagePath
        ? imagePath.replace(/^data:image\/[a-z]+;base64,/, '')
        : image,
      ratingApply,
      serviceId,
      categoryId,
      categoryType,
      expireType,
    };
    if (gallerySelected) {
      param.libraryImageId = gallerySelected;
    }
    if (isCreate) {
      actions.onCreateCoupon(param);
    } else {
      actions.onUpdateCoupon(param);
    }
  };

  toggleDelete = (status) => () => {
    this.setState({
      isOpenDelete: status,
    });
  };

  deleteCoupon = () => {
    const { id } = this.state;
    const { actions } = this.props;
    actions.onDeleteCoupon(id);
  };

  onSelect = (id) => async (e) => {
    if (e) e.preventDefault();
    this.setState({ isLoadImage: true, isOpenGallery: false });
    const dataImage = await Api.getImageCouponById(id);
    let imageData = '';
    if (dataImage) {
      const {
        data: {
          payload: { imageBase64 },
        },
      } = dataImage;
      imageData = imageBase64;
    }
    this.setState(
      {
        gallerySelected: id,
        imageGallery: `data:image/png;base64,${imageData}`,
      },
      () => {
        this.renderImage();
      }
    );
  };

  validateAndSubmit(e) {
    e.preventDefault();

    const { startDate, endDate, couponName } = this.state;
    const { errors } = this.validator;

    const formData = {
      'start date': startDate,
      'end date': endDate,
      'coupon name': couponName,
    };

    this.validator.validateAll(formData).then((success) => {
      if (success) {
        this.onHandleCoupon();
      } else {
        this.setState({ errors });
      }
    });
  }

  changeService = (serviceId) => () => this.setState({ serviceId });

  renderImage = async () => {
    const node = document.getElementsByClassName('coupon-image-gallery');
    setTimeout(() => {
      if (node.length) {
        htmlToImage
          .toPng(node[0])
          .then(() => {
            this.rederImage1();
          })
          .catch((error) => {
            console.error('oops, something went wrong!', error);
          });
      }
    }, 500);
  };

  rederImage1 = () => {
    const node = document.getElementsByClassName('coupon-image-gallery');
    setTimeout(() => {
      if (node.length) {
        htmlToImage
          .toPng(node[0])
          .then((dataUrl) => {
            this.setState({
              imagePath: dataUrl,
              isLoadImage: false,
            });
          })
          .catch((error) => {
            console.error('oops, something went wrong!', error);
          });
      }
    }, 500);
  };

  changeCategory = (categoryId) => () => this.setState({ categoryId });

  changeCategoryType = (categoryType) => () => this.setState({ categoryType });

  render() {
    const {
      couponName,
      subTitle,
      expireType,
      startDate,
      endDate,
      ratingApply,
      imagePath,
      image,
      discountType,
      percentageValue,
      amountValue,
      isOpenDelete,
      isCreate,
      isOpenGallery,
      gallerySelected,
      imageGallery,
      isLoadImage,
      errors,
      serviceId,
      categoryId,
      categoryType,
    } = this.state;
    const {
      galleryCoupon,
      serviceList,
      categoryCouponList,
      history: {
        location: { state },
      },
    } = this.props;

    const isCreateCouponForMarketing = state && !isEmpty(state);

    const isDisableImage = !(
      endDate && (discountType == 0 ? !!percentageValue : !!amountValue)
    );

    return (
      <>
        <Card className="animated fadeIn card-home">
          <PageTitle>
            <FormGroup className="d-flex m-0">
              <button
                className="btn-back"
                onClick={() => this.props.history.goBack()}
              >
                Back
              </button>
            </FormGroup>
          </PageTitle>
          <CardBody className="card-body-box d-flex flex-column">
            <FormGroup row className="row-coupon align-items-start">
              <Label sm={2} className="font-bold ">
                Coupon Type
              </Label>
              <Col sm={2}>
                <CustomInput
                  className="custom-input-large font-bold pl-0"
                  disabled={isCreateCouponForMarketing}
                  checked={categoryType == 0}
                  onChange={this.changeCategoryType(0)}
                  name="review"
                  type="checkbox"
                  id="review-type"
                  label="Review"
                />
              </Col>
              <Col sm={2}>
                <CustomInput
                  className="custom-input-large font-bold pl-0"
                  checked={categoryType == 1}
                  onChange={this.changeCategoryType(1)}
                  name="marketing"
                  type="checkbox"
                  id="marketing-type"
                  label="Marketing"
                />
              </Col>
            </FormGroup>
            <FormGroup row className="row-coupon">
              <Label sm={2}>Coupon Name</Label>
              <Col sm={4}>
                <Input
                  value={couponName}
                  name="couponName"
                  onChange={this.onChange}
                  className="input-radius"
                  placeholder="Coupon name"
                />
                {errors.has('coupon name') && (
                  <Label
                    className="error name-error"
                    dangerouslySetInnerHTML={{
                      __html: errors.first('coupon name'),
                    }}
                  />
                )}
              </Col>
              <Label sm={3} className="text-right">
                Sub Title
              </Label>
              <Col sm={3}>
                <Input
                  value={subTitle}
                  name="subTitle"
                  onChange={this.onChange}
                  className="input-radius"
                  placeholder="Sub Title"
                />
              </Col>
            </FormGroup>
            <FormGroup row className="row-coupon">
              {categoryCouponList &&
                !!categoryCouponList.length &&
                categoryType == 1 && (
                  <>
                    <Label sm={2}>Category Name</Label>
                    <Col sm={4}>
                      <UncontrolledButtonDropdown className="w-100">
                        <DropdownToggle caret color="warning">
                          {categoryId
                            ? categoryCouponList
                                .filter((category) => category.status == 1)
                                .filter((category) => category.id == categoryId)
                                .map((category) => category.name)
                            : 'None'}
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem onClick={this.changeCategory(0)}>
                            None
                          </DropdownItem>
                          {categoryCouponList.filter((category) => category.status == 1).map((category) => (
                            <DropdownItem
                              key={category.id}
                              onClick={this.changeCategory(category.id)}
                            >
                              {category.name}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </UncontrolledButtonDropdown>
                    </Col>
                  </>
                )}
              {serviceList && !!serviceList.length && (
                <>
                  <Label
                    sm={
                      categoryCouponList &&
                      !!categoryCouponList.length &&
                      isCreateCouponForMarketing
                        ? 3
                        : 2
                    }
                    className={
                      categoryCouponList &&
                      !!categoryCouponList.length &&
                      isCreateCouponForMarketing
                        ? 'text-right'
                        : ''
                    }
                  >
                    Service Name
                  </Label>
                  <Col
                    sm={
                      categoryCouponList &&
                      !!categoryCouponList.length &&
                      isCreateCouponForMarketing
                        ? 3
                        : 4
                    }
                  >
                    <UncontrolledButtonDropdown className="w-100">
                      <DropdownToggle caret color="warning">
                        {serviceId
                          ? serviceList
                              .filter((service) => service.id == serviceId)
                              .map((service) => service.serviceName)
                          : 'None'}
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem onClick={this.changeService(0)}>
                          None
                        </DropdownItem>
                        {serviceList.map((service) => (
                          <DropdownItem
                            key={service.id}
                            onClick={this.changeService(service.id)}
                          >
                            {service.serviceName}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </UncontrolledButtonDropdown>
                  </Col>
                </>
              )}
            </FormGroup>
            {categoryType == 0 && (
              <FormGroup row className="row-coupon">
                <Label sm={2} className="d-flex align-items-center">
                  Rating Apply
                </Label>
                <Col sm={7} className="d-flex justify-content-between">
                  {ratingApply &&
                    [1, 2, 3, 4, 5].map((item) => (
                      <span
                        key={item}
                        value={item}
                        onClick={this.handleRating(item)}
                        className={`item-rating-coupon ${
                          ratingApply.indexOf(item) > -1 ? 'selected' : ''
                        }`}
                      >
                        {' '}
                        {item}{' '}
                      </span>
                    ))}
                </Col>
              </FormGroup>
            )}
            <FormGroup row className="row-coupon align-items-start">
              <Label sm={2}>Expiredate</Label>
              <Col sm={4}>
                <UncontrolledButtonDropdown className="w-100">
                  <DropdownToggle caret color="warning">
                    {expireType == EXPIRE_TYPE.A_WEEK
                      ? 'A Week'
                      : expireType == EXPIRE_TYPE.A_MONTH
                      ? 'A Month'
                      : expireType == EXPIRE_TYPE.THREE_MONTHS
                      ? 'Three Months'
                      : expireType == EXPIRE_TYPE.SIX_MONTHS
                      ? 'Six Months'
                      : 'From - to'}
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      onClick={this.changeTypeExpire(EXPIRE_TYPE.FROM_TO)}
                    >
                      From - to
                    </DropdownItem>
                    <DropdownItem
                      onClick={this.changeTypeExpire(EXPIRE_TYPE.A_WEEK)}
                    >
                      A Week
                    </DropdownItem>
                    <DropdownItem
                      onClick={this.changeTypeExpire(EXPIRE_TYPE.A_MONTH)}
                    >
                      A Month
                    </DropdownItem>
                    <DropdownItem
                      onClick={this.changeTypeExpire(EXPIRE_TYPE.THREE_MONTHS)}
                    >
                      Three Months
                    </DropdownItem>
                    <DropdownItem
                      onClick={this.changeTypeExpire(EXPIRE_TYPE.SIX_MONTHS)}
                    >
                      Six Months
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
              </Col>
                <>
                  <Col sm={3}>
                    <DatePicker
                      className="form-control text-center coupon-date-input"
                      name="startDate"
                      selected={startDate ? moment(startDate).toDate() : ''}
                      maxDate={endDate ? moment(endDate).toDate() : ''}
                      onChange={(date) =>
                        this.handleChangeDate('startDate', date)
                      }
                      showYearDropdown
                      dateFormatCalendar="MMMM"
                      scrollableYearDropdown
                      yearDropdownItemNumber={15}
                      dateFormat="MM/dd"
                      isClearable={!!startDate}
                      autoComplete="off"
                    />
                    {errors.has('start date') && (
                      <Label
                        className="error name-error"
                        htmlFor="title"
                        dangerouslySetInnerHTML={{
                          __html: errors.first('start date'),
                        }}
                      />
                    )}
                  </Col>
                  <Col sm={3}>
                    <div className="date-picker-right">
                      <DatePicker
                        className="form-control text-center coupon-date-input"
                        name="startDate"
                        selected={endDate ? moment(endDate).toDate() : ''}
                        minDate={startDate ? moment(startDate).toDate() : ''}
                        onChange={(date) =>
                          this.handleChangeDate('endDate', date)
                        }
                        disabled={isLoadImage}
                        showYearDropdown
                        dateFormatCalendar="MMMM"
                        scrollableYearDropdown
                        yearDropdownItemNumber={15}
                        dateFormat="MM/dd"
                        isClearable={!!endDate}
                        popperPlacement="bottom-end"
                        autoComplete="off"
                      />
                    </div>
                    {errors.has('end date') && (
                      <Label
                        className="error name-error"
                        htmlFor="title"
                        dangerouslySetInnerHTML={{
                          __html: errors.first('end date'),
                        }}
                      />
                    )}
                  </Col>
                </>
            </FormGroup>
            <FormGroup row className="row-coupon align-items-start">
              <Label sm={2} className="font-bold ">
                Discount
              </Label>
              <Col sm={4}>
                <CustomInput
                  className="custom-input-large font-bold pl-0"
                  checked={discountType == 0}
                  onChange={this.changeDiscountType}
                  name="percentage"
                  type="checkbox"
                  id="percentage"
                  label="Percentage %"
                />
              </Col>
              <Col sm={3}>
                <Input
                  className="text-center"
                  type="number"
                  onChange={this.onChange}
                  name="percentageValue"
                  value={percentageValue}
                  disabled={discountType == 1 || isLoadImage}
                />
              </Col>
            </FormGroup>
            <FormGroup row className="row-coupon align-items-start">
              <Col sm={{ size: 4, offset: 2 }}>
                <CustomInput
                  className="custom-input-large font-bold pl-0"
                  checked={discountType == 1}
                  onChange={this.changeDiscountType}
                  name="amount"
                  type="checkbox"
                  id="amount"
                  label="Amount"
                />
              </Col>
              <Col sm={3}>
                <Input
                  className="text-center"
                  type="number"
                  onChange={this.onChange}
                  name="amountValue"
                  value={amountValue}
                  disabled={discountType == 0 || isLoadImage}
                />
              </Col>
            </FormGroup>
            <FormGroup row className="row-coupon align-items-start mb-5">
              <Label sm={2}>Image</Label>
              <Col sm={4}>
                <div className="file-button-none">
                  {isLoadImage && (
                    <div className="loading-image">
                      <img src={Spinner} />
                    </div>
                  )}
                  <Label
                    className={`mb-0 ${isDisableImage ? 'is-disable' : ''}`}
                    htmlFor="imagePath"
                  >
                    <Input
                      disabled={isDisableImage}
                      hidden
                      name="imagePath"
                      id="imagePath"
                      onChange={!isDisableImage ? this.onChangeImage : null}
                      onClick={(event) => (event.target.value = null)}
                      type="file"
                      accept="image/*"
                    />
                    {!isLoadImage && (
                      <CardImg
                        src={imagePath || image || Picture}
                        id="img_upload_id"
                      />
                    )}
                  </Label>
                  {imagePath && (
                    <Button
                      className="file-btn-delete"
                      onClick={this.deleteImage('imagePath')}
                    >
                      <i className="fa fa-times-circle" aria-hidden="true" />
                    </Button>
                  )}
                </div>
              </Col>
              <Col sm={3}>
                <Button
                  color="warning"
                  disabled={isDisableImage}
                  className="btn-gallery-coupon form-control"
                  onClick={() => this.setState({ isOpenGallery: true })}
                >
                  Select from library
                </Button>
              </Col>
            </FormGroup>
            {imageGallery && (
              <div className="coupon-gallery-container">
                <div className="coupon-image-gallery">
                  <img src={imageGallery} />

                  <div className="image-gallery-meta">
                    <h4>
                      {discountType == 0
                        ? `${percentageValue}%`
                        : `$${amountValue}`}{' '}
                      OFF
                    </h4>
                    <span>Expire: {moment(endDate).format('MM, DD YYYY')}</span>
                    {subTitle && <p>{subTitle}</p>}
                  </div>
                </div>
              </div>
            )}

            <FormGroup className="text-center mt-auto">
              {!isCreate && (
                <button
                  onClick={this.toggleDelete(true)}
                  className="btn-delete mx-2"
                >
                  Delete Coupon
                </button>
              )}
              <button
                disabled={isLoadImage}
                onClick={this.validateAndSubmit}
                className="btn-active mx-2"
              >
                Save Coupon
              </button>
            </FormGroup>
          </CardBody>
        </Card>
        <ModalDelete
          isOpen={isOpenDelete}
          onDelete={this.deleteCoupon}
          toggleDelete={this.toggleDelete}
        />

        <ModaGallery
          isOpen={isOpenGallery}
          galleryCoupon={galleryCoupon}
          gallerySelected={gallerySelected}
          toggleModal={() => this.setState({ isOpenGallery: false })}
          onSelect={this.onSelect}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    couponDetail: state.Coupon.couponDetail,
    createCouponSuccess: state.Coupon.createCouponSuccess,
    updateCouponSuccess: state.Coupon.updateCouponSuccess,
    deleteCouponSuccess: state.Coupon.deleteCouponSuccess,
    galleryCoupon: state.Coupon.galleryCoupon,
    serviceList: state.Service.serviceList,
    categoryCouponList: state.Coupon.categoryCouponList,
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {
    ...couponAction,
    ...serviceAction,
  };
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(Coupon);
