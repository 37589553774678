import { ROUTER_URL } from '../../../../constants';

export const MainNav = [
  {
    icon: 'pe-7s-home',
    label: 'Dashboard ',
    to: '#' + ROUTER_URL.DASH_BOARD,
  },
  {
    icon: 'pe-7s-config',
    label: 'Setting',
    
    content: [
      {
        // icon: 'pe-7s-pen',
        label: 'About Us',
        to: '#' + ROUTER_URL.ABOUT_US,
      },
      {
        // icon: 'pe-7s-pen',
        label: 'Account Info',
        to: '#' + ROUTER_URL.OWNER_EDIT,
      },
      {
        // icon: 'fa fa-calendar',
        label: 'Store Hours',
        to: '#' + ROUTER_URL.OWNER_SCHEDULE,
      },
      {
        // icon: 'pe-7s-users',
        label: 'Loyalty', 
        to: '#' + ROUTER_URL.SETTING_MEMBER,
      },
      {
        // icon: 'pe-7s-message',
        label: 'Sent SMS',
        to: '#' + ROUTER_URL.SETTING_SMS,
      },
      {
        // icon: 'pe-7s-rocket',
        label: 'Feedback Review',
        to: '#' + ROUTER_URL.SETTING_FEEDBACK,
      },
      {
        // icon: 'pe-7s-rocket',
        label: 'Social Chanel',
        to: '#' + ROUTER_URL.SETTING_SOCIAL,
      },
      {
        // icon: 'pe-7s-rocket',
        label: 'Notification',
        to: '#' + ROUTER_URL.SETTING_NOTIFICATION,
      },

      {
        // icon: 'pe-7s-rocket',
        label: 'Auto Sent Sms',
        to: '#' + ROUTER_URL.SETTING_AUTO_SMS,
      },
	  {
        // icon: 'pe-7s-rocket',
        label: 'Express services',
        to: '#' + ROUTER_URL.SETTING_EXPRESS_SERVICES,
      },
      // {
      //   icon: 'pe-7s-rocket',
      //   label: 'Setting Lucky Spin',
      //   to: '#' + ROUTER_URL.SETING_LUCKY_SPIN,
      // },
      
    ],
  },
  {
    icon: 'pe-7s-users',
    label: 'Staff',
    to: '#' + ROUTER_URL.STAFF,
  },
  {
    icon: 'pe-7s-photo-gallery',
    label: 'Service',
    to: '#' + ROUTER_URL.SERVICE,
  },
  {
    icon: 'pe-7s-gift',
    label: 'Coupon',
    content: [
      {
        // icon: 'pe-7s-rocket',
        label: 'Coupon List',
        to: '#' + ROUTER_URL.COUPON_LIST,
      },
      {
        // icon: 'pe-7s-rocket',
        label: 'Coupon Category',
        to: '#' + ROUTER_URL.COUPON_CATEGORY,
      },
    ],
  },
  {
    icon: 'pe-7s-gift',
    label: 'Gift Card',
    to: '#' + ROUTER_URL.GIFT_CARD,
  },
  {
    icon: 'pe-7s-speaker',
    label: 'Marketing',
    to: '#' + ROUTER_URL.MARKETTING,
  },
  {
    icon: 'pe-7s-graph3',
    label: 'Report',
    to: '#' + ROUTER_URL.REPORT,
    content: [
        {
            // icon: 'pe-7s-graph3',
            label: 'SMS Marketing',
            to: '#' + ROUTER_URL.REPORT_SMS_MARKETING,
          },
    ],
  },
  {
    icon: 'pe-7s-add-user',
    label: 'Customer',
    to: '#' + ROUTER_URL.CUSTOMER,
  },
  {
    icon: 'fa fa-calendar',
    label: 'Appointment',
    to: '#' + ROUTER_URL.DASH_BOARD,
    content: [
      {
        // icon: 'pe-7s-rocket',
        label: 'Appointment List',
        to: '#' + ROUTER_URL.APPOINTMENT_LIST,
      },
      {
        // icon: 'pe-7s-rocket',
        label: 'Appointment Scheduler',
        to: '#/appointment-detail',
      },
    ],
  },
];

export const ComponentsNav = [
  {
    icon: 'pe-7s-diamond',
    label: 'Elements',
    content: [
      {
        label: 'Standard Buttons',
        to: '#/elements/buttons-standard',
      },
      {
        label: 'Dropdowns',
        to: '#/elements/dropdowns',
      },
      {
        label: 'Icons',
        to: '#/elements/icons',
      },
      {
        label: 'Badges',
        to: '#/elements/badges-labels',
      },
      {
        label: 'Cards',
        to: '#/elements/cards',
      },
      {
        label: 'List Groups',
        to: '#/elements/list-group',
      },
      {
        label: 'Navigation Menus',
        to: '#/elements/navigation',
      },
      {
        label: 'Utilities',
        to: '#/elements/utilities',
      },
    ],
  },
  {
    icon: 'pe-7s-car',
    label: 'Components',
    content: [
      {
        label: 'Tabs',
        to: '#/components/tabs',
      },
      {
        label: 'Notifications',
        to: '#/components/notifications',
      },
      {
        label: 'Modals',
        to: '#/components/modals',
      },
      {
        label: 'Progress Bar',
        to: '#/components/progress-bar',
      },
      {
        label: 'Tooltips & Popovers',
        to: '#/components/tooltips-popovers',
      },
      {
        label: 'Carousel',
        to: '#/components/carousel',
      },
      {
        label: 'Maps',
        to: '#/components/maps',
      },
    ],
  },
  {
    icon: 'pe-7s-display2',
    label: 'Regular Tables',
    to: '#/tables/regular-tables',
  },
];
export const FormsNav = [
  {
    icon: 'pe-7s-light',
    label: 'Controls',
    to: '#/forms/controls',
  },
  {
    icon: 'pe-7s-eyedropper',
    label: 'Layouts',
    to: '#/forms/layouts',
  },
  {
    icon: 'pe-7s-pendrive',
    label: 'Validation',
    to: '#/forms/validation',
  },
];
export const WidgetsNav = [
  {
    icon: 'pe-7s-graph2',
    label: 'Dashboard Boxes',
    to: '#/widgets/dashboard-boxes',
  },
];
export const ChartsNav = [
  {
    icon: 'pe-7s-graph2',
    label: 'ChartJS',
    to: '#/charts/chartjs',
  },
];
