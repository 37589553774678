import { all, put, call, takeEvery } from 'redux-saga/effects';
import * as action from '../actions';
import API from '../../../../../api/coupon'
import * as actionDefault from '../../../container/actions'

export function* handleGetDataCoupon(payload) {
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.getCouponList, payload);
    if (response && response.data && response.data.code && response.data.code === 200) {
      yield put(action.getDataCouponSuccess(response.data.payload));
    }
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    console.log(error);
    yield put(actionDefault.isLoadingSuccess(true));
  }
}

export function* handleOnCreateCoupon(payload) {
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.onCreateCoupon, payload);
    const status = response && response.data && response.data.code && response.data.code === 200
    yield put(action.onCreateCouponSuccess(status));
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    console.log(error);
    yield put(actionDefault.isLoadingSuccess(true));
  }
}

export function* handleGetCouponById({ payload }) {
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.getDetailCouponById, payload);
    yield put(action.getDetailCouponSuccess(response.data.payload));
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    console.log(error);
    yield put(actionDefault.isLoadingSuccess(true));
  }
}

export function* handleOnUpdateCoupon(payload) {
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.onUpdateCoupon, payload);
    const status = response && response.data && response.data.code && response.data.code === 200
    yield put(action.onUpdateCouponSuccess(status));
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    console.log(error);
    yield put(actionDefault.isLoadingSuccess(true));
  }
}

export function* handleOnDeleteCoupon({ payload }) {
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.onDeleteCoupon, payload);
    const status = response && response.data && response.data.code && response.data.code === 200
    yield put(action.onDeleteCouponSuccess(status));
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    console.log(error);
    yield put(actionDefault.isLoadingSuccess(true));
  }
}

export function* handleGetGalleryCoupon() {
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.getGalleryList);
    if (response && response.data && response.data.code && response.data.code === 200) {
      yield put(action.getGalleryCouponSuccess(response.data.payload));
    }
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    console.log(error);
    yield put(actionDefault.isLoadingSuccess(true));
  }
}

export function* handleOnCreateUpdateCategoryCoupon(payload) {
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.onHandleCaterogyCoupon, payload);
    const status = response && response.data && response.data.code && response.data.code === 200
    if (status)
      yield put(action.onCreateCategoryCouponSuccess(status));
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    console.log(error);
    yield put(actionDefault.isLoadingSuccess(true));
  }
}

export function* handleGetCategoryCoupon(payload) {
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.getCaterogyCoupon, payload);
    if (response && response.data && response.data.code && response.data.code === 200)
      yield put(action.getCategoryCouponSuccess(response.data.payload));
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    console.log(error);
    yield put(actionDefault.isLoadingSuccess(true));
  }
}

export function* handleOnDeleteCategoryCoupon({ payload }) {
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.onDeleteCategoryCoupon, payload);
    const status = response && response.data && response.data.code && response.data.code === 200
    if (status)
      yield put(action.onDeleteCategoryCouponSuccess(status));
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    console.log(error);
    yield put(actionDefault.isLoadingSuccess(true));
  }
}

export function* handleGetCouponByCategoryId({ payload }) {
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.getCouponById, payload);
    const status = response && response.data && response.data.code && response.data.code === 200
    yield put(action.getCouponByCategoryIdSuccess(status ? response.data.payload : { coupons: [], smsText: '' }));
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    console.log(error);
    yield put(actionDefault.isLoadingSuccess(true));
  }
}

export default function* root() {
  yield all([
    takeEvery(action.getDataCoupon, handleGetDataCoupon),
    takeEvery(action.onCreateCoupon, handleOnCreateCoupon),
    takeEvery(action.getDetailCoupon, handleGetCouponById),
    takeEvery(action.onUpdateCoupon, handleOnUpdateCoupon),
    takeEvery(action.onDeleteCoupon, handleOnDeleteCoupon),
    takeEvery(action.getGalleryCoupon, handleGetGalleryCoupon),
    takeEvery(action.onCreateCategoryCoupon, handleOnCreateUpdateCategoryCoupon),
    takeEvery(action.getCategoryCoupon, handleGetCategoryCoupon),
    takeEvery(action.onDeleteCategoryCoupon, handleOnDeleteCategoryCoupon),
    takeEvery(action.getCouponByCategoryId, handleGetCouponByCategoryId),
  ]);
}
