import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from "react";
import { Card, CardBody, Button } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appoitmentAction from "../actions";
import * as ownerAction from "../../Owner/actions";
import * as serviceAction from "../../Service/actions";
import { isLoadingSuccess } from "../../../container/actions";
import { socketUrl } from "../../../../../config"
import Timeline, {
  TimelineHeaders,
  CustomMarker,
  CustomHeader,
} from "react-calendar-timeline";
// import "../../../../../libs/components/react-calendar-timeline/lib/Timeline.scss";
import "react-calendar-timeline/lib/Timeline.css";
import moment from "moment";
import { getRandomColor } from "../../../../../libs/utils/utils";
import cx from "classnames";
import ModalAppoitment from "./ModalAppoitment";
import { ROUTER_URL } from "../../../../../constants";
import { isEmpty } from "lodash";
import SockJS from "sockjs-client";
import { over } from "stompjs";
import ModalConfirmAppoitment from "./ModalConfirmAppoitment";
import ModalStaffSchedule from "./ModalStaffSchedule";
import Interwind from "../../../../../assets/img/Interwind.svg";
import PageTitle from "../../../container/components/PageTitle";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { isBrowser, isTablet, isMobile } from "react-device-detect";
import { ReactComponent as Invoice } from "../../../../../assets/img/invoice.svg";
import { Fragment } from "react";
import * as actionDefault from '../../../container/actions'
import * as action from '../actions';


function AppoitmentSchedule(props) {
  const [startTimeShop, setStartTimeShop] = useState("00:00");
  const [endTimeShop, setEndTimeShop] = useState("00:00");
  const [visibleTimeStart, setVisibleTimeStart] = useState(moment().valueOf());
  const [visibleTimeEnd, setVisibleTimeEnd] = useState(moment().valueOf());
  const [isOpenAppointment, setIsOpenAppointment] = useState(false);
  const [createAppoitmentSuccess, setCreateAppoitmentSuccess] = useState(0);
  const [editAppoitmentSuccess, setEditAppoitmentSuccess] = useState(0);
  const [deleteAppoitmentSuccess, setDeleteAppoitmentSuccess] = useState(0);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [listDateVisible, setListDateVisible] = useState([]);
  const [startTimeProps, setStartTimeProps] = useState();
  const [staffProps, setStaffProps] = useState();
  const [itemEditProps, setItemEditProps] = useState({});
  const [timeTemp, setTimeTemp] = useState(0);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState("Closing-time");
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [dataStaffSchedule, setDataStaffSchedule] = useState({
    group: {},
    data: [],
  });
  const [isOpenStaff, setIsOpenStaff] = useState(false);
  const [isCurrentAppointment, setIsCurrentAppointment] = useState({});
  const [zoomStaff, setZoomStaff] = useState(90);
  const [zoomCssStaff, setZoomCssStaff] = useState(1);

  const refTimeline = useRef();
  const handle = useFullScreenHandle();

  useEffect(() => {
    init_schedule(selectedDate);
  }, []);

  useEffect(() => {
    if (isCurrentAppointment.date !== undefined) {
        let current_appointment_date = moment(isCurrentAppointment.date).format("MM/DD/YYYY");
        if (current_appointment_date !==  moment(selectedDate).format("MM/DD/YYYY")) {
          setSelectedDate(current_appointment_date);
          init_schedule(current_appointment_date);
        }
    }
  }, [isCurrentAppointment]);

  const init_schedule = (selected_date) => { 
    const {
      match: { params = {} },
    } = props;
    const { apiKey } = params;
    const { actions } = props;

    onGetDataAppoitment(apiKey);
    if (!props.serviceList || !props.serviceList.length)
      actions.getServiceList({ apiKey });
    if (!props.categoryList || !props.categoryList.length) {
      actions.getCategoryList({ apiKey });
    }
    actions.getDataStaff({ apiKey });
    onGetCustomer();
    if (isEmpty(props.ownerScheduleList)) {
      actions.onGetOwnerSchedule({ apiKey });
    }
    let listDate = [moment(selected_date).toDate()];
    for (let i = 1; i < 14; i++) {
      listDate.push(moment(selected_date).add(i, 'days').toDate())
    }
    setListDateVisible(listDate);

    // Setup socket
    var stompClient = null;
    var socket = new SockJS(
      socketUrl
    );
    stompClient = over(socket);
    const keyShop = apiKey ? apiKey : localStorage.getItem('xApiKey');

    stompClient.connect({}, () => {
      stompClient.subscribe(`/topic/bookAppointmentRealtime/${keyShop}`, (res) => {
        subscribeCallback(res);
      });
    });

    function subscribeCallback(res) {
      if (res && res.body) {
        const response = JSON.parse(res.body);
        if (
          response &&
          response.body &&
          response.body.code &&
          response.body.code === 200
        ) {
          onGetDataAppoitment();
        } else {
        }
      }
    }

    // Set interval reload timeline
    const timer = window.setInterval(() => {
      setTimeTemp((prevTime) => prevTime + 1);
    }, 10000);
    return () => {
      window.clearInterval(timer);
      actions.onClearStautsAppoitment();
    };
  }

  function onGetCustomer(isNotShowLoad = false) {
    const {
      match: { params = {} },
    } = props;
    const { apiKey } = params;
    const { actions } = props;
    actions.getDataCustomer({ apiKey, isNotShowLoad });
  }

  useEffect(() => {
    const { ownerScheduleList } = props;

    const isToday = moment(moment().format("MM/DD/YYYY")).isSame(
      moment(selectedDate).format("MM/DD/YYYY")
    );
    onGetDataAppoitment();

    if (!isEmpty(ownerScheduleList)) {
      const prefix = moment(selectedDate).format("ddd").toLocaleLowerCase();
      const startTimeToday = ownerScheduleList[`${prefix}StartTime`];
      const endTimeToday = ownerScheduleList[`${prefix}EndTime`];

      setStartTimeShop(startTimeToday);
      setEndTimeShop(endTimeToday);

      const sevenTimestamp = moment(moment(selectedDate).endOf("day"))
        .subtract({ hour: 2 })
        .valueOf();
      const timeNow = moment().format("HH:mm");
      const formatTime = (date, time) =>
        moment(moment(date).format("MM/DD/YYYY") + " " + time).valueOf();
      if (
        formatTime(selectedDate, timeNow) > moment().valueOf() ||
        formatTime(selectedDate, startTimeToday) > moment().valueOf()
      ) {
        setVisibleTimeStart(formatTime(selectedDate, startTimeToday));
        setVisibleTimeEnd(
          moment(
            moment(selectedDate).format("MM/DD/YYYY") + " " + startTimeToday
          )
            .add({ hour: 2 })
            .valueOf()
        );
      } else if (
        moment(
          moment(selectedDate).format("MM/DD/YYYY") + " " + timeNow
        ).valueOf() > sevenTimestamp
      ) {
        setVisibleTimeStart(sevenTimestamp);
        setVisibleTimeEnd(moment(selectedDate).endOf("day").valueOf());
      } else {
        setVisibleTimeStart(
          moment(
            moment(selectedDate).format("MM/DD/YYYY") + " " + timeNow
          ).valueOf()
        );
        setVisibleTimeEnd(
          moment(moment(selectedDate).format("MM/DD/YYYY") + " " + timeNow)
            .add({ hour: 2 })
            .valueOf()
        );
      }
    }
  }, [selectedDate, props.ownerScheduleList]);

  useEffect(() => {
    if (startTimeProps && staffProps) setIsOpenAppointment(true);
  }, [startTimeProps, staffProps]);

  useEffect(() => {
    const {
      isCreateAppoitmentSuccess,
      isEditAppoitmentSuccess,
      isDeleteAppoitmentSuccess,
      messageError,
      actions,
    } = props;
    if (isCreateAppoitmentSuccess > createAppoitmentSuccess) {
      setIsOpenAppointment(false);
      setCreateAppoitmentSuccess(isCreateAppoitmentSuccess);
      onGetDataAppoitment();
    } else if (isCreateAppoitmentSuccess < createAppoitmentSuccess) {
      setCreateAppoitmentSuccess(isCreateAppoitmentSuccess);
      setMessage(messageError);
      setIsError(true);
    }

    if (isEditAppoitmentSuccess > editAppoitmentSuccess) {
      setIsOpenAppointment(false);
      setEditAppoitmentSuccess(isEditAppoitmentSuccess);
      onGetDataAppoitment();
    } else if (isEditAppoitmentSuccess < editAppoitmentSuccess) {
      setEditAppoitmentSuccess(isEditAppoitmentSuccess);
      setMessage(messageError);
      setIsError(true);
    }

    if (isDeleteAppoitmentSuccess > deleteAppoitmentSuccess) {
      setIsOpenAppointment(false);
      setDeleteAppoitmentSuccess(isDeleteAppoitmentSuccess);
      onGetDataAppoitment();
    } else if (isDeleteAppoitmentSuccess < deleteAppoitmentSuccess) {
      setEditAppoitmentSuccess(isDeleteAppoitmentSuccess);
      setMessage(messageError);
      setIsError(true);
    }
  }, [
    props.isCreateAppoitmentSuccess,
    props.isEditAppoitmentSuccess,
    props.isDeleteAppoitmentSuccess,
  ]);

  function onGetDataAppoitment() {
    const { actions } = props;
    const {
      match: { params = {} },
    } = props;
    const { apiKey } = params;

    const payload = {
      start_time: moment(selectedDate).format("YYYY-MM-DD"),
    };
    if (apiKey) payload.apiKey = apiKey;
    actions.getScheduleAppointment(payload);
  }

  const onTimeChange = (timeStart, timeEnd) => {
    const startDay = moment(selectedDate).startOf("day").valueOf();
    const endDay = moment(selectedDate).endOf("day").valueOf();
    if (timeStart >= startDay && timeEnd <= endDay) {
      setVisibleTimeStart(timeStart);
      setVisibleTimeEnd(timeEnd);
    } 
    // else if (timeEnd > endDay) {
    //   setSelectedDate(moment(selectedDate).add(1, "days").format("MM/DD/YYYY"));
    // } 
    // else if (
    //   timeStart < startDay &&
    //   moment(selectedDate).toDate().getTime() > moment().toDate().getTime()
    // ) {
    //   setSelectedDate(
    //     moment(selectedDate).add(-1, "days").format("MM/DD/YYYY")
    //   );
    // }
  };

  function onCanvasDoubleClick(groupId, time, e) {
    if (
      !(
        moment(time).isBefore(moment().subtract(15, "minutes")) ||
        moment(time).isBefore(
          moment(
            moment(selectedDate).format("MM/DD/YYYY") + " " + startTimeShop
          )
        ) ||
        moment(time).isAfter(
          moment(moment(selectedDate).format("MM/DD/YYYY") + " " + endTimeShop)
        )
      )
    ) {
      setStartTimeProps(moment(time).isBefore(moment()) ? new Date() : time);
      setStaffProps(groupId);
    } else {
      setMessage("Closing-time");
      setIsError(true);
    }
  }

  const showScheduleStaff = (id) => () => {
    const {
      dataAppointment: { groups, data },
    } = props;
    const startDay = moment(selectedDate).add(0, "days").format("MM/DD/YYYY");
    
    const groupSelect = groups.find((group) => group.id == id);
    const dataFilter = data.filter((item) => item.group == id).filter((itm) => startDay == moment(itm.startTime).add(0, "days").format("MM/DD/YYYY"));
    setDataStaffSchedule({ group: groupSelect, data: dataFilter });
    setIsOpenStaff(true);
  };

  const itemRenderer = ({
    item,
    itemContext,
    getItemProps,
    getResizeProps,
  }) => {
    const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
    const valueTimeNow = moment().valueOf();
	const isAnyBody = item.isAnybody ? "comming-anybody" : "comming";
    const status =
      item.end_time.valueOf() > valueTimeNow
        ? `${isAnyBody}`
        : item.status == 2
          ? "arrived"
          : item.status == 3
            ? "finished"
            : "not-comming";
    return (
      <div
        {...getItemProps({
          style: {
            backgroundColor: "transparent",
            borderColor: "transparent",
            padding: "0 4px",
          },
          onTouchStart: () => {
            setItemEditProps({ ...item, isEdit: true, status });
            setIsOpenAppointment(true);
          },
          onMouseDown: () => {
            setItemEditProps({ ...item, isEdit: true, status });
            setIsOpenAppointment(true);
          },
        })}
      >
        {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : null}

        <div className={`item-timeline ${status}`}>
          <p className="item-timeline__info">
            <i className="fa fa-clock-o" aria-hidden="true"></i>
            <span>{moment(item.start_time).format("hh:mm a")}</span> -{" "}
            <span>{moment(item.end_time).format("hh:mm a")}</span>
          </p>
          <h5>{item.customerName}</h5>
          <p>
            {item.services.map((service) => service.serviceName).join(", ")}
          </p>
          {(item.bookingType == 0 || item.bookingType == 1) && (
            <span
              className={cx("icon-type", { online: item.bookingType != 0 })}
            >
              {item.bookingType == 0 ? (
                "Call-In"
              ) : item.bookingType == 3 ? (
                <Fragment>
                  <Invoice /> Paid
                </Fragment>
              ) : (
                "Online"
              )}
            </span>
          )}
        </div>

        {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : null}
      </div>
    );
  };

  const groupRenderer = ({ group }) => {
    const titles = group.title ? group.title.split(" ") : "";
    const firstChar = group.title.substring(0, 1);
    const lastChars = titles[titles.length - 1];
    const lastChar = lastChars.substring(0, 1);
    const { staffList } = props;
    const staffSelect = staffList.find((staff) => staff.id == group.id) || {};

    return (
      <div className="custom-group" 
			style={{ fontSize: '15px', zoom: zoomCssStaff}} 
			onClick={showScheduleStaff(group.id)} 
			ref={
        (isCurrentAppointment.staff_id && isCurrentAppointment.staff_id == group.id) 
        ? 
          (element) => {if (element) {element.scrollIntoView({ behavior: 'smooth', block: "center", inline: "nearest"})}} 
        : 
          () => {}
      }
	  >
        {staffSelect.urlImage ? (
          <span
            className="icon-title"
            style={{ borderColor: getRandomColor(), width: '38px', height: '38px' }}
          >
            <img src={staffSelect.urlImage} />
          </span>
        ) : (
          <span
            className="icon-title"
            style={{ background: getRandomColor(), borderColor: "transparent" }}
          >
            {firstChar} {lastChar}
          </span>
        )}
        <p className="title mb-0">{group.title}</p>
      </div>
    );
  };

  function* range(start, end, step = 1) {
    if (end === undefined) [end, start] = [start, 0];
    for (let n = start; n <= end; n += step) yield n;
  }

  function zoomIn() {
    refTimeline.current.changeZoom(0.75);
	if (zoomStaff < 150) {
		setZoomStaff(zoomStaff + 10);
	}
  if (zoomCssStaff < 1.2) {
    setZoomCssStaff(zoomCssStaff + 0.1)
  }
  }
  function zoomOut() {
    refTimeline.current.changeZoom(1.25);
	if (zoomStaff > 90) {
		setZoomStaff(zoomStaff - 10);
	}
  if (zoomCssStaff > 0.8) {
    setZoomCssStaff(zoomCssStaff - 0.1)
  }
  }

  function refresh_appoiment() {
	onGetDataAppoitment();
  }

  useEffect(() => {
    const { group, data } = dataStaffSchedule;
    if (isEmpty(group) && isEmpty(data)) {
      setIsOpenStaff(false);
    }
  }, [dataStaffSchedule]);

  function closeStaffSchedule() {
    setDataStaffSchedule({ group: {}, data: [] });
  }

  const reportChange = useCallback(
    (state, handle) => {
      setIsFullScreen(handle.active);
    },
    [handle]
  );

  return (
    <FullScreen handle={handle} onChange={reportChange}>
      {!!props.isLoading && props.back && (
        <div className="loading-popup">
          <img src={Interwind} />
        </div>
      )}
      <Card
        className={cx("animated card-home fadeIn border-0 h-100", {
          "px-5 pb-4": props.back,
        })}
      >
        <div className="header pt-5">
          <PageTitle>
            <div className="d-flex justify-content-between align-items-center w-100">
              <h5 className="m-0">
                {isFullScreen && (
                  <Button className="mr-3" color="primary" onClick={handle.exit}>
                    Back
                  </Button>
                )}

                {"Book Appointment Calendar"}
              </h5>
              <div className="d-flex">
                {/* {(!props.match.params || !props.match.params.apiKey) && ( */}
                  <Button
                    className="mr-3"
                    onClick={() =>
                      props.history.push(ROUTER_URL.APPOINTMENT_LIST)
                    }
                    color="info"
                  >
                    Appointment List
                  </Button>
                {/* )} */}
                <Button
                  className="mr-3"
                  onClick={() => setIsOpenAppointment(true)}
                  color="primary"
                >
                  New Appointment
                </Button>
                {!isFullScreen &&
                  !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                    navigator.userAgent
                  ) && (
                    <Button
                      onClick={handle.enter}
                      color="primary"
                      id="btn-full-screen"
                    >
                      Full Screen
                    </Button>
                  )}
                <div className="btn-zoom">
                  <button onClick={zoomIn}>
                    <i className="fa fa-search-plus" aria-hidden="true"></i>
                  </button>
                  <button onClick={zoomOut}>
                    <i className="fa fa-search-minus" aria-hidden="true"></i>
                  </button>
				  <button onClick={refresh_appoiment}>
                    <i className="fa fa-refresh" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </div>
          </PageTitle>
          <PageTitle className={cx({ "py-0": props.back })}>
            <div className="header-schedule w-100">
              <div className="group-day">
                {listDateVisible.map((date, index) => {
                  // let maxIndex = listDateVisible.indexOf(
                  //   Math.max(...listDateVisible)
                  // );
                  const dateItem = moment(date)
                    // .add(index > maxIndex ? 1 : 0, "month")
                    // .set({ date });
                  return (
                    <div
                      key={index}
                      className={cx({
                        isSelected: moment(
                          moment(selectedDate).format("YYYY/MM/DD")
                        ).isSame(dateItem.format("YYYY/MM/DD")),
                      })}
                    >
                      <p
                        onClick={() =>
                          setSelectedDate(dateItem.format("MM/DD/YYYY"))
                        }
                      >
                        {dateItem.format("dd")}
                      </p>
                      <p
                        onClick={() =>
                          setSelectedDate(dateItem.format("MM/DD/YYYY"))
                        }
                      >
                        {dateItem.format("DD")}
                      </p>
                    </div>
                  );
                })}
              </div>

              <DatePicker
                className="input-datetime-appoitment"
                popperPlacement="bottom-end"
                selected={new Date(moment(selectedDate).valueOf())}
                minDate={new Date()}
                onChange={(date) => {
                    setSelectedDate(moment(date).format("MM/DD/YYYY"));
                    init_schedule(moment(date).format("MM/DD/YYYY"));
                  }
                }
              />
            </div>
          </PageTitle>
        </div>

        <div className="body-calendar">
          <CardBody className="card-body-full-margin d-flex flex-column pl-0 pt-0 mt-3 flex-1 card-appoitment">
            <Timeline
              className={cx({ isMobile: true })}
              ref={refTimeline}
              groups={props.dataAppointment.groups}
              items={props.dataAppointment.data}
              lineHeight={zoomStaff}
              canMove={false}
              canChangeGroup={false}
              canResize={false}
              timeSteps={{ hour: 0.25 }}
              itemRenderer={itemRenderer}
              groupRenderer={groupRenderer}
              onTimeChange={onTimeChange}
              onCanvasClick={onCanvasDoubleClick}
              visibleTimeStart={visibleTimeStart}
              visibleTimeEnd={visibleTimeEnd}
              itemHeightRatio={1}
            >
              {
                <TimelineHeaders
                  style={{ background: "transparent" }}
                  className=""
                >
                  <CustomHeader unit="hour">
                    {({
                      headerContext: { intervals },
                      getRootProps,
                      getIntervalProps,
                    }) => {
                      return (
                        <div {...getRootProps()}>
                          {intervals.map((interval, index) => {
                            return (
                              <div
                                key={index}
                                {...getIntervalProps({ interval })}
                                className="header-sticky-timeline"
                              >
                                <div
                                  className={cx(
                                    "sticky header-sticky-timeline__item",
                                    {
                                      bold: interval.startTime.isAfter(moment()),
                                    }
                                  )}
                                >
                                  {interval.startTime.format("h:mm a")}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      );
                    }}
                  </CustomHeader>
                </TimelineHeaders>
              }
              <CustomMarker
                date={moment(
                  moment(selectedDate).format("MM/DD/YYYY") + " " + startTimeShop
                ).valueOf()}
              >
                {({ styles, date }) => {
                  return (
                    <div
                      style={{ ...styles }}
                      className={cx("line-marker line-marker--past")}
                    >
                      <div
                        className="area-past"
                        style={{ width: styles.left }}
                      ></div>
                      <span>{moment(date).format("hh:mm a")}</span>
                    </div>
                  );
                }}
              </CustomMarker>
              <CustomMarker
                date={moment(
                  moment(selectedDate).format("MM/DD/YYYY") + " " + endTimeShop
                ).valueOf()}
              >
                {({ styles, date }) => {
                  const hour = moment(date).get("hour");
                  const width = (styles.left / hour) * (24 - hour) * 2;
                  return (
                    <div
                      style={{ ...styles }}
                      className={cx("line-marker line-marker--furture")}
                    >
                      <div
                        className="area-furture"
                        style={{ width: 10000 }}
                      ></div>
                      <span>{moment(date).format("hh:mm a")}</span>
                    </div>
                  );
                }}
              </CustomMarker>
              <CustomMarker date={moment().valueOf()}>
                {({ styles, date }) => {
                  return (
                    <div
                      style={{ ...styles }}
                      className={cx("line-marker", {
                        "d-none": !moment(selectedDate).isSame(new Date(), "day"),
                      })}
                    >
                      <div
                        className="area-past"
                        style={{ width: styles.left }}
                      ></div>
                      <span>{moment(date).format("hh:mm a")}</span>
                    </div>
                  );
                }}
              </CustomMarker>
            </Timeline>
          </CardBody>
        </div>

        {isOpenAppointment && (
          <ModalAppoitment
            isOpen={isOpenAppointment}
            setIsOpenAppointment={setIsOpenAppointment}
            serviceList={props.serviceList}
            categoryList={props.categoryList}
            dataCustomer={props.dataCustomer}
            staffList={props.staffList}
            customerList={props.customerList}
            onCreateCustomer={props.actions.onCreateCustomer}
            onEditAppoitment={props.actions.onEditAppoitment}
            onDeleteAppoitment={props.actions.onDeleteAppoitment}
            isCreateCustomerSuccess={props.isCreateCustomerSuccess}
            getDataCustomer={props.actions.getDataCustomer}
            onCreateAppoitment={props.actions.onCreateAppoitment}
            setStartTimeProps={setStartTimeProps}
            setStaffProps={setStaffProps}
            staffProps={staffProps}
            startTimeProps={startTimeProps}
            selectedDate={selectedDate}
            itemEditProps={itemEditProps}
            timeTemp={timeTemp}
            setItemEditProps={setItemEditProps}
            onGetTimeSlot={props.actions.onGetTimeSlot}
            staffTimeSlot={props.staffTimeSlot}
            match={props.match}
            endTimeShop={endTimeShop}
            startTimeShop={startTimeShop}
            onGetCustomer={onGetCustomer}
            onSetTimeSlotSuccess={props.actions.onGetTimeSlotSuccess}
            isLoading={props.actions.isLoadingSuccess}
            onGetStaff={props.actions.getDataStaff}
			      setIsCurrentAppointment={setIsCurrentAppointment}
            setVisibleTimeStart={setVisibleTimeStart}
            setVisibleTimeEnd={setVisibleTimeEnd}
			      dataAppointment={props.dataAppointment.data}
          />
        )}

        <ModalConfirmAppoitment
          isOpen={isError}
          message={message}
          onClose={() => setIsError(false)}
        />

        <ModalStaffSchedule
          isOpen={isOpenStaff}
          dataStaffSchedule={dataStaffSchedule}
          onClose={closeStaffSchedule}
        />
      </Card>
    </FullScreen>
  );
}

function mapStateToProps(state) {
  return {
    dataAppointment: state.Appoitment.dataAppointment,
    serviceList: state.Service.serviceList,
    categoryList: state.Service.categoryList,
    staffList: state.Appoitment.staffList,
    customerList: state.Appoitment.customerList,
    isCreateCustomerSuccess: state.Appoitment.isCreateCustomerSuccess,
    dataCustomer: state.Appoitment.dataCustomer,
    isCreateAppoitmentSuccess: state.Appoitment.isCreateAppoitmentSuccess,
    staffTimeSlot: state.Appoitment.staffTimeSlot,
    ownerScheduleList: state.Owner.ownerScheduleList,
    isEditAppoitmentSuccess: state.Appoitment.isEditAppoitmentSuccess,
    isDeleteAppoitmentSuccess: state.Appoitment.isDeleteAppoitmentSuccess,
    messageError: state.Appoitment.messageError,
    isLoading: state.Layout.isLoading,
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {
    ...appoitmentAction,
    ...ownerAction,
    ...serviceAction,
    isLoadingSuccess,
  };
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(AppoitmentSchedule);
