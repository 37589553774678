import freeze from 'deep-freeze';
import { handleActions } from 'redux-actions';
import * as actions from './actions';

export const name = 'Marketting';

const initialState = {
  markettingList: [],
  total: 0,
  isCreateCampainSuccess: 0,
  checkBalanceSuccess: 0,
};

export default handleActions({
  [actions.getDataMarkettingSuccess]: (state, action) => freeze({
    ...state,
    markettingList: action.payload.data,
    total: action.payload.total,
  }),
  [actions.onCreateCampainSuccess]: (state) => freeze({
    ...state,
    isCreateCampainSuccess: state.isCreateCampainSuccess + 1,
  }),
  [actions.onCheckBalanceSuccess]: (state, action) => freeze({
    ...state,
    checkBalanceSuccess: state.checkBalanceSuccess + (action.payload ? +1 : -1),
  }),
}, initialState);
