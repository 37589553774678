import React, { Component, Fragment } from 'react';
import classnames from 'classnames';

import {
  TabContent, TabPane, Nav, NavItem, NavLink,
  Row, Col, CardHeader, CardFooter,
  Card, CardBody,
  Button, ButtonGroup, Table, Input,
} from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as customerAction from './actions';
import { isEqual, isEmpty } from 'lodash';
import PageTitle from '../../container/components/PageTitle';
import Picture from '../../../../assets/img/user-empty.png';
import moment from 'moment'
import { formatPhone } from '../../../../libs/utils/utils'

class CustomerDetail extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      isCreate: true,
      expireType: 0,
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      imagePath: '',
      isOpenDelete: false,
      gender: -1,
      positionName: 'Technician',
      isLoadingDownload: false,
      isConfirmType: false,
      activeTab: '1',
      point: 0,
      balance: 0,
      spinLucky: 0,
    };
  }

  componentDidMount() {
    const {
      actions,
      match: { params: { id } }
    } = this.props;
    if (id) {
      actions.getListSignInByCustomer({ id })
      actions.getHistoryTransaction({ id })
      actions.getDetailCustomer({ id })
    }

    const {
      detailCustomer,
    } = this.props;
    
    this.setState({
      ...this.state,
      ...detailCustomer,
    });
    

  }

  componentDidUpdate(prevProps) {
    const {
      detailCustomer,
    } = this.props;
    if (!isEqual(detailCustomer, prevProps.detailCustomer)) {
      this.setState({
        ...this.state,
        ...detailCustomer,
      });
    }
  }

  onChange = ({ target: { name, value } }) => {
    this.setState({
      [name]: value,
    });
  };

  changeTypeExpire = (type) => () => this.setState({ expireType: type });

  handleChangeStartDate = (name, date) => this.setState({ [name]: date });

  handleRating = (value) => () => {
    const { ratingApply } = this.state;
    this.setState({
      ratingApply:
        ratingApply.indexOf(value) > -1
          ? [...ratingApply].filter((r) => r != value)
          : [...ratingApply, value],
    });
  };

  onChangeImage = ({ target: { files, name } }) => {
    if (files && !!files.length) {
      var reader = new FileReader();
      var file = files[0];

      reader.onload = (upload) => {
        this.setState({
          [name]: upload.target.result,
          [`${name}File`]: files,
        });
      };
      reader.readAsDataURL(file);
    }
  };

  deleteImage = (name) => () => {
    const { dataEdit } = this.state;

    this.setState({
      [name]: '',
      [`${name}File`]: '',
    });
  };

  changeDiscountType = ({ target: { name, checked } }) => {
    const { discountType } = this.state;
    let type = discountType;
    if (name == 'percentage' && discountType != 0 && checked) {
      type = 0;
    }

    if (name == 'amount' && discountType != 1 && checked) {
      type = 1;
    }

    this.setState({
      discountType: type,
    });
  };

  onHandleStaff = () => {
    const {
      id,
      isCreate,
      firstName,
      lastName,
      phone,
      email,
      imagePath,
      urlImage,
      positionName,
      gender,
    } = this.state;
    const { actions } = this.props;
    const param = {
      id: isCreate ? null : id,
      firstName,
      lastName,
      phone,
      email,
      gender,
      positionName,
      imageBase64: imagePath
        ? imagePath.replace(/^data:image\/[a-z]+;base64,/, '')
        : urlImage,
    };

    if (isCreate) {
      actions.onCreateStaff(param);
    } else actions.onUpdateStaff(param);
  };

  onHandleCustomer = () => {
    const {
      detailCustomer,
    } = this.props;

    const {
      point,
      balance,
      spinLucky
    } = this.state;
    
    const { actions } = this.props;
    const param = {
      id: detailCustomer.id,
      point,
      balance,
      spinLucky,
    };

   actions.updateDetailCustomer(param);

  };

  toggleDelete = (status) => () => {
    this.setState({
      isOpenDelete: status,
    });
  };

  deleteStaff = () => {
    const { id } = this.state;
    const { actions } = this.props;
    actions.onDeleteStaff(id);
  };

  changePosition = (positionName) => () => this.setState({ positionName });

  changeGender = ({ target: { name, checked } }) => {
    if (checked)
      this.setState({
        gender: name == 'male' ? 0 : 1,
      });
  };

  openConfirm = () => this.setState({ isConfirmType: true });


  toggle = (tab) => () => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  render() {
    const {
      detailCustomer,
      historyTransactionData,
      listSignInByCustomer,
    } = this.props;

    const{
      point, balance, spinLucky,
    } = this.state
    return (
      <Card className="animated fadeIn card-home">
        <PageTitle>
          <button
            className="btn-back"
            onClick={() => this.props.history.goBack()}
          >
            Back
            </button>
        </PageTitle>
        <CardBody className="card-body-box d-flex flex-column">
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                {!isEmpty(detailCustomer) &&
                  <div className="col-md-3">
                    <div className="card card-primary card-outline">
                      <div className="card-body box-profile">
                        <div className="text-center">
                          <img className="profile-user-img img-fluid img-circle" width="100" height="100" src={detailCustomer.imageAvatarUrl || Picture} alt="User profile picture" />
                        </div>
                        <h3 className="profile-username text-center">{detailCustomer.name}</h3>
                        <p className="text-muted text-center">{formatPhone(detailCustomer.phone)}</p>
                        <ul className="list-group list-group-unbordered mb-3">
                          <li className="list-group-item">
                            <b>Level Member</b>
                            <a className="float-right">{!isEmpty(historyTransactionData) && historyTransactionData.levelMembership}</a>
                          </li>
                          <li className="list-group-item">
                            <b>Point</b>
                            <Input className="input-radius" name = "point" value={point} onChange={this.onChange}> </Input>
                          </li>
                          <li className="list-group-item">
                            <b>Amount ($)</b>
                            <Input className="input-radius" name = "balance" value = {balance} onChange={this.onChange} ></Input>
                          </li>

                          <li className="list-group-item">
                            <b>Spin Lucky</b>
                            <Input className="float-right" name = "spinLucky" value = {spinLucky} onChange={this.onChange}></Input>
                          </li>

                        </ul>
                      
                        <button className="btn-add" onClick={this.onHandleCustomer}>
                            Save change
                    </button>

                      </div>
                      {/* /.card-body */}
                    </div>
                  </div>
                }
                {/* /.col */}
                <div className="col-md-9">
                  <Card className="main-card mb-3">
                    <CardHeader>
                      <i className="header-icon lnr-license icon-gradient bg-plum-plate"> </i>
                                History
                                <div className="btn-actions-pane-right">
                        <ButtonGroup size="sm">
                          <div className="btn-actions-pane-right">
                            <Button outline
                              className={classnames("border-0 btn-pill btn-wide btn-transition ", { active: this.state.activeTab === '1' })}
                              color="danger" onClick={this.toggle('1')}>Check In</Button>
                            <Button outline
                              className={classnames("mr-1 ml-1 btn-pill btn-wide border-0 btn-transition ", { active: this.state.activeTab === '2' })}
                              color="danger" onClick={this.toggle('2')}>Transaction</Button>
                            <Button outline
                              className={classnames("border-0 btn-pill btn-wide btn-transition ", { active: this.state.activeTab === '3' })}
                              color="danger" onClick={this.toggle('3')}>Invite Friends</Button>
                          </div>
                        </ButtonGroup>
                      </div>
                    </CardHeader>
                    <CardBody>
                      <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="1">
                          <Table className="mb-0">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Date</th>
                                <th>Time</th>
                                <th>Services</th>
                                <th>Type</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                listSignInByCustomer.map(customer => (
                                  <tr key={customer.id}>
                                    <th scope="row">{customer.id}</th>
                                    {/* <td>{moment(customer.createDate).format("MM-DD-YYYY")}</td>
                                    <td>{moment(customer.createDate).format("HH:mm")}</td> */}
                                     <td>{customer.date}</td>
                                    <td>{customer.time}</td>
                                    <td>{customer.serviceName}</td>
                                    <td>{customer.type}</td>
                                    <td><span className="btn btn-primary">
                                      <i className="fa fa-download" aria-hidden="true"></i>
                                    </span>
                                    </td>
                                  </tr>
                                ))
                              }
                            </tbody>
                          </Table>
                        </TabPane>
                        <TabPane tabId="2">
                          <Table className="mb-0">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Time</th>
                                <th>Amount</th>
                                <th>Title</th>
                                <th>Payment Type</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                historyTransactionData && historyTransactionData.transactions && historyTransactionData.transactions.map(transactions => (
                                  <tr key={transactions.id}>
                                    <th scope="row">{transactions.id}</th>
                                    <td>{transactions.timePayment}</td>
                                    <td>${transactions.price}</td>
                                    <td>{transactions.title}</td>
                                    <td>{transactions.typePayment == 1 ? 'Credit card' : transactions.typePayment == 2 ? 'Gift card' : ''}</td>
                                  </tr>
                                ))
                              }
                            </tbody>
                          </Table>
                        </TabPane>
                        <TabPane tabId="3">

                        </TabPane>
                      </TabContent>
                    </CardBody>
                  </Card>
                </div>
              </div>
            </div>
          </section>
        </CardBody>
      </Card>
    );
  }
}

function mapStateToProps(state) {
  return {
    listSignInByCustomer: state.Customer.listSignInByCustomer,
    historyTransactionData: state.Customer.historyTransactionData,
    detailCustomer: state.Customer.detailCustomer,
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {
    ...customerAction,
  };
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerDetail);
