import freeze from 'deep-freeze';
import { handleActions } from 'redux-actions';
import * as actions from './actions';
import sideBar6 from '../../../assets/styleThemes/utils/images/sidebar/city1.jpg';

export const name = 'Layout';

const initialState = {
  isLoading: 0,

  backgroundColor: 'bg-royal sidebar-text-light',
    headerBackgroundColor: 'bg-strong-bliss header-text-light',
    enableMobileMenuSmall: '',
    enableBackgroundImage: true,
    enableClosedSidebar: true,
    enableFixedHeader: true,
    enableHeaderShadow: true,
    enableSidebarShadow: true,
    enableFixedFooter: true,
    enableFixedSidebar: true,
    colorScheme: 'white',
    backgroundImage: sideBar6,
    backgroundImageOpacity: 'opacity-06',
    enablePageTitleIcon: true,
    enablePageTitleSubheading: true,
    enablePageTabsAlt: false,
};

export default handleActions({
  [actions.isLoadingSuccess]: (state, action) => freeze({
    ...state,
    isLoading: action.payload ? state.isLoading + 1 : state.isLoading - 1,
  }),
  [actions.setEnableBackgroundImage]: (state, action) => freeze({
    ...state,
    enableBackgroundImage: action.payload,
  }),
  [actions.setEnableFixedHeader]: (state, action) => freeze({
    ...state,
    enableFixedHeader: action.payload,
  }),
  [actions.setEnableHeaderShadow]: (state, action) => freeze({
    ...state,
    enableHeaderShadow: action.payload,
  }),
  [actions.setEnableSidebarShadow]: (state, action) => freeze({
    ...state,
    enableSidebarShadow: action.payload,
  }),
  [actions.setEnablePageTitleIcon]: (state, action) => freeze({
    ...state,
    enablePageTitleIcon: action.payload,
  }),
  [actions.setEnablePageTitleSubheading]: (state, action) => freeze({
    ...state,
    enablePageTitleSubheading: action.payload,
  }),
  [actions.setEnablePageTabsAlt]: (state, action) => freeze({
    ...state,
    enablePageTabsAlt: action.payload,
  }),
  [actions.setEnableFixedSidebar]: (state, action) => freeze({
    ...state,
    enableFixedSidebar: action.payload,
  }),
  [actions.setEnableClosedSidebar]: (state, action) => freeze({
    ...state,
    enableClosedSidebar: action.payload,
  }),
  [actions.setEnableMobileMenu]: (state, action) => freeze({
    ...state,
    enableMobileMenu: action.payload,
  }),
  [actions.setEnableMobileMenuSmall]: (state, action) => freeze({
    ...state,
    enableMobileMenuSmall: action.payload,
  }),
  [actions.setEnableFixedFooter]: (state, action) => freeze({
    ...state,
    enableFixedFooter: action.payload,
  }),
  [actions.setBackgroundColor]: (state, action) => freeze({
    ...state,
    backgroundColor: action.payload,
  }),
  [actions.setHeaderBackgroundColor]: (state, action) => freeze({
    ...state,
    headerBackgroundColor: action.payload,
  }),
  [actions.setColorScheme]: (state, action) => freeze({
    ...state,
    colorScheme: action.payload,
  }),
  [actions.setBackgroundImageOpacity]: (state, action) => freeze({
    ...state,
    backgroundImageOpacity: action.payload,
  }),
  [actions.setBackgroundImage]: (state, action) => freeze({
    ...state,
    backgroundImage: action.payload,
  }),
}, initialState);
