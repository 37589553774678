import { all, put, call, takeEvery } from 'redux-saga/effects';
import * as action from '../actions';
import API from '../../../../../api/customer'
import * as actionDefault from '../../../container/actions'

export function* handleGetDataCustomer(payload) {
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.getCustomerList, payload);
    if (response && response.data && response.data.code && response.data.code === 200) {
      yield put(action.getDataCustomerSuccess({ data: response.data.payload.data, total: response.data.payload.total }));
    }
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    console.log(error);
    yield put(actionDefault.isLoadingSuccess(true));
  }
}

export function* handleGetLastSignin({ payload }) {
  const { id } = payload;
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.getLastSignIn, id);
    if (response && response.data && response.data.code && response.data.code === 200) {
      if (response.data.payload.data && response.data.payload.data[0] && response.data.payload.data[0].id)
        yield put(action.getListSignInCoVidSuccess(response.data.payload.data[0]));
    }
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    console.log(error);
    yield put(actionDefault.isLoadingSuccess(true));
  }
}

export function* handleListSignInByCustomer({ payload }) {
  const { id } = payload;
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.getListSignInByCustomerApi, id);
    if (response && response.data && response.data.code && response.data.code === 200) {
      yield put(action.getListSignInByCustomerSuccess(response.data.payload.data));
    }
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    console.log(error);
    yield put(actionDefault.isLoadingSuccess(true));
  }
}

export function* handleGetHistoryTransaction({ payload }) {
  const { id } = payload;
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.getHistoryTransactionApi, id);
    if (response && response.data && response.data.code && response.data.code === 200) {
      yield put(action.getHistoryTransactionSuccess(response.data.payload));
    }
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    console.log(error);
    yield put(actionDefault.isLoadingSuccess(true));
  }
}

export function* handleGetDetailCustomer({ payload }) {
  const { id } = payload;
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.getDetailCustomerApi, id);
    if (response && response.data && response.data.code && response.data.code === 200) {
      yield put(action.getDetailCustomerSuccess(response.data.payload));
    }
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    console.log(error);
    yield put(actionDefault.isLoadingSuccess(true));
  }
}

export function* handleUpdateDetailCustomer({ payload }) {
  const data = {
    payload
  }
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.updateCustomerApi, data);
    if (response && response.data && response.data.code && response.data.code === 200) {
      yield put(action.updateDetailCustomerSuccess(response.data.payload));
    }
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    console.log(error);
    yield put(actionDefault.isLoadingSuccess(false));
  }
}

export default function* root() {
  yield all([
    takeEvery(action.getDataCustomer, handleGetDataCustomer),
    takeEvery(action.getListSignInCoVid, handleGetLastSignin),
    takeEvery(action.getListSignInByCustomer, handleListSignInByCustomer),
    takeEvery(action.getHistoryTransaction, handleGetHistoryTransaction),
    takeEvery(action.getDetailCustomer, handleGetDetailCustomer),
    takeEvery(action.updateDetailCustomer, handleUpdateDetailCustomer),
  ]);
}
