const ACTION_NAME = 'FEEDBACK/DASHBOARD';

export const GET_DATA_APPOINTMENT = `${ACTION_NAME}/GET_DATA_APPOINTMENT`;
export const GET_DATA_APPOINTMENT_SUCCESS = `${ACTION_NAME}/GET_DATA_APPOINTMENT_SUCCESS`;

export const GET_SCHEDULE_APPOINTMENT = `${ACTION_NAME}/GET_SCHEDULE_APPOINTMENT`;
export const GET_SCHEDULE_APPOINTMENT_SUCCESS = `${ACTION_NAME}/GET_SCHEDULE_APPOINTMENT_SUCCESS`;

export const GET_DATA_SERVICE = `${ACTION_NAME}/GET_DATA_SERVICE`;
export const GET_DATA_SERVICE_SUCCESS = `${ACTION_NAME}/GET_DATA_SERVICE_SUCCESS`;

export const GET_DATA_STAFF = `${ACTION_NAME}/GET_DATA_STAFF`;
export const GET_DATA_STAFF_SUCCESS = `${ACTION_NAME}/GET_DATA_STAFF_SUCCESS`;

export const GET_DATA_CUSTOMER = `${ACTION_NAME}/GET_DATA_CUSTOMER`;
export const GET_DATA_CUSTOMER_SUCCESS = `${ACTION_NAME}/GET_DATA_CUSTOMER_SUCCESS`;

export const ON_CREATE_CUSTOMER = `${ACTION_NAME}/ON_CREATE_CUSTOMER`;
export const ON_CREATE_CUSTOMER_SUCCESS = `${ACTION_NAME}/ON_CREATE_CUSTOMER_SUCCESS`;

export const ON_CREATE_APPOITMENT = `${ACTION_NAME}/ON_CREATE_APPOITMENT`;
export const ON_CREATE_APPOITMENT_SUCCESS = `${ACTION_NAME}/ON_CREATE_APPOITMENT_SUCCESS`;
export const ON_CLEAR_STATUS_APPOITMENT = `${ACTION_NAME}/ON_CLEAR_STATUS_APPOITMENT`;

export const ON_EDIT_APPOITMENT = `${ACTION_NAME}/ON_EDIT_APPOITMENT`;
export const ON_EDIT_APPOITMENT_SUCCESS = `${ACTION_NAME}/ON_EDIT_APPOITMENT_SUCCESS`;

export const ON_DELETE_APPOITMENT = `${ACTION_NAME}/ON_DELETE_APPOITMENT`;
export const ON_DELETE_APPOITMENT_SUCCESS = `${ACTION_NAME}/ON_DELETE_APPOITMENT_SUCCESS`;

export const ON_GET_TIMESLOT = `${ACTION_NAME}/ON_GET_TIMESLOT`;
export const ON_GET_TIMESLOT_SUCCESS = `${ACTION_NAME}/ON_GET_TIMESLOT_SUCCESS`;