import freeze from 'deep-freeze';
import { handleActions } from 'redux-actions';
import * as actions from './actions';

export const name = 'Staff';

const initialState = {
  staffList: [],
  staffReviewList: [],
  total: 0,
  staffDetail: {},
  createStaffSuccess: 0,
  updateStaffSuccess: 0,
  deleteStaffSuccess: 0,

  scheduleListStaff: {},
  updateScheduleStaffSuccess: 0
};

export default handleActions({
  [actions.getStaffListSuccess]: (state, action) => freeze({
    ...state,
    staffList: action.payload.listStaff,
    total: action.payload.total,
  }),
  [actions.getDetailStaffSuccess]: (state, action) => freeze({
    ...state,
    staffDetail: action.payload
  }),
  [actions.onCreateStaffSuccess]: (state, action) => freeze({
    ...state,
    createStaffSuccess: action.payload ? state.createStaffSuccess + 1 : state.createStaffSuccess - 1
  }),
  [actions.onUpdateStaffSuccess]: (state, action) => freeze({
    ...state,
    updateStaffSuccess: action.payload ? state.updateStaffSuccess + 1 : state.updateStaffSuccess - 1
  }),
  [actions.onDeleteStaffSuccess]: (state, action) => freeze({
    ...state,
    deleteStaffSuccess: action.payload ? state.deleteStaffSuccess + 1 : state.deleteStaffSuccess - 1
  }),
  [actions.getScheduleStaffSuccess]: (state, action) => freeze({
    ...state,
    scheduleListStaff: action.payload
  }),
  [actions.onUpdateScheduleStaffSuccess]: (state, action) => freeze({
    ...state,
    updateScheduleStaffSuccess: action.payload ? state.updateScheduleStaffSuccess + 1 : state.updateScheduleStaffSuccess - 1
  }),
  [actions.getDetailReviewStaffSuccess]: (state, action) => freeze({
    ...state,
    staffReviewList: action.payload,
  }),
}, initialState);
