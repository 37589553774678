import { createAction } from 'redux-actions';
import * as CONST from './constants';

export const getStaffList = createAction(CONST.GET_STAFF_LIST);
export const getStaffListSuccess = createAction(CONST.GET_STAFF_LIST_SUCCESS);
export const onCreateStaff = createAction(CONST.ON_CREATE_STAFF);
export const onCreateStaffSuccess = createAction(CONST.ON_CREATE_STAFF_SUCCESS);
export const getDetailStaff = createAction(CONST.GET_DETAIL_STAFF);
export const getDetailStaffSuccess = createAction(CONST.GET_DETAIL_STAFF_SUCCESS);
export const getDetailReviewStaff = createAction(CONST.GET_DETAIL_REVIEW_STAFF);
export const getDetailReviewStaffSuccess = createAction(CONST.GET_DETAIL_REVIEW_STAFF_SUCCESS);
export const onUpdateStaff = createAction(CONST.ON_UPDATE_STAFF);
export const onUpdateStaffSuccess = createAction(CONST.ON_UPDATE_STAFF_SUCCESS);
export const onDeleteStaff = createAction(CONST.ON_DELETE_STAFF);
export const onDeleteStaffSuccess = createAction(CONST.ON_DELETE_STAFF_SUCCESS);

export const getScheduleStaff = createAction(CONST.GET_SCHEDULE_STAFF);
export const getScheduleStaffSuccess = createAction(CONST.GET_SCHEDULE_STAFF_SUCCESS);
export const onUpdateScheduleStaff = createAction(CONST.ON_UPDATE_SCHEDULE_STAFF);
export const onUpdateScheduleStaffSuccess = createAction(CONST.ON_UPDATE_SCHEDULE_STAFF_SUCCESS);