import { ROUTER_URL } from '../../../../constants'

export const menu = [
    {
        id: 1,
        name: "Dashboard",
        route: ROUTER_URL.DASH_BOARD
    },
    {
        id: 2,
        name: "Owner",
        route: ROUTER_URL.OWNER
    },
    {
        id: 3,
        name: "Staff",
        route: ROUTER_URL.STAFF
    },
    {
        id: 4,
        name: "Service",
        route: ROUTER_URL.SERVICE
    },
    {
        id: 5,
        name: "Coupon",
        route: ROUTER_URL.COUPON
    },
    {
        id: 6,
        name: "Setting",
        route: ROUTER_URL.SETTING
    },
    {
        id: 7,
        name: "Marketting",
        route: ROUTER_URL.MARKETTING
    },
    {
        id: 8,
        name: "Report SMS Marketing",
        route: ROUTER_URL.REPORT_SMS_MARKETING
    },
    {
        id: 9,
        name: "Report",
        route: ROUTER_URL.REPORT
    },
    {
        id: 10,
        name: "Customer",
        route: ROUTER_URL.CUSTOMER
    },
    {
        id: 11,
        name: "Appointment",
        route: '/appointment-detail'
    }
]