import { apiBaseURL } from '../config';
import { request } from '../services/api';

const baseEndpoint = `${apiBaseURL}`;

function getOwnerDetail() {
  const endpoint = `${baseEndpoint}/shop/detail`;
  return request(endpoint, {
    method: 'GET',
  });
}

function onUpdateStaff(data) {
  const endpoint = `${baseEndpoint}/shop/update`;
  return request(endpoint, {
    method: 'PUT',
    data,
  });
}

function getScheduleOwner(data) {
  const endpoint = `${baseEndpoint}/shop/schedulerBooking`;
  return request(endpoint, {
    method: 'GET',
    data
  });
}

function updateScheduleOwner(data) {
  const endpoint = `${baseEndpoint}/shop/updateSchedulerBooking`;
  return request(endpoint, {
    method: 'PUT',
    data,
  });
}

function updateAboutOwner(data) {
  const endpoint = `${baseEndpoint}/shop/updateAboutUs`;
  return request(endpoint, {
    method: 'PUT',
    data,
  });
}

export default {
  getOwnerDetail,
  onUpdateStaff,
  getScheduleOwner,
  updateScheduleOwner,
  updateAboutOwner,
};
