import {
  localStoredGet, localStoredRemove, localStoredSave,
} from './localStoredService';

import * as myself from './auth';

export default myself;

export function save({ token, user }) {
  localStoredSave('token', token);
  localStoredSave('user', user);
}

export function clear() {
  localStoredRemove('user');
  localStoredRemove('token');
}

export function get() {
  return {
    token: localStoredGet('token'),
    user: localStoredGet('user'),
  };
}

export function getToken() {
  return localStoredGet('token');
}

export function getUser() {
  return localStoredGet('user');
}

// export function setUser({ user }) {
//   localStoredSet('user', user);
// }

export function getAuth() {
  return localStoredGet('auth');
}

export function clearAuth() {
  localStoredRemove('auth');
}

export function hasToken() {
  const auth = localStoredGet('auth');
  return !!(auth && auth.userName && auth.token);
}
