import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import Dashboard, {
  name as nameOfDashboard,
} from './modules/base/components/Dashboard/reducer';
import Login, {
  name as nameOfLogin,
} from './modules/base/components/Login/reducer';
import Coupon, {
  name as nameOfCoupon,
} from './modules/base/components/Coupon/reducer';
import Staff, {
  name as nameOfStaff,
} from './modules/base/components/Staff/reducer';
import Service, {
  name as nameOfService,
} from './modules/base/components/Service/reducer';
import Owner, {
  name as nameOfOwner,
} from './modules/base/components/Owner/reducer';
import Setting, {
  name as nameOfSetting,
} from './modules/base/components/Setting/reducer';
import DefautLayout, {
  name as nameOfDefault,
} from './modules/base/container/reducer';
import Marketting, {
  name as nameOfMarketting,
} from './modules/base/components/Marketting/reducer';
import Report, {
  name as nameOfReport,
} from './modules/base/components/Report/reducer';
import Customer, {
  name as nameOfCustomer,
} from './modules/base/components/Customer/reducer';
import Appoitment, {
  name as nameOfAppoitment,
} from './modules/base/components/Appoitment/reducer';
import LuckySpin, {
  name as nameOfLuckySpin,
} from './modules/base/components/LuckySpin/reducer';

import GiftCard, {
  name as nameOfGiftCard,
} from './modules/base/components/GiftCard/reducer';


const reducers = {
  [nameOfDashboard]: Dashboard,
  [nameOfLogin]: Login,
  [nameOfCoupon]: Coupon,
  [nameOfStaff]: Staff,
  [nameOfService]: Service,
  [nameOfOwner]: Owner,
  [nameOfSetting]: Setting,
  [nameOfDefault]: DefautLayout,
  [nameOfMarketting]: Marketting,
  [nameOfReport]: Report,
  [nameOfCustomer]: Customer,
  [nameOfAppoitment]: Appoitment,
  [nameOfLuckySpin]: LuckySpin,
  [nameOfGiftCard]: GiftCard,
};
const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    ...reducers,
  });
export default createRootReducer;
