import { apiBaseURL } from '../config';
import { request } from '../services/api';

const baseEndpoint = `${apiBaseURL}`;

function getCustomerList(data) {
  const searchParams = new URLSearchParams();
  Object.keys(data.payload).forEach((key) =>
    searchParams.append(key, data.payload[key])
  );
  const endpoint = `${baseEndpoint}/customer/getListByShopId?${searchParams.toString()}`;
  return request(endpoint, {
    method: 'GET',
  });
}

function getLastSignIn(id) {
  const endpoint = `${baseEndpoint}/customer/getListSignInCoVid?customerId=${id}`;
  return request(endpoint, {
    method: 'GET',
  });
}

function getListSignInByCustomerApi(id) {
  const endpoint = `${baseEndpoint}/customer/getListSignInByCustomer?customerId=${id}`;
  return request(endpoint, {
    method: 'GET',
  });
}

function getHistoryTransactionApi(id) {
  const endpoint = `${baseEndpoint}/customer/getHistoryTransaction/${id}`;
  return request(endpoint, {
    method: 'GET',
  });
}

function getDetailCustomerApi(id) {
  const endpoint = `${baseEndpoint}/customer/getDetailCustomer/${id}`;
  return request(endpoint, {
    method: 'GET',
  });
}

function updateCustomerApi(data) {
  const endpoint = `${baseEndpoint}/customer/updateCustomer/${data.payload.id}`;
  return request(endpoint, {
    method: 'PUT',
    data,
  });
}

function deleteCustomerApi(id, apiKey) {
  const endpoint = `${baseEndpoint}/customer/deleteCustomer/${id}`;
  return request(endpoint, {
    method: 'GET',
    data: { payload: { apiKey } },
  });
}

export default {
  getCustomerList,
  getLastSignIn,
  getListSignInByCustomerApi,
  getHistoryTransactionApi,
  getDetailCustomerApi,
  updateCustomerApi,
  deleteCustomerApi,
};
