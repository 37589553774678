import { apiBaseURL } from '../config';
import { request } from '../services/api';

const baseEndpoint = `${apiBaseURL}`;

function getServiceList(data) {
  const endpoint = `${baseEndpoint}/service/list`;
  return request(endpoint, {
    method: 'GET',
    data,
  });
}

function onCreateService(data) {
  const endpoint = `${baseEndpoint}/service/save`;
  return request(endpoint, {
    method: 'POST',
    data,
  });
}

function onUpdateService(data) {
  const endpoint = `${baseEndpoint}/service/update`;
  return request(endpoint, {
    method: 'PUT',
    data,
  });
}

function onDeleteService(id) {
  const endpoint = `${baseEndpoint}/service/delete/${id}`;
  return request(endpoint, {
    method: 'DELETE',
  });
}

function getCategoryList(data) {
  const endpoint = `${baseEndpoint}/service/category/list`;
  return request(endpoint, {
    method: 'GET',
    data,
  });
}

function onCreateCategory(data) {
  const endpoint = `${baseEndpoint}/service/category/save`;
  return request(endpoint, {
    method: 'POST',
    data,
  });
}

function onDeleteCategory(id) {
  const endpoint = `${baseEndpoint}/service/category/delete/${id}`;
  return request(endpoint, {
    method: 'DELETE',
  });
}


function getImageCategoryService() {
  const endpoint = `${baseEndpoint}/library_image/getImageCategoryService`;
  return request(endpoint, {
    method: 'GET',
  });
}

function getImageCategoryServiceById(data) {
  const searchParams = new URLSearchParams();
  Object.keys(data.payload).forEach((key) => searchParams.append(key, data.payload[key]));
  const endpoint = `${baseEndpoint}/library_image/getImagesLibraryServiceByCategory?${searchParams.toString()}`;
  return request(endpoint, {
    method: 'GET',
  });
}


export default {
  getServiceList,
  onCreateService,
  onUpdateService,
  onDeleteService,
  getCategoryList,
  onCreateCategory,
  onDeleteCategory,
  getImageCategoryService,
  getImageCategoryServiceById
};
