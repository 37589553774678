import { all, fork } from 'redux-saga/effects';
import dashboard from './modules/base/components/Dashboard/sagas/dashboard';
import login from './modules/base/components/Login/sagas/login';
import coupon from './modules/base/components/Coupon/sagas/coupon';
import staff from './modules/base/components/Staff/sagas/staff';
import service from './modules/base/components/Service/sagas/service';
import owner from './modules/base/components/Owner/sagas/owner';
import setting from './modules/base/components/Setting/sagas/setting';
import defautLayout from './modules/base/container/sagas/defautLayout';
import marketting from './modules/base/components/Marketting/sagas//marketting';
import report from './modules/base/components/Report/sagas/report';
import customer from './modules/base/components/Customer/sagas/customer';
import appoitment from './modules/base/components/Appoitment/sagas/appoitment';
import luckySpin from './modules/base/components/LuckySpin/sagas/luckySpin';
import giftCard from './modules/base/components/GiftCard/sagas/giftcard';

export default function* root() {
  yield all([
    fork(dashboard),
    fork(login),
    fork(coupon),
    fork(staff),
    fork(service),
    fork(owner),
    fork(setting),
    fork(defautLayout),
    fork(marketting),
    fork(report),
    fork(customer),
    fork(appoitment),
    fork(luckySpin),
    fork(giftCard),
  ]);
}
