import React, { Component, useState, useEffect, useRef } from 'react';
import {
	Modal,
	ModalBody,
	Button,
	Row,
	Col,
} from "reactstrap";
import {
	getRandomColor,
	getCompressName,
} from "../../../../../libs/utils/utils";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
// import { onGetTimeSlotApi } from '../../../../../api/appointment'
import API from '../../../../../api/appointment'

export default function ModalChooseStaff(props) {
	const inputSearch = useRef();
	const calendarRef = React.createRef();
	const scrollStaffRef = useRef();
	const [searchStaff, setSearchStaff] = useState("");
	const [verticalStaffList, setVerticalStaffList] = useState(true);
	const [isScroll, setIsScroll] = useState(false);
	const {isOpen, onClose, staffSeleted, setStaff, date, setDate, startTime, setStartTimeStaff, setIsOpenChooseService, onGetStaff, staffList, isAnyBody, setIsAnyBody } = props;

	const [ selectedDate, setSelectedDate] = useState(date);

	const handleSetStaff = (e, staff) => {
		e.preventDefault();
		setStaff(staff);
		setIsAnyBody(0);
		// open service popup
		if (setIsOpenChooseService != null) {
			setIsOpenChooseService(true);
		}
		handleClose();
	}

	const handleClose = () => {
		setDate(selectedDate)
		setSearchStaff('');
		onClose();
	}

	const handleVerticalStaffList = (isVertical) => {
		setVerticalStaffList(isVertical);
	}

	const handleSelectTime = (e, staff, time) => {
		e.preventDefault();
		e.stopPropagation();

		setStartTimeStaff(time);
		handleSetStaff(e, staff);
	}

	useEffect(() => {
		onGetStaff()
	},[selectedDate])

	useEffect(() => {
		setSelectedDate(date);
	},[date])

	useEffect(() => {
		if (inputSearch.current && !isAnyBody) {
			inputSearch.current.focus()
		}
		if (isScroll && staffSeleted && staffSeleted.id) {
			if (scrollStaffRef.current) {
				scrollStaffRef.current.scrollIntoView({ behavior: 'smooth', block: "center", inline: "nearest"});
			}
		}
		
	}, [isScroll]);

	useEffect(() => {
		setIsScroll(true)
	}, []);

	return (
		<Modal className="modal-gallery" isOpen={isOpen} centered>
			<div className="modal-header">
				<h3 className="modal-title">Choose Staff</h3>
				<button type="button" className="close" onClick={handleClose}>
					<span aria-hidden="true" style={{ fontSize: "50px"}}>×</span>
				</button>
			</div>
			<Row className="m-0 mb-3">
				<Col sm={12} id="form-search-staff" className="d-flex">
					<i className="fa fa-search"></i>
					<input
						ref={inputSearch}
						className="form-control input-keyword pl-5" 
						id="keyWord" 
						placeholder="Search staff..." 
						onChange={({ target: { value } }) => { setSearchStaff(value) }} 
					/>
					<Button className="ml-2" onClick={() => handleVerticalStaffList(false)} >
						<i className="fa fa-th staff-list-display" style={{ color: `${!verticalStaffList ? "#1e89df" : "#575757"}` }}></i>
					</Button>
					<Button className="ml-2" onClick={() => handleVerticalStaffList(true)}>
						<i className="fa fa-list-ul staff-list-display" style={{ color: `${verticalStaffList ? "#1e89df" : "#575757"}` }}></i>
					</Button>
				</Col>
			</Row>
			<Row className="m-0 mb-3">
				<Col sm={12} className="text-right">
					<DatePicker
						popperPlacement="top-end"
						selected={selectedDate}
						minDate={new Date()}
						onChange={(selectedDate) => setSelectedDate(selectedDate)}
						ref={calendarRef}
						customInput={<div style={{ fontSize: '20px', fontWeight: 'bold', color: '#000'}}>{`${moment(selectedDate).format("DD-MM-YYYY")}`}</div>}
					/>
				</Col>
			</Row>
			<ModalBody style={{
				height: '90vh',
				overflowY: 'auto'
			}}>
				<Row>
					{ staffList.length > 0  &&
						staffList.slice().sort((a, b) => {
								let name_a = a.firstName + a.lastName;
								let name_b = b.firstName + b.lastName;
								return (name_a.localeCompare(name_b));
						 	})
							.filter(
								(staff) => staff.firstName.indexOf(searchStaff) > -1 || staff.lastName.indexOf(searchStaff) > -1
							)
							.map(staff => {
								return (
									<Col 
										key={staff.id} 
										id={`staff-item-${staff.id}`}
										sm={verticalStaffList ? 12 : 4} 
										onClick={(e) => handleSetStaff(e, staff)} 
									>
										<div 
											className="select-staff-item bg-white p-3 mb-4" 
											ref={(staffSeleted && staffSeleted.id && staffSeleted.id == staff.id) ? scrollStaffRef : () => {}}
										>
											{staff.urlImage ? 
												<div className="logo-staff">
													<img class="" src={staff.urlImage} alt={staff.firstName + " " + staff.lastName}></img>
												</div>
												:
												<div className="logo-staff" style={{ background: getRandomColor() }}>
													<span>{getCompressName(staff.firstName + " " + staff.lastName)}</span>
												</div>

											}
											<div className="info-staff">
												<p>{staff.firstName} {staff.lastName}</p>
												<span>Staff</span>
											</div>
											
											{verticalStaffList ?
												(
													<div id="available-time-list">
														{staff.timeFree &&
															staff.timeFree.timeSlots &&
															!!staff.timeFree.timeSlots.length && 
															staff.timeFree.timeSlots.filter((time) =>
																	moment(
																		moment(selectedDate).format("MM/DD/YYYY") + " " + time
																	).isAfter(moment())
																	)
																	.map((time, id) => {
																		var time_selected_bg = '';
																		if (staffSeleted && staffSeleted.id == staff.id && startTime == time) {
																			time_selected_bg = '#d9d9d9';
																		}
																		
																		return (
																			<div className="time-item border-custom-modal btn-effect-click" style={{background: `${time_selected_bg}`}} onClick={(e) => handleSelectTime(e, staff, time)}>{time}</div>
																		)
																	})
															}
													</div>
												)
												: 
												''
											}
										</div>
									</Col>
								)
							})
					}
				</Row>
			</ModalBody>
		</Modal>
	)
}

