import { apiBaseURL } from '../config';
import { request } from '../services/api';

const baseEndpoint = `${apiBaseURL}`;

function getReportList(data) {
    const searchParams = new URLSearchParams();
    Object.keys(data.payload).forEach(key => searchParams.append(key, data.payload[key]));
    const endpoint = `${baseEndpoint}/giftCard/list?${searchParams.toString()}`;
    return request(endpoint, {
        method: 'GET',
    });
}

export default {
    getReportList
};
