import {
  all, put, call, takeEvery,
} from 'redux-saga/effects';
import * as action from '../actions';
import API from '../../../../../api/marketting';
import * as actionDefault from '../../../container/actions';


export function* handleGetDataMarketting(payload) {
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.getMarkettingList, payload);
    if (response && response.data && response.data.code && response.data.code === 200) {
      const { data: { payload: { data, total } } } = response;
      yield put(action.getDataMarkettingSuccess({ data, total }));
    }
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    yield put(actionDefault.isLoadingSuccess(true));
  }
}

export function* handleOnCreateCampain(payload) {
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.onCreateCampain, payload);
    if (response && response.data && response.data.code && response.data.code === 200) {
      yield put(action.onCreateCampainSuccess());
    }
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    yield put(actionDefault.isLoadingSuccess(true));
  }
}

export function* handleOnChecBalance(payload) {
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const { payload: { ids, isAllCustomer } } = payload;
    const response = yield call(API.checkBalance, { payload: { ids, isAllCustomer } });
    const status = response && response.data && response.data.code && response.data.code === 200 && response.data.payload;
    yield put(action.onCheckBalanceSuccess(status));
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    yield put(actionDefault.isLoadingSuccess(true));
  }
}

export default function* root() {
  yield all([
    takeEvery(action.getDataMarketting, handleGetDataMarketting),
    takeEvery(action.onCreateCampain, handleOnCreateCampain),
    takeEvery(action.onCheckBalance, handleOnChecBalance),
  ]);
}
