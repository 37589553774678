import React from 'react';
import { MARKETING_TYPE } from '../constants';
import moment from 'moment';

export default function MarketingType(props) {
  const { marketingType, item } = props;
    let value = item.registerDate && moment(item.registerDate).format('MM-DD-YYYY')
    if (marketingType == MARKETING_TYPE.APPOINTMENT) {
        value = item.appointmentDate
    }else if (marketingType == MARKETING_TYPE.USED_COUPON) {
        value = item.usedCouponDate
    }else if (marketingType == MARKETING_TYPE.GREATE_THAN_3_STAR) {
        value = item.reviewDate
    }else if (marketingType == MARKETING_TYPE.LESS_THAN_3_STAR) {
        value = item.reviewDate
    }else if (marketingType == MARKETING_TYPE.GIFT_CARD_PURCHASE) {
        value = item.purchaseDate
    }else if (marketingType == MARKETING_TYPE.LOYALTY_POINT) {
        value = item.totalPoint
    }
  return (
    <>
        {value}
    </>
  );
}
