import freeze from 'deep-freeze';
import { handleActions } from 'redux-actions';
import * as actions from './actions';

export const name = 'Login';

const initialState = {
  shopsData: {},
  isLoginSuccess: false,
  shopSelect: {},
  message: ''
};

export default handleActions({
  [actions.onLoginSuccess]: (state, action) => freeze({
    ...state,
    isLoginSuccess: action.payload.status,
    shopsData: action.payload.shopData,
    message: action.payload.message
  }),
  [actions.selectShopSuccess]: (state, action) => freeze({
    ...state,
    shopSelect: action.payload,
  }),
  [actions.clearDataShopSuccess]: (state, action) => freeze({
    ...state,
    shopSelect: {},
    isLoginSuccess: false
  })
}, initialState);
