import React, { useState, Fragment, useRef, useEffect, useMemo } from 'react';
import {
  ModalFooter,
  ModalHeader,
  Modal,
  FormGroup,
  Row,
  Col,
  CustomInput,
  Input,
  ModalBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import ModaCoupon from './ModaCoupon';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ROUTER_URL } from '../../../../../constants';
import { isEqual } from 'lodash';
import cx from 'classnames';
import { usePrevious } from '../../../../../libs/customHook';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

export default function ModalHandleCategory({
  isOpen,
  categoryCouponList,
  couponDataById,
  history,
  onSubmit,
  cancel,
  getListByCategoryId,
}) {
  // Validate
  const { register, handleSubmit, errors } = useForm({
    criteriaMode: 'all',
    reValidateMode: 'onSubmit',
  });

  // Custom hook
  const prevCouponDataById = usePrevious(couponDataById);

  // Ref
  const textArea = useRef();

  // State
  const [sentTime, setSentTime] = useState(new Date());
  const [smsMessage, setSmsMessage] = useState('');
  const [isNewCoupon, setIsNewCoupon] = useState(false);
  const [startNow, setStartNow] = useState(true);
  const [campaignName, setCampaignName] = useState('');
  const [categoryCouponId, setCategoryCouponId] = useState(0);
  const [timeSchedule, setTimeSchedule] = useState('');
  const [couponId, setCouponId] = useState(0);

  useEffect(() => {
    if (prevCouponDataById && !isEqual(couponDataById, prevCouponDataById)) {
      const { smsText } = couponDataById;
      setSmsMessage(smsText);
    }
  }, [couponDataById]);

  function onSubmitCampaign() {
    const payload = {
      smsMessage,
      campaignName,
      saveCampaign: true,
      sentNow: startNow,
      sentTime: startNow
        ? new Date()
        : new Date(`${moment(sentTime).format('DD/MM/YYYY')} ${timeSchedule}`),
      categoryCouponId,
      couponId,
    };
    onSubmit(payload);
  }

  const selectCategoryCoupon = (id) => () => {
    setCategoryCouponId(id);
    getListByCategoryId(id);
  };

  const addTextToMessage = (text) => () => {
    setSmsMessage(`${smsMessage + text} `);
    textArea.current.focus();
  };

  const dropdownType = useMemo(() => {
    const categorySelect = categoryCouponList.find(
      (cate) => cate.id == categoryCouponId
    );
    const isHasCategory = categoryCouponId && categorySelect;

    return (
      <DropdownToggle caret className={cx({ isSelected: isHasCategory })}>
        {isHasCategory ? categorySelect.name : 'Select Category Coupon'}
      </DropdownToggle>
    );
  }, [categoryCouponList, categoryCouponId]);

  return (
    <Fragment>
      <Modal className="modal-create-marketting" isOpen={isOpen} centered>
        <ModalHeader>New Campain</ModalHeader>
        <form onSubmit={handleSubmit(onSubmitCampaign)}>
          <ModalBody className="border-0 py-0">
            <Row>
              <Col sm={3} style={{ borderRight: '1px solid #dee2e6' }}>
                <div className="form-campain">
                  <h4>Campain Type</h4>
                  <FormGroup className="dropdown-full">
                    <UncontrolledDropdown>
                      {dropdownType}
                      {categoryCouponList && !!categoryCouponList.length && (
                        <DropdownMenu>
                          {categoryCouponList.map((category) => (
                            <DropdownItem
                              onClick={selectCategoryCoupon(category.id)}
                              key={category.id}
                            >
                              {category.name}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      )}
                    </UncontrolledDropdown>
                    <input
                      value={categoryCouponId || ''}
                      name="categoryCouponId"
                      id="form-time-select"
                      ref={register({
                        required: 'Campaign type is required.',
                      })}
                      className="d-none"
                      onChange={() => {}}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="categoryCouponId"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <p className="error-message mt-2" key={type}>
                            {message}
                          </p>
                        ))
                      }
                    />
                  </FormGroup>
                  <h4>Time Setting</h4>
                  <FormGroup className="d-flex justify-content-between">
                    <CustomInput
                      checked={startNow}
                      onChange={({ target: { checked } }) =>
                        !startNow && checked ? setStartNow(true) : null
                      }
                      type="checkbox"
                      id="start-now"
                      label="Start Now"
                    />
                    <CustomInput
                      checked={!startNow}
                      onChange={({ target: { checked } }) =>
                        startNow && checked ? setStartNow(false) : null
                      }
                      type="checkbox"
                      id="schedule"
                      label="Schedule"
                    />
                  </FormGroup>
                  <FormGroup className="dropdown-full">
                    <div className="group-dropdown-full">
                      <span>
                        <i className="fa fa-calendar-o" aria-hidden="true" />
                      </span>
                      <DatePicker
                        disabled={startNow}
                        className="form-control text-center"
                        name="startTime"
                        selected={sentTime ? moment(sentTime).toDate() : null}
                        onChange={(date) => setSentTime(date)}
                        showYearDropdown
                        dateFormatCalendar="MMMM"
                        scrollableYearDropdown
                        yearDropdownItemNumber={15}
                        dateFormat="MM/dd"
                        autoComplete="off"
                      />
                    </div>
                  </FormGroup>
                  {!startNow && (
                    <FormGroup className="dropdown-full">
                      <div className="group-dropdown-full">
                        <Input
                          className="text-center"
                          name="timeSchedule"
                          type="time"
                          value={timeSchedule}
                          onChange={({ target: { value } }) =>
                            setTimeSchedule(value)
                          }
                        />
                      </div>
                    </FormGroup>
                  )}
                </div>
              </Col>
              <Col sm={9}>
                <div className="form-campain-content">
                  <h4 className="mb-4">
                    <span>Your Message</span>
                  </h4>
                  <FormGroup className="dropdown-full position-relative">
                    <span className="quote-icon">
                      <i className="fa fa-quote-left" aria-hidden="true" />
                    </span>
                    <textarea
                      value={smsMessage}
                      ref={textArea}
                      onChange={({ target: { value } }) => setSmsMessage(value)}
                      name="smsMessage"
                      className="content-message"
                      type="textarea"
                      id="message"
                      rows="3"
                    />
                    <div className="group-btn-campain">
                      <button
                        type="button"
                        onClick={addTextToMessage('#shopName')}
                      >
                        #Shop Name
                      </button>
                      <button
                        type="button"
                        onClick={addTextToMessage('#customerName')}
                      >
                        #Customer Name
                      </button>
                      <button
                        type="button"
                        onClick={addTextToMessage('#couponLink')}
                      >
                        #Coupon Link
                      </button>
                    </div>
                  </FormGroup>

                  <h4>
                    <span>Select a Coupon</span>
                    <button
                      type="button"
                      onClick={() =>
                        history.push({ pathname: ROUTER_URL.COUPON_CREATE })
                      }
                    >
                      New Coupon
                    </button>
                  </h4>
                  <Row>
                    {couponDataById &&
                      couponDataById.coupons &&
                      !!couponDataById.coupons.length &&
                      couponDataById.coupons.map((coupon) => (
                        <Col sm={3} key={coupon.id}>
                          <div
                            className={cx('coupon-marketting', {
                              selected: couponId == coupon.id,
                            })}
                            onClick={() => setCouponId(coupon.id)}
                          >
                            <img alt="" src={coupon.imageUrl} />
                          </div>
                        </Col>
                      ))}
                  </Row>
                  <input
                    value={couponId || ''}
                    name="couponId"
                    id="form-time-select"
                    ref={register({
                      required: 'Coupon is required.',
                    })}
                    className="d-none"
                    onChange={() => {}}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="couponId"
                    render={({ messages }) =>
                      messages &&
                      Object.entries(messages).map(([type, message]) => (
                        <p className="error-message mt-2" key={type}>
                          {message}
                        </p>
                      ))
                    }
                  />
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center ml-auto">
              <button
                type="button"
                className="btn-campain btn-campain--cancel"
                onClick={cancel}
              >
                Cancel
              </button>
              <button type="button" type="submit" className="btn-campain">
                Send
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>
      <ModaCoupon isOpen={isNewCoupon} cancel={() => setIsNewCoupon(false)} />
    </Fragment>
  );
}
