import React, { Component } from 'react';
import {
    Form, FormGroup, Label, Input, Button,
} from 'reactstrap';
import logo from '../../../../../assets/img/logo-icheck1.png';

export default class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            isFocusInputEmail: false,
            isFocusInputPW: false,
        };
    }

    componentDidUpdate(prevProps) {
        const { isLoginSuccess } = this.props;
        if (isLoginSuccess != prevProps.isLoginSuccess)
            this.setState({
                isLoginPage: false
            })
    }

    onChange = (event) => {
        const { target } = event;
        const { name } = target;
        const { value } = target;
        this.setState({
            [name]: value,
        });
    }

    onFocusInput = (event) => {
        if (event.target.name === 'email') {
            this.setState({
                isFocusInputEmail: true,
            });
        } else {
            this.setState({
                isFocusInputPW: true,
            });
        }
    }

    onBlurInput = (event) => {
        if (event.target.name === 'email') {
            this.setState({
                isFocusInputEmail: false,
            });
        } else {
            this.setState({
                isFocusInputPW: false,
            });
        }
    }

    handleKeyPress = (e) => {
        if (e.keyCode === 13) {
            const { email, password } = this.state;
            this.props.onSubmitLogin({ email, password });
        }
    }

    render() {

        const { email, password, isFocusInputEmail, isFocusInputPW } = this.state;
        const { message } = this.props;


        return (
            <div className="login-form">
                <div className="text-center mb-4">
                    <a className="login-logo" href="#" style={{width: "200px"}}><img alt="icon" src={logo} /></a>
                </div>
                <h4 className="login-title mb-5"><span>Owner Log In</span></h4>
                {
                    message && <p className="error_msg">{message}</p>
                }

                <Form autoComplete="off">
                    <FormGroup className={isFocusInputEmail || email ? 'focus' : ''}>
                        <Label for="email">Email</Label>
                        <Input onKeyDown={this.handleKeyPress} onFocus={this.onFocusInput} onBlur={this.onBlurInput} onChange={this.onChange} type="email" name="email" />
                    </FormGroup>
                    <FormGroup className={isFocusInputPW || password ? 'focus' : ''}>
                        <Label for="password">Password</Label>
                        <Input onKeyDown={this.handleKeyPress} onFocus={this.onFocusInput} onChange={this.onChange} onBlur={this.onBlurInput} type="password" name="password" />
                    </FormGroup>
                    <FormGroup className="text-center">
                        <Button onClick={this.props.onSubmitLogin({ email, password })}>Sign In<i className="fas fa-sign-in-alt" />
                        </Button>
                    </FormGroup>
                </Form>
            </div>
        );
    }
}
