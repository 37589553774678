const ACTION_NAME = 'FEEDBACK/OWNER';
export const GET_GET_OWNER_LIST = `${ACTION_NAME}/GET_OWNER_LIST`;
export const GET_GET_OWNER_LIST_SUCCESS = `${ACTION_NAME}/GET_GET_OWNER_LIST_SUCCESS`;
export const GET_DETAIL_OWNER = `${ACTION_NAME}/GET_DETAIL_OWNER`;
export const GET_DETAIL_OWNER_SUCCESS = `${ACTION_NAME}/GET_DETAIL_OWNER_SUCCESS`;
export const ON_UPDATE_OWNER = `${ACTION_NAME}/ON_UPDATE_OWNER`;
export const ON_UPDATE_OWNER_SUCCESS = `${ACTION_NAME}/ON_UPDATE_OWNER_SUCCESS`;
export const ON_GET_OWNER_SCHEDULE = `${ACTION_NAME}/ON_GET_OWNER_SCHEDULE`;
export const ON_GET_OWNER_SCHEDULE_SUCCESS = `${ACTION_NAME}/ON_GET_OWNER_SCHEDULE_SUCCESS`;
export const ON_UPDATE_OWNER_SCHEDULE = `${ACTION_NAME}/ON_UPDATE_OWNER_SCHEDULE`;
export const ON_UPDATE_OWNER_SCHEDULE_SUCCESS = `${ACTION_NAME}/ON_UPDATE_OWNER_SCHEDULE_SUCCESS`;


export const ON_UPDATE_ABOUT_US = `${ACTION_NAME}/ON_UPDATE_ABOUT_US`;
export const ON_UPDATE_ABOUT_US_SUCCESS = `${ACTION_NAME}/ON_UPDATE_ABOUT_US_SUCCESS`;