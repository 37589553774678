import React, { Component } from 'react';
import {
    FormGroup, Card, CardHeader, CardBody, UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle,
    Col, Label, Input, Row, CustomInput, CardImg, Button
} from 'reactstrap';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as settingAction from '../../actions';
import { isEqual, isEmpty } from 'lodash'
import PhoneIcon from '../../../../../../assets/img/phone-icon.png'
import MailIcon from '../../../../../../assets/img/mail-icon.png'
import InputFormatPhone from 'react-phone-number-input/input'
import PageTitle from '../../../../container/components/PageTitle'

class ThanksScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            phone: '',
            email: '',
            sent: false,
            notificationList: []
        };
    }

    componentDidMount() {
        const { actions, notificationData } = this.props;
        if (!isEmpty(notificationData)) {
            this.setState({
                notificationList: notificationData
            })
        }
        else actions.getNotification()
    }

    componentDidUpdate(prevProps) {
        const { notificationData } = this.props;

        if (!isEqual(notificationData, prevProps.notificationData)) {
            this.setState({
                notificationList: notificationData
            })
        }
    }

    onChange = (index) => ({ target: { name, value } }) => {
        const { notificationList } = this.state
        const notificationChange = notificationList.map((notifyData, i) => {
            if (index == i) return {
                ...notifyData,
                [name]: value
            }
            return { ...notifyData }
        })
        this.setState({ notificationList: notificationChange })
    }

    changeSent = (index) => ({ target: { checked } }) => {
        const { notificationList } = this.state
        const notificationChange = notificationList.map((notifyData, i) => {
            if (index == i) return {
                ...notifyData,
                isSent: checked
            }
            return { ...notifyData }
        })
        this.setState({ notificationList: notificationChange })
    }

    onSave = () => {
        const { notificationList } = this.state;
        const { actions } = this.props;
        actions.onUpdateNotification({data: notificationList})
    }

    setPhone = (phone, index) => {
        const { notificationList } = this.state
        const notificationChange = notificationList.map((notifyData, i) => {
            if (index == i) return {
                ...notifyData,
                phone
            }
            return { ...notifyData }
        })
        this.setState({ notificationList: notificationChange })
    }

    addNotifyInfo = () => {
        this.setState(prevState => ({
            notificationList: [
                ...prevState.notificationList,
                {
                    phone: '',
                    email: '',
                    isSent: false,
                }]
        }))
    }

    removeNotifyInfo = (index) => () => {
        this.setState(prevState => ({
            notificationList: prevState.notificationList.filter((notify, i) => i != index)
        }))
    }

    render() {
        const { notificationList } = this.state;
        return (
            <Card className="animated fadeIn card-home">
                <PageTitle >
                    <div className="feedback-header">
                        <h4>Setup Email & Phone Number to receive notify</h4>
                        <span className="btn-add-feedback" onClick={this.addNotifyInfo}><i className="fa fa-plus" aria-hidden="true"></i></span>
                    </div>
                </PageTitle>
                <CardBody className="card-body-box d-flex flex-column">
                    {
                        notificationList.map((notify, index) => (
                            <Row className="align-items-center mb-4" key={index}>
                                <Col sm={2}>
                                    {/* <FormGroup>
                                <h4 className="title-setting mb-4 mt-5 pb-1">Sent Time</h4>
                            </FormGroup> */}
                                    <FormGroup className="social-items mb-0">
                                        <CustomInput className="custom-input-large font-bold p-0"
                                            checked={notify.isSent}
                                            onChange={this.changeSent(index)}
                                            name="sent"
                                            type="checkbox"
                                            id={`sent-${index}`}
                                            label="Immediately" />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup className="social-items mb-0">
                                        <div className="social-items-icon">
                                            <img src={PhoneIcon} />
                                        </div>
                                        <div className="social-items-input">
                                            <InputFormatPhone
                                                country="US"
                                                autoComplete="off"
                                                name="phone"
                                                value={notify.phone}
                                                onChange={e => this.setPhone(e, index)}
                                                placeholder="Phone Number" />
                                            {/* <Input value={phone} onChange={this.onChange} name="phone" placeholder="Phone Number" /> */}
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup className="social-items mb-0">
                                        <div className="social-items-icon">
                                            <img src={MailIcon} />
                                        </div>
                                        <div className="social-items-input">
                                            <Input value={notify.email} onChange={this.onChange(index)} name="email" placeholder="Email" />
                                        </div>
                                    </FormGroup>
                                </Col>
                                
                                <Col sm={2}>
                                    <span className="btn-add-feedback btn-add-feedback--remove" onClick={this.removeNotifyInfo(index)}>
                                        <i className="fa fa-minus" aria-hidden="true"></i>
                                    </span>
                                </Col>
                            </Row>
                        ))
                    }

                    <FormGroup className="text-center mt-auto">
                        <button onClick={this.onSave} className="btn-active">Save</button>
                    </FormGroup>
                </CardBody>
            </Card>
        );
    }
}

function mapStateToProps(state) {
    return {
        notificationData: state.Setting.notificationData
    };
}

function mapDispatchToProps(dispatch) {
    const actions = {
        ...settingAction,
    };
    return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(ThanksScreen);
