import React, { Component } from 'react';
import {
    FormGroup, Card, CardHeader, CardBody, UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle,
    Col, Label, Input, Row, CustomInput, CardImg, Button
} from 'reactstrap';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as settingAction from '../../actions';
import ModalDelete from './ModalDelete'
import ModalHandleQuestion from './ModalHandleQuestion'
import ModalHandleCategory from './ModalHandleCategory'
import Rating from 'react-rating'
import Star from '../../../../../../assets/img/star-review.svg'
import StarFull from '../../../../../../assets/img/star-full.svg'
import moment from 'moment'
import PageTitle from '../../../../container/components/PageTitle'

class Feedbacks extends Component {
    constructor(props) {
        super(props);
        this.state = {
            questionData: {
                stars: [],
                categoryId: 0,
                question: ''
            },
            category: {
                categoryName: "",
                description: ""
            },
            fieldDelete: '',
            isOpenDeleteQuestion: 0,
            isOpenDeleteCategory: 0,
            isOpenCategoryModal: 0,
            isOpenQuestionModal: 0
        };
    }

    componentDidMount() {
        const { actions } = this.props;
        actions.getCategoryFeedbacks()
        actions.getQuestionFeedbacks()
    }

    componentDidUpdate(prevProps) {
        const { actions, createQuestionFeedbacks, deleteQuestionFeedbacks, createCategoryFeedbacks, deleteCategoryFeedbacks } = this.props;
        if (createQuestionFeedbacks > prevProps.createQuestionFeedbacks) {
            this.setState({
                isOpenQuestionModal: 0,
                questionData: {
                    stars: [],
                    categoryId: 0,
                    question: ''
                }
            })
            actions.getQuestionFeedbacks()
        }
        if (deleteQuestionFeedbacks > prevProps.deleteQuestionFeedbacks) {
            this.setState({
                isOpenDeleteQuestion: 0
            })
            actions.getQuestionFeedbacks()
        }

        if (createCategoryFeedbacks > prevProps.createCategoryFeedbacks) {
            this.setState({
                isOpenCategoryModal: 0
            })
            actions.getCategoryFeedbacks()
        }

        if (deleteCategoryFeedbacks > prevProps.deleteCategoryFeedbacks) {
            this.setState({
                isOpenDeleteCategory: 0
            })
            actions.getCategoryFeedbacks()
        }
    }

    onChange = ({ target: { name, value } }) => this.setState({ [name]: value })

    createQuestion = (id) => () => {
        this.setState(prevState => ({
            questionData: {
                ...prevState.questionData,
                categoryId: id
            }
        }), this.toggleQuestion(1))
    }

    toggleCategory = (status) => () => this.setState({ isOpenCategoryModal: status })

    toggleQuestion = (status) => () => {
        const { questionData } = this.state;
        const emptyQuestion = {
            stars: [],
            categoryId: 0,
            question: ''
        }
        this.setState({
            isOpenQuestionModal: status,
            questionData: status == 0 ? emptyQuestion : questionData
        })
    }

    onSaveQuestion = () => {
        const { questionData } = this.state;
        const { actions } = this.props;
        actions.onCreateQuestionFeedbacks(questionData)
    }

    onChangeQuestion = ({ target: { name, value } }) => {
        this.setState(prevState => ({
            questionData: {
                ...prevState.questionData,
                [name]: value
            }
        }))
    }

    onChangeCategory = ({ target: { name, value } }) => {
        this.setState(prevState => ({
            category: {
                ...prevState.category,
                [name]: value
            }
        }))
    }

    changeCategory = (categoryId) => () => {
        this.setState(prevState => ({
            questionData: {
                ...prevState.questionData,
                categoryId
            }
        }))
    }

    changeStar = (s) => () => {
        const { questionData: { stars } } = this.state;
        this.setState(prevState => ({
            questionData: {
                ...prevState.questionData,
                stars: stars.indexOf(s) > -1 ? stars.filter(item => item != s) : [...stars, s]
            }
        }))
    }

    editQuestion = (id) => () => {
        const { questionFeedback } = this.props;
        this.setState({
            questionData: questionFeedback.filter(q => q.id == id)[0]
        }, () => this.toggleQuestion(2)())
    }

    deleteQuestion = (name, id) => () => {
        this.setState(prevState => ({
            fieldDelete: name,
            isOpenDeleteQuestion: name == 'question' ? id : prevState.isOpenDeleteQuestion,
            isOpenDeleteCategory: name == 'category' ? id : prevState.isOpenDeleteCategory
        }))
    }

    onConfirmDelete = () => {
        const { fieldDelete, isOpenDeleteCategory, isOpenDeleteQuestion } = this.state;
        const { actions } = this.props;
        if (fieldDelete == 'category')
            actions.onDeleteCategoryFeedbacks(isOpenDeleteCategory)
        else
            actions.onDeleteQuestionFeedbacks(isOpenDeleteQuestion)
    }

    cancelDelete = () => {
        this.setState({
            isOpenDeleteQuestion: 0,
            isOpenDeleteCategory: 0
        })
    }

    onSaveCategory = () => {
        const { category } = this.state;
        const { actions } = this.props;
        actions.onCreateCategoryFeedbacks(category)
    }

    render() {
        const { isOpenQuestionModal, questionData, isOpenCategoryModal, category, isOpenDeleteQuestion, fieldDelete, isOpenDeleteCategory } = this.state;
        const { categoryFeedback, questionFeedback } = this.props;
        return (
            <Card className="animated fadeIn card-home">
                <PageTitle >
                    <div className="feedback-header">
                        <h4>Feedbacks</h4>
                        <button onClick={this.toggleCategory(1)}>+ Category</button>
                    </div>
                </PageTitle>
                <CardBody className="card-body-box">
                    {
                        categoryFeedback && categoryFeedback.map(category => (
                            <FormGroup key={category.id} className="feedback-items">
                                <h5>
                                    {category.categoryName}
                                    <div className="ml-auto">
                                        <button className="mr-2" onClick={this.createQuestion(category.id)}>+ Question</button>
                                        <button onClick={this.deleteQuestion('category', category.id)}>- Category</button>
                                    </div>
                                </h5>
                                {
                                    questionFeedback && questionFeedback.filter(q => q.categoryReviewsId == category.id).sort((a, b) => a.star - b.star).map(q => (
                                        <FormGroup className="question-items" key={q.id} row>
                                            <Label sm={7}>{q.question}</Label>
                                            <Col sm={2}>
                                                <Rating
                                                    initialRating={q.star || 0}
                                                    readonly
                                                    emptySymbol={<span className="star-rating-symbol"><img src={Star} /></span>}
                                                    fullSymbol={<span className="star-rating-symbol"><img src={StarFull} /></span>}
                                                />
                                            </Col>
                                            <Label sm={2}>
                                                <span></span>
                                                {moment(q.createDate).format('MMM, DD, YYYY')}
                                            </Label>
                                            <Col sm={1} className="text-center">
                                                <button onClick={this.deleteQuestion('question', q.id)} className="btn-questions-edit">
                                                    <span><i className="fa fa-trash-o" aria-hidden="true"></i></span>
                                                </button>
                                            </Col>
                                        </FormGroup>
                                    ))
                                }
                            </FormGroup>
                        ))
                    }
                </CardBody>

                <ModalDelete
                    isOpen={fieldDelete == 'category' ? isOpenDeleteCategory : isOpenDeleteQuestion}
                    onDelete={this.onConfirmDelete}
                    fieldDelete={fieldDelete}
                    toggleDelete={this.cancelDelete} />

                <ModalHandleQuestion
                    isOpen={isOpenQuestionModal}
                    questionData={questionData}
                    category={categoryFeedback}
                    toggleModal={this.toggleQuestion}
                    onSave={this.onSaveQuestion}
                    onChange={this.onChangeQuestion}
                    changeCategory={this.changeCategory}
                    changeStar={this.changeStar} />

                <ModalHandleCategory
                    isOpen={isOpenCategoryModal}
                    category={category}
                    toggleModal={this.toggleCategory}
                    onSave={this.onSaveCategory}
                    onChange={this.onChangeCategory} />
            </Card>
        );
    }
}

function mapStateToProps(state) {
    return {
        categoryFeedback: state.Setting.categoryFeedback,
        questionFeedback: state.Setting.questionFeedback,
        createQuestionFeedbacks: state.Setting.createQuestionFeedbacks,
        deleteQuestionFeedbacks: state.Setting.deleteQuestionFeedbacks,
        createCategoryFeedbacks: state.Setting.createCategoryFeedbacks,
        deleteCategoryFeedbacks: state.Setting.deleteCategoryFeedbacks
    };
}

function mapDispatchToProps(dispatch) {
    const actions = {
        ...settingAction,
    };
    return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(Feedbacks);
