import freeze from 'deep-freeze';
import { handleActions } from 'redux-actions';
import * as actions from './actions';

export const name = 'LuckySpin';

const initialState = {
  dataLucky: [],
  updateLuckySuccess: 0,
};

export default handleActions(
  {
    [actions.getDataLuckySuccess]: (state, action) =>
      freeze({
        ...state,
        dataLucky: action.payload,
      }),
    [actions.onUpdateDataLuckySuccess]: (state, action) =>
      freeze({
        ...state,
        updateLuckySuccess: action.payload
          ? state.updateLuckySuccess + 1
          : state.updateLuckySuccess - 1,
      }),
  },
  initialState
);
