import React, { Component, Fragment } from "react";
import { FormGroup, Card, CardBody, Table } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as ownerAction from "../actions";
import moment from "moment";
import { isEqual, isEmpty } from "lodash";
import PageTitle from "../../../container/components/PageTitle";
import cx from "classnames";

class OwnerSchedule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ownerDetail: {
        address: "",
        city: "",
        companyName: "",
        email: "",
        phone: "",
        imagePath: "",
        state: "",
        zip: "",
      },
      imagePath: "",
      isLoadingDownload: false,
      isConfirmType: false,
      isLoadingDownloadSignin: false,
      ownerSchedule: {
        friEndTime: "00:00",
        friStartTime: "00:00",
        friday: 1,
        monEndTime: "00:00",
        monStartTime: "00:00",
        monday: 1,
        satEndTime: "00:00",
        satStartTime: "00:00",
        saturday: 1,
        sunEndTime: "00:00",
        sunStartTime: "00:00",
        sunday: 1,
        thuEndTime: "00:00",
        thuStartTime: "00:00",
        thursday: 1,
        tueEndTime: "00:00",
        tueStartTime: "00:00",
        tuesday: 1,
        wedEndTime: "00:00",
        wedStartTime: "00:00",
        wednesday: 1,
      },
    };
  }

  componentDidMount() {
    const { actions, ownerScheduleList } = this.props;
    actions.onGetOwnerSchedule();
    if (ownerScheduleList && !isEmpty(ownerScheduleList))
      this.setState({ ownerSchedule: ownerScheduleList });
  }

  componentDidUpdate(prevProps) {
    const { actions, ownerScheduleList, updateOwnerScheduleSuccess } =
      this.props;
    if (!isEqual(ownerScheduleList, prevProps.ownerScheduleList)) {
      this.setState({ ownerSchedule: ownerScheduleList });
    }
    if (updateOwnerScheduleSuccess > prevProps.updateOwnerScheduleSuccess) {
      actions.onGetOwnerSchedule();
    }
  }

  openConfirm = () => this.setState({ isConfirmType: true });

  formatHour = (hour, isHalf) =>
    (hour.toString().length == 1 ? "0" + hour : hour) +
    (isHalf ? ":30" : ":00");

  convertTime24to12 = (time) => {
    const hour = this.getHours(time);
    return hour > 12 ? this.formatHour(hour - 12, time.includes("30")) : time;
  };

  checkMeridiem = (time) => {
    const hour = this.getHours(time);
    return hour >= 12 && hour != 24 ? "PM" : "AM";
  };

  onChangeStateStore = ({ target: { name, value } }) => {
    const { ownerSchedule } = this.state;
    this.setState({
      ownerSchedule: {
        ...ownerSchedule,
        [name]: value,
      },
    });
  };

  getHours = (time) => {
    return time.split(":")[0];
  };

  onChangeTime = ({ target: { name, value } }) => {
    const { ownerSchedule } = this.state;
    let dataSelect = ownerSchedule[name];
    const hourChange = this.getHours(value);
    const hourPresent = this.getHours(dataSelect);

    dataSelect =
      hourPresent >= 12 && hourChange != 12 && hourPresent != 24
        ? this.formatHour(1 * hourChange + 12, value.includes("30"))
        : value;
    this.setTimeSelect(name, dataSelect);
  };

  onChangeMeridiem = ({ target: { name, value } }) => {
    const { ownerSchedule } = this.state;
    let dataSelect = ownerSchedule[name];
    const hour = this.getHours(dataSelect);

    if (
      (hour > 12 && value == "AM") ||
      (hour == 12 && value == "AM" && name.includes("StartTime")) ||
      (hour == 24 && value == "PM" && name.includes("EndTime"))
    )
      dataSelect = this.formatHour(1 * hour - 12, dataSelect.includes("30"));
    else if (
      (hour < 12 && value == "PM") ||
      (hour == 12 && value == "AM" && name.includes("EndTime"))
    )
      dataSelect = this.formatHour(1 * hour + 12, dataSelect.includes("30"));

    this.setTimeSelect(name, dataSelect);
  };

  setTimeSelect = (name, time) => {
    const { ownerSchedule } = this.state;
    const names = name.substring(0, 3);
    const state = name.substring(3, name.length);
    const nameCheck = names + (state === "EndTime" ? "StartTime" : "EndTime");
    const timeCheck = ownerSchedule[nameCheck];
    const isWrongTime =
      state === "EndTime"
        ? moment(time, "HH:mm").isBefore(moment(timeCheck, "HH:mm"))
        : moment(time, "HH:mm").isAfter(moment(timeCheck, "HH:mm"));
    this.setState({
      ownerSchedule: {
        ...ownerSchedule,
        [name]: time,
        [nameCheck]: isWrongTime ? time : timeCheck,
      },
    });
  };

  onUpdateSchedule = () => {
    const { ownerSchedule } = this.state;
    let { ownerScheduleList, actions } = this.props;
    const payload = {
      ...ownerScheduleList,
      ...ownerSchedule,
    };
    actions.onUpdateOwnerSchedule(payload);
  };

  resetSchedule = () => {
    const { ownerScheduleList } = this.props;
    this.setState({
      ownerSchedule: ownerScheduleList,
    });
  };

  render() {
    const { ownerSchedule } = this.state;

    return (
      <Card className="animated  card-home fadeIn border-0 owner-schedule">
        <PageTitle
          heading="Setting Scheduler Booking"
          // subheading="Wide selection of buttons that feature different styles for backgrounds, borders and hover options!"
          icon="fa fa-calendar icon-gradient bg-tempting-azure"
        />
        <CardBody className="card-body-box d-flex flex-column">
          {/* <h5>Setting Scheduler Booking</h5> */}

          <Table>
            <tbody>
              {[
                "monday",
                "tuesday",
                "wednesday",
                "thursday",
                "friday",
                "saturday",
                "sunday",
              ].map((day, index) => (
                <tr key={day}>
                  <th
                    className={cx("text-capitalize", {
                      "border-0": index === 0,
                    })}
                  >
                    {day}
                  </th>
                  <td className={cx({ "border-0": index === 0 })}>
                    <select
                      value={ownerSchedule[day]}
                      name={day}
                      onChange={this.onChangeStateStore}
                    >
                      {["Open", "Close"].map((e) => (
                        <option key={e} value={e == "Open" ? 1 : 0}>
                          {e}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td className={cx({ "border-0": index === 0 })}>
                    <select
                      className="mr-4"
                      name={`${day.substring(0, 3)}StartTime`}
                      value={this.convertTime24to12(
                        ownerSchedule[`${day.substring(0, 3)}StartTime`]
                      )}
                      onChange={this.onChangeTime}
                    >
                      {Array(13)
                        .fill(0)
                        .map((e, i) => {
                          return (
                            <Fragment>
                              {i != 0 && i != 12 && (
                                <>
                                  <option key={i} value={this.formatHour(i)}>
                                    {this.formatHour(i)}
                                  </option>
                                  <option
                                    key={i + "30"}
                                    value={this.formatHour(i, true)}
                                  >
                                    {this.formatHour(i, true)}
                                  </option>
                                </>
                              )}
                            </Fragment>
                          );
                        })}
                      {this.getHours(
                        ownerSchedule[`${day.substring(0, 3)}StartTime`]
                      ) < 12 && (
                        <>
                          <option value={this.formatHour(0)}>
                            {this.formatHour(12)}
                          </option>
                          <option value={this.formatHour(0, true)}>
                            {this.formatHour(12, true)}
                          </option>
                        </>
                      )}
                      {this.getHours(
                        ownerSchedule[`${day.substring(0, 3)}StartTime`]
                      ) >= 12 && (
                        <>
                          <option value={this.formatHour(12)}>
                            {this.formatHour(12)}
                          </option>
                          <option value={this.formatHour(12, true)}>
                            {this.formatHour(12, true)}
                          </option>
                        </>
                      )}
                    </select>
                    <select
                      name={day}
                      value={this.checkMeridiem(
                        ownerSchedule[`${day.substring(0, 3)}StartTime`]
                      )}
                      name={`${day.substring(0, 3)}StartTime`}
                      onChange={this.onChangeMeridiem}
                    >
                      {["AM", "PM"].map((e) => (
                        <option key={e} value={e}>
                          {e}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td className={cx({ "border-0": index === 0 })}>to</td>
                  <td className={cx({ "border-0": index === 0 })}>
                    <select
                      className="mr-4"
                      name={`${day.substring(0, 3)}EndTime`}
                      value={this.convertTime24to12(
                        ownerSchedule[`${day.substring(0, 3)}EndTime`]
                      )}
                      onChange={this.onChangeTime}
                    >
                      {Array(13)
                        .fill(0)
                        .map((e, i) => {
                          return (
                            <Fragment>
                              {i != 0 && i != 12 && (
                                <>
                                  <option key={i} value={this.formatHour(i)}>
                                    {this.formatHour(i)}
                                  </option>
                                  <option
                                    key={i + "30"}
                                    value={this.formatHour(i, true)}
                                  >
                                    {this.formatHour(i, true)}
                                  </option>
                                </>
                              )}
                            </Fragment>
                          );
                        })}
                      {this.getHours(
                        ownerSchedule[`${day.substring(0, 3)}EndTime`]
                      ) >= 12 && (
                        <>
                          <option value={this.formatHour(12)}>
                            {this.formatHour(12)}
                          </option>
                          <option value={this.formatHour(12, true)}>
                            {this.formatHour(12, true)}
                          </option>
                        </>
                      )}
                      {this.getHours(
                        ownerSchedule[`${day.substring(0, 3)}EndTime`]
                      ) < 12 && (
                        <>
                          <option value={this.formatHour(24)}>
                            {this.formatHour(12)}
                          </option>
                          <option value={this.formatHour(24, true)}>
                            {this.formatHour(12, true)}
                          </option>
                        </>
                      )}
                    </select>
                    <select
                      name={day}
                      value={this.checkMeridiem(
                        ownerSchedule[`${day.substring(0, 3)}EndTime`]
                      )}
                      name={`${day.substring(0, 3)}EndTime`}
                      onChange={this.onChangeMeridiem}
                    >
                      {["AM", "PM"].map((e) => (
                        <option key={e} value={e}>
                          {e}
                        </option>
                      ))}
                    </select>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <FormGroup className="text-center mt-auto mb-0">
            <button onClick={this.resetSchedule} className="btn-delete mx-2">
              Reset
            </button>
            <button onClick={this.onUpdateSchedule} className="btn-active mx-2">
              Save
            </button>
          </FormGroup>
        </CardBody>
      </Card>
    );
  }
}

function mapStateToProps(state) {
  return {
    ownerDetail: state.Owner.ownerDetail,
    shopSelect: state.Login.shopSelect,
    ownerScheduleList: state.Owner.ownerScheduleList,
    updateOwnerScheduleSuccess: state.Owner.updateOwnerScheduleSuccess,
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {
    ...ownerAction,
  };
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(OwnerSchedule);
