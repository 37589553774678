import React, { Component } from 'react';
import {
    Form, FormGroup, Label, Input, Button, Row, Col
} from 'reactstrap';
import Banner from '../../../../../assets/img/banner-login.jpg';

export default class ShopGallery extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidUpdate(prevProps) {

    }

    render() {

        const { shopsData } = this.props;
        const { shops } = shopsData
        return (
            <div className="shop-gallery">
                <h4>Select Shop</h4>
                <Row>
                    {
                        shops && shops.map(shop => (
                            <Col sm={4} key={shop.id}>
                                <div className="shops-item" onClick={this.props.selectShop(shop.id)}>
                                    <img src={Banner} />
                                    <div className="shops-item-meta">
                                        <h4><span>{shop.companyName}</span></h4>
                                        <p>{shop.email}</p>
                                    </div>
                                </div>
                            </Col>
                        ))
                    }
                </Row>
            </div>
        );
    }
}
