import { createAction } from 'redux-actions';
import * as CONST from './constants';

export const getDataAppointment = createAction(CONST.GET_DATA_APPOINTMENT);
export const getDataAppointmentSuccess = createAction(
  CONST.GET_DATA_APPOINTMENT_SUCCESS
);

export const getScheduleAppointment = createAction(CONST.GET_SCHEDULE_APPOINTMENT);
export const getScheduleAppointmentSuccess = createAction(
  CONST.GET_SCHEDULE_APPOINTMENT_SUCCESS
);

export const getDataService = createAction(CONST.GET_DATA_SERVICE);
export const getDataServiceSuccess = createAction(
  CONST.GET_DATA_SERVICE_SUCCESS
);

export const getDataStaff = createAction(CONST.GET_DATA_STAFF);
export const getDataStaffSuccess = createAction(
  CONST.GET_DATA_STAFF_SUCCESS
);

export const getDataCustomer = createAction(CONST.GET_DATA_CUSTOMER);
export const getDataCustomerSuccess = createAction(
  CONST.GET_DATA_CUSTOMER_SUCCESS
);

export const onCreateCustomer = createAction(CONST.ON_CREATE_CUSTOMER);
export const onCreateCustomerSuccess = createAction(
  CONST.ON_CREATE_CUSTOMER_SUCCESS
);

export const onCreateAppoitment = createAction(CONST.ON_CREATE_APPOITMENT);
export const onCreateAppoitmentSuccess = createAction(
  CONST.ON_CREATE_APPOITMENT_SUCCESS
);
export const onClearStautsAppoitment = createAction(CONST.ON_CLEAR_STATUS_APPOITMENT);


export const onEditAppoitment = createAction(CONST.ON_EDIT_APPOITMENT);
export const onEditAppoitmentSuccess = createAction(
  CONST.ON_EDIT_APPOITMENT_SUCCESS
);

export const onDeleteAppoitment = createAction(CONST.ON_DELETE_APPOITMENT);
export const onDeleteAppoitmentSuccess = createAction(
  CONST.ON_DELETE_APPOITMENT_SUCCESS
);

export const onGetTimeSlot = createAction(CONST.ON_GET_TIMESLOT);
export const onGetTimeSlotSuccess = createAction(
  CONST.ON_GET_TIMESLOT_SUCCESS
);