import React, { Component } from 'react';
import {
  ModalFooter, ModalHeader, Modal, ModalBody, Row, Col
} from 'reactstrap';


export default function ModaGallery(props) {

  const { isOpen, galleryCoupon, gallerySelected } = props
  return (
    <Modal className="modal-gallery" isOpen={isOpen} centered>
      <ModalHeader className="border-0">Select Gallery</ModalHeader>
      <ModalBody>
        <Row>
          {
            galleryCoupon.map(gallery => (
              <Col key={gallery.id} sm={3}>
                <div className={`gallery-item ${gallerySelected == gallery.id ? 'selected' : ''}`} onClick={props.onSelect(gallery.id)}>
                  <img src={gallery.urlImage} alt={`coupon-${gallery.id}`} />
                </div>
              </Col>
            ))
          }
        </Row>
      </ModalBody>
      <ModalFooter className="border-0">
        <button onClick={props.toggleModal}>Cancel</button>
      </ModalFooter>
    </Modal>
  )
}

