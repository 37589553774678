import { createAction } from 'redux-actions';
import * as CONST from './constants';

export const getDataCustomer = createAction(CONST.GET_DATA_CUSTOMER);
export const getDataCustomerSuccess = createAction(CONST.GET_DATA_CUSTOMER_SUCCESS);

export const getListSignInCoVid = createAction(CONST.GET_DATA_LAST_SIGN_IN);
export const getListSignInCoVidSuccess = createAction(CONST.GET_DATA_LAST_SIGN_IN_SUCCESS);

export const getListSignInByCustomer = createAction(CONST.GET_LIST_SIGNIN_BY_CUSTOMER);
export const getListSignInByCustomerSuccess = createAction(CONST.GET_LIST_SIGNIN_BY_CUSTOMER_SUCCESS);
export const getHistoryTransaction = createAction(CONST.GET_HISTORY_TRANSACTION);
export const getHistoryTransactionSuccess = createAction(CONST.GET_HISTORY_TRANSACTION_SUCCESS);

export const getDetailCustomer = createAction(CONST.GET_DETAIL_CUSTOMER);
export const getDetailCustomerSuccess = createAction(CONST.GET_DETAIL_CUSTOMER_SUCCESS);

export const updateDetailCustomer = createAction(CONST.UPDATE_DETAIL_CUSTOMER);
export const updateDetailCustomerSuccess = createAction(CONST.UPDATE_DETAIL_CUSTOMER_SUCCESS);