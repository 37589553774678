import React, { Component, Fragment } from 'react';
import {
  FormGroup,
  Card,
  CardHeader,
  CardBody,
  UncontrolledButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Col,
  Label,
  Input,
  Row,
  CustomInput,
  CardImg,
  Button,
  ModalBody,
  Modal,
  ModalHeader,
} from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as staffAction from '../actions';
import { EXPIRE_TYPE } from '../constants';
import Picture from '../../../../../assets/img/picture.png';
import { isEqual, isEmpty } from 'lodash';
import ModalDelete from './ModalDelete';
import Logo from '../../../../../assets/img/logo-icheck1.png';
import Icon from '../../../../../assets/img/icon-render.png';
import Banner from '../../../../../assets/img/banner-QR.jpg';
import { downloadImage } from '../../../../../libs/utils/utils';
import htmlToImage from 'html-to-image';
import Spinner from '../../../../../assets/Spinner-1sx.svg';
import PageTitle from '../../../container/components/PageTitle';

class StaffDetail extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      isCreate: true,
      expireType: EXPIRE_TYPE.FROM_TO,
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      imagePath: '',
      isOpenDelete: false,
      gender: -1,
      positionName: 'Technician',
      isLoadingDownload: false,
      isConfirmType: false,
      isReceiveSms: false
    };
  }

  componentDidMount() {
    const {
      actions,
      history: {
        location: { pathname },
      },
      staffDetail,
    } = this.props;
    if (pathname.indexOf('edit') > -1) {
      if (!isEmpty(staffDetail)) {
        this.setState({
          ...this.state,
          ...staffDetail,
        });
      }
      const id = pathname.split('/').slice(-1).pop();
      actions.getDetailStaff(id);
    }
    this.setState({
      isCreate: pathname.indexOf('edit') === -1,
    });
  }

  componentDidUpdate(prevProps) {
    const {
      staffDetail,
      createStaffSuccess,
      updateStaffSuccess,
      deleteStaffSuccess,
      history,
    } = this.props;
    if (!isEqual(staffDetail, prevProps.staffDetail)) {
      this.setState({
        ...this.state,
        ...staffDetail,
      });
    }
    if (createStaffSuccess > prevProps.createStaffSuccess)
      history.push('/staff');

    if (updateStaffSuccess > prevProps.updateStaffSuccess)
      history.push('/staff');

    if (deleteStaffSuccess > prevProps.deleteStaffSuccess)
      history.push('/staff');
  }

  onChange = ({ target: { name, value } }) => {
    this.setState({
      [name]: value,
    });
  };

  changeTypeExpire = (type) => () => this.setState({ expireType: type });

  handleChangeStartDate = (name, date) => this.setState({ [name]: date });

  handleRating = (value) => () => {
    const { ratingApply } = this.state;
    this.setState({
      ratingApply:
        ratingApply.indexOf(value) > -1
          ? [...ratingApply].filter((r) => r != value)
          : [...ratingApply, value],
    });
  };

  onChangeImage = ({ target: { files, name } }) => {
    if (files && !!files.length) {
      var reader = new FileReader();
      var file = files[0];

      reader.onload = (upload) => {
        this.setState({
          [name]: upload.target.result,
          [`${name}File`]: files,
        });
      };
      reader.readAsDataURL(file);
    }
  };

  deleteImage = (name) => () => {
    const { dataEdit } = this.state;

    this.setState({
      [name]: '',
      [`${name}File`]: '',
    });
  };

  changeDiscountType = ({ target: { name, checked } }) => {
    const { discountType } = this.state;
    let type = discountType;
    if (name == 'percentage' && discountType != 0 && checked) {
      type = 0;
    }

    if (name == 'amount' && discountType != 1 && checked) {
      type = 1;
    }

    this.setState({
      discountType: type,
    });
  };

  onHandleStaff = () => {
    const {
      id,
      isCreate,
      firstName,
      lastName,
      phone,
      email,
      imagePath,
      urlImage,
      positionName,
      gender,
      isReceiveSms
    } = this.state;
    
    const { actions } = this.props;
    const param = {
      id: isCreate ? null : id,
      firstName,
      lastName,
      phone,
      email,
      gender,
      receiveSms : isReceiveSms,
      positionName,
      imageBase64: imagePath
        ? imagePath.replace(/^data:image\/[a-z]+;base64,/, '')
        : urlImage,
    };

    if (isCreate) {
      actions.onCreateStaff(param);
    } else actions.onUpdateStaff(param);
  };

  toggleDelete = (status) => () => {
    this.setState({
      isOpenDelete: status,
    });
  };

  deleteStaff = () => {
    const { id } = this.state;
    const { actions } = this.props;
    actions.onDeleteStaff(id);
  };

  changePosition = (positionName) => () => this.setState({ positionName });

  changeGender = ({ target: { name, checked } }) => {
    if (checked)
      this.setState({
        gender: name == 'male' ? 0 : 1,
      });
  };

  changeSmsReceiveBooking = ({ target: {checked } }) => {
    console.log("changeSmsReceiveBooking", checked);
      this.setState({
        isReceiveSms: checked
      });
  };

  renderQR = (type) => () => {
    this.setState({ isLoadingDownload: true, isConfirmType: false });
    var node = document.getElementById(
      type ? 'qr-banner-share-mini' : 'qr-banner-share'
    );
    if (node) {
      htmlToImage
        .toPng(node)
        .then(() => {
          htmlToImage
            .toPng(node)
            .then((dataUrl) => {
              this.setState({
                isLoadingDownload: false,
              });
              downloadImage(dataUrl);
            })
            .catch(function (error) {
              console.error('oops, something went wrong!', error);
              this.setState({ isLoadingDownload: false });
            });
        })
        .catch(function (error) {
          console.error('oops, something went wrong!', error);
          this.setState({ isLoadingDownload: false });
        });
    }
  };

  openConfirm = () => this.setState({ isConfirmType: true });

  render() {
    const {
      isCreate,
      firstName,
      lastName,
      email,
      phone,
      imagePath,
      urlImage,
      isOpenDelete,
      positionName,
      gender,
      qrCodeReviewBase64,
      isLoadingDownload,
      isConfirmType,
      isReceiveSms
    } = this.state;
    const { shopSelect } = this.props;
    return (
      <Fragment>
        <div className="qr-banner-share-container">
          <div
            id="qr-banner-share"
            style={{ background: `url(${Banner})` }}
            ref={this.myRef}
          >
            <div className="qr-banner-share-top">
              {shopSelect && <h4>{shopSelect.companyName}</h4>}
              <div className="qr-description">
                <p>
                  Your Vital Review <br /> is helping to imporve my <br />{' '}
                  performance for your next visit!
                </p>
                <span>
                  <img src={Logo} />
                </span>
              </div>
              <div className="icon-middle">
                <img src={Icon} />
              </div>
              <p className="qr-scan-text">
                <span>
                  <i className="fa fa-mobile" aria-hidden="true"></i>
                </span>{' '}
                Scan Me
              </p>
              <div className="qr-banner">
                <img src={`data:image/jpeg;base64,${qrCodeReviewBase64}`} />
              </div>
              <p className="qr-content">
                We apprreciate your time and look forward to serving you again!
              </p>
            </div>
            <div className="qr-banner-share-bottom">
              <div className="qr-info">
                <p>
                  {firstName} {lastName}
                </p>
              </div>
              <div className="qr-end">
                <span>Thank You</span>
              </div>
            </div>
          </div>

          <div
            id="qr-banner-share-mini"
            style={{ background: `url(${Banner})` }}
            ref={this.myRef}
          >
            <div className="qr-banner-share-top">
              {shopSelect && <h4>{shopSelect.companyName}</h4>}
              <div className="qr-description">
                <p>
                  Your Vital Review <br /> is helping to imporve my <br />{' '}
                  performance for your next visit!
                </p>
                <span>
                  <img src={Logo} />
                </span>
              </div>
              <div className="icon-middle">
                <img src={Icon} />
              </div>
              <p className="qr-scan-text">
                <span>
                  <i className="fa fa-mobile" aria-hidden="true"></i>
                </span>{' '}
                Scan Me
              </p>
              <div className="qr-banner">
                <img src={`data:image/jpeg;base64,${qrCodeReviewBase64}`} />
              </div>
              <p className="qr-content">
                We apprreciate your time and look forward to serving you again!
              </p>
            </div>
            <div className="qr-banner-share-bottom">
              <div className="qr-info">
                <p>
                  {firstName} {lastName}
                </p>
              </div>
              <div className="qr-end">
                <span>Thank You</span>
              </div>
            </div>
          </div>
        </div>
        <Card className="animated fadeIn card-home">
          <PageTitle>
            <button
              className="btn-back"
              onClick={() => this.props.history.goBack()}
            >
              Back
            </button>
          </PageTitle>
          <CardBody className="card-body-box d-flex flex-column">
          <FormGroup row className="row-coupon">
              <Label sm={2}>Receive Sms Booking</Label>
              <Col sm={10}>
                  <CustomInput
                    className="custom-input-large font-bold pl-0"
                    checked={isReceiveSms}
                    onChange={this.changeSmsReceiveBooking}
                    name="receiveSms"
                    type="checkbox"
                    id="receiveSms"
                    label="Sms Receive Booking (Sms appointment reminder will send out < 3hour & > 3hour )"
                  />
              </Col>
            </FormGroup>
            <FormGroup row className="row-coupon">
              <Label sm={2}>First Name</Label>
              <Col sm={7}>
                <Input
                  value={firstName || ''}
                  name="firstName"
                  onChange={this.onChange}
                  className="input-radius"
                  placeholder="Enter first name"
                  autoComplete="off"
                />
              </Col>
            </FormGroup>
            <FormGroup row className="row-coupon">
              <Label sm={2}>Last Name</Label>
              <Col sm={7}>
                <Input
                  value={lastName || ''}
                  name="lastName"
                  onChange={this.onChange}
                  className="input-radius"
                  placeholder="Enter last name"
                  autoComplete="off"
                />
              </Col>
            </FormGroup>
            <FormGroup row className="row-coupon">
              <Label sm={2}>Email</Label>
              <Col sm={7}>
                <Input
                  value={email || ''}
                  name="email"
                  onChange={this.onChange}
                  className="input-radius"
                  placeholder="Enter email"
                  autoComplete="off"
                />
              </Col>
            </FormGroup>
            <FormGroup row className="row-coupon">
              <Label sm={2}>Phone</Label>
              <Col sm={7}>
                <Input
                  value={phone || ''}
                  name="phone"
                  onChange={this.onChange}
                  className="input-radius"
                  placeholder="Enter phone"
                  autoComplete="off"
                />
              </Col>
            </FormGroup>
            <FormGroup row className="row-coupon">
              <Label sm={2}>Position</Label>
              <Col sm={4}>
                <UncontrolledButtonDropdown>
                  <DropdownToggle caret className="w-100 m-0" color="warning">
                    {positionName}
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={this.changePosition('Admin')}>
                      Admin
                    </DropdownItem>
                    <DropdownItem onClick={this.changePosition('Technician')}>
                      Technician
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
              </Col>
            </FormGroup>
            <FormGroup row className="row-coupon">
              <Label sm={2}>Gender</Label>
              <Col sm={4}>
                <Row>
                  <Col sm={6}>
                    <CustomInput
                      className="custom-input-large font-bold pl-0"
                      checked={gender == 0}
                      onChange={this.changeGender}
                      name="male"
                      type="checkbox"
                      id="male"
                      label="Male"
                    />
                  </Col>
                  <Col sm={6}>
                    <CustomInput
                      className="custom-input-large font-bold pl-0"
                      checked={gender == 1}
                      onChange={this.changeGender}
                      name="female"
                      type="checkbox"
                      id="female"
                      label="Female"
                    />
                  </Col>
                </Row>
              </Col>
            </FormGroup>
            <FormGroup row className="row-coupon align-items-start">
              <Label sm={2}>Image</Label>
              <Col sm={4}>
                <div className="file-button-none square">
                  <Label className="mb-0" htmlFor="imagePath">
                    <Input
                      hidden
                      name="imagePath"
                      id="imagePath"
                      onChange={this.onChangeImage}
                      onClick={(event) => (event.target.value = null)}
                      type="file"
                      accept="image/*"
                    />
                    <CardImg
                      src={
                        imagePath ? imagePath : urlImage ? urlImage : Picture
                      }
                      id="img_upload_id"
                    />
                  </Label>
                  {urlImage && (
                    <Button
                      className="file-btn-delete"
                      onClick={this.deleteImage('urlImage')}
                    >
                      <i className="fa fa-times-circle" aria-hidden="true"></i>
                    </Button>
                  )}
                </div>
              </Col>
            </FormGroup>
            <FormGroup row className="row-coupon mb-5">
              <Col sm={{ offset: 2, size: 4 }}>
                <button
                  className="btn-qr-code"
                  onClick={!isLoadingDownload ? this.openConfirm : null}
                >
                  <span className={isLoadingDownload ? 'is-loading' : ''}>
                    <img src={Spinner} />
                  </span>
                  <span>Download QRCode</span>
                </button>
              </Col>
            </FormGroup>

            <FormGroup className="text-center mt-auto mb-0">
              {!isCreate && (
                <button
                  onClick={this.toggleDelete(true)}
                  className="btn-delete mx-2"
                >
                  Delete Staff
                </button>
              )}
              <button onClick={this.onHandleStaff} className="btn-active mx-2">
                Save Staff
              </button>
            </FormGroup>
          </CardBody>
        </Card>
        <ModalDelete
          isOpen={isOpenDelete}
          onDelete={this.deleteStaff}
          toggleDelete={this.toggleDelete}
        />

        <Modal className="modal-confirm" isOpen={isConfirmType} centered>
          <ModalHeader className="border-0">
            Select the size you want to download
          </ModalHeader>
          <ModalBody className="border-0 p-0">
            <CustomInput
              onClick={this.renderQR(0)}
              type="radio"
              id="confirm-A4"
              name="confirm-size"
              label="A4"
            />
            <CustomInput
              onClick={this.renderQR(1)}
              type="radio"
              id="confirm-46"
              name="confirm-size"
              label="4x6 inches"
            />
          </ModalBody>
        </Modal>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    staffDetail: state.Staff.staffDetail,
    createStaffSuccess: state.Staff.createStaffSuccess,
    updateStaffSuccess: state.Staff.updateStaffSuccess,
    deleteStaffSuccess: state.Staff.deleteStaffSuccess,
    shopSelect: state.Login.shopSelect,
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {
    ...staffAction,
  };
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(StaffDetail);
