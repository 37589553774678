import React, { Component } from 'react';
import {
  FormGroup, Card, CardHeader, CardBody, CardFooter, UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle,
  Col, Label, Input, Collapse
} from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as dashboardAction from '../actions';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from 'react-datepicker';
import moment from 'moment'
import PaginationFooter from '../../../../../libs/components/PaginationFooter'
import Star from '../../../../../assets/img/star.svg'
import {
  isMobileOnly
} from "react-device-detect";
import Rating from 'react-rating'
import { ROUTER_URL } from '../../../../../constants';
import { AiOutlineCaretRight } from 'react-icons/all'
import { formatPhone } from '../../../../../libs/utils/utils'


class BadReviewsSocial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      channelName: '',
      phone: '',
      startTime: '',
      endTime: '',
      page: 1,
      isOpen: 0,
      pageSize: 10
    };
  }

  componentDidMount() {
    const { history: { location: { pathname } } } = this.props;
    const listPath = pathname.split('/');
    const title = listPath.pop();
    this.setState({
      channelName: title.toUpperCase()
    }, () => this.onInit())
  }

  onInit = () => {
    const { startTime, endTime, page, phone, pageSize, channelName } = this.state;

    const { actions } = this.props;
    this.setState({
      isOpen: 0
    })
    const payload = {
      startTime: startTime ? moment(startTime).format('YYYY-MM-DD hh:mm:ss') : '',
      endTime: endTime ? moment(endTime).format('YYYY-MM-DD hh:mm:ss') : '',
      page,
      pageSize,
      phone,
      channelName
    }
    actions.getReviewSocial(payload)
  }

  onChange = ({ target: { name, value } }) => this.setState({ [name]: value })

  onChangePagination = (page) => () => {
    this.setState({
      page
    }, () => this.onInit())
  }

  onSearch = () => this.setState({ page: 1 }, () => this.onInit())

  handleChangeDate = (name, date) => {
    this.setState({
      [name]: date
    })
  }

  changePageSize = (pageSize) => () => this.setState({ pageSize, page: 1 }, () => this.onInit())

  render() {
    const { startTime, endTime, phone, page, isOpen, pageSize, channelName } = this.state
    const { badReviews, history } = this.props;
    const { total, data } = badReviews
    return (
      <Card className="animated fadeIn card-reviews pt-0 mb-3">
        <CardHeader>
          <h4>{channelName} REVIEW</h4>
          <button className="btn-back color-inverse ml-auto" onClick={() => history.push(ROUTER_URL.DASH_BOARD)}>Back</button>
        </CardHeader>
        <CardBody>
          <FormGroup className="d-flex flex-wrap mb-4">
            <div className="form-search-reviews">
              <Input className="w-20 search-phone" value={phone} onChange={this.onChange} name="phone" placeholder="Phone" />
              <DatePicker
                className="input-reviews-time"
                name="startTime"
                selected={startTime ? moment(startTime).toDate() : ''}
                maxDate={endTime ? moment(endTime).toDate() : ''}
                onChange={date => this.handleChangeDate('startTime', date)}
                showYearDropdown
                dateFormatCalendar="MMMM"
                scrollableYearDropdown
                yearDropdownItemNumber={15}
                placeholderText="From"
                dateFormat="MM/dd"
                autoComplete="off"
              />
              <span className="mx-3">-</span>
              <DatePicker
                className="input-reviews-time"
                name="endTime"
                selected={endTime ? moment(endTime).toDate() : ''}
                minDate={startTime ? moment(startTime).toDate() : ''}
                onChange={date => this.handleChangeDate('endTime', date)}
                showYearDropdown
                dateFormatCalendar="MMMM"
                scrollableYearDropdown
                yearDropdownItemNumber={15}
                placeholderText="To"
                dateFormat="MM/dd"
                autoComplete="off"
              />
              <button onClick={this.onSearch}>Search</button>
            </div>
          </FormGroup>

          <div className="form-group-reviews">
            <div className="header-reviews">
              <div className="w-15 hidden-xs">Date and Time</div>
              <div className="w-15">Customer</div>
              <div className="w-10 hidden-xs">Phone</div>
              <div className="w-15 hidden-xs">Review Status</div>
              <div className="w-20 hidden-xs">Description</div>
              <div className="w-10">Coupon</div>
              <div className="w-15">Owner/Staff</div>
            </div>
            <div className="body-reviews">
              {
                data && data.map(item => (
                  <div key={item.reviewsId}>
                    <div className="row-reviews" onClick={() => isMobileOnly ? this.setState({ isOpen: isOpen != item.reviewsId ? item.reviewsId : 0 }) : null}>
                      <div className="row-content">
                        <span className={`icon-xs ${isOpen == item.reviewsId ? 'rotate' : ''}`}><AiOutlineCaretRight /></span>
                        <div className="hidden-xs">{item.strReviewDate}</div>
                        <div>{item.customerName}</div>
                        <div className="hidden-xs">{formatPhone(item.customerPhone)}</div>
                        <div className="hidden-xs">
                          <Rating
                            initialRating={item.star || 0}
                            readonly
                            emptySymbol={<span className="star-rating-small"></span>}
                            fullSymbol={<span className="star-rating-small"><img src={Star} /></span>}
                          />
                        </div>
                        <div className="hidden-xs">
                          {
                            item.questions ?
                              <p dangerouslySetInnerHTML={{ __html: item.questions.join("<br> ") }}></p> :
                              <p>{item.star == 4 ? 'Perfect' : item.star == 5 ? 'Excellent' : ''}</p>
                          }
                        </div>
                        <div>{item.couponNumber}</div>
                        <div>{item.staffName}</div>
                      </div>
                      <div className="row-collapse">
                        <Collapse isOpen={isOpen == item.reviewsId}>
                          <div className="row-xs-reviews">
                            <div><b>Time</b><p>{moment(item.reviewDate).format("MM/DD/YYYY")}</p></div>
                            <div><b>Phone</b><p>{formatPhone(item.customerPhone)}</p></div>
                            <div>
                              <b>Review Status</b>
                              <p>
                                <Rating
                                  initialRating={item.star || 0}
                                  readonly
                                  emptySymbol={<span className="star-rating-small"></span>}
                                  fullSymbol={<span className="star-rating-small"><img src={Star} /></span>}
                                />
                              </p>
                            </div>
                            <div><b>Description</b><p dangerouslySetInnerHTML={{ __html: item.questions && item.questions.join("<br> ") }}></p></div>
                          </div>
                        </Collapse>
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </CardBody>
        <CardFooter className="d-flex align-items-center justify-content-between flex-wrap">
          <PaginationFooter
            pageSize={pageSize}
            currentPage={page}
            totalSearch={total}
            isShowTotal
            changePageSize={this.changePageSize}
            onChangePagination={this.onChangePagination} />
        </CardFooter>
      </Card >
    );
  }
}

function mapStateToProps(state) {
  return {
    badReviews: state.Dashboard.badReviews,
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {
    ...dashboardAction,
  };
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(BadReviewsSocial);
