import React, { useState, useEffect } from 'react';
import {
    FormGroup, Card, CardHeader, CardBody, UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle,
    Col, Label, Input, Row, CustomInput, CardImg, Button
} from 'reactstrap';

import { connect, useSelector, useDispatch } from 'react-redux';
import { getSettingSms, onUpdateSettingSms } from '../../actions';
import PageTitle from '../../../../container/components/PageTitle'
import { isEmpty } from 'lodash'
import Api from '../../../../../../api/setting'
import { isLoadingSuccess } from '../../../../container/actions'
import { SUCCESS, WARNING } from '../../../../../../constants'

export default function SettingSms(props) {

    const settingSms = useSelector(state => state.Setting.settingSms)

    const dispatch = useDispatch()

    const [smsData, setSmsData] = useState({})

    useEffect(() => {
        console.log(1111)
        dispatch(getSettingSms())
    }, [])

    useEffect(() => {
        if (!isEmpty(settingSms) && settingSms.data) {
            let listData = {}
            settingSms.data.forEach(sms => {
                listData[sms.key] = sms
            })
            setSmsData(listData)
        }
    }, [settingSms])

    function onChange({ target: { id, checked } }) {

        const dataChange = {
            ...smsData,
            [id]: {
                ...smsData[id],
                value: checked ? 1 : 0
            }
        }
        setSmsData(dataChange)
    }

    async function onSubmitUpdateSettingSms() {
        const data = [
            smsData.REVIEW,
            smsData.BOOK_APPOINTMENT,
            smsData.CONFIRM_APPOINTMENT,
            smsData.PAYMENT,
            smsData.REGISTER_MEMBER,
        ]
        dispatch(isLoadingSuccess(false))
        const response = await Api.updateSettingSmsApi({ payload: { data } })
        const status = response && response.data && response.data.code && response.data.code === 200
        props.addNotification(status ? SUCCESS : WARNING, `Update setting ${status ? 'successful' : 'failed'}.`)

        dispatch(isLoadingSuccess(true))
    }

    return (
        <Card className="animated fadeIn card-home">
            <PageTitle >
                <FormGroup className="mb-0 d-flex align-items-center justify-content-between w-100">
                    <h4>Setting Sent SMS</h4>
                    <button className="btn-add" onClick={onSubmitUpdateSettingSms}>
                        Save change
                        </button>
                </FormGroup>

            </PageTitle>
            <CardBody className="card-body-box d-flex flex-column">
                <Row className="align-items-center mb-4">
                    <Col sm={6}>
                        <FormGroup className="social-items mb-0">
                            <CustomInput className="custom-input-large font-bold p-0"
                                id="REVIEW"
                                type="checkbox"
                                checked={smsData.REVIEW && smsData.REVIEW.value == 1}
                                onChange={onChange}
                                label="Sent sms review after checkin" />
                        </FormGroup>
                    </Col>
                </Row>
                <Row className="align-items-center mb-4">
                    <Col sm={6}>
                        <FormGroup className="social-items mb-0">
                            <CustomInput className="custom-input-large font-bold p-0"
                                id="BOOK_APPOINTMENT"
                                type="checkbox"
                                checked={smsData.BOOK_APPOINTMENT && smsData.BOOK_APPOINTMENT.value == 1}
                                onChange={onChange}
                                label="Sent sms after book appointment" />
                        </FormGroup>
                    </Col>
                </Row>

                <Row className="align-items-center mb-4">
                    <Col sm={6}>
                        <FormGroup className="social-items mb-0">
                            <CustomInput className="custom-input-large font-bold p-0"
                                id="CONFIRM_APPOINTMENT"
                                type="checkbox"
                                checked={smsData.CONFIRM_APPOINTMENT && smsData.CONFIRM_APPOINTMENT.value == 1}
                                onChange={onChange}
                                label="Sent sms confirm book appointment" />
                        </FormGroup>
                    </Col>
                </Row>

                <Row className="align-items-center mb-4">
                    <Col sm={6}>
                        <FormGroup className="social-items mb-0">
                            <CustomInput className="custom-input-large font-bold p-0"
                                id="PAYMENT"
                                type="checkbox"
                                checked={smsData.PAYMENT && smsData.PAYMENT.value == 1}
                                onChange={onChange}
                                label="Sent sms payment" />
                        </FormGroup>
                    </Col>
                </Row>


                <Row className="align-items-center mb-4">
                    <Col sm={6}>
                        <FormGroup className="social-items mb-0">
                            <CustomInput className="custom-input-large font-bold p-0"
                                id="REGISTER_MEMBER"
                                type="checkbox"
                                onChange={onChange}
                                checked={smsData.REGISTER_MEMBER && smsData.REGISTER_MEMBER.value == 1}
                                label="Sent sms register members" />
                        </FormGroup>
                    </Col>
                </Row>

                <FormGroup className="text-center mt-auto">
                    <button className="btn-add" onClick={onSubmitUpdateSettingSms}>
                        Save change
                        </button>
                </FormGroup>
            </CardBody>
        </Card >
    )
}
