const ACTION_NAME = 'FEEDBACK/MARKETTING';
export const GET_DATA_MARKETTING = `${ACTION_NAME}/GET_DATA_MARKETTING`;
export const GET_DATA_MARKETTING_SUCCESS = `${ACTION_NAME}/GET_DATA_MARKETTING_SUCCESS`;

export const ON_CREATE_CAMPAIN = `${ACTION_NAME}/ON_CREATE_CAMPAIN`;
export const ON_CREATE_CAMPAIN_SUCCESS = `${ACTION_NAME}/ON_CREATE_CAMPAIN_SUCCESS`;

export const ON_CHECK_BALANCE = `${ACTION_NAME}/ON_CHECK_BALANCE`;
export const ON_CHECK_BALANCE_SUCCESS = `${ACTION_NAME}/ON_CHECK_BALANCE_SUCCESS`;


export const MARKETING_TYPE = {
    ALL: 0,
    LESS_THAN_3_STAR: 1,
    GREATE_THAN_3_STAR: 2,
    APPOINTMENT: 3,
    BIRTHDAY: 4,
    USED_COUPON: 5,
    SERVICE_GROUP: 6,
    GIFT_CARD_PURCHASE: 7,
    LOYALTY_POINT: 8
  };
  
