import React, { Component } from 'react';
import {
  FormGroup, Card, CardHeader, CardBody, UncontrolledDropdown, UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle,
  Col, Label, Input
} from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as dashboardAction from './actions';
import { SEARCH_TYPE } from './constants'
import Rating from 'react-rating'
import Star from '../../../../assets/img/star-review.svg'
import StarFull from '../../../../assets/img/star-full.svg'
import icon1 from '../../../../assets/img/dashboard-icon-1.png'
import icon2 from '../../../../assets/img/dashboard-icon-2.png'
import icon3 from '../../../../assets/img/dashboard-icon-3.png'
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from 'react-datepicker';
import moment from 'moment'
import { ROUTER_URL } from '../../../../constants';
import PageTitle from '../../container/components/PageTitle'

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchType: SEARCH_TYPE.ALL_TIME,
      startTime: '',
      endTime: '',
    };
  }

  componentDidMount() {
    const { actions } = this.props;
    actions.getDataDashboard();
  }

  componentDidUpdate(prevProps, prevState) {
    const { startTime, endTime, searchType } = this.state;
    const { actions } = this.props;
    if (searchType == SEARCH_TYPE.ALL_TIME) {
      if (searchType != prevState.searchType) {
        this.setState({
          startTime: '',
          endTime: ''
        }, () => actions.getDataDashboard())
      }
      if (startTime && endTime) {
        if (startTime != prevState.startTime || endTime != prevState.endTime) {
          actions.getDataDashboard({ startTime: moment(startTime).format('YYYY-MM-DD hh:mm:ss'), endTime: moment(endTime).format('YYYY-MM-DD hh:mm:ss') });
        }
      }
    }
    else if (searchType == SEARCH_TYPE.THIS_WEEK && searchType != prevState.searchType) {
      actions.getDataDashboard({ startTime: moment().subtract(7, 'days').format('YYYY-MM-DD hh:mm:ss'), endTime: moment().format('YYYY-MM-DD hh:mm:ss') });
    }
    else if (searchType == SEARCH_TYPE.TWO_WEEK && searchType != prevState.searchType) {
      actions.getDataDashboard({ startTime: moment().subtract(14, 'days').format('YYYY-MM-DD hh:mm:ss'), endTime: moment().format('YYYY-MM-DD hh:mm:ss') });
    }
    else if (searchType == SEARCH_TYPE.THIS_MONTH && searchType != prevState.searchType) {
      actions.getDataDashboard({ startTime: moment().startOf('month').format('YYYY-MM-DD hh:mm:ss'), endTime: moment().format('YYYY-MM-DD hh:mm:ss') });
    }
  }

  changeTypeSearch = (searchType) => () => this.setState({ searchType })

  handleChangeDate = (name, date) => {
    this.setState({
      [name]: date
    })
  }

  render() {
    const { searchType, startTime, endTime } = this.state;
    const { history, dashboardData: { reviews, coupon } } = this.props;
    const {
      totalReviews, totalOneStar, totalTwoStar, totalThreeStar,
      totalFourStar, totalFiveStar, totalReviewsFacebook, totalReviewsGoggle,
      totalReviewsYelp, totalReviewsLocal, totalLessThanThreeStarReturn,
      totalStarLocal, totalStarYelp, totalStarFacebook, totalStarGoggle
    } = reviews;
    const { totalCoupon, totalCouponUsed, totalCouponExpire } = coupon;

    const totalLessThanThreeStar = totalOneStar + totalTwoStar + totalThreeStar;

    let improveLessThanThreeStar = 0;
    if (totalLessThanThreeStar > 0) {
      improveLessThanThreeStar = Math.round(totalLessThanThreeStarReturn / totalLessThanThreeStar * 100)
    }
    const total = totalOneStar + totalTwoStar + totalThreeStar + totalFourStar + totalFiveStar

    const avgOne = Math.round(totalOneStar / total * 100)
    const avgTwo = Math.round(totalTwoStar / total * 100)
    const avgThree = Math.round(totalThreeStar / total * 100)
    const avgFour = Math.round(totalFourStar / total * 100)
    const avgFive = 100 - avgOne - avgTwo - avgThree - avgFour

    const max = Math.max(avgOne, avgTwo, avgThree, avgFour, avgFive)
    const maxStar = max == avgOne ? 1 :
      max == avgTwo ? 2 :
        max == avgThree ? 3 :
          max == avgFour ? 4 :
            max == avgFive ? 5 : 0
    return (
      <Card className="animated fadeIn card-home">
        <PageTitle>
          <FormGroup className="form-search-reviews m-0">
            <UncontrolledButtonDropdown className="mr-4">
              <DropdownToggle caret className="mr-2" color="warning">
                {
                  searchType == SEARCH_TYPE.TOODAY ? 'Today' :
                    searchType == SEARCH_TYPE.THIS_WEEK ? 'This week' :
                      searchType == SEARCH_TYPE.TWO_WEEK ? '2 week' :
                        searchType == SEARCH_TYPE.THIS_MONTH ? 'This month' :
                          searchType == SEARCH_TYPE.ALL_TIME ? 'All time' : ''
                }
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={this.changeTypeSearch(SEARCH_TYPE.THIS_WEEK)}>This week</DropdownItem>
                <DropdownItem onClick={this.changeTypeSearch(SEARCH_TYPE.TWO_WEEK)}>2 week</DropdownItem>
                <DropdownItem onClick={this.changeTypeSearch(SEARCH_TYPE.THIS_MONTH)}>This month</DropdownItem>
                <DropdownItem onClick={this.changeTypeSearch(SEARCH_TYPE.ALL_TIME)}>All time</DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>

          {/* <UncontrolledDropdown direction="down" className="dashboard-search">
              <DropdownToggle caret className="dashboard-dropdown-toggle">
                {
                  searchType == SEARCH_TYPE.TOODAY ? 'Today' :
                    searchType == SEARCH_TYPE.THIS_WEEK ? 'This week' :
                      searchType == SEARCH_TYPE.TWO_WEEK ? '2 week' :
                        searchType == SEARCH_TYPE.THIS_MONTH ? 'This month' :
                          searchType == SEARCH_TYPE.ALL_TIME ? 'All time' : ''
                }
              </DropdownToggle>
              <DropdownMenu right className="header-dropdown-right-menu home-dropdown dashboard-dropdown">
                <DropdownItem onClick={this.changeTypeSearch(SEARCH_TYPE.THIS_WEEK)}>This week</DropdownItem>
                <DropdownItem onClick={this.changeTypeSearch(SEARCH_TYPE.TWO_WEEK)}>2 week</DropdownItem>
                <DropdownItem onClick={this.changeTypeSearch(SEARCH_TYPE.THIS_MONTH)}>This month</DropdownItem>
                <DropdownItem onClick={this.changeTypeSearch(SEARCH_TYPE.ALL_TIME)}>All time</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown> */}
          {
            searchType == SEARCH_TYPE.ALL_TIME &&
            <div className="form-search form-dashboard-mb">
              <Label>Custom</Label>
              <DatePicker
                className="form-control text-center"
                name="startTime"
                selected={startTime ? moment(startTime).toDate() : ''}
                maxDate={endTime ? moment(endTime).toDate() : ''}
                onChange={date => this.handleChangeDate('startTime', date)}
                showYearDropdown
                dateFormatCalendar="MMMM"
                scrollableYearDropdown
                yearDropdownItemNumber={15}
                dateFormat="MM/dd"
                autoComplete="off"
                placeholderText="From"
              />
              <span>-</span>
              <div className="date-picker-right">
                <DatePicker
                  className="form-control text-center "
                  name="endTime"
                  selected={endTime ? moment(endTime).toDate() : ''}
                  minDate={startTime ? moment(startTime).toDate() : ''}
                  onChange={date => this.handleChangeDate('endTime', date)}
                  showYearDropdown
                  dateFormatCalendar="MMMM"
                  scrollableYearDropdown
                  yearDropdownItemNumber={15}
                  popperPlacement="bottom-end"
                  dateFormat="MM/dd"
                  autoComplete="off"
                  placeholderText="To"
                />
              </div>
            </div>
          }
          </FormGroup>
        </PageTitle>
      <CardBody className="card-body-full">
        <FormGroup row className="dashboard-rating mb-5">
          <Col sm={6}>
            <div className="rating-total">
              <h5>{maxStar}.0</h5>
              <span>
                <Rating
                  initialRating={maxStar || 0}
                  readonly
                  emptySymbol={<span className="star-rating-symbol"><img src={Star} /></span>}
                  fullSymbol={<span className="star-rating-symbol"><img src={StarFull} /></span>}
                />
              </span>
              <p>From {totalReviews || 0} reviews</p>
              <h4>Total feedback & reviews</h4>
            </div>
          </Col>
          <Col sm={6}>
            <div className="rating-detail">
              <div className="rating-detail-row">
                <span>
                  <Rating
                    initialRating="5"
                    readonly
                    emptySymbol={<span className="star-rating-symbol"><img src={Star} /></span>}
                    fullSymbol={<span className="star-rating-symbol"><img src={StarFull} /></span>}
                  />
                </span>
                <div className="rating-bar">
                  <span className={`w-${avgFive}`}></span>
                </div>
                <span>{avgFive || 0}%</span>
              </div>
              <div className="rating-detail-row">
                <span>
                  <Rating
                    initialRating="4"
                    readonly
                    emptySymbol={<span className="star-rating-symbol"><img src={Star} /></span>}
                    fullSymbol={<span className="star-rating-symbol"><img src={StarFull} /></span>}
                  />
                </span>
                <div className="rating-bar">
                  <span className={`w-${avgFour}`}></span>
                </div>
                <span>{avgFour || 0}%</span>
              </div>
              <div className="rating-detail-row">
                <span>
                  <Rating
                    initialRating="3"
                    readonly
                    emptySymbol={<span className="star-rating-symbol"><img src={Star} /></span>}
                    fullSymbol={<span className="star-rating-symbol"><img src={StarFull} /></span>}
                  />
                </span>
                <div className="rating-bar">
                  <span className={`w-${avgThree}`}></span>
                </div>
                <span>{avgThree || 0}%</span>
              </div>
              <div className="rating-detail-row">
                <span>
                  <Rating
                    initialRating="2"
                    readonly
                    emptySymbol={<span className="star-rating-symbol"><img src={Star} /></span>}
                    fullSymbol={<span className="star-rating-symbol"><img src={StarFull} /></span>}
                  />
                </span>
                <div className="rating-bar">
                  <span className={`w-${avgTwo}`}></span>
                </div>
                <span>{avgTwo || 0}%</span>
              </div>
              <div className="rating-detail-row">
                <span>
                  <Rating
                    initialRating="1"
                    readonly
                    emptySymbol={<span className="star-rating-symbol"><img src={Star} /></span>}
                    fullSymbol={<span className="star-rating-symbol"><img src={StarFull} /></span>}
                  />
                </span>
                <div className="rating-bar">
                  <span className={`w-${avgOne}`}></span>
                </div>
                <span>{avgOne || 0}%</span>
              </div>
            </div>
          </Col>
        </FormGroup>
        <FormGroup row className="mb-5">
          <Col sm={3} className="d-flex">
            <div className="dashboard-statis" onClick={() => history.push(ROUTER_URL.BAD_REVIEW)}>
              <span className="feedback-sent">{totalLessThanThreeStar || 0}</span>
              <p>1-3 Star Review</p>
            </div>
          </Col>
          <Col sm={3} className="d-flex">
            <div className="dashboard-statis">
              <span className="stt-return">{totalLessThanThreeStarReturn || 0}</span>
              <p>1-3 Star Return </p>
            </div>
          </Col>
          <Col sm={3} className="d-flex">
            <div className="dashboard-statis">
              <span className="imp-feedback">0</span>
              <p>{improveLessThanThreeStar}% Improve from 1-3 Star</p>
            </div>
          </Col>
        </FormGroup>

        <FormGroup row className="mb-5">
          <Col sm={3} className="d-flex">
            <div className="dashboard-statis">
              <span className="local-feedback">{totalReviewsLocal || 0}</span>
              <p>Local Feedback</p>
              <div>
                <Rating
                  initialRating={!!totalReviewsLocal ? Math.round(totalStarLocal / totalReviewsLocal) : 0}
                  readonly
                  emptySymbol={<span className="star-rating-symbol"><img src={Star} /></span>}
                  fullSymbol={<span className="star-rating-symbol"><img src={StarFull} /></span>}
                />
              </div>
            </div>
          </Col>
          <Col sm={3} className="d-flex">
            <div className="dashboard-statis" onClick={() => history.push(ROUTER_URL.BAD_REVIEW_YELP)}>
              <span className="yelp-feedback">{totalReviewsYelp || 0}</span>
              <p>Yelp Feedback</p>
              <div>
                <Rating
                  initialRating={!!totalReviewsYelp ? Math.round(totalStarYelp / totalReviewsYelp) : 0}
                  readonly
                  emptySymbol={<span className="star-rating-symbol"><img src={Star} /></span>}
                  fullSymbol={<span className="star-rating-symbol"><img src={StarFull} /></span>}
                />
              </div>
            </div>
          </Col>
          <Col sm={3} className="d-flex">
            <div className="dashboard-statis" onClick={() => history.push(ROUTER_URL.BAD_REVIEW_FACEBOOK)}>
              <span className="fb-feedback">{totalReviewsFacebook || 0}</span>
              <p>Facebook Feedback</p>
              <div>
                <Rating
                  initialRating={!!totalReviewsFacebook ? Math.round(totalStarFacebook / totalReviewsFacebook) : 0}
                  readonly
                  emptySymbol={<span className="star-rating-symbol"><img src={Star} /></span>}
                  fullSymbol={<span className="star-rating-symbol"><img src={StarFull} /></span>}
                />
              </div>
            </div>
          </Col>
          <Col sm={3} className="d-flex">
            <div className="dashboard-statis" onClick={() => history.push(ROUTER_URL.BAD_REVIEW_GOOGLE)}>
              <span className="gg-feedback">{totalReviewsGoggle || 0}</span>
              <p>Google Feedback</p>
              <div>
                <Rating
                  initialRating={!!totalReviewsGoggle ? Math.round(totalStarGoggle / totalReviewsGoggle) : 0}
                  readonly
                  emptySymbol={<span className="star-rating-symbol"><img src={Star} /></span>}
                  fullSymbol={<span className="star-rating-symbol"><img src={StarFull} /></span>}
                />
              </div>
            </div>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Col sm={4}>
            <div className="dashboard-coupon coupon-sent" onClick={() => history.push(ROUTER_URL.BAD_REVIEW_COUPON_SENT)}>
              <span className="ds-icon-sent"><img src={icon1} /></span>
              <div className="dashboard-coupon-meta">
                <p>{totalCoupon || 0}</p>
                <span>Coupon Sent</span>
              </div>
            </div>
          </Col>
          <Col sm={4}>
            <div className="dashboard-coupon coupon-used" onClick={() => history.push(ROUTER_URL.BAD_REVIEW_COUPON_USED)}>
              <span className="ds-icon-used"><img src={icon2} /></span>
              <div className="dashboard-coupon-meta">
                <p>{totalCouponUsed || 0}</p>
                <span>Used Coupon</span>
              </div>
            </div>
          </Col>
          <Col sm={4}>
            <div className="dashboard-coupon coupon-expire">
              <span className="ds-icon-expire"><img src={icon3} /></span>
              <div className="dashboard-coupon-meta">
                <p>{totalCouponExpire || 0}</p>
                <span>Coupon Expire</span>
              </div>
            </div>
          </Col>
        </FormGroup>
      </CardBody>
      </Card >
    );
  }
}

function mapStateToProps(state) {
  return {
    dashboardData: state.Dashboard.dashboardData,
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {
    ...dashboardAction,
  };
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
