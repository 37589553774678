import React, { Component } from 'react';
import {
  ModalFooter, ModalHeader, Modal
} from 'reactstrap';


export default function ModalDelete(props) {

  const { isOpen, fieldDelete } = props
  return (
    <Modal className="modal-delete" isOpen={!!isOpen} centered>
      <ModalHeader className="border-0">Are you sure want to Delete this Channel ?</ModalHeader>
      <ModalFooter className="border-0">
        <button onClick={props.toggleDelete}>Cancel</button>
        <button onClick={props.onDelete}>Yes, Delete it</button>
      </ModalFooter>
    </Modal>
  )
}

