import { apiBaseURL } from '../config';
import { request } from '../services/api';

const baseEndpoint = `${apiBaseURL}`;

function getReportSMS(data) {
    const searchParams = new URLSearchParams();
    Object.keys(data.payload).forEach(key => searchParams.append(key, data.payload[key]));
    // const endpoint = `${baseEndpoint}/report/getReportByFilter?${searchParams.toString()}`;
    const endpoint = `${baseEndpoint}/report/getReportSmsLog?${searchParams.toString()}`;
    return request(endpoint, {
        method: 'GET',
    });
}

function getReportList(data) {
    const searchParams = new URLSearchParams();
    Object.keys(data.payload).forEach(key => searchParams.append(key, data.payload[key]));
    // const endpoint = `${baseEndpoint}/campaign/getListByShop`;
    const endpoint = `${baseEndpoint}/report/getReportSmsLog?${searchParams.toString()}`;
    return request(endpoint, {
        method: 'GET',
    });
}

export default {
    getReportList,
    getReportSMS
};
