import axios from 'axios';
import * as _ from 'lodash';
import { ErrorCode, ROUTER_URL } from '../constants';
import { clearAuth } from './auth';
import { localStoredGet } from './localStoredService';

export const CONTENT_TYPE = {
  form: 'multipart/form-data',
  json: 'application/json',
};

const handleError = (error) => {
  const { response: { data: { message }, status, statusText } } = error;
  if (status === ErrorCode.UNAUTHENTICATED) {
    clearAuth();
    window.location.href = ROUTER_URL.LOGIN;
    // NO MEANING
    return Promise.reject();
  }
  // NO MEANING
  // eslint-disable-next-line prefer-promise-reject-errors
  return Promise.reject({ message, errorCode: status, errorText: statusText });
};

const handleSuccess = (res, extra) => {
  if (extra) {
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', extra);
    document.body.appendChild(link);
    link.click();
    return;
  }
  // eslint-disable-next-line consistent-return
  return res;
};

export const getHeaders = () => {
  const xApi = localStoredGet('xApiKey');

  return {
    'x-api-key': xApi ? xApi : ''
  };
};

export const request = (endpoint, { method, headers, data }, extra) => {
  const initHeader = {
    ...getHeaders(),
    ...headers,
  };
  if (data && data.payload && data.payload.apiKey)
    initHeader['x-api-key'] = data.payload.apiKey
  return axios({
    method,
    url: endpoint,
    headers: initHeader,
    timeout: 30000,
    data,
    responseType: extra ? 'blob' : undefined,
  }).then((res) => handleSuccess(res, extra)).catch((error) => handleError(error));
};
