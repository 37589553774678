import { createAction } from 'redux-actions';
import * as CONST from './constants';

export const isLoading = createAction(CONST.IS_LOADING);
export const isLoadingSuccess = createAction(CONST.IS_LOADING_SUCCESS);

// Theme Options
export const setEnableBackgroundImage = createAction(CONST.SET_ENABLE_BACKGROUND_IMAGE);
export const setEnableFixedHeader = createAction(CONST.SET_ENABLE_FIXED_HEADER);
export const setEnableHeaderShadow = createAction(CONST.SET_ENABLE_HEADER_SHADOW);
export const setEnableSidebarShadow = createAction(CONST.SET_ENABLE_SIDEBAR_SHADOW);
export const setEnablePageTitleIcon = createAction(CONST.SET_ENABLE_PAGETITLE_ICON);
export const setEnablePageTitleSubheading = createAction(CONST.SET_ENABLE_PAGETITLE_SUBHEADING);
export const setEnablePageTabsAlt = createAction(CONST.SET_ENABLE_PAGE_TABS_ALT);
export const setEnableFixedSidebar = createAction(CONST.SET_ENABLE_FIXED_SIDEBAR);
export const setEnableMobileMenu = createAction(CONST.SET_ENABLE_MOBILE_MENU);
export const setEnableMobileMenuSmall = createAction(CONST.SET_ENABLE_MOBILE_MENU_SMALL);
export const setEnableClosedSidebar = createAction(CONST.SET_ENABLE_CLOSED_SIDEBAR);
export const setEnableFixedFooter = createAction(CONST.SET_ENABLE_FIXED_FOOTER);
export const setBackgroundColor = createAction(CONST.SET_BACKGROUND_COLOR);
export const setHeaderBackgroundColor = createAction(CONST.SET_HEADER_BACKGROUND_COLOR);
export const setColorScheme = createAction(CONST.SET_COLOR_SCHEME);
export const setBackgroundImageOpacity = createAction(CONST.SET_BACKGROUND_IMAGE_OPACITY);
export const setBackgroundImage = createAction(CONST.SET_BACKGROUND_IMAGE);