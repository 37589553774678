import React, { useState, useEffect, useRef } from 'react';
import {
  FormGroup,
  Card,
  CardBody,
  Col,
  Label,
  Input,
  Row,
  CustomInput,
  CardTitle,
  Form,
} from 'reactstrap';
import * as action from '../../actions';
import { useSelector, useDispatch } from 'react-redux';
import PageTitle from '../../../../container/components/PageTitle';
import Api from '../../../../../../api/setting';
import { isLoadingSuccess } from '../../../../container/actions';
import { SUCCESS, WARNING } from '../../../../../../constants';

const listHash = [
  {
    id: 'shopName',
    value: '#Shop Name',
  },
  {
    id: 'customerName',
    value: '#Customer Name',
  },
  {
    id: 'couponLink',
    value: '#Coupon Link',
  },
];

export default function AutoSentSms(props) {
  const dispatch = useDispatch();
  const inputBIRTHDAY = useRef();
  const inputNO_VISIT = useRef();

  const settingAutoSms = useSelector((state) => state.Setting.settingAutoSms);

  const [autoSmsData, setAutoSmsData] = useState({
    BIRTHDAY: {},
    NO_VISIT: {},
  });
  const [pos, setPos] = useState({ BIRTHDAY: 0, NO_VISIT: 0 });

  useEffect(() => {
    setAutoSmsData(settingAutoSms);
    dispatch(action.getSettingAutoSms());
  }, []);

  useEffect(() => {
    setAutoSmsData(settingAutoSms);
  }, [settingAutoSms]);

  function getKeyAndNameById(id) {
    if (typeof id == 'number') return {};
    const ids = id.split('-');
    return { key: ids[0], name: ids[1] };
  }

  function onChange({ target: { id, value, checked, type } }) {
    console.log(value);
    const { key, name } = getKeyAndNameById(id);
    setAutoSmsData({
      ...autoSmsData,
      [key]: {
        ...autoSmsData[key],
        [name]: type == 'checkbox' ? checked : value,
      },
    });
  }

  const addHash = (textId, value) => (e) => {
    e.preventDefault();
    const { key } = getKeyAndNameById(textId);
    const selectPos = pos[key];

    const smsBefore = autoSmsData[key].contentSms.substring(0, selectPos);
    const smsAfter = autoSmsData[key].contentSms.substring(
      selectPos,
      autoSmsData[key].contentSms.length
    );
    if (key == 'BIRTHDAY') {
      console.log(key);
      inputBIRTHDAY.current.selectionStart = selectPos + value.length;
      inputBIRTHDAY.current.focus();
    } else inputNO_VISIT.current.focus();
    setAutoSmsData({
      ...autoSmsData,
      [key]: {
        ...autoSmsData[key],
        contentSms: `${smsBefore}(${value})${smsAfter}`,
      },
    });
  };

  function focusText({ target: { id, selectionStart } }) {
    const { key } = getKeyAndNameById(id);
    setPos({ ...pos, [key]: selectionStart });
  }

  function blurText({ relatedTarget }) {
    if (
      !(
        relatedTarget &&
        relatedTarget.id &&
        (relatedTarget.id.includes('BIRTHDAY') ||
          relatedTarget.id.includes('NO_VISIT'))
      )
    ) {
      const { BIRTHDAY, NO_VISIT } = autoSmsData;
      setPos({
        BIRTHDAY: BIRTHDAY.contentSms.length,
        NO_VISIT: NO_VISIT.contentSms.length,
      });
      inputBIRTHDAY.current.selectionStart = BIRTHDAY.contentSms.length;
      inputBIRTHDAY.current.selectionStart = NO_VISIT.contentSms.length;
    }
  }

  async function updateAutoSendSMS() {
    let status = false;
    try {
      dispatch(isLoadingSuccess(false));
      const { BIRTHDAY, NO_VISIT } = autoSmsData;
      const data = [BIRTHDAY, NO_VISIT];
      let response = await Api.updateAutoSettingSentSmsApi({
        payload: { data },
      });
      status =
        response &&
        response.data &&
        response.data.code &&
        response.data.code === 200;
    } catch (ex) {
      console.log(ex);
    }
    props.addNotification(
      status ? SUCCESS : WARNING,
      `Update setting auto sms ${status ? 'successful' : 'failed'}.`
    );
    dispatch(isLoadingSuccess(true));
  }

  return (
    <Card className="animated fadeIn card-home">
      <PageTitle>
        <FormGroup className="mb-0 d-flex align-items-center justify-content-between w-100">
          <h4>Setting Auto Sent SMS</h4>
          <button className="btn-add" onClick={updateAutoSendSMS}>
            Save change
          </button>
        </FormGroup>
      </PageTitle>
      <CardBody className="card-body-box d-flex flex-column">
        <Row className="mb-4">
          <Col md="12" className="mb-2 d-flex align-item-stretch">
            <Card className="main-card mb-2 w-100">
              <CardBody>
                <CardTitle className="d-flex align-items-center">
                  Auto sent sms birthday
                  <div className="ml-auto">
                    <CustomInput
                      className="custom-input-large font-bold pl-0"
                      checked={!!autoSmsData.BIRTHDAY.status}
                      onChange={onChange}
                      name="reward"
                      type="checkbox"
                      id="BIRTHDAY-status"
                      label=""
                    />
                  </div>
                </CardTitle>

                <FormGroup className="dropdown-full position-relative">
                  <span className="quote-icon">
                    <i className="fa fa-quote-left" aria-hidden="true" />
                  </span>
                  <textarea
                    name="smsMessage"
                    className="content-message w-100 p-2"
                    type="textarea"
                    id="BIRTHDAY-contentSms"
                    rows="4"
                    ref={inputBIRTHDAY}
                    onMouseUp={focusText}
                    onBlur={blurText}
                    value={autoSmsData.BIRTHDAY.contentSms}
                    onChange={onChange}
                  />
                  <div className="group-btn-campain">
                    {listHash.map((hash) => (
                      <button
                        onClick={addHash('BIRTHDAY-contentSms', hash.value)}
                        key={hash.id}
                        type="button"
                        id={`BIRTHDAY-${hash.id}`}
                      >
                        {hash.value}
                      </button>
                    ))}
                  </div>
                </FormGroup>
                <Form inline>
                  <FormGroup className="mb-1 mr-sm-1 mb-sm-0">
                    <Label for="BIRTHDAY-preSentSmsDay" className="mr-sm-2">
                      Sent in advance how many days
                    </Label>
                    <Input
                      id="BIRTHDAY-preSentSmsDay"
                      name="BIRTHDAY-preSentSmsDay"
                      onChange={onChange}
                      value={autoSmsData.BIRTHDAY.preSentSmsDay || 0}
                      style={{ width: '70px' }}
                    />
                    <Label className="mr-sm-2" style={{ marginLeft: '10px' }}>
                      (day)
                    </Label>
                  </FormGroup>
                </Form>
                <Form inline style={{ marginTop: '20px' }}>
                  <CustomInput
                    className="custom-input-large font-bold pl-0"
                    checked={!!autoSmsData.BIRTHDAY.isSentCoupon}
                    onChange={onChange}
                    name="reward"
                    type="checkbox"
                    id="BIRTHDAY-isSentCoupon"
                    label="Sent Coupon"
                  />
                  <FormGroup
                    className="mb-1 mr-sm-1 mb-sm-0"
                    style={{ marginLeft: '20px' }}
                  >
                    <Label className="mr-sm-2">Discount Type</Label>
                    <select
                      className="form-control"
                      value={autoSmsData.BIRTHDAY.discountType}
                      onChange={onChange}
                      id="BIRTHDAY-discountType"
                    >
                      <option value="0">Percent</option>
                      <option value="1">Amount</option>
                    </select>

                    <Label className="mr-sm-2" style={{ marginLeft: '20px' }}>
                      Value
                    </Label>
                    <Input
                      type="text"
                      name="minBill"
                      onChange={onChange}
                      value={autoSmsData.BIRTHDAY.discountValue || 0}
                      id="BIRTHDAY-discountValue"
                      style={{ width: '70px' }}
                    />

                    <Label
                      for="exampleEmail22"
                      className="mr-sm-2"
                      style={{ marginLeft: '20px' }}
                    >
                      Expire Date
                    </Label>
                    <Input
                      type="text"
                      onChange={onChange}
                      name="minBill"
                      id="LOYALTY_REWARD-amount"
                      value={autoSmsData.BIRTHDAY.expireCoupon || 0}
                      style={{ width: '70px' }}
                    />
                    <Label className="mr-sm-2" style={{ marginLeft: '10px' }}>
                      (day)
                    </Label>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Col>

          <Col md="12" className="mb-2 d-flex align-item-stretch">
            <Card className="main-card mb-2 w-100">
              <CardBody>
                <CardTitle className="d-flex align-items-center">
                  Auto Sent Sms Customer No Visit
                  <div className="ml-auto">
                    <CustomInput
                      className="custom-input-large font-bold pl-0"
                      checked={!!autoSmsData.NO_VISIT.status}
                      onChange={onChange}
                      name="reward"
                      type="checkbox"
                      id="NO_VISIT-status"
                      label=""
                    />
                  </div>
                </CardTitle>

                <FormGroup className="dropdown-full position-relative">
                  <span className="quote-icon">
                    <i className="fa fa-quote-left" aria-hidden="true" />
                  </span>
                  <textarea
                    name="smsMessage"
                    className="content-message w-100 p-2"
                    type="textarea"
                    id="NO_VISIT-contentSms"
                    ref={inputNO_VISIT}
                    value={autoSmsData.NO_VISIT.contentSms}
                    rows="4"
                    onMouseUp={focusText}
                    onBlur={blurText}
                    onChange={onChange}
                  />
                  <div className="group-btn-campain">
                    {listHash.map((hash) => (
                      <button
                        onClick={addHash('NO_VISIT-contentSms', hash.value)}
                        key={hash.id}
                        type="button"
                        id={`NO_VISIT-${hash.id}`}
                      >
                        {hash.value}
                      </button>
                    ))}
                  </div>
                </FormGroup>
                <Form inline>
                  <FormGroup className="mb-1 mr-sm-1 mb-sm-0">
                    <Label for="NO_VISIT-preSentSmsDay" className="mr-sm-2">
                      Sent in advance how many days
                    </Label>
                    <Input
                      type="text"
                      name="minBill"
                      id="NO_VISIT-preSentSmsDay"
                      onChange={onChange}
                      value={autoSmsData.NO_VISIT.preSentSmsDay || 0}
                      style={{ width: '70px' }}
                    />
                    <Label className="mr-sm-2" style={{ marginLeft: '10px' }}>
                      (day)
                    </Label>
                  </FormGroup>
                </Form>
                <Form inline style={{ marginTop: '20px' }}>
                  <CustomInput
                    className="custom-input-large font-bold pl-0"
                    checked={!!autoSmsData.NO_VISIT.isSentCoupon}
                    name="reward"
                    onChange={onChange}
                    type="checkbox"
                    id="NO_VISIT-isSentCoupon"
                    label="Sent Coupon"
                  />
                  <FormGroup
                    className="mb-1 mr-sm-1 mb-sm-0"
                    style={{ marginLeft: '20px' }}
                  >
                    <Label className="mr-sm-2">Discount Type</Label>
                    <select
                      className="form-control"
                      id="NO_VISIT-discountType"
                      value={autoSmsData.NO_VISIT.discountType}
                      onChange={onChange}
                    >
                      <option value="0">Percent</option>
                      <option value="1">Amount</option>
                    </select>

                    <Label
                      for="NO_VISIT-discountValue"
                      className="mr-sm-2"
                      style={{ marginLeft: '20px' }}
                    >
                      Value
                    </Label>
                    <Input
                      type="text"
                      name="minBill"
                      id="NO_VISIT-discountValue"
                      onChange={onChange}
                      value={autoSmsData.NO_VISIT.discountValue || 0}
                      style={{ width: '70px' }}
                    />

                    <Label
                      for="NO_VISIT-expireCoupon"
                      className="mr-sm-2"
                      style={{ marginLeft: '20px' }}
                    >
                      Expire Date
                    </Label>
                    <Input
                      type="text"
                      name="minBill"
                      id="NO_VISIT-expireCoupon"
                      onChange={onChange}
                      value={autoSmsData.NO_VISIT.expireCoupon || 0}
                      style={{ width: '70px' }}
                    />
                    <Label className="mr-sm-2" style={{ marginLeft: '10px' }}>
                      (day)
                    </Label>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <FormGroup className="text-center mt-auto">
          <button onClick={updateAutoSendSMS} className="btn-add">
            Save change
          </button>
        </FormGroup>
      </CardBody>
    </Card>
  );
}
