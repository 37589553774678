import React, { Component } from 'react';
import {
  FormGroup,
  Card,
  Label,
  CardBody,
  Col,
  Input,
  CardTitle,
  Button,
} from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as luckySpinAction from './actions';
import PageTitle from '../../container/components/PageTitle';
import { isEqual } from 'lodash';
import { SUCCESS, WARNING } from '../../../../constants';

class LuckySpin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLucky: [],
    };
  }

  componentDidMount() {
    this.onInit();
  }

  componentDidUpdate(prevProps) {
    const { dataLucky, updateLuckySuccess } = this.props;
    if (!isEqual(dataLucky, prevProps.dataLucky)) {
      this.setState({ dataLucky });
    }
    if (!isEqual(updateLuckySuccess, prevProps.updateLuckySuccess)) {
      const isSuccess = updateLuckySuccess > prevProps.updateLuckySuccess;
      this.props.addNotification(
        isSuccess ? SUCCESS : WARNING,
        `Update setting lucky spin ${isSuccess ? 'successfully' : 'failed'}.`
      );
    }
  }

  onInit = () => {
    const { actions } = this.props;
    actions.getDataLucky();
  };

  onChange = ({ target: { title, name, value } }) => {
    let { dataLucky } = this.state;
    const list = dataLucky.map((data) => ({
      ...data,
      [name]: title == data.id ? value : data[name],
    }));
    this.setState({ dataLucky: list });
  };

  onUpdateDataLuckySpin = () => {
    const { dataLucky } = this.state;
    const { actions } = this.props;
    const payload = { data: dataLucky };
    actions.onUpdateDataLucky(payload);
  };

  render() {
    const { dataLucky } = this.state;
    return (
      <Card className="animated fadeIn card-home">
        <PageTitle>
          <FormGroup className="mb-0 d-flex align-items-center justify-content-between w-100">
            <h5 className="font-weight-bold">Setting Lucky Spin</h5>
            <button className="btn-add" onClick={this.onUpdateDataLuckySpin}>
              Save change
            </button>
          </FormGroup>
        </PageTitle>
        <CardBody className="card-body-full pb-0">
          <FormGroup row className="mb-0">
            {!dataLucky && (
              <p className="text-center font-weight-bold">
                There is no lucky spin setting.
              </p>
            )}
            {dataLucky &&
              dataLucky.map((data) => (
                <Col sm={6} key={data.id}>
                  <Card className="main-card mb-3">
                    <CardBody>
                      <CardTitle>Spin: {data.id}</CardTitle>
                      <FormGroup className="mb-2">
                        <Label>Spin Name: </Label>
                        <Input
                          value={data.spinType}
                          name="spinType"
                          title={data.id}
                          onChange={this.onChange}
                        />
                      </FormGroup>
                      <FormGroup className="mb-2">
                        <Label>Value: </Label>
                        <Input
                          value={data.spinValue}
                          name="spinValue"
                          title={data.id}
                          type="number"
                          onChange={this.onChange}
                        />
                      </FormGroup>
                      <FormGroup className="mb-0">
                        <Label>Rate Win: </Label>
                        <Input
                          value={data.odds}
                          name="odds"
                          type="number"
                          title={data.id}
                          onChange={this.onChange}
                        />
                      </FormGroup>
                    </CardBody>
                  </Card>
                </Col>
              ))}
          </FormGroup>
        </CardBody>
      </Card>
    );
  }
}

function mapStateToProps(state) {
  return {
    dataLucky: state.LuckySpin.dataLucky,
    updateLuckySuccess: state.LuckySpin.updateLuckySuccess,
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {
    ...luckySpinAction,
  };
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(LuckySpin);
