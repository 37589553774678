import freeze from 'deep-freeze';
import { handleActions } from 'redux-actions';
import * as actions from './actions';

export const name = 'Coupon';

const initialState = {
  couponList: [],
  total: 0,
  couponDetail: {},
  createCouponSuccess: 0,
  updateCouponSuccess: 0,
  deleteCouponSuccess: 0,
  galleryCoupon: [],
  categoryCouponList: [],
  createCategoryCouponSuccess: 0,
  deleteCategoryCouponSuccess: 0,
  couponDataById: {
    coupons: [],
    smsText: ''
  }
};

export default handleActions({
  [actions.getDataCouponSuccess]: (state, action) => freeze({
    ...state,
    couponList: action.payload.coupons,
    total: action.payload.total
  }),
  [actions.getDetailCouponSuccess]: (state, action) => freeze({
    ...state,
    couponDetail: action.payload
  }),
  [actions.onCreateCouponSuccess]: (state, action) => freeze({
    ...state,
    createCouponSuccess: action.payload ? state.createCouponSuccess + 1 : state.createCouponSuccess - 1
  }),
  [actions.onUpdateCouponSuccess]: (state, action) => freeze({
    ...state,
    updateCouponSuccess: action.payload ? state.updateCouponSuccess + 1 : state.updateCouponSuccess - 1
  }),
  [actions.onDeleteCouponSuccess]: (state, action) => freeze({
    ...state,
    deleteCouponSuccess: action.payload ? state.deleteCouponSuccess + 1 : state.deleteCouponSuccess - 1
  }),
  [actions.getGalleryCouponSuccess]: (state, action) => freeze({
    ...state,
    galleryCoupon: action.payload
  }),
  [actions.onCreateCategoryCouponSuccess]: (state, action) => freeze({
    ...state,
    createCategoryCouponSuccess: action.payload ? state.createCategoryCouponSuccess + 1 : state.createCategoryCouponSuccess - 1
  }),
  [actions.getCategoryCouponSuccess]: (state, action) => freeze({
    ...state,
    categoryCouponList: action.payload
  }),
  [actions.onDeleteCategoryCouponSuccess]: (state, action) => freeze({
    ...state,
    deleteCategoryCouponSuccess: action.payload ? state.deleteCategoryCouponSuccess + 1 : state.deleteCategoryCouponSuccess - 1
  }),
  [actions.getCouponByCategoryIdSuccess]: (state, action) => freeze({
    ...state,
    couponDataById: action.payload
  }),
}, initialState);
