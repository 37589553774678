import React, { Component } from 'react';
import {
  ModalFooter, ModalHeader, Modal, ModalBody, Row, Col
} from 'reactstrap';
import PaginationFooter from '../../../../../libs/components/PaginationFooter'

export default class ModaGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
    }
  }

  componentDidMount() {
    const { idImage } = this.props;
    if (!!idImage) {
      this.setState({ id: idImage })
    }
  }

  onSave = () => {
    const { id } = this.state;
    const { galleryCategoryServiceByID } = this.props;
    const galleryOb = galleryCategoryServiceByID.find(gallery => gallery.id == id)
    this.props.onSelectService(id, galleryOb.urlImage)
  }

  render() {
    const { id } = this.state;
    const { isOpen, galleryCategoryService, galleryCategoryServiceByID, page, total } = this.props
    return (
      <Modal className="modal-gallery" isOpen={!!isOpen} centered>
        <ModalHeader className="border-0">Select Gallery {isOpen == 1 ? 'Category' : 'Service'}</ModalHeader>
        <ModalBody>
          {isOpen == 1 &&
            <Row>
              {
                galleryCategoryService.map(gallery => (
                  <Col key={gallery.id} sm={3}>
                    <div className={`gallery-category-item`} onClick={this.props.onSelect(gallery.id)}>
                      <h4>{gallery.categoryName}</h4>
                    </div>
                  </Col>
                ))
              }
            </Row>
          }
          {isOpen == 2 &&
            <>
              <Row className="row-stretch">
                {
                  galleryCategoryServiceByID.map(gallery => (
                    <Col key={gallery.id} sm={3}>
                      <div className={`gallery-service-item ${id == gallery.id ? 'selected' : ''}`} onClick={() => this.setState({ id: gallery.id })}>
                        <img alt="" src={gallery.urlImage} />
                      </div>
                    </Col>
                  ))
                }
              </Row>
              <PaginationFooter
                currentPage={page}
                pageSize={10}
                totalSearch={total}
                onChangePagination={this.props.onChangePagination}
              />
            </>
          }
        </ModalBody>
        <ModalFooter className="border-0">
          <button onClick={this.props.toggleModal}>Cancel</button>
          <button onClick={this.onSave}>Save</button>
        </ModalFooter>
      </Modal>
    )
  }
}

