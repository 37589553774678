const ACTION_NAME = 'FEEDBACK/SETTING';
export const GET_SOCIAL_LIST = `${ACTION_NAME}/GET_SOCIAL_LIST`;
export const GET_SOCIAL_LIST_SUCCESS = `${ACTION_NAME}/GET_SOCIAL_LIST_SUCCESS`;
export const ON_CREATE_SOCIAL_CHANNEL = `${ACTION_NAME}/ON_CREATE_SOCIAL_CHANNEL`;
export const ON_CREATE_SOCIAL_CHANNEL_SUCCESS = `${ACTION_NAME}/ON_CREATE_SOCIAL_CHANNEL_SUCCESS`;
export const ON_UPDATE_SOCIAL_CHANNEL = `${ACTION_NAME}/ON_UPDATE_SOCIAL_CHANNEL`;
export const ON_UPDATE_SOCIAL_CHANNEL_SUCCESS = `${ACTION_NAME}/ON_UPDATE_SOCIAL_CHANNEL_SUCCESS`;
export const ON_DELETE_SOCIAL_CHANNEL = `${ACTION_NAME}/ON_DELETE_SOCIAL_CHANNEL`;
export const ON_DELETE_SOCIAL_CHANNEL_SUCCESS = `${ACTION_NAME}/ON_DELETE_SOCIAL_CHANNEL_SUCCESS`;

export const GET_CATEGORY_FEEDBACKS = `${ACTION_NAME}/GET_CATEGORY_FEEDBACKS`;
export const GET_CATEGORY_FEEDBACKS_SUCCESS = `${ACTION_NAME}/GET_CATEGORY_FEEDBACKS_SUCCESS`;
export const ON_CREATE_CATEGORY_FEEDBACKS = `${ACTION_NAME}/ON_CREATE_CATEGORY_FEEDBACKS`;
export const ON_CREATE_CATEGORY_FEEDBACKS_SUCCESS = `${ACTION_NAME}/ON_CREATE_CATEGORY_FEEDBACKS_SUCCESS`;
export const ON_DELETE_CATEGORY_FEEDBACKS = `${ACTION_NAME}/ON_DELETE_CATEGORY_FEEDBACKS`;
export const ON_DELETE_CATEGORY_FEEDBACKS_SUCCESS = `${ACTION_NAME}/ON_DELETE_CATEGORY_FEEDBACKS_SUCCESS`;

export const GET_QUESTION_FEEDBACKS = `${ACTION_NAME}/GET_QUESTION_FEEDBACKS`;
export const GET_QUESTION_FEEDBACKS_SUCCESS = `${ACTION_NAME}/GET_QUESTION_FEEDBACKS_SUCCESS`;
export const ON_CREATE_QUESTION_FEEDBACKS = `${ACTION_NAME}/ON_CREATE_QUESTION_FEEDBACKS`;
export const ON_CREATE_QUESTION_FEEDBACKS_SUCCESS = `${ACTION_NAME}/ON_CREATE_QUESTION_FEEDBACKS_SUCCESS`;
export const ON_DELETE_QUESTION_FEEDBACKS = `${ACTION_NAME}/ON_DELETE_QUESTION_FEEDBACKS`;
export const ON_DELETE_QUESTION_FEEDBACKS_SUCCESS = `${ACTION_NAME}/ON_DELETE_QUESTION_FEEDBACKS_SUCCESS`;

export const GET_NOTIFICATION = `${ACTION_NAME}/GET_NOTIFICATION`;
export const GET_NOTIFICATION_SUCCESS = `${ACTION_NAME}/GET_NOTIFICATION_SUCCESS`;
export const ON_UPDATE_NOTIFICATION = `${ACTION_NAME}/ON_UPDATE_NOTIFICATION`;
export const ON_UPDATE_NOTIFICATION_SUCCESS = `${ACTION_NAME}/ON_UPDATE_NOTIFICATION_SUCCESS`;

export const GET_ALL_SETTING_MEMBER = `${ACTION_NAME}/GET_ALL_SETTING_MEMBER`;
export const GET_ALL_SETTING_MEMBER_SUCCESS = `${ACTION_NAME}/GET_ALL_SETTING_MEMBER_SUCCESS`;
export const ON_UPDATE_SETTING_MEMBER = `${ACTION_NAME}/ON_UPDATE_SETTING_MEMBER`;
export const ON_UPDATE_SETTING_MEMBER_SUCCESS = `${ACTION_NAME}/ON_UPDATE_SETTING_MEMBER_SUCCESS`;

export const GET_SETTING_SMS = `${ACTION_NAME}/GET_SETTING_SMS`;
export const GET_SETTING_SMS_SUCCESS = `${ACTION_NAME}/GET_SETTING_SMS_SUCCESS`;
export const ON_UPDATE_SETTING_SMS = `${ACTION_NAME}/ON_UPDATE_SETTING_SMS`;
export const ON_UPDATE_SETTING_SMS_SUCCESS = `${ACTION_NAME}/ON_UPDATE_SETTING_SMS_SUCCESS`;

export const GET_SETTING_AUTO_SMS = `${ACTION_NAME}/GET_SETTING_AUTO_SMS`;
export const GET_SETTING_AUTO_SMS_SUCCESS = `${ACTION_NAME}/GET_SETTING_AUTO_SMS_SUCCESS`;

export const GET_EXPRESS_SERVICES = `${ACTION_NAME}/GET_EXPRESS_SERVICES`;
export const GET_EXPRESS_SERVICES_SUCCESS = `${ACTION_NAME}/GET_EXPRESS_SERVICES_SUCCESS`;
export const ON_ADD_SETTING_EXPRESS_SERVICES = `${ACTION_NAME}/ON_ADD_SETTING_EXPRESS_SERVICES`;
export const ON_ADD_SETTING_EXPRESS_SERVICES_SUCCESS = `${ACTION_NAME}/ON_ADD_SETTING_EXPRESS_SERVICES_SUCCESS`;
export const ON_UPDATE_SETTING_EXPRESS_SERVICES = `${ACTION_NAME}/ON_UPDATE_SETTING_EXPRESS_SERVICES`;
export const ON_UPDATE_SETTING_EXPRESS_SERVICES_SUCCESS = `${ACTION_NAME}/ON_UPDATE_SETTING_EXPRESS_SERVICES_SUCCESS`;
export const ON_DELETE_SETTING_EXPRESS_SERVICES = `${ACTION_NAME}/ON_DELETE_SETTING_EXPRESS_SERVICES`;
export const ON_DELETE_SETTING_EXPRESS_SERVICES_SUCCESS = `${ACTION_NAME}/ON_DELETE_SETTING_EXPRESS_SERVICES_SUCCESS`;