import freeze from 'deep-freeze';
import { handleActions } from 'redux-actions';
import * as actions from './actions';
import moment from 'moment'


export const name = 'Appoitment';

const initialState = {
  appoitmentist: [],
  total: 0,
  dataAppointment: {
    data: [{ id: 1, title: 'Faadi Al Rahman' }],
    groups: [
      {
        id: 1,
        group: 1,
        title: 'Song Bao',
        service: 'Haircut standard',
        arrived: true,
        price: 10,
        start_time: moment().set({ hour: 8, minute: 0 }),
        end_time: moment().set({ hour: 9, minute: 30 }),
      },
    ],
  },
  serviceList: [],
  staffList: [],
  customerList: [],
  isCreateCustomerSuccess: 0,
  isCreateAppoitmentSuccess: 0,
  isEditAppoitmentSuccess: 0,
  isDeleteAppoitmentSuccess: 0,
  staffTimeSlot: {
    timeSlots: []
  },
  messageError: ''
};

export default handleActions({
  [actions.getDataAppointmentSuccess]: (state, action) => freeze({
    ...state,
    appoitmentist: action.payload.data,
    total: action.payload.total,
  }),
  [actions.getScheduleAppointmentSuccess]: (state, action) => freeze({
    ...state,
    dataAppointment: action.payload,
  }),
  [actions.getDataServiceSuccess]: (state, action) =>
    freeze({
      ...state,
      serviceList: action.payload,
    }),
  [actions.getDataStaffSuccess]: (state, action) =>
    freeze({
      ...state,
      staffList: action.payload,
    }),
  [actions.getDataCustomerSuccess]: (state, action) =>
    freeze({
      ...state,
      customerList: action.payload,
    }),
  [actions.onCreateCustomerSuccess]: (state, action) => freeze({
    ...state,
    isCreateCustomerSuccess: action.payload.status ? state.isCreateCustomerSuccess + 1 : state.isCreateCustomerSuccess - 1,
    dataCustomer: action.payload.data
  }),
  [actions.onCreateAppoitmentSuccess]: (state, action) => freeze({
    ...state,
    isCreateAppoitmentSuccess: action.payload.status ? state.isCreateAppoitmentSuccess + 1 : state.isCreateAppoitmentSuccess - 1,
    messageError: action.payload.message
  }),
  [actions.onEditAppoitmentSuccess]: (state, action) => freeze({
    ...state,
    isEditAppoitmentSuccess: action.payload.status ? state.isEditAppoitmentSuccess + 1 : state.isEditAppoitmentSuccess - 1,
    messageError: action.payload.message
  }),
  [actions.onDeleteAppoitmentSuccess]: (state, action) => freeze({
    ...state,
    isDeleteAppoitmentSuccess: action.payload.status ? state.isDeleteAppoitmentSuccess + 1 : state.isDeleteAppoitmentSuccess - 1,
    messageError: action.payload.message
  }),
  [actions.onGetTimeSlotSuccess]: (state, action) => freeze({
    ...state,
    staffTimeSlot: action.payload,
  }),
  [actions.onClearStautsAppoitment]: (state, action) => freeze({
    ...state,
    isCreateAppoitmentSuccess: 0,
  }),
}, initialState);
