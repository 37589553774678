import store from 'store';

export const localStoredSave = (name, value) => {
  if (name === undefined) throw new Error('Cant store value with name undefined');
  return store.set(name, value);
};

export const localStoredGet = (name) => store.get(name);

export const checkExist = (name) => {
  if (localStoredGet(name)) return true;
  return false;
};


export const localStoredRemove = (name) => {
  store.remove(name);
};

export const localStoredClearAll = () => store.clearAll();

export default {
  localStoredSave,
  localStoredGet,
  localStoredRemove,
  localStoredClearAll,
};
