import { all, put, takeEvery } from 'redux-saga/effects';
import * as action from '../actions';

export function* handleLoading(payload) {
  try {
    yield put(action.isLoadingSuccess(payload));
  } catch (error) {
    console.log(error);
  }
}

export default function* root() {
  yield all([
    takeEvery(action.isLoading, handleLoading),
  ]);
}
