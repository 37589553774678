import { createAction } from 'redux-actions';
import * as CONST from './constants';

export const getSocialChannelList = createAction(CONST.GET_SOCIAL_LIST);
export const getSocialChannelListSuccess = createAction(CONST.GET_SOCIAL_LIST_SUCCESS);
export const onCreateSocialChannel = createAction(CONST.ON_CREATE_SOCIAL_CHANNEL);
export const onCreateSocialChannelSuccess = createAction(CONST.ON_CREATE_SOCIAL_CHANNEL_SUCCESS);
export const onUpdateSocialChannel = createAction(CONST.ON_UPDATE_SOCIAL_CHANNEL);
export const onUpdateSocialChannelSuccess = createAction(CONST.ON_UPDATE_SOCIAL_CHANNEL_SUCCESS);
export const onDeleteSocialChannel = createAction(CONST.ON_DELETE_SOCIAL_CHANNEL);
export const onDeleteSocialChannelSuccess = createAction(CONST.ON_DELETE_SOCIAL_CHANNEL_SUCCESS);

export const getCategoryFeedbacks = createAction(CONST.GET_CATEGORY_FEEDBACKS);
export const getCategoryFeedbacksSuccess = createAction(CONST.GET_CATEGORY_FEEDBACKS_SUCCESS);
export const onCreateCategoryFeedbacks = createAction(CONST.ON_CREATE_CATEGORY_FEEDBACKS);
export const onCreateCategoryFeedbacksSuccess = createAction(CONST.ON_CREATE_CATEGORY_FEEDBACKS_SUCCESS);
export const onDeleteCategoryFeedbacks = createAction(CONST.ON_DELETE_CATEGORY_FEEDBACKS);
export const onDeleteCategoryFeedbacksSuccess = createAction(CONST.ON_DELETE_CATEGORY_FEEDBACKS_SUCCESS);

export const getQuestionFeedbacks = createAction(CONST.GET_QUESTION_FEEDBACKS);
export const getQuestionFeedbacksSuccess = createAction(CONST.GET_QUESTION_FEEDBACKS_SUCCESS);
export const onCreateQuestionFeedbacks = createAction(CONST.ON_CREATE_QUESTION_FEEDBACKS);
export const onCreateQuestionFeedbacksSuccess = createAction(CONST.ON_CREATE_QUESTION_FEEDBACKS_SUCCESS);
export const onDeleteQuestionFeedbacks = createAction(CONST.ON_DELETE_QUESTION_FEEDBACKS);
export const onDeleteQuestionFeedbacksSuccess = createAction(CONST.ON_DELETE_QUESTION_FEEDBACKS_SUCCESS);

export const getNotification = createAction(CONST.GET_NOTIFICATION);
export const getNotificationSuccess = createAction(CONST.GET_NOTIFICATION_SUCCESS);
export const onUpdateNotification = createAction(CONST.ON_UPDATE_NOTIFICATION);
export const onUpdateNotificationSuccess = createAction(CONST.ON_UPDATE_NOTIFICATION_SUCCESS);

export const getAllSettingMember = createAction(CONST.GET_ALL_SETTING_MEMBER);
export const getAllSettingMemberSuccess = createAction(CONST.GET_ALL_SETTING_MEMBER_SUCCESS);
export const onUpdateSettingMember = createAction(CONST.ON_UPDATE_SETTING_MEMBER);
export const onUpdateSettingMemberSuccess = createAction(CONST.ON_UPDATE_SETTING_MEMBER_SUCCESS);

export const getSettingSms = createAction(CONST.GET_SETTING_SMS);
export const getSettingSmsSuccess = createAction(CONST.GET_SETTING_SMS_SUCCESS);
export const onUpdateSettingSms = createAction(CONST.ON_UPDATE_SETTING_SMS);
export const onUpdateSettingSmsSuccess = createAction(CONST.ON_UPDATE_SETTING_SMS_SUCCESS);

export const getSettingAutoSms = createAction(CONST.GET_SETTING_AUTO_SMS);
export const getSettingAutoSmsSuccess = createAction(CONST.GET_SETTING_AUTO_SMS_SUCCESS);

export const getExpressServices = createAction(CONST.GET_EXPRESS_SERVICES);
export const getExpressServicesSuccess = createAction(CONST.GET_EXPRESS_SERVICES_SUCCESS);
export const onAddExpressServices = createAction(CONST.ON_ADD_SETTING_EXPRESS_SERVICES);
export const onAddExpressServicesSuccess = createAction(CONST.ON_ADD_SETTING_EXPRESS_SERVICES_SUCCESS);
export const onUpdateExpressServices = createAction(CONST.ON_UPDATE_SETTING_EXPRESS_SERVICES);
export const onUpdateExpressServicesSuccess = createAction(CONST.ON_UPDATE_SETTING_EXPRESS_SERVICES_SUCCESS);
export const onDeleteExpressServices = createAction(CONST.ON_DELETE_SETTING_EXPRESS_SERVICES);
export const onDeleteExpressServicesSuccess = createAction(CONST.ON_DELETE_SETTING_EXPRESS_SERVICES_SUCCESS);