import React, { Component } from 'react';
import {
  FormGroup,
  Card,
  CardHeader,
  CardBody,
  Col,
  Row,
  Container,
  Table,
  CardFooter,
} from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as reportAction from './actions';
import PaginationFooter from '../../../../libs/components/PaginationFooter';

const tabList = [
  {
    id: 1,
    name: 'All',
  },
  {
    id: 2,
    name: 'Campaign 1',
  },
  {
    id: 3,
    name: 'Campaign 2',
  },
  {
    id: 4,
    name: 'Campaign 3',
  },
];

class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {
      marketingType: '1',
      page: 1,
      pageSize: 10,
      isOpen: false,
      type: 0,
    };
  }

  componentDidMount() {
    this.onInit();
  }

  toggle = (tab) => {
    const { marketingType } = this.state;
    if (marketingType != tab)
      this.setState({ marketingType: tab }, () => this.onInit());
  };

  onInit = () => {
    const { page, pageSize } = this.state;
    const { actions } = this.props;
    const payload = { page, pageSize };
    actions.getDataReport(payload);
  };

  onChangePagination = (page) => () =>
    this.setState({ page }, () => this.onInit());

  submitCoupon = (payload) => {
    const { actions } = this.props;
    console.log(payload);
    actions.onCreateCampain(payload);
  };

  getListByCategoryId = (id) => {
    const { actions } = this.props;
    actions.getCouponByCategoryId(id);
  };

  getTotalByType = (type = 0) => {
    const { reportList } = this.props;
    return reportList.reduce(
      (sum, report) =>
        sum +
        (type
          ? report.clientType == type
            ? report.billNumber
            : 0
          : report.billNumber),
      0
    );
  };

  render() {
    const { marketingType, page, type } = this.state;
    const { reportList, total } = this.props;
    return (
      <Card className="animated fadeIn card-home card-home--marketting">
        <CardBody className="d-flex p-0">
          <Container fluid className="d-flex w-100">
            <FormGroup className="table-marketting mt-0 w-100 d-flex">
              <Card className="table-list-marketting w-100">
                <CardHeader>
                  <h4>
                    <span>Report</span>
                  </h4>
               
                </CardHeader>
                <CardBody>
                  <div className="table-marketting-content">
                    <Table borderless striped>
                      <thead>
                        <tr>
                          <th>Campaign Name</th>
                          <th>Coupon Active</th>
                          <th>Coupon Expire</th>
                          <th>Total User</th>
                          <th>Total User Reject</th>
                          <th>Total User Used</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                        reportList.map((item) => (
                            <tr key={item.id}>
                              <td>{item.campaignName}</td>
                              <td>{item.totalCouponActive}</td>
                              <td>{item.totalExpireDate}</td>
                              <td>{item.totalUser}</td>
                              <td>{item.totalUserReject}</td>
                              <td>{item.totalUserUsed}</td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
                <CardFooter className="pt-0">
                  <PaginationFooter
                    currentPage={page}
                    totalSearch={total}
                    onChangePagination={this.onChangePagination}
                  />
                </CardFooter>
              </Card>
            </FormGroup>
          </Container>
        </CardBody>
      </Card>
    );
  }
}

function mapStateToProps(state) {
  return {
    reportList: state.Report.reportList,
    totalReport: state.Report.totalReport,
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {
    ...reportAction,
  };
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(Report);
