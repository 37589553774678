import React, { useRef, Fragment } from 'react';
import { withRouter } from 'react-router-dom';

import MetisMenu from 'react-metismenu';
import classnames from 'classnames'
import { MainNav } from './NavItems';

function Nav(props) {

    const inputEl = useRef();

    function isPathActive(path) {
        return props.location.pathname.startsWith(path);
    }

    function DefaultLink({
        className,
        classNameActive,
        classNameHasActiveChild,
        active,
        hasActiveChild,
        to,
        externalLink,
        hasSubMenu,
        toggleSubMenu,
        activateMe,
        children,
    }) {

        function onSelect() {
            console.log(2222222222)
            // inputEl.current.blur();
            activateMe()
        }

        return (
            <a
                className={classnames(
                    className,
                    active && classNameActive,
                    hasActiveChild && classNameHasActiveChild,
                )}
                href={to}
                onClick={hasSubMenu ? toggleSubMenu : onSelect}
                target={externalLink ? '_blank' : undefined}
            >
                {children}
            </a>
        )
    };

    return (
        <Fragment>
            <h5 className="app-sidebar__heading">Menu</h5>
            <MetisMenu
                content={MainNav}
                activeLinkFromLocation
                className="vertical-nav-menu"
                iconNamePrefix=""
                ref={inputEl}
                LinkComponent={DefaultLink}
                classNameStateIcon="pe-7s-angle-down" />
        </Fragment>
    );
}


export default withRouter(Nav);