import freeze from 'deep-freeze';
import { handleActions } from 'redux-actions';
import * as actions from './actions';

export const name = 'GiftCard';

const initialState = {
  giftCardList: [],
  total: 0,
};

export default handleActions({
  [actions.getDataGiftCardSuccess]: (state, action) => freeze({
    ...state,
    giftCardList: action.payload.data,
    total: action.payload.total
  }),
}, initialState);
