import React, { Component } from "react";
import Dropzone from "react-dropzone";

// for profile picture
export default class ImageUpload extends Component {
    state = { warningMsg: "" };

    onDrop = (accepted, rejected) => {
        if (Object.keys(rejected).length !== 0) {
            const message = "Please submit valid file type";
            this.setState({ warningMsg: message });
        } else {
            this.props.addFile(accepted);
            this.setState({ warningMsg: "" });
            console.log(accepted[0].preview);

            var blobPromise = new Promise((resolve, reject) => {
                const reader = new window.FileReader();
                reader.readAsDataURL(accepted[0]);
                reader.onloadend = () => {
                    const base64data = reader.result;
                    resolve(base64data);
                };
            });
            blobPromise.then(value => {
                // console.log(value);
            });
        }
    };

    render() {
        const { files } = this.props;
        const thumbs = files.map((file, id) => (
            <img key={id} src={file.preview} alt="profile" />
        ));

        const render = Object.keys(files).length !== 0 ? files.map(file => <aside>{thumbs}</aside>) : <><span><i class="fa fa-file-image-o" aria-hidden="true"></i></span><p className="hello">Drag and drop or click to upload image</p></>;

        return (
            <div className="image-upload">
                <p>{this.state.warningMsg}</p>
                <Dropzone
                    className="image-drop"
                    multiple={false}
                    onDrop={(accepted, rejected) => this.onDrop(accepted, rejected)}
                >
                    {({ isDragAccept, isDragReject, acceptedFiles, rejectedFiles }) => {
                        if (isDragReject) return "Please submit a valid file";
                        return render;
                    }}
                </Dropzone>

            </div>
        );
    }
}

