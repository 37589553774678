import { apiBaseURL } from '../config';
import { request } from '../services/api';

const baseEndpoint = `${apiBaseURL}`;

function onLogin({email, password}) {
    const endpoint = `${baseEndpoint}/account/sign_in`;
    const data = {payload: {email, password}}
    return request(endpoint, {
        method: 'POST',
        data,
    });
}

export default {
    onLogin,
};
