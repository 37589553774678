import React, { Component, Fragment } from 'react';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Button,
} from 'reactstrap';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import Avatar from '../../../../assets/img/avatar-none.png';
import { menu } from './menu';
import classnames from 'classnames';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import cx from 'classnames';
import DefaultLogo from './DefaultLogo';
import SearchBox from './SearchBox';
import UserBox from './UserBox';

export class DefaultHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  setSelectedNavigation = (path) => {
    const listPath = this.props.history.location.pathname;
    return listPath.indexOf(path) > -1;
  };

  logOut = () => {
    this.props.clearDataShop();
    localStorage.removeItem('xApiKey');
    this.props.history.push('/login');
  };

  selectShop = () => {
    localStorage.removeItem('xApiKey');
    this.props.history.push('/login');
  };

  render() {
    const {
      shopSelect,
      history: {
        location: { pathname },
      },
      headerBackgroundColor,
      enableHeaderShadow,
      enableMobileMenuSmall,
    } = this.props;

    return (
      <Fragment>
        <ReactCSSTransitionGroup
          component="div"
          className={cx('app-header', headerBackgroundColor, {
            'header-shadow': enableHeaderShadow,
          })}
          transitionName="HeaderAnimation"
          transitionAppear={true}
          transitionAppearTimeout={1500}
          transitionEnter={false}
          transitionLeave={false}
        >
          <DefaultLogo />

          <div
            className={cx('app-header__content', {
              'header-mobile-open': enableMobileMenuSmall,
            })}
          >
            {
              // <div className="app-header-left">
              //   <SearchBox />
              // </div>
            }
            <div className="app-header-right">
              <UserBox shopSelect={shopSelect} logOut={this.logOut} />
            </div>
          </div>
        </ReactCSSTransitionGroup>
        {/* <header>
          <div className="header-container">
            <div className="header-menu">
              {
                menu.map((item) => (
                  <Link key={item.id} to={item.route} className={classnames({ selected: pathname.includes(item.route) })}> {item.name}</Link>
                ))
              }
            </div>
            <div className="header-right">
              <UncontrolledDropdown>
                <DropdownToggle caret className="header-dropdown-right">
                  <span><img src={shopSelect.avatar ? 'data:image/png;base64,' + shopSelect.avatar : Avatar} alt="user" /></span>
                  <p>{shopSelect.companyName}</p>
                </DropdownToggle>
                <DropdownMenu right className="header-dropdown-right-menu home-dropdown bg-white">
                  <DropdownItem onClick={this.logOut}>Logout</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </div>
        </header> */}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  enableHeaderShadow: state.Layout.enableHeaderShadow,
  closedSmallerSidebar: state.Layout.closedSmallerSidebar,
  headerBackgroundColor: state.Layout.headerBackgroundColor,
  enableMobileMenuSmall: state.Layout.enableMobileMenuSmall,
});

export default connect(mapStateToProps, null)(DefaultHeader);
