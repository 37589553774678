import React, { Component } from 'react';
import {
    FormGroup, Card, CardHeader, CardBody, UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle,
    Col, Label, Input, Row, CustomInput, CardImg, Button
} from 'reactstrap';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as settingAction from '../../actions';
import { isEqual } from 'lodash'
import MapLogo from '../../../../../../assets/img/map-logo.png'
import YelpLogo from '../../../../../../assets/img/yelp-logo.png'
import InstagramLogo from '../../../../../../assets/img/instagram-logo.png'
import FBLogo from '../../../../../../assets/img/facebook-logo.png'
import ModalDeleteChannel from './ModalDeleteChannel'
import ModalHandleSocialChannel from './ModalHandleSocialChannel'
import PageTitle from '../../../../container/components/PageTitle'

class SocialChannel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            socialList: [],
            isOpenDeleteChannel: 0,
            isCreateChannel: false,
            socialChannel: {
                channelName: '',
                channelUrl: '',
                id: 0
            }
        };
    }

    componentDidMount() {
        const { actions, socialList } = this.props;
        actions.getSocialChannelList()
        if (socialList && !!socialList.length) {
            this.setState({ socialList })
        }
    }

    componentDidUpdate(prevProps) {
        const { actions, socialList, deleteSocialSuccess, createSocialSuccess } = this.props;
        if (!isEqual(socialList, prevProps.socialList) && !isEqual(socialList, this.state.socialList)) {
            this.setState({ socialList })
        }
        if (createSocialSuccess > prevProps.createSocialSuccess) {
            this.setState({
                isCreateChannel: false
            })
            actions.getSocialChannelList()
        }

        if (deleteSocialSuccess > prevProps.deleteSocialSuccess) {
            this.setState({
                isOpenDeleteChannel: 0
            })
            actions.getSocialChannelList()
        }
    }

    onChange = ({ target: { name, value } }) => {
        const { socialList } = this.state;
        let newList = []
        if (socialList.some(social => social.channelName === name)) {
            newList = socialList.map(item => {
                return {
                    ...item,
                    channelUrl: name == item.channelName ? value : item.channelUrl
                }
            })
        }
        else newList = [...socialList, { id: 0, channelName: name, channelUrl: value }]
        this.setState({
            socialList: newList
        })
    }

    onUpdate = () => {
        const { socialList } = this.state;
        const { actions } = this.props;

        const payload = { request: socialList }
        actions.onUpdateSocialChannel(payload)
    }

    onDeleteChannel = (id) => () => {
        this.setState({
            isOpenDeleteChannel: id
        })
    }

    confirmDelete = () => {
        const { isOpenDeleteChannel } = this.state;
        const { actions } = this.props;
        actions.onDeleteSocialChannel(isOpenDeleteChannel)
    }

    createSocialChannel = () => {
        this.setState({
            socialChannel: {
                channelName: '',
                channelUrl: '',
                id: 0
            },
            isCreateChannel: true
        })
    }

    onChangeSocialChannel = ({ target: { name, value } }) => {
        this.setState(prevState => ({
            socialChannel: {
                ...prevState.socialChannel,
                [name]: value
            }
        }))
    }

    onSave = () => {
        const { socialChannel } = this.state;
        const { actions } = this.props;
        const payload = socialChannel
        actions.onCreateSocialChannel(payload)
    }

    filterChannelUrl = (name) => {
        const { socialList } = this.state;
        return socialList.filter(social => social.channelName === name).map(social => social.channelUrl)
    }

    render() {

        const { socialList, isOpenDeleteChannel, isCreateChannel, socialChannel } = this.state;

        return (
            <Card className="animated fadeIn card-home">
                <PageTitle >
                    <div className="feedback-header">
                        <h4>Setup link social rating</h4>
                    </div>
                </PageTitle>
                <CardBody className="card-body-box d-flex flex-column">
                    <FormGroup className="social-items">
                        <div className="social-items-icon">
                            <img src={FBLogo} alt="FB-logo" />
                        </div>
                        <div className="social-items-input">
                            <Input value={this.filterChannelUrl("FACEBOOK")} onChange={this.onChange} name="FACEBOOK" placeholder="Facebook ex: https://facebook.com/fgstyle" />
                        </div>
                    </FormGroup>
                    <FormGroup className="social-items">
                        <div className="social-items-icon">
                            <img src={MapLogo} alt="GG-logo" />
                        </div>
                        <div className="social-items-input">
                            <Input value={this.filterChannelUrl("GOOGLE")} onChange={this.onChange} name="GOOGLE" placeholder="Google ex: https://google.com/fgstyle" />
                        </div>
                    </FormGroup>
                    <FormGroup className="social-items">
                        <div className="social-items-icon">
                            <img src={YelpLogo} alt="Yelp-logo" />
                        </div>
                        <div className="social-items-input">
                            <Input value={this.filterChannelUrl("YELP")} onChange={this.onChange} name="YELP" placeholder="Yelp ex: https://yelp.com/fbstyle" />
                        </div>
                    </FormGroup>
                    <FormGroup className="social-items">
                        <div className="social-items-icon">
                            <img src={InstagramLogo} alt="Instagram-logo" />
                        </div>
                        <div className="social-items-input">
                            <Input value={this.filterChannelUrl("INSTAGRAM")} onChange={this.onChange} name="INSTAGRAM" placeholder="Instagram ex: https://www.instagram.com/fgstyle" />
                        </div>
                    </FormGroup>

                    <FormGroup className="text-center mt-auto">
                        <button onClick={this.onUpdate} className="btn-active">Save</button>
                    </FormGroup>
                </CardBody>
                <ModalDeleteChannel
                    isOpen={isOpenDeleteChannel}
                    toggleDelete={() => this.setState({ isOpenDeleteChannel: 0 })}
                    onDelete={this.confirmDelete}
                />
                <ModalHandleSocialChannel
                    isOpen={isCreateChannel}
                    socialChannel={socialChannel}
                    onChange={this.onChangeSocialChannel}
                    onSave={this.onSave}
                    toggleModal={() => this.setState({ isCreateChannel: false })} />
            </Card>
        );
    }
}

function mapStateToProps(state) {
    return {
        socialList: state.Setting.socialList,
        createSocialSuccess: state.Setting.createSocialSuccess,
        updateSocialSuccess: state.Setting.updateSocialSuccess,
        deleteSocialSuccess: state.Setting.deleteSocialSuccess
    };
}

function mapDispatchToProps(dispatch) {
    const actions = {
        ...settingAction,
    };
    return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(SocialChannel);
