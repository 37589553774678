import { all, put, call, takeEvery } from 'redux-saga/effects';
import * as action from '../actions';
import API from '../../../../../api/report'
import * as actionDefault from '../../../container/actions'

export function* handleGetDataReport(payload) {
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.getReportList, payload);
    if (response && response.data && response.data.code && response.data.code === 200) {
      yield put(action.getDataReportSuccess({ data: response.data.payload }));
    }
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    console.log(error);
    yield put(actionDefault.isLoadingSuccess(true));
  }
}

export function* handleGetDataReportSMS(payload) {
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.getReportList, payload);
    if (response && response.data && response.data.code && response.data.code === 200) {
      yield put(action.getDataReportSMSSuccess({ data: response.data.payload.data, total: response.data.payload.total,totalSmsRemain:  response.data.payload.totalSmsRemain}));
    }
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    console.log(error);
    yield put(actionDefault.isLoadingSuccess(true));
  }
}

export default function* root() {
  yield all([
    takeEvery(action.getDataReport, handleGetDataReport),
    takeEvery(action.getDataReportSMS, handleGetDataReportSMS),
  ]);
}
