import { all, put, call, takeEvery } from 'redux-saga/effects';
import * as action from '../actions';
import API from '../../../../../api/owner';
import * as actionDefault from '../../../container/actions';

export function* handleGetDetailOwner() {
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.getOwnerDetail);
    if (
      response &&
      response.data &&
      response.data.code &&
      response.data.code === 200
    ) {
      yield put(action.getDetailOwnerSuccess(response.data.payload));
    }
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    console.log(error);
    yield put(actionDefault.isLoadingSuccess(true));
  }
}

export function* handleOnUpdateCoupon(payload) {
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.onUpdateStaff, payload);
    const status =
      response &&
      response.data &&
      response.data.code &&
      response.data.code === 200;
    yield put(action.onUpdateOwnerSuccess(status));
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    console.log(error);
    yield put(actionDefault.isLoadingSuccess(true));
  }
}

export function* handleOnGetOwnerSchedule(payload) {
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.getScheduleOwner, payload);
    const status =
      response &&
      response.data &&
      response.data.code &&
      response.data.code === 200;
    if (status)
      yield put(action.onGetOwnerScheduleSuccess(response.data.payload));
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    console.log(error);
    yield put(actionDefault.isLoadingSuccess(true));
  }
}

export function* handleOnUpdateOwnerSchedule(payload) {
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.updateScheduleOwner, payload);
    const status =
      response &&
      response.data &&
      response.data.code &&
      response.data.code === 200;
    if (status) {
      yield put(action.onUpdateOwnerScheduleSuccess(status));
    }
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    console.log(error);
    yield put(actionDefault.isLoadingSuccess(true));
  }
}

export function* handleOnUpdateAboutShop(payload) {
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.updateAboutOwner, payload);
    const status =
      response &&
      response.data &&
      response.data.code &&
      response.data.code === 200;
    if (status) {
      yield put(action.onUpdateAboutUsSuccess(status));
    }
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    console.log(error);
    yield put(actionDefault.isLoadingSuccess(true));
  }
}

export default function* root() {
  yield all([
    takeEvery(action.getDetailOwner, handleGetDetailOwner),
    takeEvery(action.onUpdateOwner, handleOnUpdateCoupon),
    takeEvery(action.onGetOwnerSchedule, handleOnGetOwnerSchedule),
    takeEvery(action.onUpdateOwnerSchedule, handleOnUpdateOwnerSchedule),
    takeEvery(action.onUpdateAboutUs, handleOnUpdateAboutShop),
  ]);
}
