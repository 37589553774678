import React, { Component } from 'react';
import {
  FormGroup,
  Card,
  CardBody,
  Col,
  Label,
  Row,
} from 'reactstrap';
import { connect } from 'react-redux';
import { isEqual, isEmpty } from 'lodash'
import { bindActionCreators } from 'redux';
import * as settingAction from '../../actions';
import PageTitle from '../../../../container/components/PageTitle'

class SettingExpressServices extends Component {
	constructor(props) {
		super(props);
		this.state = {
			expressList: []
		};
	}

	componentDidMount() {
		const { actions, expressList } = this.props;
		if (!isEmpty(expressList)) {
			this.setState({
				expressList: expressList
			})
		}
		else {
			actions.getExpressServices()
		}
	}

	componentDidUpdate(prevProps) {
		const { expressList } = this.props;
		if (!isEqual(expressList, prevProps.expressList)) {
			this.setState({
				expressList: expressList
			})
		}
	}
	onChange = (index) => ({ target: { name, value } }) => {
		if (name === 'expressValue' && value <= 0) {
			value = 0;
		}
		const { expressList } = this.state;
		const expressList_change = expressList.map((item, i) => {
			if (i == index) {
				return {
					...item,
					[name]: value
				}
			}
			return {...item};
		});
		this.setState({expressList: expressList_change});
	}

	checkExpressValue = () => {
		const { expressList } = this.state;
		var error_message = '';
		if (expressList.length > 1) {
			var expressValue = {};
			expressList.forEach((item, index) => {
				if (index > 0) {
					if (expressValue.value >= item.expressValue) {
						error_message = `Express value ${index + 1} must be greater than express value ${expressValue.index + 1}`;
						return false;
					}
				}
				expressValue = {
					index: index,
					value: item.expressValue
				};
			})
		}
		if (error_message == '') {
			return true;
		}
		else {
			alert(error_message);
			return false;
		}
	}

	addExpress = () => {
		this.setState(prevState => ({
			expressList: [
				...prevState.expressList,
				{
					id: '',
					expressValue: 0,
					commissionValue: 0
				}
			]
		}))
	}
	updateExpress =  (item) => (e) => {
		e.preventDefault();
		const check = this.checkExpressValue();
		if (!check) return false;
		const { actions } = this.props;
		if (window.confirm('Are you sure want to Save?')) {
			if (item.id && item.id != '') {
				actions.onUpdateExpressServices({"data": [item]})
			}
			else {
				actions.onAddExpressServices(item)
			}
			actions.getExpressServices();
		}
	}

	deleteExpress =  (id, index) => (e) => {
		e.preventDefault();
		const { expressList } = this.state;
		if (expressList.length > 1) {
			if (window.confirm('Are you sure want to Delete?')) {
				if (id != '') {
					const { actions } = this.props;
					actions.onDeleteExpressServices(id)
				}
				expressList.splice(index, 1)
				this.setState({
					expressList: expressList
				})
			}
		}
	}

	render() {
		const {expressList} = this.state;

		return (
		<>
			<Card className="animated fadeIn card-home">
			<PageTitle>
				<h4>Express Services</h4>
				<span className="btn-add-feedback" onClick={this.addExpress}><i className="fa fa-plus" aria-hidden="true"></i></span>
			</PageTitle>
			<CardBody className="card-body-box d-flex flex-column">
				<FormGroup row>
					<Col sm={12} md={12}>
						{
							expressList.length > 0 &&
							expressList.map((item, index) => {
								let commission_price_option = '';
								let start_value = (50*index) + 1;
								let end_value = 50*(index + 1);
								for(let i = 0 ; i <= 20; i++) {
									let selected = '';
									if (i == item.expressValue) {
										selected = 'selected';
									}
									commission_price_option += `<option value=${i} ${selected}>${i}.00</option>`;
								}
								return (
									<Row>
										<Col sm={6}>
											<Row>
												<Label sm={3}><h5>Express value</h5></Label>
												<Col sm={4}>
													<input
													type='number'
													className="form-control" 
													value={item.expressValue}
													onChange={this.onChange(index)}
													name="expressValue"
													/>
												</Col>
												<Label sm={5}><h6>From ${start_value}.00 - ${end_value}.00</h6></Label>
											</Row>
										</Col>
										<Col sm={4}>
											<Row>
												<Label sm={3}><h5>Commission value</h5></Label>
												<Col sm={4}>
													<select
													className="form-control" 
													value={item.commissionValue}
													onChange={this.onChange(index)}
													name="commissionValue"
													dangerouslySetInnerHTML={{ __html: commission_price_option }}
													>
													</select>
												</Col>
												<Col sm={5}></Col>
											</Row>
										</Col>
										<Col sm={2}>
											<Row className="m-auto">
												<span class="btn-add-feedback btn-add-feedback--check mr-1" onClick={this.updateExpress(item)}><i class="fa fa-check" aria-hidden="true"></i></span>
												<span class="btn-add-feedback btn-add-feedback--remove" onClick={this.deleteExpress(item.id, index)}><i class="fa fa-minus" aria-hidden="true"></i></span>
											</Row>
										</Col>
									</Row>
								)
							})
						}
						
						
					</Col>
				</FormGroup>
				{/* <FormGroup className="text-center mt-auto">
				<button
					onClick={this.onSave}
					className="btn-active mx-2"
				>
					Save
				</button>
				</FormGroup> */}
			</CardBody>
			</Card>
		</>
		);
	}
}

function mapStateToProps(state) {
	return {
		expressList: state.Setting.expressServices
	};
}

function mapDispatchToProps(dispatch) {
	const actions = {
		...settingAction,
	};
	return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingExpressServices);