import React, { Component } from 'react';
import {
  FormGroup,
  Card,
  CardHeader,
  CardBody,
  UncontrolledButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Col,
  Label,
  Input,
  Row,
  CustomInput,
  CardImg,
  Button,
} from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as serviceiAction from '../actions';
import 'react-datepicker/dist/react-datepicker.css';
import Picture from '../../../../../assets/img/picture.png';
import ModalDelete from './ModalDelete';
import { SUCCESS, WARNING } from '../../../../../constants';
import ModaGallery from './ModaGallery'
import PageTitle from '../../../container/components/PageTitle'

class ServiceHandle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCreate: true,
      serviceDetail: {
        serviceName: '',
        price: '',
        duration: '',
        isShowReviews: 0,
        description: '',
        catId: '',
        image: '',
        isOpenDelete: false,
        id: 0,
        isOpenGallery: 0,
        idImage: 0,
        isShowPrice: 0,
        isShownOnCheckIn: 0,
		commissionPrice: 0,
		isApplyCommissionPriceAll: 0,
      },
      page: 1
    };
  }

  componentDidMount() {
    const {
      history: {
        location: { pathname },
      },
      serviceList,
      galleryCategoryService,
      history,
      actions,
    } = this.props;

    let catId = 0;
    if (pathname.indexOf('edit') > -1) {
      const id = pathname.split('/').slice(-1).pop();
      const service = serviceList && serviceList.filter((sv) => sv.id == id);
      if (service && !!service.length) {
        catId = service[0].catId;
        this.setState({
          serviceDetail: service[0],
          catId,
        });
      } else history.push('/service');
    }
    if (pathname.indexOf('create') > -1) {
      catId = pathname.split('/').slice(-1).pop();
      this.setState({ catId });
    } else {
      this.setState({ isCreate: false });
    }

    if (!galleryCategoryService || !galleryCategoryService.length) {
      actions.getImageCategoryService()
    }
  }

  componentDidUpdate(prevProps) {
    const {
      createServiceSuccess,
      updateServiceSuccess,
      deleteServiceSuccess,
      history,
      getGalleryServiceSuccess
    } = this.props;

    if (
      createServiceSuccess > prevProps.createServiceSuccess ||
      updateServiceSuccess > prevProps.updateServiceSuccess ||
      deleteServiceSuccess > prevProps.deleteServiceSuccess
    ) {
      history.push('/service');
    }

    const isCreate =
      createServiceSuccess > prevProps.createServiceSuccess
        ? 1
        : createServiceSuccess < prevProps.createServiceSuccess
          ? -1
          : 0;
    if (isCreate === 1 || isCreate === -1) {
      this.props.addNotification(
        isCreate === 1 ? SUCCESS : WARNING,
        `Create service ${isCreate === 1 ? 'successful' : 'failed'}.`
      );
    }

    const isUpdate =
      updateServiceSuccess > prevProps.updateServiceSuccess
        ? 1
        : updateServiceSuccess < prevProps.updateServiceSuccess
          ? -1
          : 0;
    if (isUpdate === 1 || isUpdate === -1) {
      this.props.addNotification(
        isUpdate === 1 ? SUCCESS : WARNING,
        `Update service ${isUpdate === 1 ? 'successful' : 'failed'}.`
      );
    }

    const isDelete =
      deleteServiceSuccess > prevProps.deleteServiceSuccess
        ? 1
        : deleteServiceSuccess < prevProps.deleteServiceSuccess
          ? -1
          : 0;
    if (isDelete === 1 || isDelete === -1) {
      this.props.addNotification(
        isDelete === 1 ? SUCCESS : WARNING,
        `Delete service ${isDelete === 1 ? 'successful' : 'failed'}.`
      );
    }

    if (getGalleryServiceSuccess > prevProps.getGalleryServiceSuccess) {
      this.setState({
        isOpenGallery: 2
      })
    }
  }

  onChange = ({ target: { name, value } }) => {
    this.setState((prevState) => ({
      serviceDetail: {
        ...prevState.serviceDetail,
        [name]: value,
      },
    }));
  };

  onChangeImage = ({ target: { files, name } }) => {
    if (files && !!files.length) {
      var reader = new FileReader();
      var file = files[0];

      reader.onload = (upload) => {
        this.setState({
          [name]: upload.target.result,
          [`${name}File`]: files,
        });
      };
      reader.readAsDataURL(file);
    }
  };

  deleteImage = (name) => () => {
    this.setState({
      [name]: '',
      [`${name}File`]: '',
    });
  };

  onHandleService = () => {
    const { isCreate, imagePath, image, catId, serviceDetail, idImage } = this.state;
    const { actions } = this.props;
    const param = {
      ...serviceDetail,
      id: isCreate ? 0 : serviceDetail.id,
      catId,
      idImage,
      price: serviceDetail.price ? serviceDetail.price : 0,
      duration: serviceDetail.duration ? serviceDetail.duration : 0,
      image: imagePath
        ? imagePath.replace(/^data:image\/[a-z]+;base64,/, '')
        : image,
		commissionPrice: serviceDetail.commissionPrice ? serviceDetail.commissionPrice : 0,
    };
    if (isCreate) actions.onCreateService(param);
    else actions.onUpdateService(param);
  };

  toggleDelete = (status) => () => {
    this.setState({
      isOpenDelete: status,
    });
  };

  deleteService = () => {
    const {
      serviceDetail: { id },
    } = this.state;
    const { actions } = this.props;
    actions.onDeleteService(id);
  };

  changeCategory = (catId) => () => this.setState({ catId });

  changeShowReviews = ({ target: { checked } }) =>
    this.setState((prevState) => ({
      serviceDetail: {
        ...prevState.serviceDetail,
        isShowReviews: checked ? 0 : 1,
      },
    }));

  changeShowPrice = ({ target: { checked } }) => {
    this.setState((prevState) => ({
      serviceDetail: {
        ...prevState.serviceDetail,
        isShowPrice: checked ? 1 : 0,
      },
    }));
  }

  changeShowOnCheckIn = ({ target: { checked } }) => {
    this.setState((prevState) => ({
      serviceDetail: {
        ...prevState.serviceDetail,
        isShownOnCheckIn: checked ? 1 : 0,
      },
    }));
  }

  changeApplyCommissionPriceAll = ({ target: { checked } }) => {
    this.setState((prevState) => ({
      serviceDetail: {
        ...prevState.serviceDetail,
        isApplyCommissionPriceAll: checked ? true : false,
      },
    }));
  }
  

  onSelectCategpryService = (id) => () => {
    const { page } = this.state;
    const { actions } = this.props;
    this.setState({ idCategory: id }, () => actions.getImageByCategoryID({ categoryId: id, page }))
  }

  onSelectGalleryService = (idImage, imagePath) => {
    this.setState({ idImage, isOpenGallery: 0, imagePath })
  }

  onChangePagination = (page) => () => {
    const { idCategory } = this.state;
    const { actions } = this.props;
    this.setState({ page }, () => actions.getImageByCategoryID({ categoryId: idCategory, page }))
  }

  render() {
    const {
      isCreate,
      serviceDetail,
      imagePath,
      isOpenDelete,
      catId,
      isOpenGallery,
      idImage,
      page,
      imageUrl,

    } = this.state;
    const { categoryList, galleryCategoryService, galleryCategoryServiceByID, total } = this.props;

    const categorySelect =
      categoryList && categoryList.filter((cate) => cate.id == catId);
	let commission_price_option = '';
	for(let i = 0 ; i <= 20; i++) {
		let selected = '';
		if (i == serviceDetail.commissionPrice) {
			selected = 'selected';
		}
		commission_price_option += `<option value=${i} ${selected}>${i}.00</option>`;
	}
    return (
      <>
        <Card className="animated fadeIn card-home">
          <PageTitle>
            <FormGroup className="d-flex mb-0">
              <button
                className="btn-back"
                onClick={() => this.props.history.goBack()}
              >
                Back
              </button>
            </FormGroup>
          </PageTitle>
          <CardBody className="card-body-box d-flex flex-column">
            <FormGroup row className="row-coupon">
              <Label sm={2}>Service Name</Label>
              <Col sm={7}>
                <Input
                  value={serviceDetail.serviceName || ''}
                  name="serviceName"
                  onChange={this.onChange}
                  className="input-radius"
                  placeholder="Enter service name"
                  autoComplete="off"
                />
              </Col>
            </FormGroup>
            
            <FormGroup row className="row-coupon">
              <Label sm={2}>Sale Price</Label>
              <Col sm={5} md={7}>
                <Row>
                  <Col sm={2}>
                  { serviceDetail.isShowPrice == 0 && (
                    <Input
                      value={serviceDetail.price}
                      name="price"
                      onChange={this.onChange}
                      className="input-radius"
                      placeholder="Enter price"
                      autoComplete="off"
                    />
                  )}
                  </Col>
				  <Col sm={6}>
                    <Row>
                      <Label sm={6}>Commission Price</Label>
                      <Col sm={6}>
						<select
						className="form-control" 
						value={serviceDetail.commissionPrice}
						onChange={this.onChange}
						name="commissionPrice"
						dangerouslySetInnerHTML={{ __html: commission_price_option }}
						>
						</select>
                      </Col>
                    </Row>
                  </Col>
                  <Col sm={4}>
                    <Row>
                      <Label sm={6}>Duration</Label>
                      <Col sm={6}>
                        <Input
                          value={serviceDetail.duration}
                          name="duration"
                          onChange={this.onChange}
                          className="input-radius"
                          type="number"
                          autoComplete="off"
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
				<Row>
                  <Col sm={2}></Col>
                  <Col sm={3}></Col>
				  <Col sm={7}>
                    <Row className="mt-2">
                      <Col sm={12} className="d-flex">
						<CustomInput
                          className="custom-input-large font-bold pl-0"
                          checked={serviceDetail.isApplyCommissionPriceAll}
                          onChange={this.changeApplyCommissionPriceAll}
                          name="isApplyCommissionPriceAll"
                          type="checkbox"
                          id="isApplyCommissionPriceAll"
                        />
						<Label>* Note: Apply commission price for all service</Label>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </FormGroup>

            <FormGroup row className="row-coupon">
              <Label sm={2}></Label>
              <Col sm={7}>
                <Row>
                  <Col sm={4}>
                    <Row className="align-items-center">
                      <Label sm={4}>Hide</Label>
                      <Col >
                        <CustomInput
                          className="custom-input-large font-bold pl-0"
                          checked={serviceDetail.isShowPrice}
                          onChange={this.changeShowPrice}
                          name="showPrice"
                          type="checkbox"
                          id="showPrice"
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col sm={8}>
                    <Row className="align-items-center">
                      <Label sm={6}>Show On CHECK-IN</Label>
                      <Col >
                        <CustomInput
                          className="custom-input-large font-bold pl-0"
                          checked={serviceDetail.isShownOnCheckIn}
                          onChange={this.changeShowOnCheckIn}
                          name="isShownOnCheckIn"
                          type="checkbox"
                          id="isShownOnCheckIn"
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </FormGroup>
            <FormGroup row className="row-coupon">
              <Label sm={2}>Category</Label>
              <Col sm={7}>
                <Row>
                  <Col sm={6}>
                    <UncontrolledButtonDropdown>
                      <DropdownToggle
                        caret color="warning"
                      >
                        {categorySelect &&
                          categorySelect[0] &&
                          categorySelect[0].categoryName}
                      </DropdownToggle>
                      <DropdownMenu>
                        {categoryList &&
                          categoryList.map((cate) => (
                            <DropdownItem
                              key={cate.id}
                              onClick={this.changeCategory(cate.id)}
                            >
                              {cate.categoryName}
                            </DropdownItem>
                          ))}
                      </DropdownMenu>
                    </UncontrolledButtonDropdown>
                  </Col>
                  <Col sm={6}>
                    <Row className="align-items-center">
                      <Label sm={6}>Is Show</Label>
                      <Col sm={6}>
                        <CustomInput
                          className="custom-input-large font-bold pl-0"
                          checked={serviceDetail.isShowReviews == 0}
                          onChange={this.changeShowReviews}
                          name="showReviews"
                          type="checkbox"
                          id="showReviews"
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </FormGroup>

            <FormGroup row className="row-coupon align-items-start">
              <Label sm={2}>Description</Label>
              <Col sm={7}>
                <Input
                  type="textarea"
                  rows="4"
                  value={serviceDetail.description || ''}
                  name="description"
                  onChange={this.onChange}
                  className="input-radius"
                  autoComplete="off"
                />
              </Col>
            </FormGroup>

            <FormGroup row className="row-coupon align-items-start mb-5">
              <Label sm={2}>Image</Label>
              <Col sm={10} className="d-flex align-items-center">
                <div className="file-button-none square">
                  <Label className="mb-0" htmlFor="imagePath">
                    <Input
                      hidden
                      name="imagePath"
                      id="imagePath"
                      onChange={this.onChangeImage}
                      onClick={(event) => (event.target.value = null)}
                      type="file"
                      accept="image/*"
                    />
                    <CardImg
                      src={
                        imagePath
                          ? imagePath
                          : serviceDetail.urlImage
                            ? serviceDetail.urlImage
                            : Picture
                      }
                      id="img_upload_id"
                    />
                  </Label>
                  {imagePath && (
                    <Button
                      className="file-btn-delete"
                      onClick={this.deleteImage('imagePath')}
                    >
                      <i className="fa fa-times-circle" aria-hidden="true"></i>
                    </Button>
                  )}
                </div>
                <Button
                  className="btn-gallery-coupon ml-3" color="warning"
                  onClick={() => this.setState({ isOpenGallery: true })}
                >
                  Select from library
                </Button>
              </Col>
            </FormGroup>

            <FormGroup className="text-center mt-auto">
              {!isCreate && (
                <button
                  onClick={this.toggleDelete(true)}
                  className="btn-delete mx-2"
                >
                  Delete Service
                </button>
              )}
              <button
                onClick={this.onHandleService}
                className="btn-active mx-2"
              >
                Save Service
              </button>
            </FormGroup>
          </CardBody>
        </Card>
        <ModalDelete
          isOpen={isOpenDelete}
          onDelete={this.deleteService}
          toggleDelete={this.toggleDelete}
        />
        <ModaGallery
          isOpen={isOpenGallery}
          toggleModal={() => this.setState({ isOpenGallery: 0 })}
          onSelect={this.onSelectCategpryService}
          onSelectService={this.onSelectGalleryService}
          idImage={idImage}
          total={total}
          page={page}
          onChangePagination={this.onChangePagination}
          galleryCategoryService={galleryCategoryService}
          galleryCategoryServiceByID={galleryCategoryServiceByID} />
      </>
    );
  }
}

function mapStateToProps(state) {
  console.log(state)
  return {
    serviceList: state.Service.serviceList,
    categoryList: state.Service.categoryList,
    total: state.Service.total,
    createServiceSuccess: state.Service.createServiceSuccess,
    updateServiceSuccess: state.Service.updateServiceSuccess,
    deleteServiceSuccess: state.Service.deleteServiceSuccess,
    galleryCategoryService: state.Service.galleryCategoryService,
    galleryCategoryServiceByID: state.Service.galleryCategoryServiceByID,
    getGalleryCategorySuccess: state.Service.getGalleryCategorySuccess,
    getGalleryServiceSuccess: state.Service.getGalleryServiceSuccess
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {
    ...serviceiAction,
  };
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceHandle);
