import { all, put, call, takeEvery } from 'redux-saga/effects';
import * as action from '../actions';
import API from '../../../../../api/staff'
import * as actionDefault from '../../../container/actions'

export function* handleGetStaffList(payload) {
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.getStaffList, payload);
    if (response && response.data && response.data.code && response.data.code === 200) {
      yield put(action.getStaffListSuccess(response.data.payload));
    }
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    console.log(error);
    yield put(actionDefault.isLoadingSuccess(true));
  }
}

export function* handleOnCreateStaff(payload) {
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.onCreateStaff, payload);
    const status = response && response.data && response.data.code && response.data.code === 200
    yield put(action.onCreateStaffSuccess(status));
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    console.log(error);
    yield put(actionDefault.isLoadingSuccess(true));
  }
}

export function* handleGetStaffById({ payload }) {
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.getDetailStaffById, payload);
    yield put(action.getDetailStaffSuccess(response.data.payload));
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    console.log(error);
    yield put(actionDefault.isLoadingSuccess(true));
  }
}

export function* handleGetReviewStaffById({ payload }) {
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.getDetailReviewStaffById, payload);
    yield put(action.getDetailReviewStaffSuccess(response.data.payload));
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    console.log(error);
    yield put(actionDefault.isLoadingSuccess(true));
  }
}

export function* handleOnUpdateStaff(payload) {
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.onUpdateStaff, payload);
    const status = response && response.data && response.data.code && response.data.code === 200
    yield put(action.onUpdateStaffSuccess(status));
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    console.log(error);
    yield put(actionDefault.isLoadingSuccess(true));
  }
}

export function* handleOnDeleteStaff({ payload }) {
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.onDeleteStaff, payload);
    const status = response && response.data && response.data.code && response.data.code === 200
    yield put(action.onDeleteStaffSuccess(status));
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    console.log(error);
    yield put(actionDefault.isLoadingSuccess(true));
  }
}

export function* handleGetScheduleStaff({ payload: { id } }) {
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.getScheduleStaffById, id);
    if (response && response.data && response.data.code && response.data.code === 200) {
      console.log(response)
      yield put(action.getScheduleStaffSuccess(response.data.payload));
    }
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    console.log(error);
    yield put(actionDefault.isLoadingSuccess(true));
  }
}

export function* handleOnUpdateScheduleStaff(payload) {
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.onUpdateScheduleStaff, payload);
    const status = response && response.data && response.data.code && response.data.code === 200
    yield put(action.onUpdateScheduleStaffSuccess(status));
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    console.log(error);
    yield put(action.onUpdateScheduleStaffSuccess(false));
    yield put(actionDefault.isLoadingSuccess(true));
  }
}

export default function* root() {
  yield all([
    takeEvery(action.getStaffList, handleGetStaffList),
    takeEvery(action.onCreateStaff, handleOnCreateStaff),
    takeEvery(action.getDetailStaff, handleGetStaffById),
    takeEvery(action.onUpdateStaff, handleOnUpdateStaff),
    takeEvery(action.onDeleteStaff, handleOnDeleteStaff),
    takeEvery(action.getScheduleStaff, handleGetScheduleStaff),
    takeEvery(action.onUpdateScheduleStaff, handleOnUpdateScheduleStaff),
    takeEvery(action.getDetailReviewStaff, handleGetReviewStaffById),
  ]);
}
