import { all, put, call, takeEvery } from 'redux-saga/effects';
import * as action from '../actions';
import API from '../../../../../api/appointment'
import * as actionDefault from '../../../container/actions'
import moment from 'moment'

export function* handleGetDataAppointment(payload) {
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.getAppointmentList, payload);
    if (
      response &&
      response.data &&
      response.data.code &&
      response.data.code === 200
    ) {

      yield put(
        action.getDataAppointmentSuccess(response.data.payload)
      );
    }
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    console.log(error);
    yield put(actionDefault.isLoadingSuccess(true));
  }
}

export function* handleGetScheduleAppointment(payload) {
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.getAppoitmentDataApi, payload);
    if (
      response &&
      response.data &&
      response.data.code &&
      response.data.code === 200
    ) {
      const {
        data: {
          payload: { data, groups },
        },
      } = response;
      const convertData = data.map((item) => ({
        ...item,
        start_time: moment(item.start_time),
        end_time: moment(item.end_time),
      }));
      yield put(
        action.getScheduleAppointmentSuccess({ data: convertData, groups })
      );
    }
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    console.log(error);
    yield put(actionDefault.isLoadingSuccess(true));
  }
}

export function* handleGetDataService(payload) {
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.getServiceList, payload);
    if (
      response &&
      response.data &&
      response.data.code &&
      response.data.code === 200
    ) {
      yield put(action.getDataServiceSuccess(response.data.payload));
    }
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    console.log(error);
    yield put(actionDefault.isLoadingSuccess(true));
  }
}

export function* handleGetDataStaff(payload) {
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.getStaffList, payload);
    if (
      response &&
      response.data &&
      response.data.code &&
      response.data.code === 200
    ) {
      yield put(action.getDataStaffSuccess(response.data.payload));
    }
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    console.log(error);
    yield put(actionDefault.isLoadingSuccess(true));
  }
}

export function* handleGetDataCustomer(payload) {
  const {
    payload: { isNotShowLoad },
  } = payload;
  try {
    if (!isNotShowLoad) yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.getCustomerList, payload);
    if (
      response &&
      response.data &&
      response.data.code &&
      response.data.code === 200
    ) {
      yield put(action.getDataCustomerSuccess(response.data.payload));
    }
    if (!isNotShowLoad) yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    console.log(error);
    if (!isNotShowLoad) yield put(actionDefault.isLoadingSuccess(true));
  }
}

export function* handleOnCreateCustomer(payload) {
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.onCreateCustomerApi, payload);
    const status = response && response.data && response.data.code && response.data.code === 200

    yield put(action.onCreateCustomerSuccess({ status, data: response.data.payload }));
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    console.log(error);
    yield put(actionDefault.isLoadingSuccess(true));
  }
}

export function* handleOnCreateAppoitment(payload) {
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.onCreateAppoitmentApi, payload);
    const status = response && response.data && response.data.code && response.data.code === 200
    yield put(action.onCreateAppoitmentSuccess({ status, message: !status ? response.data.message : '' }));
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    const { message } = error;
    yield put(action.onCreateAppoitmentSuccess({ status: false, message }));
    yield put(actionDefault.isLoadingSuccess(true));
  }
}

export function* handleOnEditAppoitment(payload) {
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.onEditAppoitmentApi, payload);
    const status = response && response.data && response.data.code && response.data.code === 200
    yield put(action.onEditAppoitmentSuccess({ status, message: !status ? response.data.message : '' }));
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    const { message } = error;
    yield put(action.onEditAppoitmentSuccess({ status: false, message }));
    yield put(actionDefault.isLoadingSuccess(true));
  }
}

export function* handleOnDeleteAppoitment(payload) {
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.onDeleteAppoitmentApi, payload);
    const status = response && response.data && response.data.code && response.data.code === 200
    yield put(action.onDeleteAppoitmentSuccess({ status, message: !status ? response.data.message : '' }));
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    const { message } = error;
    yield put(action.onDeleteAppoitmentSuccess({ status: false, message }));
    yield put(actionDefault.isLoadingSuccess(true));
  }
}

export function* handleOnGetTimeSlot(payload) {
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.onGetTimeSlotApi, payload);
    if (response && response.data && response.data.code && response.data.code === 200)
      yield put(action.onGetTimeSlotSuccess(response.data.payload));
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    console.log(error);
    yield put(actionDefault.isLoadingSuccess(true));
  }
}


export default function* root() {
  yield all([
    takeEvery(action.getDataAppointment, handleGetDataAppointment),
    takeEvery(action.getScheduleAppointment, handleGetScheduleAppointment),
    takeEvery(action.getDataService, handleGetDataService),
    takeEvery(action.getDataStaff, handleGetDataStaff),
    takeEvery(action.getDataCustomer, handleGetDataCustomer),
    takeEvery(action.onCreateCustomer, handleOnCreateCustomer),
    takeEvery(action.onCreateAppoitment, handleOnCreateAppoitment),
    takeEvery(action.onEditAppoitment, handleOnEditAppoitment),
    takeEvery(action.onDeleteAppoitment, handleOnDeleteAppoitment),
    takeEvery(action.onGetTimeSlot, handleOnGetTimeSlot),
  ]);
}
