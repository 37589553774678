import React, { Component, Suspense } from 'react';
import {
  Route, Switch, Redirect,
} from 'react-router-dom';
import map from 'lodash/map';
import routes from '../../../routes';
import DefaultHeader from './components/DefaultHeader';
import DefaultSidebar from './components/DefaultSidebar';
import Page404 from '../components/Page404/page404'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as loginAction from '../components/Login/actions';
import * as defaultAction from './actions';
import { isEmpty, get } from 'lodash'
import Interwind from '../../../assets/img/Interwind.svg'
import ReactNotification, { store } from 'react-notifications-component'
import ResizeDetector from 'react-resize-detector';
import cx from 'classnames'
import { SUCCESS, WARNING, DANGER } from '../../../constants'


const loading = () => (
  <div>Loading...</div>
);

export class DefautLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount() {
    const { actions, shopSelect } = this.props;
    if (localStorage.getItem('xApiKey')) {
      if (isEmpty(shopSelect)) {
        let shop = localStorage.getItem('shopSelect')
        if (shop)
          actions.selectShop(JSON.parse(shop))
      }
    }
  }

  clearDataShop = () => {
    const { actions } = this.props;
    actions.clearDataShop()
  }

  addNotification = (type, message) => {
    if (message) {
      let icon = ''
      switch (type) {
        case SUCCESS:
          icon = "fa fa-check-circle";
          break;
        case DANGER:
          icon = "fa fa-exclamation-circle";
          break;
        case WARNING:
          icon = "fa fa-exclamation-triangle";
          break;
        default: break;
      }
      store.addNotification({
        type: type || "default",
        insert: "top-right",
        container: "top-right",
        animationIn: ["animated", "fadeInRight"],
        animationOut: ["animated", "fadeOutRight"],
        content: (
          <div className={`notification-custom-${type || "default"}`}>
            {
              icon &&
              <div className="notification-custom-icon">
                <i className={icon} />
              </div>
            }
            <div className="notification-custom-content">
              <p className="notification-message">{message}</p>
            </div>
          </div>
        ),
        dismiss: {
          duration: 5000
        }
      });
    }
    else
      console.log("Missing argument for parameter")
  }

  render() {
    if (!localStorage.getItem('xApiKey')) {
      return <Redirect to="/login" />;
    }
    const { shopSelect, isLoading, colorScheme, enableFixedHeader, enableFixedSidebar, enableFixedFooter, enableClosedSidebar, closedSmallerSidebar, enableMobileMenu } = this.props;
    return (
      <ResizeDetector
        handleWidth
        render={({ width }) => (
          <div className={cx(
            "app-container app-theme-" + colorScheme,
            { 'fixed-header': enableFixedHeader },
            { 'fixed-sidebar': enableFixedSidebar || width < 1250 },
            { 'fixed-footer': enableFixedFooter },
            { 'closed-sidebar': enableClosedSidebar || width < 1250 },
            { 'closed-sidebar-mobile': closedSmallerSidebar || width < 1250 },
            { 'sidebar-mobile-open': enableMobileMenu },
          )}>
            <ReactNotification />
            <DefaultHeader
              shopSelect={shopSelect}
              history={this.props.history}
              clearDataShop={this.clearDataShop}
              changeStateProject={this.changeStateProject}
            />
            <div className="app-main">
              <DefaultSidebar />
              <div className="app-main__outer">
                <div className="app-main__inner">
                  <div className="layout-body d-flex">
                    {
                      !!isLoading &&
                      <div className="loading-popup">
                        <img src={Interwind} />
                      </div>
                    }
                    <div className="layout-main d-flex flex-column">
                      <Suspense fallback={loading()}>
                        <Switch>
                          {map(routes, (route, idx) => route.component && (
                            <Route
                              key={idx}
                              path={route.path}
                              exact={route.exact}
                              name={route.name}
                              render={(props) => (
                                <route.component {...props} addNotification={this.addNotification} />
                              )}
                            />
                          ))}

                          <Redirect from="/" exact to="/dashboard" />
                          <Route path='/404' component={Page404} />
                          <Redirect to="/404" />
                        </Switch>
                      </Suspense>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      />
    );
  }
}


function mapStateToProps(state) {
  const shopSelect = get(state, 'Login.shopSelect');
  const isLoading = get(state, 'Layout.isLoading');
  const colorScheme = get(state, 'Layout.colorScheme');
  const enableFixedHeader = get(state, 'Layout.enableFixedHeader');
  const enableMobileMenu = get(state, 'Layout.enableMobileMenu');
  const enableFixedFooter = get(state, 'Layout.enableFixedFooter');
  const enableFixedSidebar = get(state, 'Layout.enableFixedSidebar');
  const enableClosedSidebar = get(state, 'Layout.enableClosedSidebar');
  const enablePageTabsAlt = get(state, 'Layout.enablePageTabsAlt');
  return {
    shopSelect,
    isLoading,
    colorScheme,
    enableFixedHeader,
    enableMobileMenu,
    enableFixedFooter,
    enableFixedSidebar,
    enableClosedSidebar,
    enablePageTabsAlt
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {
    ...loginAction,
    ...defaultAction
  };
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(DefautLayout)