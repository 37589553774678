import React, { Component, useEffect } from 'react';
import { ModalFooter, ModalHeader, ModalBody, Button } from 'reactstrap';
import ModalCustom from './Modal';
import moment from 'moment';
import Avatar from '../../../../../assets/img/avatar-user.png';
import { formatPhone } from '../../../../../libs/utils/utils';
import cx from 'classnames';

export default function ModalStaffSchedule({
  isOpen,
  onClose,
  btnClose,
  dataStaffSchedule: { group, data },
}) {
  function getStatus(item) {
    const valueTimeNow = moment().valueOf();
    return item.strStatus == 'CHECKIN'
      ? ''
      : item.end_time.valueOf() > valueTimeNow
        ? 'comming'
        : item.end_time.valueOf() < valueTimeNow && item.status
          ? 'arrived'
          : 'not-comming';
  }

  return (
    <ModalCustom
      className="modal-appoitment modal-appoitment--staff"
      isOpen={isOpen}
    >
      <ModalHeader>
        <span>Staff Name: </span> {group.staffName}
      </ModalHeader>
      <ModalBody style={{
        maxHeight: 'calc(100vh - 210px)',
        overflowY: 'auto'}}>
        {!!data.length
          ? data.sort((a, b) => a.startTime - b.startTime).map((item) => (
            <div className="staff-schedule-item">
              <div className="staff-schedule-item__meta">
                <img src={Avatar} />
              </div>
              <div className="staff-schedule-item__content">
                <h5>{item.customerName}</h5>
                <p>{formatPhone(item.customerPhone)}</p>
                <p className={cx('time-service', getStatus(item))}>
                  <span>{moment(item.start_time).format('ddd MM/DD hh:mm A')}</span> -
                    <span>{moment(item.end_time).format('hh:mm A')}</span>
                </p>
              </div>
            </div>
          ))
          : 'No appointment'}
      </ModalBody>
      <ModalFooter className="border-0 justify-content-center">
        <Button
          color="primary"
          type="submit"
          className="w-25"
          onClick={onClose}
        >
          {btnClose || 'OK'}
        </Button>
      </ModalFooter>
    </ModalCustom>
  );
}
