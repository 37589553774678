import { all, put, call, takeEvery } from 'redux-saga/effects';
import * as action from '../actions';
import API from '../../../../../api/luckySpin';
import * as actionDefault from '../../../container/actions';

export function* handleGetDataLuckySpin(payload) {
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.getDataLuckySpinApi, payload);
    if (
      response &&
      response.data &&
      response.data.code &&
      response.data.code === 200
    ) {
      yield put(action.getDataLuckySuccess(response.data.payload));
    }
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    console.log(error);
    yield put(actionDefault.isLoadingSuccess(true));
  }
}

export function* handleOnUpdateDataLuckySpin(payload) {
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.updateDataLuckySpinApi, payload);
    const status =
      response &&
      response.data &&
      response.data.code &&
      response.data.code === 200;
    yield put(action.onUpdateDataLuckySuccess(status));
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    console.log(error);
    yield put(action.onUpdateDataLuckySuccess(false));
    yield put(actionDefault.isLoadingSuccess(true));
  }
}

export default function* root() {
  yield all([takeEvery(action.getDataLucky, handleGetDataLuckySpin)]);
  yield all([takeEvery(action.onUpdateDataLucky, handleOnUpdateDataLuckySpin)]);
}
