import React, { Component } from 'react';
import {
  FormGroup,
  Card,
  CardHeader,
  CardBody,
  UncontrolledButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Col,
  Label,
  Input,
  CardFooter,
  Modal,
  Button,
} from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as staffAction from '../actions';
import { SEARCH_TYPE } from '../constants';
import Picture from '../../../../../assets/img/picture.png';
import PaginationFooter from '../../../../../libs/components/PaginationFooter';
import Rating from 'react-rating';
import Star from '../../../../../assets/img/star-review.svg';
import StarFull from '../../../../../assets/img/star-full.svg';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import PageTitle from '../../../container/components/PageTitle';
import ModalReview from './ModalReview';

class Staff extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startTime: '',
      endTime: '',
      keyword: '',
      optionStar: 0,
      page: 1,
      pageSize: 12,
      onShowModalDelete: false,
      isOpenModalCate: 0,
    };
  }

  componentDidMount() {
    this.onInit();
  }

  componentDidUpdate(prevProps, prevState) {
    const { typeSearch, startTime, endTime, keyword, optionStar } = this.state;
    if (typeSearch != prevState.typeSearch && typeSearch == SEARCH_TYPE.ALL) {
      this.setState(
        {
          startTime: '',
          endTime: '',
          keyword: '',
          page: 1,
        },
        () => this.onInit()
      );
    }

    if (typeSearch != prevState.typeSearch) {
      this.setState({
        startTime: typeSearch != SEARCH_TYPE.EXPIRE ? '' : startTime,
        endTime: typeSearch != SEARCH_TYPE.EXPIRE ? '' : endTime,
        keyword: typeSearch != SEARCH_TYPE.KEYWORD ? '' : keyword,
        optionStar: typeSearch != SEARCH_TYPE.STAR ? 0 : optionStar,
      });
    }

    if (
      keyword != prevState.keyword ||
      optionStar != prevState.optionStar ||
      startTime != prevState.startTime ||
      endTime != prevState.endTime
    )
      this.onInit();
  }

  onChange = ({ target: { name, value } }) => this.setState({ [name]: value });

  onInit = () => {
    const { actions } = this.props;
    const {
      keyword,
      startTime,
      endTime,
      optionStar,
      page,
      pageSize,
    } = this.state;
    let payload = {
      keyword,
      startTime: startTime
        ? moment(startTime).format('YYYY-MM-DD hh:mm:ss')
        : '',
      endTime: endTime ? moment(endTime).format('YYYY-MM-DD hh:mm:ss') : '',
      optionStar,
      page,
      pageSize,
    };

    actions.getStaffList(payload);
  };

  changeTypeSearch = (typeSearch) => () => this.setState({ typeSearch });

  changeStarSearch = (optionStar) => () => this.setState({ optionStar });

  handleChangeDate = (name, date) => {
    this.setState({
      [name]: date,
    });
  };

  onChangePagination = (page) => () => {
    this.setState({
      page
    }, () => this.onInit())
  }

  toggleModalReview = (status, id) => () => {
    const { actions } = this.props;
    actions.getDetailReviewStaff(id);
    this.setState({ isOpenModalCate: status })
  }


  onDeleteCategory = () => () => {
    this.setState({
      isShowDeleteCate: true
    })
  }

  toggleModal = (status) => () => this.setState({ isOpenModalCate: status })

  render() {
    const { optionStar, keyword, startTime, endTime, isOpenModalCate, page} = this.state;
    const { staffList, history, total, staffReviewList } = this.props;

    return (
      <Card className="animated fadeIn card-home">
        <PageTitle>
          <FormGroup className="form-search-reviews justify-content-between m-0 w-100">
            <div className="d-flex align-items-center">
              <div className="form-search">
                <UncontrolledButtonDropdown>
                  <DropdownToggle caret className="mr-2" color="warning">
                    {optionStar > 3
                      ? '> 3'
                      : optionStar <= 3 && optionStar > 0
                      ? '< 3'
                      : 'All Star'}
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={this.changeStarSearch(0)}>
                      All Star
                    </DropdownItem>
                    <DropdownItem onClick={this.changeStarSearch(1)}>
                      {'<'} 3 star
                    </DropdownItem>
                    <DropdownItem onClick={this.changeStarSearch(2)}>
                      {'>'} 3 star
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledButtonDropdown>

                <Input
                  className="input-keyword mr-2"
                  value={keyword}
                  name="keyword"
                  onChange={this.onChange}
                  placeholder="Name, email or phone"
                  autoComplete="off"
                />

                <DatePicker
                  className="form-control text-center input-search-staff"
                  name="startTime"
                  selected={startTime ? moment(startTime).toDate() : ''}
                  maxDate={endTime ? moment(endTime).toDate() : ''}
                  onChange={(date) => this.handleChangeDate('startTime', date)}
                  showYearDropdown
                  dateFormatCalendar="MMMM"
                  scrollableYearDropdown
                  yearDropdownItemNumber={15}
                  dateFormat="MM/dd"
                  autoComplete="off"
                  placeholderText="From"
                />
                <span>-</span>
                <DatePicker
                  className="form-control text-center input-search-staff"
                  name="endTime"
                  selected={endTime ? moment(endTime).toDate() : ''}
                  minDate={startTime ? moment(startTime).toDate() : ''}
                  onChange={(date) => this.handleChangeDate('endTime', date)}
                  showYearDropdown
                  dateFormatCalendar="MMMM"
                  scrollableYearDropdown
                  yearDropdownItemNumber={15}
                  dateFormat="MM/dd"
                  autoComplete="off"
                  placeholderText="To"
                />
              </div>
            </div>
            <div>
              <button
                className="btn-add"
                onClick={() => history.push('/staff/create')}
              >
                + Staff
              </button>
            </div>
          </FormGroup>
        </PageTitle>
        <CardBody className="card-body-full">
          <FormGroup row>
            {staffList &&
              staffList.map((staff) => (
                <Col sm={3} md={3} lg={3} key={staff.id} className="d-flex">
                  <div className="staff-item">
                    <div
                      className="staff-item-meta"
                      onClick={() => history.push(`/staff/edit/${staff.id}`)}
                    >
                      <img
                        className={staff.urlImage ? '' : 'empty'}
                        src={staff.urlImage ? staff.urlImage : Picture}
                        alt={`${staff.firstName} ${staff.lastName}`}
                      />
                    </div>
                    <div className="staff-item-info">
                      <h5
                        onClick={() => history.push(`/staff/edit/${staff.id}`)}
                      >
                        {staff.firstName} {staff.lastName}
                      </h5>
                      <p>
                        <span>
                          <Rating
                            initialRating={
                              staff.totalReview
                                ? Math.round(
                                    staff.totalStar / staff.totalReview
                                  )
                                : 0
                            }
                            readonly
                            emptySymbol={
                              <span className="star-rating-symbol">
                                <img src={Star} />
                              </span>
                            }
                            fullSymbol={
                              <span className="star-rating-symbol">
                                <img src={StarFull} />
                              </span>
                            }
                          />
                        </span>
                      </p>
                      <p className="total-reviews">
                        <h5 onClick={this.toggleModalReview(1, staff.id)}>{staff.totalReview} reviews</h5>
                      </p>
                    </div>
                    <div className="staff-group-btn">
                      <Button
                        color="primary"
                        onClick={() => history.push(`/staff/edit/${staff.id}`)}
                      >
                        Detail
                      </Button>
                      <Button onClick={() => history.push(`/staff/schedule/${staff.id}`)} color="warning">Schedule</Button>
                    </div>
                  </div>
                </Col>
              ))}
          </FormGroup>
          <CardFooter className="mt-auto">
            <PaginationFooter
              currentPage={page}
              totalSearch={total}
              onChangePagination={this.onChangePagination} />
          </CardFooter>
        </CardBody>

        <ModalReview
          isOpen={isOpenModalCate}
          staffReviewList={staffReviewList}
          toggleModal={this.toggleModal}
        />

      </Card>
    );
  }
}

function mapStateToProps(state) {
  return {
    staffList: state.Staff.staffList,
    staffReviewList : state.Staff.staffReviewList,
    total: state.Staff.total,
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {
    ...staffAction,
  };
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(Staff);
