const ACTION_NAME = 'FEEDBACK/STAFF';
export const GET_STAFF_LIST = `${ACTION_NAME}/GET_STAFF_LIST`;
export const GET_STAFF_LIST_SUCCESS = `${ACTION_NAME}/GET_STAFF_LIST_SUCCESS`;
export const ON_CREATE_STAFF = `${ACTION_NAME}/ON_CREATE_STAFF`;
export const ON_CREATE_STAFF_SUCCESS = `${ACTION_NAME}/ON_CREATE_STAFF_SUCCESS`;
export const GET_DETAIL_STAFF = `${ACTION_NAME}/GET_DETAIL_STAFF`;
export const GET_DETAIL_STAFF_SUCCESS = `${ACTION_NAME}/GET_DETAIL_STAFF_SUCCESS`;
export const ON_UPDATE_STAFF = `${ACTION_NAME}/ON_UPDATE_STAFF`;
export const ON_UPDATE_STAFF_SUCCESS = `${ACTION_NAME}/ON_UPDATE_STAFF_SUCCESS`;
export const ON_DELETE_STAFF = `${ACTION_NAME}/ON_DELETE_STAFF`;
export const ON_DELETE_STAFF_SUCCESS = `${ACTION_NAME}/ON_DELETE_STAFF_SUCCESS`;
export const GET_DETAIL_REVIEW_STAFF = `${ACTION_NAME}/GET_DETAIL_REVIEW_STAFF`;
export const GET_DETAIL_REVIEW_STAFF_SUCCESS = `${ACTION_NAME}/GET_DETAIL_REVIEW_STAFF_SUCCESS`;

export const GET_SCHEDULE_STAFF = `${ACTION_NAME}/GET_SCHEDULE_STAFF`;
export const GET_SCHEDULE_STAFF_SUCCESS = `${ACTION_NAME}/GET_SCHEDULE_STAFF_SUCCESS`;
export const ON_UPDATE_SCHEDULE_STAFF = `${ACTION_NAME}/ON_UPDATE_SCHEDULE_STAFF`;
export const ON_UPDATE_SCHEDULE_STAFF_SUCCESS = `${ACTION_NAME}/ON_UPDATE_SCHEDULE_STAFF_SUCCESS`;


export const EXPIRE_TYPE = {
    FROM_TO: 0,
    NOT_EXPIRE: 1
}

export const SEARCH_TYPE = {
    ALL: 0,
    KEYWORD: 1,
    EXPIRE: 2,
    STAR: 3
}