import React, { Component } from 'react';
import {
  FormGroup, Card, CardHeader, CardBody, CardFooter, UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle,
  Col, Label, Input, Collapse
} from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as dashboardAction from '../actions';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from 'react-datepicker';
import moment from 'moment'
import PaginationFooter from '../../../../../libs/components/PaginationFooter'
import {
  isMobileOnly
} from "react-device-detect";
import { ROUTER_URL } from '../../../../../constants';
import { AiOutlineCaretRight } from 'react-icons/all'
import { formatPhone } from '../../../../../libs/utils/utils'


class BadReviewsCoupon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phone: '',
      startTime: '',
      endTime: '',
      page: 1,
      isOpen: 0,
      pageSize: 10,
      isUsed: false,
      couponNumber: ''
    };
  }

  componentDidMount() {
    const { history: { location: { pathname } } } = this.props;
    const listPath = pathname.split('/');
    const type = listPath.pop();
    this.setState({
      isUsed: type === 'used'
    }, () => this.onInit())
  }

  onInit = () => {
    const { startTime, endTime, page, phone, pageSize, isUsed, couponNumber } = this.state;
    const { actions } = this.props;
    this.setState({
      isOpen: 0
    })
    const payload = {
      startTime: startTime ? moment(startTime).format('YYYY-MM-DD hh:mm:ss') : '',
      endTime: endTime ? moment(endTime).format('YYYY-MM-DD hh:mm:ss') : '',
      page,
      pageSize,
      phone,
      isUsed,
      couponNumber
    }
    actions.getReviewCoupon(payload)
  }

  onChange = ({ target: { name, value } }) => this.setState({ [name]: value })

  onChangePagination = (page) => () => {
    this.setState({
      page
    }, () => this.onInit())
  }

  onSearch = () => this.setState({ page: 1 }, () => this.onInit())

  handleChangeDate = (name, date) => {
    this.setState({
      [name]: date
    })
  }

  changePageSize = (pageSize) => () => this.setState({ pageSize, page: 1 }, () => this.onInit())

  render() {
    const { startTime, endTime, phone, page, isOpen, pageSize, couponNumber, isUsed } = this.state
    const { badReviews, history } = this.props;
    const { total, data } = badReviews
    return (
      <Card className="animated fadeIn card-reviews pt-0 mb-3">
        <CardHeader>
          <h4>Coupon {isUsed ? 'Used' : 'Sent'}</h4>
          <button className="btn-back color-inverse ml-auto" onClick={() => history.push(ROUTER_URL.DASH_BOARD)}>Back</button>
        </CardHeader>
        <CardBody>
          <FormGroup className="d-flex flex-wrap mb-4">
            <div className="form-search-reviews">
              <Input className="w-20 search-phone" value={couponNumber} onChange={this.onChange} name="couponNumber" placeholder="Coupon Number" />
              <Input className="w-15 search-phone" value={phone} onChange={this.onChange} name="phone" placeholder="Phone" />
              <DatePicker
                className="input-reviews-time"
                name="startTime"
                selected={startTime ? moment(startTime).toDate() : ''}
                maxDate={endTime ? moment(endTime).toDate() : ''}
                onChange={date => this.handleChangeDate('startTime', date)}
                showYearDropdown
                dateFormatCalendar="MMMM"
                scrollableYearDropdown
                yearDropdownItemNumber={15}
                placeholderText="From"
                dateFormat="MM/dd"
                autoComplete="off"
              />
              <span className="mx-3">-</span>
              <DatePicker
                className="input-reviews-time"
                name="endTime"
                selected={endTime ? moment(endTime).toDate() : ''}
                minDate={startTime ? moment(startTime).toDate() : ''}
                onChange={date => this.handleChangeDate('endTime', date)}
                showYearDropdown
                dateFormatCalendar="MMMM"
                scrollableYearDropdown
                yearDropdownItemNumber={15}
                placeholderText="To"
                dateFormat="MM/dd"
                autoComplete="off"
              />
              <button onClick={this.onSearch}>Search</button>
            </div>
          </FormGroup>

          <div className="form-group-reviews">
            <div className="header-reviews">
              <div className="w-15">Number</div>
              <div className="w-15 hidden-xs text-center">Discount Value</div>
              <div className="w-10 hidden-xs">Customer</div>
              <div className="w-15">Phone</div>
              <div className="w-20 hidden-xs">Start Date</div>
              <div className="w-10 hidden-xs">End Date</div>
              <div className="w-15">{isUsed ? 'Used' : 'Sent'} Date</div>
            </div>
            <div className="body-reviews">
              {
                data && data.map(item => (
                  <div key={item.couponNumber}>
                    <div className="row-reviews" onClick={() => isMobileOnly ? this.setState({ isOpen: isOpen != item.couponNumber ? item.couponNumber : 0 }) : null}>
                      <div className="row-content">
                        <span className={`icon-xs ${isOpen == item.couponNumber ? 'rotate' : ''}`}><AiOutlineCaretRight /></span>
                        <div>{item.couponNumber}</div>
                        <div className="hidden-xs text-center">{!!item.discountType ? '$' + item.discountValue : item.discountValue + '%'}</div>
                        <div className="hidden-xs">{item.customerName}</div>
                        <div>{formatPhone(item.customerPhone)}</div>
                        <div className="hidden-xs">{moment(item.startDate).format("MM/DD/YYYY")}</div>
                        <div className="hidden-xs">{moment(item.expireDate).format("MM/DD/YYYY")}</div>
                        <div>{moment(isUsed ? item.dateUsed : item.createDate).format("MM/DD/YYYY")}</div>
                      </div>
                      <div className="row-collapse">
                        <Collapse isOpen={isOpen == item.couponNumber}>
                          <div className="row-xs-reviews">
                            <div><b>Discount Value</b><p>{!!item.discountType ? '$' + item.discountValue : item.discountValue + '%'}</p></div>
                            <div><b>Start Date</b><p>{moment(item.startDate).format("MM/DD/YYYY")}</p></div>
                            <div><b>End Date</b><p>{moment(item.expireDate).format("MM/DD/YYYY")}</p></div>
                          </div>
                        </Collapse>
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </CardBody>
        <CardFooter className="d-flex align-items-center justify-content-between flex-wrap">
          <PaginationFooter
            pageSize={pageSize}
            currentPage={page}
            totalSearch={total}
            isShowTotal
            changePageSize={this.changePageSize}
            onChangePagination={this.onChangePagination} />
        </CardFooter>
      </Card >
    );
  }
}

function mapStateToProps(state) {
  return {
    badReviews: state.Dashboard.badReviews,
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {
    ...dashboardAction,
  };
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(BadReviewsCoupon);
