import { createAction } from 'redux-actions';
import * as CONST from './constants';

export const getDataCoupon = createAction(CONST.GET_DATA_COUPON);
export const getDataCouponSuccess = createAction(CONST.GET_DATA_COUPON_SUCCESS);
export const onCreateCoupon = createAction(CONST.ON_CREATE_COUPON);
export const onCreateCouponSuccess = createAction(CONST.ON_CREATE_COUPON_SUCCESS);
export const getDetailCoupon = createAction(CONST.GET_DETAIL_COUPON);
export const getDetailCouponSuccess = createAction(CONST.GET_DETAIL_COUPON_SUCCESS);
export const onUpdateCoupon = createAction(CONST.ON_UPDATE_COUPON);
export const onUpdateCouponSuccess = createAction(CONST.ON_UPDATE_COUPON_SUCCESS);
export const onDeleteCoupon = createAction(CONST.ON_DELETE_COUPON);
export const onDeleteCouponSuccess = createAction(CONST.ON_DELETE_COUPON_SUCCESS);

export const getGalleryCoupon = createAction(CONST.GET_GALLERY_COUPON);
export const getGalleryCouponSuccess = createAction(CONST.GET_GALLERY_COUPON_SUCCESS);

export const getCategoryCoupon = createAction(CONST.GET_CATEGORY_COUPON);
export const getCategoryCouponSuccess = createAction(CONST.GET_CATEGORY_COUPON_SUCCESS);
export const onCreateCategoryCoupon = createAction(CONST.ON_CREATE_CATEGORY_COUPON);
export const onCreateCategoryCouponSuccess = createAction(CONST.ON_CREATE_CATEGORY_COUPON_SUCCESS);
export const onUpdateCategoryCoupon = createAction(CONST.ON_UPDATE_CATEGORY_COUPON);
export const onUpdateCategoryCouponSuccess = createAction(CONST.ON_UPDATE_CATEGORY_COUPON_SUCCESS);
export const onDeleteCategoryCoupon = createAction(CONST.ON_DELETE_CATEGORY_COUPON);
export const onDeleteCategoryCouponSuccess = createAction(CONST.ON_DELETE_CATEGORY_COUPON_SUCCESS);

export const getCouponByCategoryId = createAction(CONST.GET_COUPON_BY_CATEGORY_ID);
export const getCouponByCategoryIdSuccess = createAction(CONST.GET_COUPON_BY_CATEGORY_ID_SUCCESS);