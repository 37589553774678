import freeze from 'deep-freeze';
import { handleActions } from 'redux-actions';
import * as actions from './actions';

export const name = 'Customer';

const initialState = {
  customerList: [],
  total: 0,
  dataProps: {},
  isOpenDownload: 0,
  listSignInByCustomer: [],
  historyTransactionData: {},
  detailCustomer: {},
};

export default handleActions({
  [actions.getDataCustomerSuccess]: (state, action) => freeze({
    ...state,
    customerList: action.payload.data,
    total: action.payload.total
  }),
  [actions.getListSignInCoVidSuccess]: (state, action) => freeze({
    ...state,
    dataProps: action.payload,
    isOpenDownload: state.isOpenDownload + 1
  }),
  [actions.getListSignInByCustomerSuccess]: (state, action) => freeze({
    ...state,
    listSignInByCustomer: action.payload,
  }),
  [actions.getHistoryTransactionSuccess]: (state, action) => freeze({
    ...state,
    historyTransactionData: action.payload,
  }),
  [actions.getDetailCustomerSuccess]: (state, action) => freeze({
    ...state,
    detailCustomer: action.payload,
  }),
}, initialState);
