import React, { Component } from 'react';
import {
  Form, FormGroup, Label, Input, Button,
} from 'reactstrap';
import { Redirect } from 'react-router-dom';
import Particles from 'react-particles-js';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as loginAction from './actions';
import LoginForm from './components/LoginForm'
import ShopGallery from './components/ShopGallery'

export class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      isFocusInputEmail: false,
      isFocusInputPW: false,
      isLoginPage: true
    };
  }

  componentDidMount() {
    const { isLoginSuccess } = this.props;
    if (isLoginSuccess) {
      this.setState({
        isLoginPage: false
      })
    }
  }

  componentDidUpdate(prevProps) {
    const { isLoginSuccess, shopsData } = this.props;
    if (shopsData) {
      const { shops } = shopsData;
      if (isLoginSuccess != prevProps.isLoginSuccess) {
        if (isLoginSuccess) {
          if (shops && shops.length === 1) {
            const id = shops[0].id
            this.selectShop(id)()
          }
          else {
            this.setState({
              isLoginPage: false
            })
          }
        }
      }
    }
  }

  onChange = (event) => {
    const { target } = event;
    const { name } = target;
    const { value } = target;
    this.setState({
      [name]: value,
    });
  }

  onFocusInput = (event) => {
    if (event.target.name === 'email') {
      this.setState({
        isFocusInputEmail: true,
      });
    } else {
      this.setState({
        isFocusInputPW: true,
      });
    }
  }

  onBlurInput = (event) => {
    if (event.target.name === 'email') {
      this.setState({
        isFocusInputEmail: false,
      });
    } else {
      this.setState({
        isFocusInputPW: false,
      });
    }
  }

  onSubmitLogin = (data) => () => {
    const { email, password } = data;
    const { actions } = this.props;
    actions.onLogin({ email, password })
  }

  selectShop = (id) => () => {
    const { shopsData: { shops }, actions } = this.props;
    const shopSelects = shops.filter(shop => shop.id == id)
    console.log(shopSelects)
    if (!!shopSelects.length) {
      const shop = shopSelects[0]
      actions.selectShop(shop)
      localStorage.setItem("xApiKey", shop.apiKey);
      localStorage.setItem("shopSelect", JSON.stringify(shop));
    }
  }

  render() {
    if (localStorage.getItem('xApiKey')) {
      return <Redirect to="/dashboard" />;
    }
    const { shopsData, message } = this.props;

    const { isLoginPage } = this.state;
    return (
      <div className="animated fadeIn" style={{ height: '100vh' }}>
        {
          isLoginPage ?
            <LoginForm onSubmitLogin={this.onSubmitLogin} message={message} /> :
            <ShopGallery shopsData={shopsData} selectShop={this.selectShop} />
        }

        <Particles
          className="canvas-login"
          style={{ position: 'absolute' }}
          params={{
            particles: {
              number: {
                value: 50,
                density: {
                  enable: true,
                  value_area: 800,
                },
              },
              color: {
                value: '#218c74',
              },
              shape: {
                type: 'circle',
                stroke: {
                  width: 0,
                  color: '#000000',
                },
                polygon: {
                  nb_sides: 5,
                },
                image: {
                  src: 'img/github.svg',
                  width: 100,
                  height: 100,
                },
              },
              opacity: {
                value: 0.5,
                random: false,
                anim: {
                  enable: false,
                  speed: 1,
                  opacity_min: 0.1,
                  sync: false,
                },
              },
              size: {
                value: 10,
                random: true,
                anim: {
                  enable: false,
                  speed: 40,
                  size_min: 0.1,
                  sync: false,
                },
              },
              line_linked: {
                enable: true,
                distance: 150,
                color: '#218c74',
                opacity: 0.4,
                width: 2,
              },
              move: {
                enable: true,
                speed: 6,
                direction: 'none',
                random: false,
                straight: false,
                out_mode: 'out',
                bounce: false,
                attract: {
                  enable: false,
                  rotateX: 600,
                  rotateY: 1200,
                },
              },
            },
            interactivity: {
              onresize: {
                enable: true,
                density_auto: true,
                density_area: 400, // nb_particles = particles.nb * (canvas width *  canvas height / 1000) / density_area
              },
              detect_on: 'canvas',
              events: {
                onhover: {
                  enable: false,
                  mode: 'repulse',
                },
                onclick: {
                  enable: false,
                  mode: 'push',
                },
                resize: true,
              },
              modes: {
                grab: {
                  distance: 400,
                  line_linked: {
                    opacity: 1,
                  },
                },
                bubble: {
                  distance: 400,
                  size: 40,
                  duration: 2,
                  opacity: 8,
                  speed: 3,
                },
                repulse: {
                  distance: 200,
                  duration: 0.4,
                },
                push: {
                  particles_nb: 4,
                },
                remove: {
                  particles_nb: 2,
                },
              },
            },
            retina_detect: true,
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoginSuccess: state.Login.isLoginSuccess,
    shopsData: state.Login.shopsData,
    shopSelect: state.Login.shopSelect,
    message: state.Login.message
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {
    ...loginAction
  };
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)