import freeze from 'deep-freeze';
import { handleActions } from 'redux-actions';
import * as actions from './actions';

export const name = 'Service';

const initialState = {
  serviceList: [],
  total: 0,
  serviceDetail: {},
  createServiceSuccess: 0,
  updateServiceSuccess: 0,
  deleteServiceSuccess: 0,

  categoryList: [],
  createCategorySuccess: 0,
  updateCategorySuccess: 0,
  deleteCategorySuccess: 0,

  getGalleryCategorySuccess: 0,
  galleryCategoryService: [],

  getGalleryServiceSuccess: 0,
  galleryCategoryServiceByID: [],
};

export default handleActions(
  {
    [actions.getServiceListSuccess]: (state, action) =>
      freeze({
        ...state,
        serviceList: action.payload,
      }),
    [actions.getDetailServiceSuccess]: (state, action) =>
      freeze({
        ...state,
        serviceDetail: action.payload,
      }),
    [actions.onCreateServiceSuccess]: (state, action) =>
      freeze({
        ...state,
        createServiceSuccess: action.payload
          ? state.createServiceSuccess + 1
          : state.createServiceSuccess - 1,
      }),
    [actions.onUpdateServiceSuccess]: (state, action) =>
      freeze({
        ...state,
        updateServiceSuccess: action.payload
          ? state.updateServiceSuccess + 1
          : state.updateServiceSuccess - 1,
      }),
    [actions.onDeleteServiceSuccess]: (state, action) =>
      freeze({
        ...state,
        deleteServiceSuccess: action.payload
          ? state.deleteServiceSuccess + 1
          : state.deleteServiceSuccess - 1,
      }),

    [actions.getCategoryListSuccess]: (state, action) =>
      freeze({
        ...state,
        categoryList: action.payload,
      }),
    [actions.onCreateCategorySuccess]: (state, action) =>
      freeze({
        ...state,
        createCategorySuccess: action.payload
          ? state.createCategorySuccess + 1
          : state.createCategorySuccess - 1,
      }),
    [actions.onUpdateCategorySuccess]: (state, action) =>
      freeze({
        ...state,
        updateCategorySuccess: action.payload
          ? state.updateCategorySuccess + 1
          : state.updateCategorySuccess - 1,
      }),
    [actions.onDeleteCategorySuccess]: (state, action) =>
      freeze({
        ...state,
        deleteCategorySuccess: action.payload
          ? state.deleteCategorySuccess + 1
          : state.deleteCategorySuccess - 1,
      }),
    [actions.getImageCategoryServiceSuccess]: (state, action) =>
      freeze({
        ...state,
        getGalleryCategorySuccess:state.getGalleryCategorySuccess + 1,
        galleryCategoryService: action.payload,
      }),
    [actions.getImageByCategoryIDSuccess]: (state, action) =>
      freeze({
        ...state,
        getGalleryServiceSuccess: state.getGalleryServiceSuccess + 1,
        galleryCategoryServiceByID: action.payload.data,
        total: action.payload.total
      }),
  },
  initialState
);
