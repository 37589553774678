import React from 'react';
import { Provider } from 'react-redux';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import ReactDOM from 'react-dom';
import configureStore, { history } from './stores';
import 'bootstrap/dist/css/bootstrap.css';
import './styles/index.scss';
// import './assets/styleThemes/base.scss';
import './assets/styleThemes/base.css';
import Login from './modules/base/components/Login/Login';
import DefautLayout from './modules/base/container/DefautLayout';
import AppoitmentSchedule from './modules/base/components/Appoitment/components/AppoitmentSchedule';
import DomToImage from './modules/base/components/_domToImage/DomToImage';
// import Page404 from './modules/base/components/Page404/page404';
import 'react-notifications-component/dist/theme.css'
import './index.css';

const store = configureStore({});
ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <HashRouter>
        <Switch>
          <Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />
          <Route exact path="/:apiKey/appointment-detail" name="Appoitment Schedule" render={(props) => <AppoitmentSchedule {...props} back={true} />} />

          <Route exact path="/appointment-detail" name="Appoitment Schedule" render={(props) => <AppoitmentSchedule {...props} back={true} />} />
          <Route path="/" name="Home" render={(props) => <DefautLayout {...props} />} />
          {/* <Route path='/404' component={Page404} />
          <Redirect to="/404" /> */}
        </Switch>
      </HashRouter>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root'),
);
