import { createAction } from 'redux-actions';
import * as CONST from './constants';

export const getDataMarketting = createAction(CONST.GET_DATA_MARKETTING);
export const getDataMarkettingSuccess = createAction(CONST.GET_DATA_MARKETTING_SUCCESS);

export const onCreateCampain = createAction(CONST.ON_CREATE_CAMPAIN);
export const onCreateCampainSuccess = createAction(CONST.ON_CREATE_CAMPAIN_SUCCESS);

export const onCheckBalance = createAction(CONST.ON_CHECK_BALANCE);
export const onCheckBalanceSuccess = createAction(CONST.ON_CHECK_BALANCE_SUCCESS);
