import React, { Component } from 'react';
import {
  FormGroup, Card, CardHeader, CardBody, UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle,
  Col, Label, Input, CardFooter, Modal
} from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as serviceAction from '../actions';
import { SEARCH_TYPE } from '../constants'
import Picture from '../../../../../assets/img/picture.png';
import PaginationFooter from '../../../../../libs/components/PaginationFooter';
import ModalHandleCategory from './ModalHandleCategory'
import ModalDelete from './ModalDelete'
import PageTitle from '../../../container/components/PageTitle'


class Service extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      pageSize: 12,
      onShowModalDelete: false,
      categoryId: '',
      serviceName: '',
      typeSearch: SEARCH_TYPE.ALL,
      isOpenModalCate: 0,
      category: {
        id: 0,
        categoryName: '',
        description: ''
      },
      categoryIdDelete: 0,
    };
  }

  componentDidMount() {
    this.onInit()
  }

  componentDidUpdate(prevProps, prevState) {
    const { typeSearch, categoryId, serviceName, isOpenModalCate } = this.state;
    const { 
      createCategorySuccess, updateCategorySuccess, deleteCategorySuccess, 
       } = this.props;

    if (typeSearch != prevState.typeSearch) {
      this.setState({
        categoryId: typeSearch == SEARCH_TYPE.ALL || typeSearch == SEARCH_TYPE.SERVICE_NAME ? '' : categoryId,
        serviceName: typeSearch == SEARCH_TYPE.ALL || typeSearch == SEARCH_TYPE.CATEGORY ? '' : serviceName,
      })
    }
    if (createCategorySuccess > prevProps.createCategorySuccess) {
      this.setState({
        isOpenModalCate: 0
      })
      this.onInit()
    }
    if (updateCategorySuccess > prevProps.updateCategorySuccess) {
      this.setState({
        isOpenModalCate: 0
      })
      this.onInit()
    }
    if (deleteCategorySuccess > prevProps.deleteCategorySuccess) {
      this.setState({
        isShowDeleteCate: false
      })
      this.onInit()
    }
    if (isOpenModalCate != prevState.isOpenModalCate && isOpenModalCate == 1) {
      this.setState({
        category: {
          id: 0,
          categoryName: '',
          description: ''
        },
      })
    }

    
  }

  onChange = ({ target: { name, value } }) => this.setState({ [name]: value })

  onInit = () => {
    const { actions } = this.props;
    const { page, pageSize } = this.state;
    let payload = {
      page,
      pageSize
    }
    actions.getCategoryList()
    actions.getServiceList(payload)
  }

  changeTypeSearch = (typeSearch) => () => this.setState({ typeSearch })

  changeCategorySearch = (categoryId) => () => this.setState({ categoryId })

  onChangePagination = (page) => () => {
    this.setState({
      page
    }, () => this.onInit())
  }

  onChangeCategory = ({ target: { name, value } }) => {
    this.setState(prevProps => ({
      category: {
        ...prevProps.category,
        [name]: value
      }
    }))
  }

  resultCategory = (cate) => {
    const { typeSearch, categoryId, serviceName, category } = this.state;
    const { serviceList } = this.props;
    if (typeSearch == SEARCH_TYPE.CATEGORY && !!categoryId) {
      return categoryId == cate.id
    }
    else if (typeSearch == SEARCH_TYPE.SERVICE_NAME) {
      return serviceList.some(service => service.catId == cate.id && service.serviceName.toLowerCase().indexOf(serviceName.toLowerCase()) > -1)
    }
    else return true
  }

  resultService = (sv, cateId) => {
    const { typeSearch, categoryId, serviceName } = this.state;
    let isTrue = true
    if (typeSearch == SEARCH_TYPE.CATEGORY && !!categoryId) {

      isTrue = sv.catId == categoryId
    }
    else if (typeSearch == SEARCH_TYPE.SERVICE_NAME) {
      isTrue = sv.serviceName.toLowerCase().indexOf(serviceName.toLowerCase()) > -1
    }
    return isTrue && sv.catId == cateId
  }

  toggleModalCategory = (status) => () => this.setState({ isOpenModalCate: status })

  toggleDelete = (status) => () => this.setState({ isShowDeleteCate: status })

  onSaveCategory = () => {
    const { category } = this.state;
    const { actions } = this.props;
    actions.onCreateCategory(category)
  }

  changeCategory = (id) => () => {
    const { actions, categoryList } = this.props;
    let cate = categoryList.filter(category => category.id == id)
    this.setState({
      category: cate[0],
      isOpenModalCate: 2
    })
  }

  onDeleteCategory = (id) => () => {
    this.setState({
      categoryIdDelete: id,
      isShowDeleteCate: true
    })
  }

  onConfirmDeleteCategory = () => {
    const { categoryIdDelete } = this.state;
    const { actions } = this.props;
    actions.onDeleteCategory(categoryIdDelete)
  }

  onSelectCategpryService = (id) => () => {
  }

  render() {
    const { typeSearch, categoryId, serviceName, isOpenModalCate, category, isShowDeleteCate, isOpenGallery, galleryServiceSelected } = this.state;
    const { serviceList, history, categoryList, galleryCategoryService, galleryCategoryServiceByID } = this.props;

    return (
      <>
        <Card className="animated fadeIn card-home card-home-main">
          <PageTitle>
            <FormGroup className="d-flex justify-content-between m-0 w-100">
              <div className="form-search-reviews">
                <UncontrolledButtonDropdown>
                  <DropdownToggle caret className="mr-3" color="warning">
                    {
                      typeSearch == SEARCH_TYPE.CATEGORY ? 'Search by Category' :
                        typeSearch == SEARCH_TYPE.SERVICE_NAME ? 'Search by Service Name' : 'All Service'
                    }
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={this.changeTypeSearch(SEARCH_TYPE.ALL)}>All Service</DropdownItem>
                    <DropdownItem onClick={this.changeTypeSearch(SEARCH_TYPE.CATEGORY)}>Category</DropdownItem>
                    <DropdownItem onClick={this.changeTypeSearch(SEARCH_TYPE.SERVICE_NAME)}>Service Name</DropdownItem>
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
                <div className="form-search">
                  {
                    typeSearch == SEARCH_TYPE.CATEGORY &&
                    <UncontrolledButtonDropdown>
                      <DropdownToggle caret color="info">
                        {categoryId ? categoryList.filter(cate => cate.id == categoryId).map(cate => cate.categoryName) : 'All Category'}
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem onClick={this.changeCategorySearch()}>All Category</DropdownItem>
                        {
                          categoryList && categoryList.map(category => <DropdownItem key={category.id} onClick={this.changeCategorySearch(category.id)}>{category.categoryName}</DropdownItem>)
                        }
                      </DropdownMenu>
                    </UncontrolledButtonDropdown>
                  }
                  {
                    typeSearch == SEARCH_TYPE.SERVICE_NAME &&
                    <Input className="input-keyword" value={serviceName} name="serviceName" onChange={this.onChange} placeholder="Enter service name" autoComplete="off" />
                  }
                </div>
              </div>
              <button className="btn-add" onClick={this.toggleModalCategory(1)}>+ Category</button>
            </FormGroup>
          </PageTitle>
          <CardBody className="card-body-full">
            {
              categoryList && categoryList.filter(category => this.resultCategory(category)).map(category => (
                <FormGroup row key={category.id} className="category-items">
                  <Col md={3} lg={2}>
                    <div className="label-category">
                      <Label><span>{category.categoryName}</span></Label>
                      <div className="group-btn-category">
                        <button onClick={() => history.push(`/service/create/${category.id}`)} className="btn-add-sv">+</button>
                        <button onClick={this.changeCategory(category.id)}><i className="fa fa-pencil" aria-hidden="true"></i></button>
                        <button onClick={this.onDeleteCategory(category.id)}><i className="fa fa-trash" aria-hidden="true"></i></button>
                      </div>
                    </div>
                  </Col>
                  <Col md={9} lg={10}>
                    <FormGroup row className="service-items mb-0">
                      {
                        serviceList && serviceList.filter(service => this.resultService(service, category.id)).map(service => (
                          <Col md={6} lg={4} key={service.id} className="d-flex">
                            <div className="service-item">
                              <div className="service-item-meta" onClick={() => history.push(`/service/edit/${service.id}`)}>
                                <span><img className={service.urlImage ? '' : 'empty'} src={service.urlImage ? service.urlImage : Picture} alt={service.serviceName} /></span>
                              </div>
                              <div className="service-item-info">
                                <h5 onClick={() => history.push(`/service/edit/${service.id}`)}>{service.serviceName}</h5>
                                <p>
                                  <span>{service.serviceCode}</span>
                                </p>
                              </div>
                            </div>
                          </Col>
                        ))
                      }
                    </FormGroup>
                  </Col>
                </FormGroup>
              ))
            }
          </CardBody>
          {/* <CardFooter>
          <PaginationFooter
            currentPage={page}
            totalSearch={total}
            onChangePagination={this.onChangePagination} />
        </CardFooter> */}
        </Card>
        <ModalHandleCategory
          isOpen={isOpenModalCate}
          category={category}
          onChange={this.onChangeCategory}
          toggleModal={this.toggleModalCategory}
          onSave={this.onSaveCategory}
          onDelete={this.onDeleteCategory} />
        <ModalDelete
          isOpen={isShowDeleteCate}
          toggleDelete={this.toggleDelete}
          onDelete={this.onConfirmDeleteCategory}
        />
       
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    serviceList: state.Service.serviceList,
    categoryList: state.Service.categoryList,
    createCategorySuccess: state.Service.createCategorySuccess,
    updateCategorySuccess: state.Service.updateCategorySuccess,
    deleteCategorySuccess: state.Service.deleteCategorySuccess,
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {
    ...serviceAction,
  };
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(Service);
