import { apiBaseURL } from '../config';
import { request } from '../services/api';

const baseEndpoint = `${apiBaseURL}`;

function getCouponList(data) {
    const searchParams = new URLSearchParams();
    Object.keys(data.payload).forEach(key => searchParams.append(key, data.payload[key]));
    const endpoint = `${baseEndpoint}/coupon/list_web?${searchParams.toString()}`;
    return request(endpoint, {
        method: 'GET',
        data,
    });
}

function onCreateCoupon(data) {
    const endpoint = `${baseEndpoint}/coupon/create`;
    return request(endpoint, {
        method: 'POST',
        data,
    });
}

function getDetailCouponById(id) {
    const endpoint = `${baseEndpoint}/coupon/detail/${id}`;
    return request(endpoint, {
        method: 'GET',
    });
}

function onUpdateCoupon(data) {
    const endpoint = `${baseEndpoint}/coupon/update`;
    return request(endpoint, {
        method: 'PUT',
        data,
    });
}

function onDeleteCoupon(id) {
    const endpoint = `${baseEndpoint}/coupon/delete/${id}`;
    return request(endpoint, {
        method: 'DELETE'
    });
}


function getGalleryList() {
    const endpoint = `${baseEndpoint}/library_image/getAllCouponImage`;
    return request(endpoint, {
        method: 'GET',
    });
}

function getCaterogyCoupon() {
    const endpoint = `${baseEndpoint}/coupon/category/getAll`;
    return request(endpoint, {
        method: 'GET',
    });
}

function onHandleCaterogyCoupon(data) {
    const endpoint = `${baseEndpoint}/coupon/category/saveOrUpdate`;
    return request(endpoint, {
        method: 'POST',
        data,
    });
}

function onDeleteCategoryCoupon(id) {
    const endpoint = `${baseEndpoint}/coupon/category/delete/${id}`;
    return request(endpoint, {
        method: 'DELETE'
    });
}

function getCouponById(categoryId) {
    const endpoint = `${baseEndpoint}/coupon/listByCategory?categoryId=${categoryId}`;
    return request(endpoint, {
        method: 'GET'
    });
}

function getImageCouponById(id) {
    const endpoint = `${baseEndpoint}/library_image/getImageCouponDetail/${id}`;
    return request(endpoint, {
        method: 'GET'
    });
}

export default {
    getCouponList,
    onCreateCoupon,
    getDetailCouponById,
    onUpdateCoupon,
    onDeleteCoupon,
    getGalleryList,
    getCaterogyCoupon,
    onHandleCaterogyCoupon,
    onDeleteCategoryCoupon,
    getCouponById,
    getImageCouponById
};
