const ACTION_NAME = 'ICHECKINS/LAYOUT';

export const IS_LOADING = `${ACTION_NAME}/IS_LOADING`;
export const IS_LOADING_SUCCESS = `${ACTION_NAME}/IS_LOADING_SUCCESS`;

// Theme Options
export const SET_ENABLE_BACKGROUND_IMAGE = `${ACTION_NAME}THEME_OPTIONS/SET_ENABLE_BACKGROUND_IMAGE`;

export const SET_ENABLE_MOBILE_MENU = `${ACTION_NAME}THEME_OPTIONS/SET_ENABLE_MOBILE_MENU`;
export const SET_ENABLE_MOBILE_MENU_SMALL = `${ACTION_NAME}THEME_OPTIONS/SET_ENABLE_MOBILE_MENU_SMALL`;

export const SET_ENABLE_FIXED_HEADER = `${ACTION_NAME}THEME_OPTIONS/SET_ENABLE_FIXED_HEADER`;
export const SET_ENABLE_HEADER_SHADOW = `${ACTION_NAME}THEME_OPTIONS/SET_ENABLE_HEADER_SHADOW`;
export const SET_ENABLE_SIDEBAR_SHADOW = `${ACTION_NAME}THEME_OPTIONS/SET_ENABLE_SIDEBAR_SHADOW`;
export const SET_ENABLE_FIXED_SIDEBAR = `${ACTION_NAME}THEME_OPTIONS/SET_ENABLE_FIXED_SIDEBAR`;
export const SET_ENABLE_CLOSED_SIDEBAR = `${ACTION_NAME}THEME_OPTIONS/SET_ENABLE_CLOSED_SIDEBAR`;
export const SET_ENABLE_FIXED_FOOTER = `${ACTION_NAME}THEME_OPTIONS/SET_ENABLE_FIXED_FOOTER`;

export const SET_ENABLE_PAGETITLE_ICON = `${ACTION_NAME}THEME_OPTIONS/SET_ENABLE_PAGETITLE_ICON`;
export const SET_ENABLE_PAGETITLE_SUBHEADING = `${ACTION_NAME}THEME_OPTIONS/SET_ENABLE_PAGETITLE_SUBHEADING`;
export const SET_ENABLE_PAGE_TABS_ALT = `${ACTION_NAME}THEME_OPTIONS/SET_ENABLE_PAGE_TABS_ALT`;

export const SET_BACKGROUND_IMAGE = `${ACTION_NAME}THEME_OPTIONS/SET_BACKGROUND_IMAGE`;
export const SET_BACKGROUND_COLOR = `${ACTION_NAME}THEME_OPTIONS/SET_BACKGROUND_COLOR`;
export const SET_COLOR_SCHEME = `${ACTION_NAME}THEME_OPTIONS/SET_COLOR_SCHEME`;
export const SET_BACKGROUND_IMAGE_OPACITY = `${ACTION_NAME}THEME_OPTIONS/SET_BACKGROUND_IMAGE_OPACITY`;

export const SET_HEADER_BACKGROUND_COLOR = `${ACTION_NAME}THEME_OPTIONS/SET_HEADER_BACKGROUND_COLOR`;