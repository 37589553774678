import { all, put, call, takeEvery } from 'redux-saga/effects';
import * as action from '../actions';
import API from '../../../../../api/setting'
import * as actionDefault from '../../../container/actions'

export function* handleGetSocialChannel() {
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.getSocialChannelList);
    if (response && response.data && response.data.code && response.data.code === 200)
      yield put(action.getSocialChannelListSuccess(response.data.payload));
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    console.log(error);
    yield put(actionDefault.isLoadingSuccess(true));
  }
}

export function* handleOnUpdateSocialChannel(payload) {
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.onUpdateChannelList, payload);
    const status = response && response.data && response.data.code && response.data.code === 200
    yield put(action.onUpdateSocialChannelSuccess(status));
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    console.log(error);
    yield put(actionDefault.isLoadingSuccess(true));
  }
}

export function* handleGetCategoryFeedbacks(payload) {
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.getCategoryFeedbacks, payload);

    if (response && response.data && response.data.code && response.data.code === 200)
      yield put(action.getCategoryFeedbacksSuccess(response.data.payload));
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    console.log(error);
    yield put(actionDefault.isLoadingSuccess(true));
  }
}

export function* handleGetQuestionFeedbacks(payload) {
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.getQuestionFeedbacks, payload);

    if (response && response.data && response.data.code && response.data.code === 200)
      yield put(action.getQuestionFeedbacksSuccess(response.data.payload));
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    console.log(error);
    yield put(actionDefault.isLoadingSuccess(true));
  }
}

export function* handleOnCreateQuestionFeedbacks(payload) {
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.onCreateQuestionFeedbacks, payload);

    const status = response && response.data && response.data.code && response.data.code === 200
    yield put(action.onCreateQuestionFeedbacksSuccess(status));
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    console.log(error);
    yield put(actionDefault.isLoadingSuccess(true));
  }
}

export function* handleOnDeleteQuestionFeedbacks({ payload }) {
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.onDeleteQuestionFeedbacks, payload);

    const status = response && response.data && response.data.code && response.data.code === 200
    yield put(action.onDeleteQuestionFeedbacksSuccess(status));
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    console.log(error);
    yield put(actionDefault.isLoadingSuccess(true));
  }
}

export function* handleOnCreateCategoryFeedbacks(payload) {
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.onCreateCategoryFeedbacks, payload);

    const status = response && response.data && response.data.code && response.data.code === 200
    yield put(action.onCreateCategoryFeedbacksSuccess(status));
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    console.log(error);
    yield put(actionDefault.isLoadingSuccess(true));
  }
}

export function* handleOnDeleteCategoryFeedbacks({ payload }) {
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.onDeleteCategoryFeedbacks, payload);

    const status = response && response.data && response.data.code && response.data.code === 200
    yield put(action.onDeleteCategoryFeedbacksSuccess(status));
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    console.log(error);
    yield put(actionDefault.isLoadingSuccess(true));
  }
}

export function* handleGetDataNotification() {
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.getDataNotification);

    if (response && response.data && response.data.code && response.data.code === 200)
      yield put(action.getNotificationSuccess(response.data.payload.data));
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    console.log(error);
    yield put(actionDefault.isLoadingSuccess(true));
  }
}

export function* handleOnUpdateNotification(payload) {
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.onUpdateNotification, payload);

    const status = response && response.data && response.data.code && response.data.code === 200
    yield put(action.onUpdateNotificationSuccess(status));
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    console.log(error);
    yield put(actionDefault.isLoadingSuccess(true));
  }
}

export function* handleOnCreateChannel(payload) {
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.onCreateChannel, payload);

    const status = response && response.data && response.data.code && response.data.code === 200
    yield put(action.onCreateSocialChannelSuccess(status));
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    console.log(error);
    yield put(actionDefault.isLoadingSuccess(true));
  }
}

export function* handleOnDeleteChannel(payload) {
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.onDeleteChannel, payload);

    const status = response && response.data && response.data.code && response.data.code === 200
    yield put(action.onDeleteSocialChannelSuccess(status));
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    console.log(error);
    yield put(actionDefault.isLoadingSuccess(true));
  }
}

export function* handleGetAllSettingMember() {
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.getAllSettingMemberApi);
    if (response && response.data && response.data.code && response.data.code === 200)
      yield put(action.getAllSettingMemberSuccess(response.data.payload));
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    console.log(error);
    yield put(actionDefault.isLoadingSuccess(true));
  }
}

export function* handleOnUpdateSettingMember(payload) {
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.updateSettingMemberApi, payload);
    const status = response && response.data && response.data.code && response.data.code === 200
    yield put(action.onUpdateSettingMemberSuccess(status));
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    console.log(error);
    yield put(actionDefault.isLoadingSuccess(true));
  }
}

export function* handleGetSettingSms() {
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.getSettingSmsApi);
    if (response && response.data && response.data.code && response.data.code === 200)
      yield put(action.getSettingSmsSuccess(response.data.payload));
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    console.log(error);
    yield put(actionDefault.isLoadingSuccess(true));
  }
}

export function* handleGetSettingAutoSms() {
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.getAutoSettingSentSmsApi);
    console.log(response)
    if (response && response.data && response.data.code && response.data.code === 200) {
      const { data: { payload: { data } } } = response
      let listData = {}
      data.forEach(sms => {
        listData[sms.autoSmsType] = sms
      })
      yield put(action.getSettingAutoSmsSuccess(listData));
    }

    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    console.log(error);
    yield put(actionDefault.isLoadingSuccess(true));
  }
}

export function* handleGetExpressServices() {
try {
	yield put(actionDefault.isLoadingSuccess(false));
	const response = yield call(API.getExpressServices);

	if (response && response.data && response.data.code && response.data.code === 200)
	yield put(action.getExpressServicesSuccess(response.data.payload));
	yield put(actionDefault.isLoadingSuccess(true));
} catch (error) {
	console.log(error);
	yield put(actionDefault.isLoadingSuccess(true));
}
}

export function* handleOnAddExpressServices(payload) {
try {
	yield put(actionDefault.isLoadingSuccess(false));
	const response = yield call(API.onAddExpressServices, payload);

	const status = response && response.data && response.data.code && response.data.code === 200
	yield put(action.onAddExpressServicesSuccess(status));
	yield put(actionDefault.isLoadingSuccess(true));
} catch (error) {
	console.log(error);
	yield put(actionDefault.isLoadingSuccess(true));
}
}

export function* handleOnUpdateExpressServices(payload) {
try {
	yield put(actionDefault.isLoadingSuccess(false));
	const response = yield call(API.onUpdateExpressServices, payload);

	const status = response && response.data && response.data.code && response.data.code === 200
	yield put(action.onUpdateExpressServicesSuccess(status));
	yield put(actionDefault.isLoadingSuccess(true));
} catch (error) {
	console.log(error);
	yield put(actionDefault.isLoadingSuccess(true));
}
}

export function* handleOnDeleteExpressServices({ payload }) {
	try {
		yield put(actionDefault.isLoadingSuccess(false));
		const response = yield call(API.onDeleteExpressServices, payload);
	
		const status = response && response.data && response.data.code && response.data.code === 200
		yield put(action.onDeleteExpressServicesSuccess(status));
		yield put(actionDefault.isLoadingSuccess(true));
	} catch (error) {
		console.log(error);
		yield put(actionDefault.isLoadingSuccess(true));
	}
	}

export default function* root() {
  yield all([
    takeEvery(action.getSocialChannelList, handleGetSocialChannel),
    takeEvery(action.onCreateSocialChannel, handleOnCreateChannel),
    takeEvery(action.onUpdateSocialChannel, handleOnUpdateSocialChannel),
    takeEvery(action.onDeleteSocialChannel, handleOnDeleteChannel),
    takeEvery(action.getCategoryFeedbacks, handleGetCategoryFeedbacks),
    takeEvery(action.getQuestionFeedbacks, handleGetQuestionFeedbacks),
    takeEvery(action.onCreateQuestionFeedbacks, handleOnCreateQuestionFeedbacks),
    takeEvery(action.onDeleteQuestionFeedbacks, handleOnDeleteQuestionFeedbacks),
    takeEvery(action.onCreateCategoryFeedbacks, handleOnCreateCategoryFeedbacks),
    takeEvery(action.onDeleteCategoryFeedbacks, handleOnDeleteCategoryFeedbacks),
    takeEvery(action.getNotification, handleGetDataNotification),
    takeEvery(action.onUpdateNotification, handleOnUpdateNotification),
    takeEvery(action.getAllSettingMember, handleGetAllSettingMember),
    takeEvery(action.onUpdateSettingMember, handleOnUpdateSettingMember),
    takeEvery(action.getSettingSms, handleGetSettingSms),
    takeEvery(action.getSettingAutoSms, handleGetSettingAutoSms),
	takeEvery(action.getExpressServices, handleGetExpressServices),
    takeEvery(action.onAddExpressServices, handleOnAddExpressServices),
    takeEvery(action.onUpdateExpressServices, handleOnUpdateExpressServices),
    takeEvery(action.onDeleteExpressServices, handleOnDeleteExpressServices),
  ]);
}
