import { apiBaseURL } from '../config';
import { request } from '../services/api';

const baseEndpoint = `${apiBaseURL}`;

function getDataLuckySpinApi() {
  const endpoint = `${baseEndpoint}/shop/getAllLuckySpinSetting`;
  return request(endpoint, {
    method: 'GET',
  });
}

function updateDataLuckySpinApi(data) {
  const endpoint = `${baseEndpoint}/shop/updateLuckySpinSetting`;
  return request(endpoint, {
    method: 'PUT',
    data,
  });
}

export default {
  getDataLuckySpinApi,
  updateDataLuckySpinApi,
};
