export const ErrorCode = {
  UNAUTHENTICATED: 401,
};

export const PROJECT = 'project';
export const iCHECKIN = 'icheckin';
export const iREVIEW = 'ireview';

export const ROUTER_URL = {
  LOGIN: '/login',
  DASH_BOARD: '/dashboard',
  BAD_REVIEW: '/review-star/1-3',
  BAD_REVIEW_FACEBOOK: '/review-star/facebook',
  BAD_REVIEW_YELP: '/review-star/yelp',
  BAD_REVIEW_GOOGLE: '/review-star/google',
  BAD_REVIEW_COUPON_SENT: '/review-coupon/sent',
  BAD_REVIEW_COUPON_USED: '/review-coupon/used',
  OWNER: '/owner',
  OWNER_EDIT: '/owner/edit',
  OWNER_SCHEDULE: '/owner/schedule',
  ABOUT_US: '/about-us',
  STAFF: '/staff',
  STAFF_CREATE: '/staff/create',
  STAFF_HANDLE: '/staff/handle/:id',
  STAFF_EDIT: '/staff/edit/:id',
  STAFF_EDIT_SCHEDULE: '/staff/schedule/:id',
  STAFF_SCHEDULE: '/staff/schedule',
  SERVICE: '/service',
  SERVICE_CREATE: '/service/create/:id',
  SERVICE_EDIT: '/service/edit/:id',
  COUPON_CATEGORY: '/coupon/category',
  COUPON: '/coupon',
  COUPON_LIST: '/coupon/list',
  COUPON_CREATE: '/coupon/create',
  COUPON_EDIT: '/coupon/edit/:id',
  SETTING: '/setting',
  SETTING_FEEDBACK: '/setting/feedback',
  SETTING_SOCIAL: '/setting/social-channel',
  SETTING_THANKS_SCREEN: '/setting/thanks-screen',
  SETTING_NOTIFICATION: '/setting/notification',
  MARKETTING: '/marketting',
  REPORT: '/report',
  REPORT_SMS_MARKETING: '/report-sms-marketing',
  CUSTOMER: '/customer',
  CUSTOMER_DETAIL: '/customer/detail/:id',
  // STAFF_EDIT: '/customer/detail/:id',
  APPOINTMENT: '/appointment',
  APPOINTMENT_LIST: '/appointment/list',
  APPOINTMENT_SCHEDULE: '/appointment/schedule',
  SETING_LUCKY_SPIN: '/setting/lucky-spin',
  SETING_PAYMENT: '/setting/payment',
  GIFT_CARD: '/gift-card/list',
  SETTING_MEMBER: '/setting/member',
  SETTING_SMS: '/setting/sms',
  SETTING_AUTO_SMS: '/setting/autoSms',
  SETTING_EXPRESS_SERVICES: '/setting/express-services',
};

export const SUCCESS = 'success';
export const WARNING = 'warning';
export const DANGER = 'danger';
