import { createAction } from 'redux-actions';
import * as CONST from './constants';

export const getDataLucky = createAction(CONST.GET_DATA_LUCKY_SETTING);
export const getDataLuckySuccess = createAction(
  CONST.GET_DATA_LUCKY_SETTING_SUCCESS
);

export const onUpdateDataLucky = createAction(CONST.UPDATE_DATA_LUCKY_SETTING);
export const onUpdateDataLuckySuccess = createAction(
  CONST.UPDATE_DATA_LUCKY_SETTING_SUCCESS
);
