import React, { useEffect, useState, Fragment } from 'react'
import cx from 'classnames'

export default function Modal({ isOpen, onOpened, onClosed, children, className }) {


    const [open, setOpen] = useState(false)
    const [open2, setOpen2] = useState(false)

    useEffect(() => {
        setOpen(isOpen)
    }, [isOpen])

    useEffect(() => {
        if (open) {
            const openFunc = onOpenModal()
            openFunc.next()
            openFunc.next()
        }
        else {
            const closeFunc = onCloseModal()
            closeFunc.next()
            closeFunc.next()
        }
    }, [open])

    function* onOpenModal() {
        if (onOpened) {
            yield onOpened()
        }
        yield setOpen2(true)
    }

    function* onCloseModal() {
        if (onClosed) {
            yield onClosed()
        }
        yield setOpen2(false)
    }

    return (
        <Fragment>
            { open2 &&
                <div className={cx('modal-custom-group', { open: open2 })}>
                    <div className={cx('modal-custom-dialog', className)}>
                        <div className="modal-content">
                        {children}
                        </div>
                    </div>
                </div>
            }
        </Fragment>
    )
} 