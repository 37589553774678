import freeze from 'deep-freeze';
import { handleActions } from 'redux-actions';
import * as actions from './actions';

export const name = 'Owner';

const initialState = {
  ownerDetail: {},
  updateOwnerSuccess: 0,
  ownerScheduleList: {},
  updateOwnerScheduleSuccess: 0,
  updateAboutUsSuccess: 0,
};

export default handleActions(
  {
    [actions.getDetailOwnerSuccess]: (state, action) =>
      freeze({
        ...state,
        ownerDetail: action.payload,
      }),
    [actions.onUpdateOwnerSuccess]: (state, action) =>
      freeze({
        ...state,
        updateOwnerSuccess: action.payload
          ? state.updateOwnerSuccess + 1
          : state.updateOwnerSuccess - 1,
      }),
    [actions.onGetOwnerScheduleSuccess]: (state, action) =>
      freeze({
        ...state,
        ownerScheduleList: action.payload,
      }),
    [actions.onUpdateOwnerScheduleSuccess]: (state, action) =>
      freeze({
        ...state,
        updateOwnerScheduleSuccess: action.payload
          ? state.updateOwnerScheduleSuccess + 1
          : state.updateOwnerScheduleSuccess - 1,
      }),
    [actions.onUpdateAboutUsSuccess]: (state, action) =>
      freeze({
        ...state,
        updateAboutUsSuccess: action.payload
          ? state.updateAboutUsSuccess + 1
          : state.updateAboutUsSuccess - 1,
      }),
  },
  initialState
);
