import React, { useState, useEffect, useMemo, useRef } from 'react';
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Form,
} from 'reactstrap';
import InputFormatPhone from 'react-phone-number-input/input';
import classnames from 'classnames';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import ModalCustom from './Modal';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { isEmpty } from 'lodash';
import {
	format_phone_custom,
	convert_phone
  } from "../../../../../libs/utils/utils";

export default function ModalCustomerHandle(props) {
	const firstNameRef = useRef(null);
	const phoneRef = useRef(null);
  const { register, handleSubmit, errors, control } = useForm({
    criteriaMode: 'all',
    reValidateMode: 'onSubmit',
  });

  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [phone, setPhone] = useState();
  const [formatPhone, setFormatPhone] = useState();
  const [email, setEmail] = useState();
  const [month, setMonth] = useState('');
  const [date, setDate] = useState('');
  const [year, setYear] = useState(moment().format('YYYY'));
  const [gender, setGender] = useState('None');
  const [isFocus, setIsFocus] = useState(true);

	useEffect(() => {
		if (isFocus) {
			if (firstName || !phone) {
				if (firstNameRef.current) {
					firstNameRef.current.focus();
				}
			}
			else {
				if (phoneRef.current) {
					phoneRef.current.focus();
				}
			}
			setIsFocus(false);
		}
	}, [isFocus]);
	
  useEffect(() => {
	if (month != '') {
		const dateChange = moment()
		.set({ month: month - 1, year })
		.add(1, 'months')
		.date(1)
		.subtract(1, 'days')
		.get('date');
		if (date > dateChange) setDate(1);
	}
  }, [month, year]);

  const itemDate = useMemo(() => {
    const total = moment()
      .set({ month: month - 1, year })
      .add(1, 'months')
      .date(1)
      .subtract(1, 'days')
      .get('date');
    return [...Array(total).keys()].map((item) => (
      <DropdownItem key={item} onClick={() => setDate(item + 1)}>
        {item + 1}
      </DropdownItem>
    ));
  }, [month, year]);

  useEffect(() => {
	if (phone && phone.length == 10) {
		setFormatPhone(format_phone_custom(phone))
	}
  }, [phone])
  function onSubmitHandle() {
    const {
      match: { params = {} },
      customerEdit,
    } = props;
    const { apiKey } = params;

    const payload = {
      email,
      firstName,
      lastName,
      phone,
      gender,
      birthDay: moment()
        .set({ month: month - 1, date, year })
        .format('MM/DD/YYYY'),
    };
    if (apiKey) payload.apiKey = apiKey;
    if (isEmpty(customerEdit)) props.onCreateCustomer(payload);
    else props.onUpdateCustomer({ ...customerEdit, ...payload });
  }

  function cancelCustomer() {
    props.setIsOpenAppointment(true);
    props.setIsOpenCustomer(false);
  }

  function onClosed() {
    setFirstName('');
    setLastName('');
    setPhone('');
    setFormatPhone('');
    setEmail('');
    setMonth('');
    setDate('');
    setYear(moment().format('YYYY'));
    setGender('None');
	setIsFocus(false);
    props.setCustomerEdit({});
  }

  function onOpened() {
	setIsFocus(true);
    const { customerEdit } = props;
    if (!isEmpty(customerEdit)) {
    //   console.log(customerEdit);
      const { name: _name, phone: _phone, email: _email } = customerEdit;
      if (_name) {
        const names = _name.trim().split(' ');
        if (names.length > 1) {
          setFirstName(names[0]);
          setLastName(names.filter((item, index) => index != 0).join(' '));
        } else setFirstName(_name);
      }
    //   setPhone(_phone && _phone.includes('+1') ? _phone : '+1' + _phone);
      setPhone(_phone && _phone);
      setEmail(_email);
    }
	else {
		// handle value of search
		const regex = /^\d+$/;
		if (regex.test(props.nameCustomer)) {
			setPhone(props.nameCustomer);
		}
		else {
			const customer_name = props.nameCustomer.trim().split(' ');
			if (customer_name.length > 1) {
				setFirstName(customer_name[0]);
				setLastName(customer_name.filter((item, index) => index != 0).join(' '));
			} else {
				setFirstName(props.nameCustomer);
				setLastName("");
			}
		}
	}
  }

  return (
    <Modal
      isOpen={props.isOpen}
      centered
      className="modal-appoitment"
      onClosed={onClosed}
      onOpened={onOpened}
	  style={{maxWidth: '1000px'}}
    >
      <Form onSubmit={handleSubmit(onSubmitHandle)}>
        <ModalHeader className="d-flex align-items-center justify-space-between">
          {isEmpty(props.customerEdit) ? 'Add' : 'Edit'} Customer
          {!isEmpty(props.customerEdit) && (
            <Button color="danger" onClick={props.onDeleteCustomer}>
              Delete
            </Button>
          )}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm={6} className="pr-2">
              <Label
                className={classnames('form-custom', {
                  'form-focus': !!firstName,
                })}
                for="form-first-name"
              >
                <span>
                  First Name <b>*</b>
                </span>
                <input
                  ref={(e) => {
					register(e, {
							required: 'FirstName is required.',
						  });
						firstNameRef.current = e;
					  }}
                  value={firstName}
                  name="firstName"
                  id="form-first-name"
                  onChange={({ target: { value } }) => setFirstName(value)}
				autoFocus
                />
              </Label>
              <ErrorMessage
                errors={errors}
                name="firstName"
                render={({ messages }) =>
                  messages &&
                  Object.entries(messages).map(([type, message]) => (
                    <p className="error-message" key={type}>
                      {message}
                    </p>
                  ))
                }
              />
            </Col>
            <Col sm={6} className="pr-2">
              <Label
                className={classnames('form-custom', {
                  'form-focus': !!lastName,
                })}
                for="form-last-name"
              >
                <span>
                  Last Name <b>*</b>
                </span>
                <Input
                  value={lastName}
                  name="lastName"
                  id="form-last-name"
                  innerRef={register({
                    required: 'LastName is required.',
                  })}
                  onChange={({ target: { value } }) => setLastName(value)}
                />
              </Label>
              <ErrorMessage
                errors={errors}
                name="lastName"
                render={({ messages }) =>
                  messages &&
                  Object.entries(messages).map(([type, message]) => (
                    <p className="error-message" key={type}>
                      {message}
                    </p>
                  ))
                }
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col sm={6} className="pr-2">
              <Label
                className={classnames('form-custom', { 'form-focus': !!phone })}
                for="phone-customer-number"
              >
                <span>
                  Phone Number <b>*</b>
                </span>
                <input
					ref={(e) => {
						register(e, {
							required: 'Phone is required.',
							pattern: {
								value: /\([0-9]{3}\) [0-9]{3}-[0-9]{4}/,
								message: "Please enter the correct format. Ex: (xxx) xxx-xxxx"
							}
						  });
						phoneRef.current = e;
					  }}
					value={formatPhone ? formatPhone : phone}
					name="phone"
					id="form-phone"
					onChange={({ target: { value } }) => {
						if (value.length > 14) {
							return;
						}
						setFormatPhone("");
						setPhone(convert_phone(value));
					}}
                />
              </Label>
              <ErrorMessage
                errors={errors}
                name="phone"
                render={({ messages }) =>
                  messages &&
                  Object.entries(messages).map(([type, message]) => (
                    <p className="error-message" key={type}>
                      {message}
                    </p>
                  ))
                }
              />
            </Col>
            <Col sm={6} className="pr-2">
              <Label
                className={classnames('form-custom', { 'form-focus': !!email })}
                for="form-email"
              >
                <span>Email</span>
                <Input
                  value={email}
                  id="form-email"
                  onChange={({ target: { value }, preventDefault }) => {
                    setEmail(value);
                  }}
                />
              </Label>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col sm={3} className="pr-2">
              <Label
                className={classnames('form-custom', { 'form-focus': !!month })}
              >
                <span>Month</span>
                <UncontrolledDropdown className="dropdown-service dropdown-service--date  mt-auto">
                  <DropdownToggle className="bg-white border-0 pb-0">
                    {month}
                  </DropdownToggle>
                  <DropdownMenu>
				 	 <DropdownItem
                        onClick={() => setMonth('')}
                      >
                      </DropdownItem>
                    {[...Array(12).keys()].map((item) => (
                      <DropdownItem
                        key={item}
                        onClick={() => setMonth(item + 1)}
                      >
                        {item + 1}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Label>
            </Col>
            <Col sm={3} className="pr-2">
              <Label
                className={classnames('form-custom', { 'form-focus': !!date })}
              >
                <span>Date</span>
                <UncontrolledDropdown className="dropdown-service dropdown-service--date mt-auto">
                  <DropdownToggle className="bg-white border-0 pb-0">
                    {date}
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={() => setDate('')}>
                    </DropdownItem>
                    {itemDate}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Label>
            </Col>
            {
              //     <Col sm={2} className="pr-2">
              //   <Label
              //     className={classnames('form-custom', { 'form-focus': !!year })}
              //   >
              //     <span>Year</span>
              //     <UncontrolledDropdown className="dropdown-service dropdown-service--date mt-auto">
              //       <DropdownToggle className="bg-white border-0 pb-0">
              //         {year}
              //       </DropdownToggle>
              //       <DropdownMenu>
              //         {[...Array.from(range(1970, moment().get('year') + 1))].map(
              //           (item) => (
              //             <DropdownItem key={item} onClick={() => setYear(item)}>
              //               {item}
              //             </DropdownItem>
              //           )
              //         )}
              //       </DropdownMenu>
              //     </UncontrolledDropdown>
              //   </Label>
              // </Col>
            }
            <Col sm={6} className="pr-2">
              <Label className="form-custom form-focus">
                <span>Gender</span>
                <UncontrolledDropdown className="dropdown-service mt-auto">
                  <DropdownToggle className="bg-white border-0 pb-0">
                    {gender}
                  </DropdownToggle>
                  <DropdownMenu>
                    {['Male', 'Female', 'None'].map((item) => (
                      <DropdownItem key={item} onClick={() => setGender(item)}>
                        {item}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Label>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="border-0">
          <Button color="secondary" onClick={cancelCustomer}>
            Cancel
          </Button>
          <button type="submit" className="btn btn-primary">
            {isEmpty(props.customerEdit) ? 'Add' : 'Update'}
          </button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}
