import React, { Component } from 'react';
import {
  FormGroup,
  Card,
  CardHeader,
  CardBody,
  Col,
  Label,
  Input,
  Row,
  Modal,
  ModalHeader,
  CardImg,
  ModalBody,
  CustomInput,
  Button,
} from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ownerAction from '../actions';
import { isEqual, isEmpty } from 'lodash';
import Spinner from '../../../../../assets/Spinner-1sx.svg';
import Logo from '../../../../../assets/img/logo-icheck1.png';
import Icon from '../../../../../assets/img/icon-render.png';
import Banner from '../../../../../assets/img/banner-QR.jpg';
import BannerSignin from '../../../../../assets/img/banner-QR-signin.jpg';
import htmlToImage from 'html-to-image';
import { downloadImage } from '../../../../../libs/utils/utils';
import GroupInput from './_groupInput';
import InputFormatPhone from 'react-phone-number-input/input';
import Picture from '../../../../../assets/img/picture.png';
import { ROUTER_URL } from '../../../../../constants';
import cx from 'classnames';
import PageTitle from '../../../container/components/PageTitle'
import { formatPhone } from '../../../../../libs/utils/utils'


class OwnerHandle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ownerDetail: {
        address: '',
        city: '',
        companyName: '',
        email: '',
        phone: '',
        imagePath: '',
        state: '',
        zip: '',
      },
      imagePath: '',
      isLoadingDownload: false,
      isConfirmType: false,
      isLoadingDownloadSignin: false,
    };
  }

  componentDidMount() {
    const { actions, ownerDetail } = this.props;
    if (!isEmpty(ownerDetail)) {
      this.setState({
        ownerDetail,
      });
    }
    actions.getDetailOwner();
  }

  componentDidUpdate(prevProps) {
    const { ownerDetail, updateCouponSuccess, history } = this.props;
    if (!isEqual(ownerDetail, prevProps.ownerDetail)) {
      this.setState({
        ownerDetail,
      });
    }

    if (updateCouponSuccess > prevProps.updateCouponSuccess)
      history.push('/coupon');
  }

  onChange = ({ target: { name, value } }) => {
    this.setState((prevState) => ({
      ownerDetail: {
        ...prevState.ownerDetail,
        [name]: value,
      },
    }));
  };

  onHandleOwner = () => {
    const { ownerDetail, imagePath } = this.state;
    const { actions } = this.props;
    const payload = {
      ...ownerDetail,
      shopId: ownerDetail.id,
      avatar: imagePath
        ? imagePath.replace(/^data:image\/[a-z]+;base64,/, '')
        : ownerDetail.avatar,
    };

    actions.onUpdateOwner(payload);
    actions.getDetailOwner();
  };

  resetDetail = () => {
    const { ownerDetail } = this.props;
    this.setState({
      ownerDetail,
    });
  };

  renderQR = (type) => () => {
    this.setState({
      isLoadingDownload: true,
      isConfirmType: false,
    });
    var node = document.getElementById(
      type ? 'qr-banner-share-mini' : 'qr-banner-share'
    );
    if (node) {
      htmlToImage
        .toPng(node)
        .then(() => {
          htmlToImage
            .toPng(node)
            .then((dataUrl) => {
              this.setState({
                isLoadingDownload: false,
              });
              downloadImage(dataUrl);
            })
            .catch(function (error) {
              console.error('oops, something went wrong!', error);
            });
        })
        .catch(function (error) {
          console.error('oops, something went wrong!', error);
        });
    }
  };

  renderQRSignin = () => {
    this.setState({
      isLoadingDownloadSignin: true,
    });
    var node = document.getElementById('qr-banner-signin');
    if (node) {
      htmlToImage
        .toPng(node)
        .then(() => {
          htmlToImage
            .toPng(node)
            .then((dataUrl) => {
              this.setState({
                isLoadingDownloadSignin: false,
              });
              downloadImage(dataUrl);
            })
            .catch(function (error) {
              console.error('oops, something went wrong!', error);
            });
        })
        .catch(function (error) {
          console.error('oops, something went wrong!', error);
        });
    }
  };

  onChangeImage = ({ target: { files, name } }) => {
    if (files && !!files.length) {
      var reader = new FileReader();
      var file = files[0];

      reader.onload = (upload) => {
        this.setState({
          [name]: upload.target.result,
          [`${name}File`]: files,
        });
      };
      reader.readAsDataURL(file);
    }
  };

  deleteImage = () => {
    this.setState((prevState) => ({
      imagePath: '',
      imagePathFile: '',
      ownerDetail: {
        ...prevState.ownerDetail,
        avatar: '',
      },
    }));
  };

  openConfirm = () => this.setState({ isConfirmType: true });

  render() {
    const {
      ownerDetail,
      isLoadingDownload,
      isConfirmType,
      imagePath,
      isLoadingDownloadSignin,
    } = this.state;
    const { shopSelect, history } = this.props;
    const {
      location: { pathname },
    } = history;
    const avatar = ownerDetail.avatar
      ? 'data:image/png;base64,' + ownerDetail.avatar
      : '';
    return (
      <>
        <Card className="animated card-home fadeIn">
        <PageTitle
          heading="Owner Edit"
          // subheading="Wide selection of buttons that feature different styles for backgrounds, borders and hover options!"
          icon="pe-7s-pen icon-gradient bg-tempting-azure"
        />
          <CardBody className="card-body-box d-flex flex-column">
            <GroupInput
              label="Company Name"
              value={ownerDetail.companyName || ''}
              name="companyName"
              onChange={this.onChange}
              placeholder="Company Name"
            />
            <GroupInput
              label="Email"
              value={ownerDetail.email || ''}
              name="email"
              onChange={this.onChange}
              placeholder="Email"
            />
            <FormGroup row className="row-coupon">
              <Label sm={3} md={2}>
                Phone
              </Label>
              <Col sm={6} md={6}>
                <InputFormatPhone
                  country="US"
                  autoComplete="off"
                  name="phone"
                  className="input-radius form-control"
                  value={ownerDetail.phone}
                  onChange={(phone) =>
                    this.setState((prevState) => ({
                      ownerDetail: { ...prevState.ownerDetail, phone },
                    }))
                  }
                  placeholder="Phone Number"
                />
                {/* <Input value={phone} name="phone" onChange={this.onChange} className={'input-radius' + (classInput ? ' ' + classInput : '')} placeholder={placeholder} /> */}
              </Col>
            </FormGroup>

            <GroupInput
              label="Address"
              value={ownerDetail.address || ''}
              name="address"
              onChange={this.onChange}
              placeholder="Address"
            />

            <GroupInput
              label="City"
              value={ownerDetail.city || ''}
              name="city"
              onChange={this.onChange}
              placeholder="City"
            />

            <FormGroup row className="row-coupon align-items-star">
              <Label sm={3} md={2}>
                State
              </Label>
              <Col sm={6} md={6}>
                <Row>
                  <Col sm={5}>
                    <Input
                      value={ownerDetail.state || ''}
                      name="state"
                      onChange={this.onChange}
                      className="input-radius"
                      placeholder="State"
                    />
                  </Col>
                  <Col sm={7}>
                    <Row>
                      <Label sm={5}>Zip Code</Label>
                      <Col sm={7}>
                        <Input
                          value={ownerDetail.zip || ''}
                          name="zip"
                          onChange={this.onChange}
                          className="input-radius"
                          placeholder="Zip Code"
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </FormGroup>
            <FormGroup row className="row-coupon align-items-start">
              <Label sm={3} md={2}>
                Avatar
              </Label>
              <Col sm={6} md={6}>
                <div className="file-button-none square">
                  <Label className="mb-0" htmlFor="imagePath">
                    <Input
                      hidden
                      name="imagePath"
                      id="imagePath"
                      onChange={this.onChangeImage}
                      onClick={(event) => (event.target.value = null)}
                      type="file"
                      accept="image/*"
                    />
                    <CardImg
                      src={imagePath ? imagePath : avatar ? avatar : Picture}
                      id="img_upload_id"
                    />
                  </Label>
                  {(imagePath || avatar) && (
                    <Button
                      className="file-btn-delete"
                      onClick={this.deleteImage}
                    >
                      <i className="fa fa-times-circle" aria-hidden="true"></i>
                    </Button>
                  )}
                </div>
              </Col>
            </FormGroup>
            <FormGroup row className="row-coupon mb-5">
              <Label sm={3} md={2}>
                Option
              </Label>
              <Col sm={9} md={10} className="d-flex align-items-stretch">
                <button
                  disabled={isLoadingDownload}
                  className="btn-qr-code"
                  onClick={!isLoadingDownload ? this.openConfirm : null}
                >
                  <span className={isLoadingDownload ? 'is-loading' : ''}>
                    <img src={Spinner} />
                  </span>
                  <span>Download QRCode Review</span>
                </button>
                <a
                  href={ownerDetail.linkShareFormCovid19}
                  target="_blank"
                  className="link-qr-code"
                >
                  <span>
                    <i className="fa fa-link" aria-hidden="true"></i>
                  </span>{' '}
                  <span>Get link share form coupon</span>
                </a>
                <button
                  disabled={isLoadingDownloadSignin}
                  className="btn-qr-code"
                  onClick={
                    !isLoadingDownloadSignin ? this.renderQRSignin : null
                  }
                >
                  <span className={isLoadingDownloadSignin ? 'is-loading' : ''}>
                    <img src={Spinner} />
                  </span>
                  <span>Download QRCode Sign-in</span>
                </button>
              </Col>
            </FormGroup>

            <FormGroup className="text-center mt-auto">
              <button onClick={this.resetDetail} className="btn-delete mx-2">
                Reset
              </button>
              <button onClick={this.onHandleOwner} className="btn-active mx-2">
                Save
              </button>
            </FormGroup>
          </CardBody>
        </Card>
        <div className="qr-banner-share-container">
          <div id="qr-banner-share" style={{ background: `url(${Banner})` }}>
            <div className="qr-banner-share-top">
              {shopSelect && <h4>{shopSelect.companyName}</h4>}
              <div className="qr-description">
                <p>
                  Your Vital Review <br /> is helping to imporve my <br />{' '}
                  performance for your next visit!
                </p>
                <span>
                  <img src={Logo} />
                </span>
              </div>
              <div className="icon-middle">
                <img src={Icon} />
              </div>
              <p className="qr-scan-text">
                <span>
                  <i className="fa fa-mobile" aria-hidden="true"></i>
                </span>{' '}
                Scan Me
              </p>
              <div className="qr-banner">
                <img
                  src={`data:image/jpeg;base64,${ownerDetail.imageQrCodeReviewBase64}`}
                />
              </div>
              <p className="qr-content">
                We apprreciate your time and look forward to serving you again!
              </p>
            </div>
            <div className="qr-banner-share-bottom">
              <div className="qr-info">
                <p>{formatPhone(ownerDetail.phone)}</p>
              </div>
              <div className="qr-end">
                <span>Thank You</span>
              </div>
            </div>
          </div>

          <div
            id="qr-banner-share-mini"
            style={{ background: `url(${Banner})` }}
          >
            <div className="qr-banner-share-top">
              {shopSelect && <h4>{shopSelect.companyName}</h4>}
              <div className="qr-description">
                <p>
                  Your Vital Review <br /> is helping to imporve my <br />{' '}
                  performance for your next visit!
                </p>
                <span>
                  <img src={Logo} />
                </span>
              </div>
              <div className="icon-middle">
                <img src={Icon} />
              </div>
              <p className="qr-scan-text">
                <span>
                  <i className="fa fa-mobile" aria-hidden="true"></i>
                </span>{' '}
                Scan Me
              </p>
              <div className="qr-banner">
                <img
                  src={`data:image/jpeg;base64,${ownerDetail.imageQrCodeReviewBase64}`}
                />
              </div>
              <p className="qr-content">
                We apprreciate your time and look forward to serving you again!
              </p>
            </div>
            <div className="qr-banner-share-bottom">
              <div className="qr-info">
                <p>{formatPhone(ownerDetail.phone)}</p>
              </div>
              <div className="qr-end">
                <span>Thank You</span>
              </div>
            </div>
          </div>

          <div
            id="qr-banner-signin"
            style={{ background: `url(${BannerSignin})` }}
          >
            <div className="qr-banner-share-top">
              {shopSelect && <h4>{shopSelect.companyName}</h4>}
              <div className="qr-banner">
                <img
                  src={`data:image/jpeg;base64,${ownerDetail.imageQrCodeSigninBase64}`}
                />
              </div>
              <p className="qr-text-checkin">Check - In</p>
              <p className="qr-scan-text">
                <span>
                  <i className="fa fa-mobile" aria-hidden="true"></i>
                </span>{' '}
                Scan with camera
              </p>
            </div>
          </div>
        </div>
        <Modal className="modal-confirm" isOpen={isConfirmType} centered>
          <ModalHeader className="border-0">
            Select the size you want to download
          </ModalHeader>
          <ModalBody className="border-0 p-0">
            <CustomInput
              onClick={this.renderQR(0)}
              type="radio"
              id="confirm-A4"
              name="confirm-size"
              label="A4"
            />
            <CustomInput
              onClick={this.renderQR(1)}
              type="radio"
              id="confirm-46"
              name="confirm-size"
              label="4x6 inches"
            />
          </ModalBody>
        </Modal>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    ownerDetail: state.Owner.ownerDetail,
    updateOwnerSuccess: state.Owner.updateOwnerSuccess,
    shopSelect: state.Login.shopSelect,
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {
    ...ownerAction,
  };
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(OwnerHandle);
