import React, { Component } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';

import TitleComponent2 from './Variation2'

class PageTitle extends Component {

    render() {
        let {
            enablePageTitleIcon,
            enablePageTitleSubheading,

            heading,
            icon,
            subheading,
            children
        } = this.props;

        return (

            <div className={cx('app-page-title', this.props.className)}>
                <div className="page-title-wrapper">
                    <div className="page-title-heading w-100">
                        {icon &&
                            <div
                                className={cx("page-title-icon", { 'd-none': !enablePageTitleIcon })}>
                                <i className={icon} />
                            </div>}
                        {heading &&
                            <div className="font-weight-bold white-space-nowrap mr-4">
                                {heading}
                                {subheading &&
                                    <div
                                        className={cx("page-title-subheading", { 'd-none': !enablePageTitleSubheading })}>
                                        {subheading}
                                    </div>
                                }
                            </div>
                        }
                        {children}
                    </div>
                    {/* 
                        <div className="page-title-actions">
                            <TitleComponent2/>
                        </div> 
                    */}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    enablePageTitleIcon: state.Layout.enablePageTitleIcon,
    enablePageTitleSubheading: state.Layout.enablePageTitleSubheading,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PageTitle);