import { createAction } from 'redux-actions';
import * as CONST from './constants';

export const onLogin = createAction(CONST.ON_LOGIN);
export const onLoginSuccess = createAction(CONST.ON_LOGIN_SUCCESS);

export const selectShop = createAction(CONST.SELECT_SHOP);
export const selectShopSuccess = createAction(CONST.SELECT_SHOP_SUCCESS);

export const clearDataShop = createAction(CONST.CLEAR_DATA_SHOP);
export const clearDataShopSuccess = createAction(CONST.CLEAR_DATA_SHOP_SUCCESS);