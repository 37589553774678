const ACTION_NAME = 'FEEDBACK/DASHBOARD';
export const GET_DATA_DASHBOARD = `${ACTION_NAME}/GET_DATA_DASHBOARD`;
export const GET_DATA_DASHBOARD_SUCCESS = `${ACTION_NAME}/GET_DATA_DASHBOARD_SUCCESS`;

export const GET_REVIEWS = `${ACTION_NAME}/GET_REVIEWS`;
export const GET_REVIEWS_SUCCESS = `${ACTION_NAME}/GET_REVIEWS_SUCCESS`;

export const GET_REVIEWS_SOCIAL = `${ACTION_NAME}/GET_REVIEWS_SOCIAL`;

export const GET_REVIEWS_COUPON = `${ACTION_NAME}/GET_REVIEWS_COUPON`;

export const SEARCH_TYPE = {
    TOODAY: 0,
    THIS_WEEK: 1,
    TWO_WEEK: 2,
    THIS_MONTH: 3,
    ALL_TIME: 4
}