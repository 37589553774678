import { isEmpty } from 'lodash'
import { formatPhoneNumber } from 'react-phone-number-input';
import moment from "moment";

export function downloadImage(base64) {
  var a = document.createElement("a");
  a.href = base64;
  a.download = "Image.png";
  a.click();
}

export function getRandomColor() {
  var letters = '0123456789ABCD';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 14)];
  }
  return color;
}

export function getCompressName(title) {
  const titles = title ? title.split(' ') : '';
  const firstChar = title.substring(0, 1);
  const lastChars = titles[titles.length - 1];
  const lastChar = lastChars.substring(0, 1);
  return firstChar + ' ' + lastChar
}


export function objectToSearchParams(param) {
  let searchParams = '';
  if (!isEmpty(param)) {
    searchParams = new URLSearchParams();
    Object.keys(param).forEach((key) => {
      if (key != 'apiKey')
        searchParams.append(key, param[key])
    });
    searchParams = '?' + searchParams.toString();
  }
  return searchParams
}

export function getHour(time) {
  if (!time) return null
  return time.split(':')[0]
}


export function formatPhone(phone) {
  if (!phone) return ''
  return formatPhoneNumber(phone && phone.includes('+1') ? phone : '+1' + phone)
}

export function format_phone_custom(phone) {
	if (!phone) return '';
	phone = phone.replace('+1', '');
	let output = "";
	phone.replace( /^\D*(\d{0,3})\D*(\d{0,3})\D*(\d{0,4})/, function( match, g1, g2, g3 )
		{
			if ( g1.length ) {
			output += "(" + g1;
			if ( g1.length == 3 ) {
				output += ")";
				if ( g2.length ) {
					output += " " + g2; 
					if ( g2.length == 3 ) {
						output += "-";
						if ( g3.length ) {
							output += g3;
						}
					}
				}
			}
			}
		}
	);
	return output;
}

export function convert_phone(phone) {
	phone = phone.replace(/\D+/g, '');
	return phone;
}

export function times_list(date, startTime, timeServices) {
	let start_date = moment(moment(date).format("MM/DD/YYYY") + " " + startTime).add(timeServices, 'minute');
	let end_date = moment(start_date).add(65, 'minute');
	var end_time = [];
	for (var m = start_date; m.isBefore(end_date); m.add(5, 'minute')) {
		end_time.push(m.format('hh:mm A'));
	}
	return end_time;
}
