import { applyMiddleware, compose, createStore } from 'redux';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';
import rootReducer from './reducers';
import sagas from './sagas';

export const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();

const loggerMiddleware = createLogger({
  predicate: () => process.env.NODE_ENV === 'development',
});

export default function configureStore(preloadedState) {
  const store = createStore(
    rootReducer(history), // root reducer with router state,
    preloadedState,
    compose(
      applyMiddleware(
        sagaMiddleware,
        routerMiddleware(history),
        loggerMiddleware, // neat middleware that logs actions
      ),
    ),
  );
  sagaMiddleware.run(sagas);
  return store;
}
