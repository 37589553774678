import React from 'react';
import {
  Pagination, PaginationItem, PaginationLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';


function Paginations(props) {

  const { currentPage, onChangePagination, totalSearch, pageSize, isShowTotal, changePageSize } = props;
  const totalPage = Math.ceil(totalSearch / (pageSize || 12))
  let pageNumbers = [];
  for (let i = 1; i <= totalPage; i++)
    pageNumbers.push(i)
  const listRecords = [10, 12, 16, 20]

  const displayTo = (currentPage - 1) * pageSize + pageSize

  return ((pageNumbers && !!pageNumbers.length) || !!totalSearch) && (
    <>
      <div className="group-footer">
        {
          (pageNumbers && !!pageNumbers.length) &&
          <Pagination className="pagination-footer" aria-label="Page navigation">
            <PaginationItem disabled={currentPage == 1} className="previous-link">
              <PaginationLink previous onClick={onChangePagination(currentPage - 1)} />
            </PaginationItem>
            {
              currentPage > 3 &&
              <PaginationItem className={`first-pagination ${currentPage > 4 ? 'dot' : ''}`}>
                <PaginationLink tag="button" onClick={onChangePagination(1)}>1</PaginationLink>
              </PaginationItem>
            }
            {
              currentPage > 4 &&
              <span className="dot-pagination">...</span>
            }
            {
              pageNumbers.map(page => {
                if (page <= currentPage + 2 && page >= currentPage - 2)
                  return (
                    <PaginationItem key={page} disabled={currentPage == page}>
                      <PaginationLink tag="button" key={page} id={page} onClick={onChangePagination(page)}>
                        {page}
                      </PaginationLink>
                    </PaginationItem>
                  )
              })
            }
            {
              currentPage + 3 < pageNumbers.length &&
              <span className="dot-pagination">...</span>
            }
            {
              currentPage < pageNumbers.length - 2 &&
              <PaginationItem className={`last-pagination  ${currentPage < pageNumbers.length - 3 ? 'dot' : ''}`}>
                <PaginationLink tag="button" onClick={onChangePagination(pageNumbers.length)}>
                  {pageNumbers.length}
                </PaginationLink>
              </PaginationItem>
            }
            <PaginationItem disabled={currentPage == pageNumbers.length} className="next-link">
              <PaginationLink next onClick={onChangePagination(currentPage + 1)} />
            </PaginationItem>
          </Pagination>
        }
      </div>
      {
        isShowTotal && !!totalSearch &&
        <div className="total-footer">
          <UncontrolledDropdown direction="up">
            <DropdownToggle caret>{pageSize}</DropdownToggle>
            <DropdownMenu>
              {
                listRecords.map(record => (
                  <DropdownItem key={record} onClick={changePageSize(record)}>{record}</DropdownItem>
                ))
              }
            </DropdownMenu>
          </UncontrolledDropdown>
          <p>Displaying {(currentPage - 1) * pageSize + 1} - {displayTo > totalSearch ? totalSearch : displayTo} of {totalSearch}</p>
        </div>
      }
    </>
  );
}

// function TotalSearch({ total }) {
//   return (
//     <div className="total-search">
//       {total} kết quả tìm kiếm
//     </div>
//   )
// }



export default Paginations;
