import React from 'react';
import {
  ModalFooter, ModalHeader, Modal, FormGroup, Textarea, Col, Label, Input, ModalBody, CustomInput
} from 'reactstrap';




export default function ModalHandleCategory(props) {
  const { isOpen, category } = props;
  console.log(`Enable: ${category.status}`);

  return (
    <Modal className="modal-create" isOpen={!!isOpen} centered>
      <ModalHeader className="border-0">{isOpen == 1 ? 'Create' : 'Edit'} Category</ModalHeader>
      <ModalBody className="border-0">
        <FormGroup row>
          <Label sm={3}>Enable</Label>
          <Col sm={9}>
            <CustomInput
              className="custom-input-large font-bold pl-0"
              checked = {category.status == 1 ? 1 : 0}
              onChange={props.changeEnable}
              name="enable"
              type="checkbox"
              id="enable"
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>Category Name</Label>
          <Col sm={9}>
            <Input name="name" value={category.name || ''} onChange={props.onChange} />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>Description</Label>
          <Col sm={9}>
            <Input type="textarea" rows="3" name="description" value={category.description || ''} onChange={props.onChange} />
          </Col>
        </FormGroup>
      </ModalBody>
      <ModalFooter className="border-0">
        <button onClick={props.toggleModal(false)}>Cancel</button>
        <button disabled={!category.name} onClick={props.onSave}>Save</button>
      </ModalFooter>
    </Modal>
  )
}

