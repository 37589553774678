import React, { Component } from 'react';
import {
  FormGroup, Card, CardHeader, CardBody, UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle,
  Col, Label, Input, CardFooter, Modal, Row
} from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as couponAction from '../actions';
import ModalHandleCategory from './ModalHandleCategory'
import ModalDeleteCategory from './ModalDeleteCategory'
import PageTitle from '../../../container/components/PageTitle'

class CouponCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onShowModalDelete: false,
      isOpenHandleCategory: false,
      category: {
        id: 0,
        name: '',
        description: '',
        status: 1
      },
      isShowDeleteCate: false,
      categoryIdDelete: 0
    };
  }

  componentDidMount() {
    this.onInitCategoryCoupon()
  }

  componentDidUpdate(prevProps) {
    const { createCategoryCouponSuccess, deleteCategoryCouponSuccess } = this.props;

    if (createCategoryCouponSuccess > prevProps.createCategoryCouponSuccess ||
      deleteCategoryCouponSuccess > prevProps.deleteCategoryCouponSuccess) {
      console.log(deleteCategoryCouponSuccess > prevProps.deleteCategoryCouponSuccess)
      this.setState({
        isOpenHandleCategory: false,
        isShowDeleteCate: false
      }, () => this.onInitCategoryCoupon())
    }
  }

  onInitCategoryCoupon = () => {
    const { actions } = this.props;
    actions.getCategoryCoupon()
  }

  toggleModalCategory = (status) => () => this.setState({ isOpenHandleCategory: status })

  onChangeCategory = ({ target: { name, value } }) => {
    this.setState(prevProps => ({
      category: {
        ...prevProps.category,
        [name]: value
      }
    }))
  }

  onSaveCategory = () => {
    const { category } = this.state;
    const { actions } = this.props;
    actions.onCreateCategoryCoupon(category)
  }

  openHandleCategory = () => {
    this.setState({
      isOpenHandleCategory: true,
      category: {
        id: 0
      }
    })
  }

  deleteCategory = () => {
    const { categoryIdDelete } = this.state;
    const { actions } = this.props;
    if (!!categoryIdDelete)
      actions.onDeleteCategoryCoupon(categoryIdDelete)
  }

  onDeleteCategory = (id) => () => {
    this.setState({
      categoryIdDelete: id,
      isShowDeleteCate: true
    })
  }

  toggleDelete = (status) => () => this.setState({ isShowDeleteCate: status })

  onEditCategory = (category) => () => {
    this.setState({
      category
    }, () => this.setState({
      isOpenHandleCategory: true
    }))
  }

  changeEnable = ({ target: { checked } }) => {
    this.setState((prevState) => ({
      category: {
        ...prevState.category,
        status : checked ? 1 : 2,
      },
    }));
  }

  render() {
    const { isOpenHandleCategory, category, isShowDeleteCate } = this.state;
    const { categoryCouponList } = this.props;
    return (
      <>
        <Card className="animated fadeIn card-home">
          <PageTitle>
            <div className="feedback-header">
              <h4>Category Coupon</h4>
              <button className="btn-add ml-auto" onClick={this.openHandleCategory}>+ Category</button>
            </div>
          </PageTitle>
          <CardBody className="card-body-box d-flex flex-column">
            <div className="category-coupon">
              <FormGroup row className="body-category-coupon">
                {
                  categoryCouponList && categoryCouponList.map(category => (
                    <Col sm={4} key={category.id}>
                      <div className="category-coupon-item">
                        <div className="category-item-meta">
                          <h4><span><i className="fa fa-bars" aria-hidden="true"></i></span> <span>{category.name}</span></h4>
                          {
                            category.description &&
                            <p><span><i className="fa fa-sticky-note-o" aria-hidden="true"></i></span> <span>{category.description}</span></p>
                          }
                        </div>
                        <div className="category-item-icon">
                          <span onClick={this.onEditCategory(category)}><i className="fa fa-pencil" aria-hidden="true"></i></span>
                          <span onClick={this.onDeleteCategory(category.id)}><i className="fa fa-trash-o" aria-hidden="true"></i></span>
                        </div>
                      </div>
                    </Col>
                  ))
                }
              </FormGroup>
            </div>
          </CardBody>
        </Card>

        <ModalHandleCategory
          isOpen={isOpenHandleCategory}
          category={category}
          onChange={this.onChangeCategory}
          toggleModal={this.toggleModalCategory}
          onSave={this.onSaveCategory}
          changeEnable={this.changeEnable} />
        <ModalDeleteCategory
          isOpen={isShowDeleteCate}
          toggleDelete={this.toggleDelete}
          onDelete={this.deleteCategory} />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    categoryCouponList: state.Coupon.categoryCouponList,
    createCategoryCouponSuccess: state.Coupon.createCategoryCouponSuccess,
    deleteCategoryCouponSuccess: state.Coupon.deleteCategoryCouponSuccess,
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {
    ...couponAction,
  };
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(CouponCategory);
