import React, { Component } from 'react';
import {
  FormGroup,
  Card,
  CardHeader,
  CardBody,
  Col,
  Row,
  Container,
  Table,
  CardFooter,
  CustomInput,
  DropdownMenu,
  DropdownItem,
  UncontrolledButtonDropdown,
  DropdownToggle,
  UncontrolledDropdown,
  Input,
  Label,
} from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { formatPhone } from '../../../../libs/utils/utils';
import * as markettingAction from './actions';
import * as couponAction from '../Coupon/actions';
import * as serviceAction from '../Service/actions';
import * as defaultAction from '../../container/actions';

import ModalHandleCampain from './components/ModalHandleCampain';
import PaginationFooter from '../../../../libs/components/PaginationFooter';
import ModalWarning from './components/ModalWarning';
import MarketingType from './components/MarketingType';
import HeaderTable from './components/HeaderTable';
import DatePicker from 'react-datepicker';
import { MARKETING_TYPE } from './constants';
import { SUCCESS, WARNING } from '../../../../constants';
import TagsInput from 'react-tagsinput';
import cx from 'classnames';
import Api from '../../../../api/marketting';
import { isEqual } from 'lodash';
import Rating from 'react-rating';
import Star from '../../../../assets/img/star-review.svg';
import StarFull from '../../../../assets/img/star-full.svg';
import { Fragment } from 'react';

const filterSearch = {
  [MARKETING_TYPE.ALL]: 'All group customer',
  [MARKETING_TYPE.LESS_THAN_3_STAR]: '1 - 2 or 3 star',
  [MARKETING_TYPE.GREATE_THAN_3_STAR]: '4 - 5 star',
  [MARKETING_TYPE.APPOINTMENT]: 'Appointment',
  // [MARKETING_TYPE.BIRTHDAY]: 'Birthday',
  [MARKETING_TYPE.USED_COUPON]: 'Used Coupon',
  [MARKETING_TYPE.SERVICE_GROUP]: 'Service group',
  [MARKETING_TYPE.GIFT_CARD_PURCHASE]: 'Gift Card Purchase',
  [MARKETING_TYPE.LOYALTY_POINT]: 'Loyalty Point',
};

class Marketting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      marketingType: 0,
      page: 1,
      pageSize: 10,
      isOpen: false,
      listChecked: [],
      isShowWarning: false,
      startTime: '',
      endTime: '',
      tags: [],
      sort: 1,
      select_all: 0,
	  isSendAllCustomer: false
    };
  }

  componentDidMount() {
    const {
      actions,
      categoryCouponList,
      categoryList,
      serviceList,
    } = this.props;
    this.onInit();
    if (!categoryCouponList || !categoryCouponList.length) {
      actions.getCategoryCoupon();
    }
    if (!categoryList.length) {
      actions.getCategoryList();
    }
    if (!serviceList.length) {
      actions.getServiceList();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { startTime, endTime, marketingType } = this.state;
    const { isCreateCampainSuccess, checkBalanceSuccess } = this.props;
    if (isCreateCampainSuccess != prevProps.isCreateCampainSuccess) {
      const isCreateSuccess =
        isCreateCampainSuccess > prevProps.isCreateCampainSuccess;
      this.onSetState('isOpen', !isCreateSuccess);
      this.props.addNotification(
        isCreateSuccess ? SUCCESS : WARNING,
        `Create campain ${isCreateSuccess ? 'successful' : 'failed'}.`
      );
    }

    if (checkBalanceSuccess < prevProps.checkBalanceSuccess) {
      this.onSetState('isShowWarning', true);
    } else if (checkBalanceSuccess > prevProps.checkBalanceSuccess) {
      this.onSetState('isOpen', true);
    }
    if (
      !isEqual(endTime, prevState.endTime) &&
      moment(endTime).isBefore(moment(startTime))
    ) {
      this.setState({
        startTime: endTime,
      });
    }
    if (
      !isEqual(startTime, prevState.startTime) &&
      moment(startTime).isAfter(moment(endTime))
    ) {
      this.setState({
        endTime: startTime,
      });
    }
    if (
      marketingType != prevState.marketingType ||
      startTime != prevState.startTime ||
      endTime != prevState.endTime
    )
      this.onInit();
  }

  onSetState = (name, value) => this.setState({ [name]: value });

  toggle = (tab) => {
    const { marketingType } = this.state;
    if (marketingType !== tab) {
      this.setState({ marketingType: tab }, () => this.onInit());
    }
  };

  onInit = () => {
    const {
      page,
      pageSize,
      marketingType,
      startTime,
      endTime,
      tags,
    } = this.state;
    const { actions } = this.props;
    const payload = {
      page,
      pageSize,
      marketingType,
      startTime: startTime ? moment(startTime).format('YYYY-MM-DD') : '',
      endTime: endTime ? moment(endTime).format('YYYY-MM-DD') : '',
    };
    if (marketingType == MARKETING_TYPE.SERVICE_GROUP) {
      payload.serviceIds = tags;
    }
    actions.getDataMarketting(payload);
	this.setState({ select_all: 0 });
  };

  onChangePagination = (page) => () =>
    this.setState({ page }, () => this.onInit());

  submitCoupon = (payload) => {
    const { listChecked, isSendAllCustomer } = this.state;
    const { actions } = this.props;
    actions.onCreateCampain({ customerIds: listChecked, isAllCustomer: isSendAllCustomer, ...payload });
    this.setState({ listChecked: [] });
  };

  getListByCategoryId = (id) => {
    const { actions } = this.props;
    actions.getCouponByCategoryId(id);
  };

  selectCustomer = (id) => () => {
    const { listChecked } = this.state;
    this.setState({
      listChecked: listChecked.includes(id)
        ? listChecked.filter((item) => parseInt(item, 10) !== parseInt(id, 10))
        : [...listChecked, id],
    });
  };

  selectAll = (select_all_check) => () => {
    const { listChecked, pageSize } = this.state;
    const { markettingList } = this.props;
	if (!select_all_check) {
		this.setState({
			listChecked: listChecked.concat(markettingList.map((item) => item.customerId)),
			select_all: !select_all_check
		});
	}
	else {
		let marketting_list = markettingList.map((item) => item.customerId);
		let list_checked = listChecked.filter(id => !marketting_list.includes(id));
		this.setState({ listChecked: list_checked, select_all: !select_all_check });
	}
  };

  checkBalance = (isAllCustomer) => () => {
    const { actions } = this.props;
    const { listChecked } = this.state;
    actions.onCheckBalance({ ids: listChecked, isAllCustomer: isAllCustomer });
  };

  handleChangeDate = (name, date) => {
    this.setState({
      [name]: date,
    });
  };

  onSearch = () => this.setState({ page: 1 }, () => this.onInit());

  changeTypeSearch = (marketingType) => () => this.setState({ marketingType });

  onRemove(e, key) {
    e.preventDefault();
    this.setState((prevState) => ({
      tags: prevState.tags.filter((tag, index) => index != key),
    }));
  }

  handleService = (id) => () => {
    this.setState((prevState) => ({
      tags: prevState.tags.includes(id)
        ? prevState.tags.filter((tag) => tag != id)
        : [...prevState.tags, id],
    }));
  };

  setTypeSort = (sort) => () => this.setState({ sort });

  cancelCampaign = () => {
    const { actions } = this.props;
    actions.getCouponByCategoryIdSuccess({ coupons: [], smsText: '' });
    this.setState({ isOpen: false });
  };

  render() {
    const {
      marketingType,
      isOpen,
      page,
      listChecked,
      pageSize,
      isShowWarning,
      startTime,
      endTime,
      tags,
      sort,
	  select_all
    } = this.state;
    const {
      markettingList,
      total,
      categoryCouponList,
      couponDataById,
      history,
      categoryList,
      serviceList,
    } = this.props;
	let select_all_check = select_all;
	if (markettingList .length > 0 && markettingList[0].customerId && listChecked.includes(markettingList[0].customerId)) {
		select_all_check = 1;
	}
    return (
      <>
        <Card className="animated fadeIn card-home card-home--marketting">
          <CardBody className="d-flex p-0">
            <Container fluid className="d-flex w-100">
              <FormGroup className="table-marketting mt-0 w-100 d-flex">
                <Card className="table-list-marketting w-100">
                  <CardHeader>
                    <h4>
                      <span style={{flex: 1}}>Marketing</span>
                      <button
                        type="button"
                        className="btn-campain mr-3"
                        onClick={this.checkBalance(true)}
                      >
                        Create Campaign All
                      </button>
                      <button
                        type="button"
                        disabled={!listChecked.length}
                        className="btn-campain"
                        onClick={this.checkBalance(false)}
                      >
                        Create Campaign
                      </button>
                    </h4>
                    {/* <ul className="header-tabs">
                      {
                        tabList.map((tab) => (
                          <li key={tab.id} className={marketingType == tab.id ? 'active' : ''} onClick={() => this.toggle(tab.id)}>
                            {tab.name}
                          </li>
                        ))
                      }
                    </ul> */}
                  </CardHeader>
                  <CardBody>
                    <FormGroup className={`d-flex flex-wrap mb-4`}>
                      <div className="form-search-reviews">
                        <UncontrolledButtonDropdown className="group-customer-select">
                          <DropdownToggle caret color="info">
                            {filterSearch[marketingType]}
                          </DropdownToggle>
                          <DropdownMenu>
                            {Object.keys(filterSearch).map((key) => (
                              <DropdownItem
                                key={key}
                                onClick={this.changeTypeSearch(key)}
                              >
                                {filterSearch[key]}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        </UncontrolledButtonDropdown>

                        {marketingType == MARKETING_TYPE.SERVICE_GROUP && (
                          <Label
                            className={cx(
                              'form-custom form-custom--filter mr-2',
                              { 'form-focus': !!tags.length }
                            )}
                          >
                            <UncontrolledDropdown className="dropdown-service dropdown-service--filter mt-auto">
                              <DropdownToggle className="bg-white border-0">
                                {!!tags.length ? (
                                  <TagsInput
                                    value={serviceList
                                      .filter((service) =>
                                        tags.includes(service.id)
                                      )
                                      .map((service) => service.serviceName)}
                                    inputProps={{
                                      placeholder: '',
                                      onChange: () => {},
                                    }}
                                    renderInput={() => null}
                                    renderTag={(props) => {
                                      let {
                                        tag,
                                        key,
                                        disabled,
                                        classNameRemove,
                                        getTagDisplayValue,
                                        ...other
                                      } = props;
                                      return (
                                        <span key={key} {...other}>
                                          {getTagDisplayValue(tag)}
                                          {!disabled && (
                                            <a
                                              className={classNameRemove}
                                              onClick={(e) =>
                                                this.onRemove(e, key)
                                              }
                                            />
                                          )}
                                        </span>
                                      );
                                    }}
                                  />
                                ) : (
                                  'Select service'
                                )}
                              </DropdownToggle>
                              <DropdownMenu>
                                {serviceList.map((service) => (
                                  <DropdownItem
                                    onClick={this.handleService(service.id)}
                                    key={service.id}
                                  >
                                    <Input
                                      type="checkbox"
                                      checked={tags.includes(service.id)}
                                      onChange={() => {}}
                                    />
                                    {service.serviceName}
                                  </DropdownItem>
                                ))}
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </Label>
                        )}
                        <DatePicker
                          className="input-reviews-time"
                          name="startTime"
                          selected={startTime ? moment(startTime).toDate() : ''}
                          onChange={(date) =>
                            this.handleChangeDate('startTime', date)
                          }
                          showYearDropdown
                          dateFormatCalendar="MMMM"
                          scrollableYearDropdown
                          yearDropdownItemNumber={15}
                          placeholderText="From"
                          dateFormat="MM/dd/yyyy"
                          autoComplete="off"
                        />
                        <span className="mx-3">-</span>
                        <DatePicker
                          className="input-reviews-time"
                          name="endTime"
                          selected={endTime ? moment(endTime).toDate() : ''}
                          onChange={(date) =>
                            this.handleChangeDate('endTime', date)
                          }
                          showYearDropdown
                          dateFormatCalendar="MMMM"
                          scrollableYearDropdown
                          yearDropdownItemNumber={15}
                          placeholderText="To"
                          dateFormat="MM/dd/yyyy"
                          autoComplete="off"
                        />
                        <button onClick={this.onSearch}>Search</button>
                      </div>
                    </FormGroup>

                    <Table className="mb-0">
                      <thead>
                        <tr>
                          <td>
                            <CustomInput
                              type="checkbox"
                              checked={select_all_check}
                              onChange={this.selectAll(select_all_check)}
                              id="item-all"
                            />
                          </td>
                          <th>ID</th>
                          <th
                            onClick={this.setTypeSort(sort != 1 ? 1 : -1)}
                            className={cx('w-30', {
                              sort: Math.abs(sort) == 1,
                            })}
                          >
                            Customer Name
                            {Math.abs(sort) == 1 && (
                              <span>
                                <i
                                  className={`fa fa-caret-${
                                    sort == 1 ? 'down' : 'up'
                                  }`}
                                  aria-hidden="true"
                                ></i>
                              </span>
                            )}
                          </th>
                          <th>Customer Phone</th>
                          <th>
                            {marketingType == 1 || marketingType == 2
                              ? 'Star'
                              : ''}
                          </th>
                          <th
                            onClick={
                              marketingType == MARKETING_TYPE.ALL ||
                              marketingType == MARKETING_TYPE.BIRTHDAY ||
                              marketingType == MARKETING_TYPE.SERVICE_GROUP
                                ? this.setTypeSort(sort != 2 ? 2 : -2)
                                : null
                            }
                            className={cx('w-20', {
                              sort: Math.abs(sort) == 2,
                            })}
                          >
                            <HeaderTable
                              marketingType={marketingType}
                            ></HeaderTable>
                            {Math.abs(sort) == 2 && (
                              <span>
                                <i
                                  className={`fa fa-caret-${
                                    sort == 2 ? 'down' : 'up'
                                  }`}
                                  aria-hidden="true"
                                ></i>
                              </span>
                            )}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {markettingList &&
                          !!markettingList.length &&
                          markettingList
                            .slice()
                            .sort((a, b) => {
                              const nameA = a.customerName
                                .charAt()
                                .toLocaleLowerCase()
                                .charCodeAt();
                              const nameB = b.customerName
                                .charAt()
                                .toLocaleLowerCase()
                                .charCodeAt();
                              const resA = a.registerDate;
                              const resB = b.registerDate;
                              return Math.abs(sort) == 1
                                ? sort > 0
                                  ? nameA - nameB
                                  : nameB - nameA
                                : Math.abs(sort) == 2
                                ? sort > 0
                                  ? resA - resB
                                  : resB - resA
                                : a.id - b.id;
                            })
                            .map((item, index) => (
                              <tr key={index}>
                                <td>
                                  <CustomInput
                                    type="checkbox"
                                    checked={listChecked.includes(
                                      item.customerId
                                    )}
                                    onChange={this.selectCustomer(
                                      item.customerId
                                    )}
                                    id={`item-${item.customerId}`}
                                  />
                                </td>
                                <td>{item.customerId}</td>
                                <td>{item.customerName}</td>
                                <td>{formatPhone(item.phoneNumber)}</td>
                                {marketingType == 1 || marketingType == 2 ? (
                                  <td>
                                    <Rating
                                      initialRating={item.star}
                                      readonly
                                      emptySymbol={
                                        <span className="star-rating-symbol">
                                          <img alt="" src={Star} />
                                        </span>
                                      }
                                      fullSymbol={
                                        <span className="star-rating-symbol">
                                          <img alt="" src={StarFull} />
                                        </span>
                                      }
                                    />
                                  </td>
                                ) : (
                                  <td></td>
                                )}
                                <td>
                                  <MarketingType
                                    marketingType={marketingType}
                                    item={item}
                                  />
                                </td>
                              </tr>
                            ))}
                        <tr />
                      </tbody>
                    </Table>
                    {/* </div> */}
                  </CardBody>
                  <CardFooter className="pt-0">
                    <PaginationFooter
                      currentPage={page}
                      totalSearch={total}
                      onChangePagination={this.onChangePagination}
                    />
                  </CardFooter>
                </Card>
              </FormGroup>
            </Container>
          </CardBody>
        </Card>
        {isOpen && (
          <ModalHandleCampain
            isOpen={isOpen}
            onSubmit={this.submitCoupon}
            cancel={this.cancelCampaign}
            categoryCouponList={categoryCouponList}
            couponDataById={couponDataById}
            getListByCategoryId={this.getListByCategoryId}
            history={history}
          />
        )}
        {isShowWarning && (
          <ModalWarning
            isOpen={isShowWarning}
            onConfirm={() => this.setState({ isShowWarning: false })}
          />
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    markettingList: state.Marketting.markettingList,
    checkBalanceSuccess: state.Marketting.checkBalanceSuccess,
    total: state.Marketting.total,
    isCreateCampainSuccess: state.Marketting.isCreateCampainSuccess,
    categoryCouponList: state.Coupon.categoryCouponList,
    couponDataById: state.Coupon.couponDataById,
    serviceList: state.Service.serviceList,
    categoryList: state.Service.categoryList,
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {
    ...markettingAction,
    ...couponAction,
    ...serviceAction,
    ...defaultAction,
  };
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(Marketting);
