import freeze from 'deep-freeze';
import { handleActions } from 'redux-actions';
import * as actions from './actions';

export const name = 'Report';

const initialState = {
  reportList: [],
  reportSMSList: [],
  total: 0,
  totalSmsRemain: 0
};

export default handleActions({
  [actions.getDataReportSuccess]: (state, action) => freeze({
    ...state,
    reportList: action.payload.data,
  }),
  [actions.getDataReportSMSSuccess]: (state, action) => freeze({
    ...state,
    reportSMSList: action.payload.data ? action.payload.data : [],
    total: action.payload.total,
    totalSmsRemain: action.payload.totalSmsRemain
  }),
}, initialState);
