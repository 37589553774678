import React from 'react';
import {
  ModalFooter, ModalHeader, Modal, FormGroup, Col, Label, Input, ModalBody
} from 'reactstrap';


export default function ModalHandleSocialChannel(props) {
  const { isOpen, socialChannel } = props;
  return (
    <Modal className="modal-create" isOpen={isOpen} centered>
      <ModalHeader className="border-0">Create Social Channel</ModalHeader>
      <ModalBody className="border-0">
        <FormGroup row>
          <Label sm={3}>Channel Name</Label>
          <Col sm={9}>
            <Input name="channelName" value={socialChannel.channelName || ''} onChange={props.onChange} />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>Channel Url</Label>
          <Col sm={9}>
            <Input name="channelUrl" value={socialChannel.channelUrl || ''} onChange={props.onChange} />
          </Col>
        </FormGroup>
      </ModalBody>
      <ModalFooter className="border-0">
        <button onClick={props.toggleModal}>Cancel</button>
        <button onClick={props.onSave}>Save</button>
      </ModalFooter>
    </Modal>
  )
}

