const ACTION_NAME = 'FEEDBACK/SERVICE';
export const GET_SERVICE_LIST = `${ACTION_NAME}/GET_SERVICE_LIST`;
export const GET_SERVICE_LIST_SUCCESS = `${ACTION_NAME}/GET_SERVICE_LIST_SUCCESS`;
export const ON_CREATE_SERVICE = `${ACTION_NAME}/ON_CREATE_SERVICE`;
export const ON_CREATE_SERVICE_SUCCESS = `${ACTION_NAME}/ON_CREATE_SERVICE_SUCCESS`;
export const GET_DETAIL_SERVICE = `${ACTION_NAME}/GET_DETAIL_SERVICE`;
export const GET_DETAIL_SERVICE_SUCCESS = `${ACTION_NAME}/GET_DETAIL_SERVICE_SUCCESS`;
export const ON_UPDATE_SERVICE = `${ACTION_NAME}/ON_UPDATE_SERVICE`;
export const ON_UPDATE_SERVICE_SUCCESS = `${ACTION_NAME}/ON_UPDATE_SERVICE_SUCCESS`;
export const ON_DELETE_SERVICE = `${ACTION_NAME}/ON_DELETE_SERVICE`;
export const ON_DELETE_SERVICE_SUCCESS = `${ACTION_NAME}/ON_DELETE_SERVICE_SUCCESS`;

export const GET_CATEGORY_LIST = `${ACTION_NAME}/GET_CATEGORY_LIST`;
export const GET_CATEGORY_LIST_SUCCESS = `${ACTION_NAME}/GET_CATEGORY_LIST_SUCCESS`;
export const ON_CREATE_CATEGORY = `${ACTION_NAME}/ON_CREATE_CATEGORY`;
export const ON_CREATE_CATEGORY_SUCCESS = `${ACTION_NAME}/ON_CREATE_CATEGORY_SUCCESS`;
export const ON_UPDATE_CATEGORY = `${ACTION_NAME}/ON_UPDATE_CATEGORY`;
export const ON_UPDATE_CATEGORY_SUCCESS = `${ACTION_NAME}/ON_UPDATE_CATEGORY_SUCCESS`;
export const GET_DETAIL_CATEGORY = `${ACTION_NAME}/GET_DETAIL_CATEGORY`;
export const GET_DETAIL_CATEGORY_SUCCESS = `${ACTION_NAME}/GET_DETAIL_CATEGORY_SUCCESS`;
export const ON_DELETE_CATEGORY = `${ACTION_NAME}/ON_DELETE_CATEGORY`;
export const ON_DELETE_CATEGORY_SUCCESS = `${ACTION_NAME}/ON_DELETE_CATEGORY_SUCCESS`;

export const GET_IMAGE_CATEGORY_SERVICE = `${ACTION_NAME}/GET_IMAGE_CATEGORY_SERVICE`;
export const GET_IMAGE_CATEGORY_SERVICE_SUCCESS = `${ACTION_NAME}/GET_IMAGE_CATEGORY_SERVICE_SUCCESS`;
export const GET_IMAGE_BY_CATEGORY_ID = `${ACTION_NAME}/GET_IMAGE_BY_CATEGORY_ID`;
export const GET_IMAGE_BY_CATEGORY_ID_SUCCESS = `${ACTION_NAME}/GET_IMAGE_BY_CATEGORY_ID_SUCCESS`;

export const EXPIRE_TYPE = {
  FROM_TO: 0,
  NOT_EXPIRE: 1,
};

export const SEARCH_TYPE = {
  ALL: 0,
  CATEGORY: 1,
  SERVICE_NAME: 2,
};