import React, { useState, useEffect, useRef, useMemo, Fragment } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CustomInput,
  UncontrolledCollapse,
} from "reactstrap";
import InputFormatPhone from "react-phone-number-input/input";
import classnames from "classnames";
import { format_phone_custom } from "../../../../../libs/utils/utils";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import TagsInput from "react-tagsinput";
// import SockJS from "sockjs-client";
// import { over } from "stompjs";
import ModalChooseCustomer from "./ModalChooseCustomer";
import ModalCustomerHandle from "./ModalCustomerHandle";
import ModalConfirmAppoitment from "./ModalConfirmAppoitment";
import ModalChooseStaff from "./ModalChooseStaff";
import ModalChooseService from "./ModalChooseService";
import { useForm } from "react-hook-form";
import ModalCustom from "./Modal";
import { ErrorMessage } from "@hookform/error-message";
import DropdownCustom from "./DropdownCustom";
import { isEmpty } from "lodash";
import Api from "../../../../../api/customer";
import { SUCCESS, WARNING } from "../../../../../constants";
import * as actionDefault from '../../../container/actions'
// import { socketUrl } from "../../../../../config"
import {
	times_list
  } from "../../../../../libs/utils/utils";


export default function ModalAppoitment(props) {
  const { register, handleSubmit, errors, control } = useForm({
    criteriaMode: "all",
    reValidateMode: "onSubmit",
  });

  const [phone, setPhone] = useState();
  const [date, setDate] = useState(new Date());
  const [startTime, setStartTime] = useState();
  const [startTimeStaff, setStartTimeStaff] = useState("");
  // const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [tags, setTags] = useState([]);
  const [staff, setStaff] = useState({});
  const [customer, setCustomer] = useState({});
  const [isOpenCustomer, setIsOpenCustomer] = useState(false);
  const [isOpenChooseCustomer, setIsOpenChooseCustomer] = useState(true);
  const [createCustomerSuccess, setCreateCustomerSuccess] = useState(0);
  const [searchName, setSearchName] = useState("");
  const [nameCustomer, setNameCustomer] = useState("");
  const [note, setNote] = useState();
  const [isDelete, setIsDelete] = useState(false);
  const [isInvidual, setIsInvidual] = useState(true);
  const [totalPeople, setTotalPeople] = useState(2);
  const [customerEdit, setCustomerEdit] = useState({});

  const [isOpenChooseStaff, setIsOpenChooseStaff] = useState(false);
  const [isOpenChooseService, setIsOpenChooseService] = useState(false);
  const [timeServices, setTimeServices] = useState(0);
  const [isAnyBody, setIsAnyBody] = useState(0);
  const customerChanged = useHasChanged(customer);

//   var stompClient = null;
//   var socket = new SockJS(
//     socketUrl
//   );

//   stompClient = over(socket);
//   stompClient.connect({}, () => {
//   });

  function useHasChanged(val) {
    const prevVal = usePrevious(val);
    return prevVal !== val;
  }

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  useEffect(() => {
    return () => onClosed();
  }, []);

  useEffect(() => {
    const { isCreateCustomerSuccess } = props;
    if (isCreateCustomerSuccess > createCustomerSuccess) {
      setCreateCustomerSuccess(isCreateCustomerSuccess);
      const {
        match: { params = {} },
      } = props;
      const { apiKey } = params;
      const payload = {};
      if (apiKey) payload.apiKey = apiKey;
      props.getDataCustomer(payload);

      setCustomer(props.dataCustomer);
      setSearchName(props.dataCustomer.name);
      setIsOpenCustomer(false);
    }
  }, [props.isCreateCustomerSuccess]);

  useEffect(() => {
    if (!searchName) setCustomer({});
    let timeout = 0;
    if (!customerChanged && searchName) {
      timeout = setTimeout(() => {
        props.onGetCustomer(true);
      }, 500);
    }
    return () => clearTimeout(timeout);
  }, [searchName]);

  useEffect(() => {
    if (staff && staff.id) {
      const {
        match: { params = {} },
      } = props;
      const { apiKey } = params;
      const param = {
        currentDate: moment(date).format("YYYY-MM-DD"),
        staffId: staff.id,
      };

      if (apiKey) param.apiKey = apiKey;
      props.onGetTimeSlot(param);
    }
  }, [staff, date]);

  useEffect(() => {
	setStartTime(startTimeStaff);
  }, [startTimeStaff])

  useEffect(() => {
	if (startTimeStaff) {
		setStartTime(startTimeStaff);
		return;
	}
    const { itemEditProps, startTimeProps } = props;
    if (!itemEditProps.isEdit) {
      const getOffsets = (time) =>
        Math.abs(
          moment(moment(date).format("MM/DD/YYYY") + " " + time).valueOf() -
          moment(startTimeProps).valueOf()
        );
      const timeNearest = !!props.staffTimeSlot.timeSlots.length
        ? props.staffTimeSlot.timeSlots.reduce((min, time) => {
          min =
            getOffsets(min || props.staffTimeSlot.timeSlots[0]) <
              getOffsets(time)
              ? min
              : time;
          return min;
        }, props.staffTimeSlot.timeSlots[0])
        : null;

      const tempTime = props.staffTimeSlot.timeSlots.find(
        (time) =>
          moment(moment(date).format("MM/DD/YYYY") + " " + time).valueOf() >
          moment(startTimeProps).valueOf()
      );

      if (
        !tempTime &&
        props.staffTimeSlot &&
        props.staffTimeSlot.timeSlots &&
        !!props.staffTimeSlot.timeSlots.length
      ) {
        const firstSlot = props.staffTimeSlot.timeSlots[0];
        const lastSlot =
          props.staffTimeSlot.timeSlots[
          props.staffTimeSlot.timeSlots.length - 1
          ];
        if (
          moment(
            moment(date).format("MM/DD/YYYY") + " " + firstSlot
          ).valueOf() > moment(startTimeProps).valueOf()
        )
          setStartTime(firstSlot);
        if (
          moment(moment(date).format("MM/DD/YYYY") + " " + lastSlot).valueOf() <
          moment(startTimeProps).valueOf()
        )
          setStartTime(lastSlot);
      }
      if (
        timeNearest &&
        moment(
          moment(date).format("MM/DD/YYYY") + " " + timeNearest
        ).valueOf() > moment().valueOf()
      ) {
        setStartTime(timeNearest);
      } else if (tempTime) {
        setStartTime(tempTime);
      }
    }
  }, [props.staffTimeSlot]);

  useEffect(() => {
    const startTimeValue = startTime ? startTime : moment().format("hh:mm");
	let services_time = 0;
    if (!!tags.length) {
      const { serviceList } = props;
      const serviceFilter = serviceList
        .filter((service) => tags.includes(service.id))
        .map((service) => (service.duration > 0 ? service.duration : 15));
      if (!!serviceFilter.length) {
        const upDuration = serviceFilter.reduce(
          (total, duration) => total + duration
        );
		services_time = upDuration;
        setEndTime(
          moment(moment(date).format("MM/DD/YYYY") + " " + startTimeValue).add({
            minute: upDuration,
          })
        );
      }
    } else {
      setEndTime(
        moment(moment(date).format("MM/DD/YYYY") + " " + startTimeValue)
      );
    }
	setTimeServices(services_time);
  }, [startTime, tags, date]);

  const onUpdateCustomer = async (payload) => {
    const { isLoading } = props;
    isLoading(false);
    const response = await Api.updateCustomerApi({ payload });
    const status =
      response &&
      response.data &&
      response.data.code &&
      response.data.code === 200;
    if (status) {
      const {
        data: { payload },
      } = response;
      if (payload.id == customer.id) {
        setCustomer(payload);
        setSearchName(payload.name);
      }
      setIsOpenCustomer(false);
      props.onGetCustomer();
    }
    isLoading(true);
  };

  const onDeleteCustomer = async () => {
    const { id } = customerEdit;
    const { isLoading } = props;
    isLoading(false);
    const response = await Api.deleteCustomerApi(id);
    const status =
      response &&
      response.data &&
      response.data.code &&
      response.data.code === 200;
    if (status) {
      if (id == customer.id) {
        setCustomer({});
        setSearchName("");
      }
      setIsOpenCustomer(false);
      props.onGetCustomer();
    }
    isLoading(true);
  };

  function handleChange(e, tags) {
    // setTags(tags);
  }

  function onRemove(e) {
    e.preventDefault();
    const {
      target: { name },
    } = e;
    setTags(tags.filter((tag, index) => index != name));
  }

  function openCreateCustomer(e) {
    e.preventDefault();
    // props.setIsOpenAppointment(false)
    setIsOpenCustomer(true);
  }

  function updateAppointmentForOtherStaff() {
	const staffs_list = props.staffList;
	if (props.dataAppointment.length == 0 || staffs_list.length == 0) {
		return true;
	}
	var busy_time_staffs_list = props.dataAppointment.map((item) => {
		return item.group;
	});

	props.dataAppointment.map((item) => {
		if (item.group == staff.id && item.isAnybody) {
			let staff_id_assign = '';
			staffs_list.map((staff) => {
				if (staff_id_assign != '') {
					return;
				}
				if (!busy_time_staffs_list.includes(staff.id)) {
					staff_id_assign = staff.id;
					return;
				}
			})
			const payload = {
				id: item.id,
				customerId: item.customerId,
				customerName: item.customerName,
				phone: item.customerPhone,
				staffId: staff_id_assign,
				startTime: moment(item.start_time).format("MM/DD/YYYY HH:mm"),
				endTime: moment(item.end_time).format("MM/DD/YYYY HH:mm"),
				isAnybody: 1,
			};
			const {
				match: { params = {} },
				} = props;
				const { apiKey } = params;
				if (apiKey) {
				payload.apiKey = apiKey;
				} else {
				payload.apiKey = localStorage.getItem('xApiKey');
				}
				props.onEditAppoitment(payload);
		}
	});
  }
  function submitCreateAppoitment() {
    if (!isDelete) {
		if (props.itemEditProps && props.itemEditProps.isEdit) {
		}
		else {
			if (!isAnyBody) {
				updateAppointmentForOtherStaff();
			}
		}
      actionDefault.isLoadingSuccess(false);
      let start_time = moment(moment(date).format("YYYY/MM/DD") + " " + startTime);
      const payload = {
        customerId: customer.id,
        customerName: customer.name,
        phone: phone,
        type: 1,
        serviceIds: tags,
        staffId: staff.id,
        totalPeople: isInvidual ? 1 : totalPeople,
        note,
        startTime: start_time.format("MM/DD/YYYY HH:mm"),
        endTime: moment(endTime).format("MM/DD/YYYY HH:mm"),
        isAnybody: isAnyBody ? 1: 0,
      };

      const {
        match: { params = {} },
      } = props;
      const { apiKey } = params;
      if (apiKey) {
        payload.apiKey = apiKey;
      } else {
        payload.apiKey = localStorage.getItem('xApiKey');
      }

      if (props.itemEditProps && props.itemEditProps.isEdit) {
        payload.id = props.itemEditProps.id;
        props.onEditAppoitment(payload);
      } else {
        props.onCreateAppoitment(payload);
        // props.setIsOpenAppointment(false);
        // isLoading(false)
        // stompClient.send(
        //   "/socket/bookAppointmentRealtime",
        //   {},
        //   JSON.stringify({ payload })
        // );

        // setTimeout(function () {
        //   isLoading(true)
        // }, 5000);

      }
      props.setIsCurrentAppointment(
        {
          staff_id: staff.id,
          date: moment(date).format("MM/DD/YYYY")
        }
      )
      props.setVisibleTimeStart(
        start_time
        .subtract({ hour: 0.25 })
        .valueOf()
      )
      props.setVisibleTimeEnd(
        start_time
        .add({ hour: 2 }) 
        .valueOf()
      )
    }

  }

  function onGetStaff() {
    const {
      match: { params = {} },
    } = props;
    const { apiKey } = params;

	const payload = {
		date: moment(date).format("YYYY-MM-DD"),
	  };
	if (apiKey) payload.apiKey = apiKey;

    return props.onGetStaff(payload);
  }
  const selectCustomer = (customer) => () => {
    setCustomer(customer);
    setSearchName(customer.name);
  };

  function onClosed() {
    setSearchName("");
    setCustomer({});
    setPhone("");
    setDate(new Date());
    // setStartTime(new Date())
    setStartTime("");
    setTags([]);
    setStaff({});
    setNote();
    setTotalPeople(1);
    setIsInvidual(true);
    props.setStartTimeProps("");
    props.setStaffProps("");
    props.setItemEditProps({});
  }

  function onOpened() {
	onGetStaff();
    const {
      startTimeProps,
      staffProps,
      staffList,
      selectedDate,
      customerList,
      itemEditProps,
      endTimeShop,
      startTimeShop,
    } = props;

    const findStaff = (id) => staffList.find((staff) => staff.id == id);

    if (startTimeProps && staffProps) {
      const staffSelect = findStaff(staffProps);
      setStaff(staffSelect || {});
    } else if (!itemEditProps.isEdit && endTimeShop) {
      if (moment(date).isSame(moment(), "day")) {
        const startHour = startTimeShop.split(":")[0];
        const endHour = endTimeShop.split(":")[0];
        const hourNow = moment(selectedDate).hour();
        let hourSelect = hourNow > startHour ? hourNow : startHour;
        let timeSlots = [];
        const convertToTime = (number) =>
          number.toString().length == 1 ? "0" + number : number;
        for (let i = hourSelect; i < endHour; i++) {
          for (let j = 0; j <= 3; j++) {
            timeSlots.push(
              moment(
                convertToTime(i) + ":" + convertToTime(15 * j),
                "HH:mm"
              ).format("hh:mm A")
            );
          }
        }
        props.onSetTimeSlotSuccess({ timeSlots });
      }
    }

    if (itemEditProps && itemEditProps.isEdit) {
      const staffSelect = findStaff(itemEditProps.group);
      setStaff(staffSelect || {});
      const customerSelect = customerList.find(
        (customer) => customer.id == itemEditProps.customerId
      );
      setNote(itemEditProps.note);
      setCustomer(customerSelect);
      setSearchName(customerSelect.name || "");
      setDate(new Date(itemEditProps.start_time));
      setTags(itemEditProps.services.map((service) => service.id));
      setPhone(itemEditProps.customerPhone);
      setStartTime(moment(itemEditProps.start_time).format("hh:mm a"));
      setEndTime(moment(itemEditProps.end_time));
      if (itemEditProps.totalPeople > 1) {
        setIsInvidual(false);
        setTotalPeople(itemEditProps.totalPeople);
      }
	  setIsAnyBody(itemEditProps.isAnybody)
    } else {
      setDate(new Date(selectedDate));
      const hourNow = moment(startTimeProps ? startTimeProps : new Date()).get(
        "hour"
      );
      const minuteNow = moment(
        startTimeProps ? startTimeProps : new Date()
      ).get("minute");
      // setStartTime(moment().set({ hour: minuteNow > 45 ? hourNow + 1 : hourNow, minute: minuteNow % 15 == 0 ? minuteNow : (minuteNow > 45 ? 0 : Math.ceil(minuteNow / 15) * 15) }).format('MM/DD/YYYY HH:mm'))
      setEndTime(
        moment()
          .set({
            hour: minuteNow > 45 ? hourNow + 1 : hourNow,
            minute:
              minuteNow % 15 == 0
                ? minuteNow
                : minuteNow > 45
                  ? 0
                  : Math.ceil(minuteNow / 15) * 15,
          })
          .format("MM/DD/YYYY HH:mm")
      );
      // console.log(
      //   moment()
      //     .set({
      //       hour: minuteNow > 45 ? hourNow + 1 : hourNow,
      //       minute:
      //         minuteNow % 15 == 0
      //           ? minuteNow
      //           : minuteNow > 45
      //             ? 0
      //             : Math.ceil(minuteNow / 15) * 15,
      //     })
      //     .format("MM/DD/YYYY HH:mm")
      // );
    }
  }

  function onDelete() {
    const { itemEditProps } = props;
    setIsDelete(false);
    const {
      match: { params = {} },
    } = props;
    const { apiKey } = params;

    const payload = {
      id: itemEditProps.id,
    };
    if (apiKey) payload.apiKey = apiKey;
    props.onDeleteAppoitment(payload);
  }

  function openChooseCustomer(e) {
	e.preventDefault();
	setIsOpenChooseCustomer(true);
  }

  function openChooseStaff(e) {
	e.preventDefault();
	setIsOpenChooseStaff(true);
  }

  function openChooseService(e) {
	e.preventDefault();
	setIsOpenChooseService(true);
  }

  const staffDropdown = useMemo(() => {
    return (
		<UncontrolledDropdown className="dropdown-service  mt-auto">
			<DropdownToggle 
				className="bg-white border-0 pb-0 appointment-input" 
				onClick={openChooseStaff}
			>
				{staff.id ? staff.firstName + " " + staff.lastName : "Random Staff"}
			</DropdownToggle>
		</UncontrolledDropdown>
    );
  }, [staff]);

  useEffect(() => {
    if (!isEmpty(customerEdit)) setIsOpenCustomer(true);
  }, [customerEdit]);

  useEffect(() => {
	if (customer && customer.id) {
		setIsOpenChooseCustomer(false);
	}
  }, [customer]);

	// check anybody
	function find_any_body() {
		const staffs_list = props.staffList;
		if (staffs_list.length > 0) {
			var exit_loops = false;
			for (let i = 0; i < staffs_list.length; i++) {
				let timeFree = staffs_list[i].timeFree;
				if (timeFree && timeFree.timeSlots && !!timeFree.timeSlots.length) {
					for (let j = 0; j < timeFree.timeSlots.length; j++) {
						if (startTime == timeFree.timeSlots[j]) {
							exit_loops = true;
							setStaff(staffs_list[i]);
							break;
						}
					}
				}
				if (exit_loops) {
					break;
				}
			}
			if (!exit_loops) {
				setStaff(staffs_list[0]);
			}
		}
	}

  const editCustomer = (customer) => (e) => {
    e.preventDefault();
    setCustomerEdit(customer);
  };

  const getCategoryList = (categoryList, serviceList) => {
    let categorys = [];
    categoryList.map((category) => {
      let total = serviceList.filter((service) => service.catId == category.id).length;
      if (total > 0) {
        categorys.push(category);
      }
    })
    return categorys;
  }

  return (
    <Fragment>
      <Modal
        isOpen={props.isOpen}
        // centered
        className="modal-appoitment"
        onClosed={onClosed}
        onOpened={onOpened}
		style={{maxWidth: '1000px'}}
      >
        <form onSubmit={handleSubmit(submitCreateAppoitment)}>
          <ModalHeader className="w-100">
            {props.itemEditProps && props.itemEditProps.isEdit ? "Edit" : "Add"}{" "}
            {"Appointment"}
            {props.itemEditProps && props.itemEditProps.isEdit ? (
              <button
                className="btn btn-danger"
                style={{ color: "white" }}
                disabled={customerEdit.status == "arrived"}
                onClick={() => setIsDelete(true)}
              >
                <i className="fa fa-trash-o" aria-hidden="true"></i>
                {"Delete"}
              </button>
            )
            : 
            <button type="button" className="close" onClick={() => props.setIsOpenAppointment(false)}>
              <span aria-hidden="true" style={{ fontSize: "50px", color: '#000'}}>×</span>
            </button>
            }
          </ModalHeader>
          <ModalBody>
            <h4>Customer Information</h4>
            <Row>
              <Col sm={12} className="d-flex flex-wrap align-items-center">
                <div
                  className={classnames("form-custom flex-fill", {
                    "form-focus": (customer && customer.name) || searchName,
                  })}
                >
                  <span>
                    Name <b>*</b>
                  </span>
                  <DropdownCustom className="dropdown-service ">
                    <DropdownToggle className="bg-white border-0 pb-0" onClick={openChooseCustomer}>
                      <Input
                        value={searchName}
                        id="form-search-name"
                        className="p-0 appointment-input"
                        autoComplete="off"
                        onChange={({ target: { value } }) =>
                          setSearchName(value)
                        }
						style={{ height: '30px!important' }}
                      />
                      {
                        (customer && customer.phone) ? <p className="font-size-lg">{format_phone_custom(customer.phone)}</p> : ''
                      }
                      <input
                        value={customer.id || ""}
                        name="customer"
                        id="form-customer"
                        ref={register({
                          required: "Customer is required.",
                        })}
                        className="d-none"
                        onChange={() => { }}
                      />
                    </DropdownToggle>
                    {/* <DropdownMenu>
                      {props.customerList
					//   {customer_list
                        .filter((item) => item.name)
                        .filter(
                          (item) =>
                            item.name
                              .toLowerCase()
                              .includes(searchName.toLowerCase()) ||
                            item.phone
                              .toLowerCase()
                              .includes(searchName.toLowerCase())
                        )
                        .map((customer) => (
                          <DropdownItem
                            key={customer.id}
                            className="custom-group custom-group--staff custom-group--edit p-0"
                          >
                            <p
                              className="m-0 py-2"
                              onClick={selectCustomer(customer)}
                            >
                              {customer.name}
                              {customer.phone &&
                                ` - ${formatPhone(customer.phone)}`}
                            </p>
                            <span
                              className="staff-edit"
                              onClick={editCustomer(customer)}
                            >
                              <i
                                className="fa fa-pencil-square-o"
                                aria-hidden="true"
                              ></i>
                            </span>
                          </DropdownItem>
                        ))}
                    </DropdownMenu> */}
                  </DropdownCustom>
                </div>
              </Col>
              {(!customer || !customer.id) && (
                <Col sm={12}>
                  <ErrorMessage
                    errors={errors}
                    name="customer"
                    render={({ messages }) =>
                      messages &&
                      Object.entries(messages).map(([type, message]) => (
                        <p className="error-message mt-2" key={type}>
                          {message}
                        </p>
                      ))
                    }
                  />
                </Col>
              )}
            </Row>
            <h4>Date and Time</h4>
            <Row>
              <Col sm={4}>
                <div
                  className={classnames("form-custom", {
                    "form-focus": !!date,
                  })}
                >
                  <span>
                    Date <b>*</b>
                  </span>
                  <DatePicker
                    id="form-date"
                    className="form-input appointment-input"
                    selected={date}
                    minDate={new Date()}
                    onChange={(date) => setDate(date)}
                  />
                </div>
              </Col>
              <Col sm={2} style={{ fontSize: '20px', marginTop: '10px', fontWeight: 'bold', textAlign: 'center' }}>
				<Label check>
					<Input 
					type="checkbox" 
					value={isAnyBody} 
					checked={isAnyBody} 
					name="isAnyBody" 
					id="isAnyBody" 
					style={{width: '20px', height: '20px'}}
					onChange={(e) => {
							if (e.target.checked) {
								find_any_body();
							}
							setIsAnyBody(!isAnyBody)
						}
					}
					/> <span className="ml-2">Anybody</span>
				</Label>
			  </Col>
              <Col sm={6}>
				<div className="form-custom form-focus">
					<span>Select staff</span>
					{staffDropdown}
				</div>
              </Col>
            </Row>
            <h4>Service Information</h4>
            <Label
              className={classnames("form-custom", {
                "form-focus": tags && !!tags.length,
              })}
            >
              <span>
                Select a services <b>*</b>
              </span>
              <DropdownCustom className="dropdown-service dropdown-service--appoitment mt-auto">
                <DropdownToggle className="bg-white border-0" onClick={openChooseService}>
                  <TagsInput
                    // onChange={handleChange}
                    value={props.serviceList
                      .filter((service) => tags.includes(service.id))
                      .map((service) => service.serviceName)}
                    inputProps={{ placeholder: "", onChange: handleChange }}
                    renderTag={(props) => {
                      let {
                        tag,
                        key,
                        disabled,
                        classNameRemove,
                        getTagDisplayValue,
                        ...other
                      } = props;
                      return (
                        <span key={key} {...other}>
                          {getTagDisplayValue(tag)}
                          {!disabled && (
                            <a
                              className={classNameRemove}
                              name={key}
                              onClick={onRemove}
                            />
                          )}
                        </span>
                      );
                    }}
                    renderInput={() => <Fragment></Fragment>}
                  />
                </DropdownToggle>
              </DropdownCustom>
            </Label>
            <input
              value={tags.join("") || ""}
              name="services"
              ref={register({
                required: "Services is required.",
              })}
              className="d-none"
              onChange={() => { }}
            />
            {(!tags || !tags.length) && (
              <ErrorMessage
                errors={errors}
                name="services"
                render={({ messages }) =>
                  messages &&
                  Object.entries(messages).map(([type, message]) => (
                    <p className="error-message mt-2" key={type}>
                      {message}
                    </p>
                  ))
                }
              />
            )}
            <Row className="my-3">
              <Col sm={6} className="pr-2">
                <div
                  className={classnames("form-custom", {
                    "form-focus": !!startTime,
                  })}
                >
                  <span>
                    Start time <b>*</b>
                  </span>
                  {
                    // <DatePicker
                    //   id="start-time"
                    //   selected={new Date(startTime)}
                    //   onChange={(date) => setStartTime(date)}
                    //   showTimeSelect
                    //   minTime={new Date(startTime)}
                    //   maxTime={moment().endOf('day').toDate()}
                    //   showTimeSelectOnly
                    //   timeIntervals={15}
                    //   timeCaption="Time"
                    //   dateFormat="h:mm aa"
                    // />
                  }
                   <UncontrolledDropdown className="dropdown-service  mt-auto">
                    <DropdownToggle className="bg-white border-0 pb-0 text-uppercase appointment-input">
                      {startTime}
                    </DropdownToggle>
                    {props.staffTimeSlot &&
                      props.staffTimeSlot.timeSlots &&
                      !!props.staffTimeSlot.timeSlots.length && (
                        <DropdownMenu>
                          {props.staffTimeSlot.timeSlots
                            .filter((time) =>
                              moment(
                                moment(date).format("MM/DD/YYYY") + " " + time
                              ).isAfter(moment())
                            )
                            .map((time, id) => (
                              <DropdownItem
                                key={id}
                                className="custom-group custom-group--staff px-0"
                                onClick={() => setStartTime(time)}
                              >
                                {time}
                              </DropdownItem>
                            ))}
                        </DropdownMenu>
                      )}
                  </UncontrolledDropdown>
                </div>
                <input
                  value={startTime || ""}
                  name="startTime"
                  id="form-time-select"
                  ref={register({
                    required: "StartTime is required.",
                  })}
                  className="d-none"
                  onChange={() => { }}
                />
                {!startTime && (
                  <ErrorMessage
                    errors={errors}
                    name="startTime"
                    render={({ messages }) =>
                      messages &&
                      Object.entries(messages).map(([type, message]) => (
                        <p className="error-message mt-2" key={type}>
                          {message}
                        </p>
                      ))
                    }
                  />
                )}
              </Col>
              <Col sm={6} className="pl-2">
                <div
                  className={classnames("form-custom", {
                    "form-focus": !!endTime,
                  })}
                >
                  <span>
                    End time <b>*</b>
                  </span>
				  <UncontrolledDropdown className="dropdown-service  mt-auto">
						<DropdownToggle className="bg-white border-0 pb-0 text-uppercase appointment-input">
						{endTime && moment(new Date(endTime)).format("hh:mm A")}
						</DropdownToggle>
						<DropdownMenu>
							{times_list(date, startTime, timeServices)
							.filter((time) =>
								moment(time, 'h:mma').isAfter(moment(startTime, 'h:mma').add({
									minute: timeServices
								  })
								)
							)
							.map((time, id) => (
								<DropdownItem
								key={id}
								className="custom-group custom-group--staff px-0"
								onClick={() => setEndTime(moment(moment(date).format("YYYY/MM/DD") + " " + time).format("MM/DD/YYYY HH:mm"))}
								>
								{time}
								</DropdownItem>
							))}
						</DropdownMenu>
					</UncontrolledDropdown>
                </div>
              </Col>
            </Row>
            <div className="d-flex align-items-center row-group-select">
              <CustomInput
                type="radio"
                checked={isInvidual}
                id="isIndividual"
                name="isIndividual"
                label="Individual Appointment"
                onChange={() => setIsInvidual(true)}
              />
              <CustomInput
                type="radio"
                checked={!isInvidual}
                id="isIndividual1"
                className="mx-4"
                name="isIndividual"
                label="Group"
                onChange={() => setIsInvidual(false)}
              />
              <FormGroup className="d-flex m-0 align-items-center flex-grow-1">
                People{" "}
                <Input
                  disabled={isInvidual}
                  min={2}
                  onChange={({ target: { value } }) => setTotalPeople(value)}
                  value={isInvidual && totalPeople == 2 ? "" : totalPeople}
                  type="number"
                  className="ml-2 appointment-input"
                />
              </FormGroup>
            </div>
            <h4>Appointment Notes</h4>
            <Input
              type="textarea"
              value={note}
              onChange={({ target: { value } }) => setNote(value)}
              name="note"
              rows="4"
			  className="appointment-input"
            />
          </ModalBody>
          <ModalFooter className="border-0">
            <Button
              color="secondary"
              onClick={() => props.setIsOpenAppointment(false)}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              type="submit"
            // onClick={submitCreateAppoitment}
            >
              {props.itemEditProps && props.itemEditProps.isEdit
                ? "Update"
                : "Add"}
            </Button>
          </ModalFooter>
        </form>
      </Modal>
	{/* Modal choose customer */}
	<ModalChooseCustomer
		isOpen={isOpenChooseCustomer}	
		onClose={() => setIsOpenChooseCustomer(false)}
		customerList={props.customerList}
		customer_selected={customer}
		setCustomer={setCustomer}
		setCustomerEdit={setCustomerEdit}
		searchName={searchName}
		setSearchName={setSearchName}
		openCreateCustomer={openCreateCustomer}
		setNameCustomer={setNameCustomer}
		setIsOpenChooseStaff={(staff && staff.id) ? null : setIsOpenChooseStaff}
		setIsOpenChooseService={(!!tags.length) ? null : setIsOpenChooseService}
	/>
      <ModalCustomerHandle
        isOpen={isOpenCustomer}
        setIsOpenCustomer={setIsOpenCustomer}
        setIsOpenAppointment={props.setIsOpenAppointment}
        onCreateCustomer={props.onCreateCustomer}
        setCustomer={setCustomer}
        match={props.match}
        isCreateCustomerSuccess={props.isCreateCustomerSuccess}
        customerEdit={customerEdit}
        setCustomerEdit={setCustomerEdit}
        isLoading={props.isLoading}
        onDeleteCustomer={onDeleteCustomer}
        onUpdateCustomer={onUpdateCustomer}
		nameCustomer={nameCustomer}
      />

      <ModalConfirmAppoitment
        isOpen={isDelete}
        message="Are you sure want to Delete this Appoitment?"
        btnClose="Yes, Delete it"
        toggleDelete={setIsDelete}
        onClose={onDelete}
      />
	{/* Modal choose staff */}
	{isOpenChooseStaff ? 
	<ModalChooseStaff
		isOpen={isOpenChooseStaff}	
		onClose={() => setIsOpenChooseStaff(false)}
		setStaff={setStaff}
		staffSeleted={staff}
		date={date}
		setDate={setDate}
		startTime={startTime}
		setStartTimeStaff={setStartTimeStaff}
		setIsOpenChooseService={!!tags.length ? null : setIsOpenChooseService}
		onGetStaff={onGetStaff}
		staffList={props.staffList}
		isAnyBody={isAnyBody}
		setIsAnyBody={setIsAnyBody}
	/>
	: '' }
	{/* Modal choose service */}
	<ModalChooseService
		isOpen={isOpenChooseService}	
		onClose={() => setIsOpenChooseService(false)}
		serviceList={props.serviceList}
		tags={tags}
		setTags={setTags}
		categories_list={getCategoryList(props.categoryList, props.serviceList)}
	/>
    </Fragment>
  );
}
