import { apiBaseURL } from '../config';
import { request } from '../services/api';
import { isEmpty } from 'lodash';
import { objectToSearchParams } from '../libs/utils/utils'

const baseEndpoint = `${apiBaseURL}`;

function getAppointmentList(data) {
  const searchParams = objectToSearchParams(data.payload)
  const endpoint = `${baseEndpoint}/appointment/getListAppointment?${searchParams}`;
  return request(endpoint, {
    method: 'GET',
    data
  });
}

function getAppoitmentDataApi(data) {
  const searchParams = objectToSearchParams(data.payload)
  const endpoint = `${baseEndpoint}/appointment/listAppointmentRealtime${searchParams}`;
  return request(endpoint, {
    method: 'GET',
    data
  });
}

function onCreateAppoitmentApi(data) {
  const endpoint = `${baseEndpoint}/appointment/v2/bookingAppointment`;
  return request(endpoint, {
    method: 'POST',
    data,
  });
}

function getServiceList(data) {
  const endpoint = `${baseEndpoint}/service/list`;
  return request(endpoint, {
    method: 'GET',
    data
  });
}

function getStaffList(data) {
  const searchParams = objectToSearchParams(data.payload)
  const endpoint = `${baseEndpoint}/staff/list${searchParams}`;
  return request(endpoint, {
    method: 'GET',
    data
  });
}

function getCustomerList(data) {
  const searchParams = objectToSearchParams(data.payload)
  const endpoint = `${baseEndpoint}/customer/getAllCustomerAutoSearch${searchParams}`;
  return request(endpoint, {
    method: 'GET',
    data
  });
}

function onCreateCustomerApi(data) {
  const endpoint = `${baseEndpoint}/customer/save`;
  return request(endpoint, {
    method: 'POST',
    data
  });
}

function onEditAppoitmentApi(data) {
  const endpoint = `${baseEndpoint}/appointment/v2/updateAppointment`;
  return request(endpoint, {
    method: 'PUT',
    data
  });
}

function onDeleteAppoitmentApi(data) {
  const { payload: { id } } = data
  const endpoint = `${baseEndpoint}/appointment/deleteAppointment/${id}`;
  return request(endpoint, {
    method: 'DELETE',
    data
  });
}


function onGetTimeSlotApi(data) {
  const searchParams = objectToSearchParams(data.payload)
  const endpoint = `${baseEndpoint}/appointment/getAvailableTimeSlotByStaff/${searchParams}`;
  return request(endpoint, {
    method: 'GET',
    data
  });
}

export default {
  getAppointmentList,
  getAppoitmentDataApi,
  onCreateAppoitmentApi,
  onEditAppoitmentApi,
  onDeleteAppoitmentApi,
  getServiceList,
  getStaffList,
  getCustomerList,
  onCreateCustomerApi,
  onGetTimeSlotApi
};
