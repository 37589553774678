import React from 'react';
import {
    Card, CardFooter, CardBody,
} from 'reactstrap';
import { Redirect } from 'react-router-dom';
import Banner404 from '../../../../assets/img/banner-404.png';

export default function Login({ history }) {
    return (
        <Card className="animated fadeIn card-home">
            <CardBody className="card-body-normal">
                <div className="page-404">
                    <div className="banner-404">
                        <img src={Banner404} alt="Page not found" />
                    </div>
                    <div className="content-404">
                        <h5>Page not found!</h5>
                        <button onClick={() => history.push('/')}><span><i className="fa fa-undo" aria-hidden="true"></i></span> Back to home</button>
                    </div>
                </div>
            </CardBody>
        </Card>
    );
}