import React, { Component } from 'react';
import {
  FormGroup,
  Card,
  CardHeader,
  CardBody,
  Col,
  Row,
  Input,
  Container,
  Table,
  CardFooter,
  CustomInput,
} from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as giftCardAction from './actions';
import moment from 'moment';
import Rating from 'react-rating';
import Star from '../../../../assets/img/star-review.svg';
import cx from 'classnames';
import PaginationFooter from '../../../../libs/components/PaginationFooter';
import DatePicker from 'react-datepicker';



class GiftCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      pageSize: 10,
      type: 0,
      giftCardCode: '',
      startTime: '',
      endTime: '',
    };
  }

  componentDidMount() {
    const { actions } = this.props;
    this.onInit();
  }


  onInit = () => {

    const {  startTime, endTime,giftCardCode,page, pageSize } = this.state;
    const { actions } = this.props;
    const payload = { page, pageSize, giftCardCode, 
                    startTime: startTime ? moment(startTime).format('YYYY-MM-DD') : '',
                    endTime: endTime ? moment(endTime).format('YYYY-MM-DD') : '',
     };
    actions.getDataGiftCard(payload);
  };

  onChangePagination = (page) => () =>
    this.setState({ page }, () => this.onInit());

  

  handleChangeDate = (name, date) => {
    this.setState({
      [name]: date
    })
  }

  
  onChange = ({ target: { name, value } }) => this.setState({ [name]: value })

  onSearch = () => this.setState({ page: 1 }, () => this.onInit())

  render() {
    const {  page } = this.state;
    const { giftCardList, total } = this.props;

    const { startTime, endTime, giftCardCode } = this.state

    console.log("giftCardList",giftCardList);

  
    return (
      <Card className="animated fadeIn card-home card-home--marketting">
        <CardBody className="d-flex p-0">
          <Container fluid className="d-flex w-100">
            <FormGroup className="table-marketting mt-0 w-100 d-flex">
              <Card className="table-list-marketting w-100">
                <CardHeader>
                
                  <h4>
                    <span>Gift Card Report</span>
                  </h4>
                  
                </CardHeader>
                <CardBody>
                <FormGroup className="d-flex flex-wrap mb-4">
                  <div className="form-search-reviews">
                    <Input className="w-15 search-phone" value={giftCardCode} onChange={this.onChange} name="giftCardCode" placeholder="code" />
                    <DatePicker
                      className="input-reviews-time"
                      name="startTime"
                      selected={startTime ? moment(startTime).toDate() : ''}
                      maxDate={endTime ? moment(endTime).toDate() : ''}
                      onChange={date => this.handleChangeDate('startTime', date)}
                      showYearDropdown
                      dateFormatCalendar="MMMM"
                      scrollableYearDropdown
                      yearDropdownItemNumber={15}
                      placeholderText="From"
                      dateFormat="MM/dd/yyyy"
                      autoComplete="off"
                    />
                    <span className="mx-3">-</span>
                    <DatePicker
                      className="input-reviews-time"
                      name="endTime"
                      selected={endTime ? moment(endTime).toDate() : ''}
                      minDate={startTime ? moment(startTime).toDate() : ''}
                      onChange={date => this.handleChangeDate('endTime', date)}
                      showYearDropdown
                      dateFormatCalendar="MMMM"
                      scrollableYearDropdown
                      yearDropdownItemNumber={15}
                      placeholderText="To"
                      dateFormat="MM/dd/yyyy"
                      autoComplete="off"
                    />
                    <button onClick={this.onSearch}>Search</button>
                  </div>
                </FormGroup>
                  
                  <Table className="mb-0">
                    <thead>
                      <tr>
                        <th>Gift Card Code</th>
                        <th>Amount</th>
                        <th>Used Amount</th>
                        <th>Create Date</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {giftCardList
                        .map((data) => (
                          <tr key={data.id}>
                           
                            <td>{data.giftCardCode}</td>
                            <td>{data.amount}</td>
                            <td>{data.used}</td>
                            <td>
                              {moment(new Date(data.createDate)).format(
                                'MM-DD-YYYY'
                              )}
                            </td>
                            <td>
                              {
                                data.status == 1 ? <div class="badge badge-success">Success</div> : data.status == 4 ? <div class="badge badge-danger">Exprire</div> : ''
                              }
                            </td>
                            <td>
                              <button type="button" id="PopoverC-2" class="mr-2 mb-2 btn btn-info">Detail</button>
                              <button type="button" id="PopoverC-2" class="mr-2 mb-2 btn btn-danger">Delete</button>
                            </td>
                          </tr>
                        )
                        )}
                    </tbody>
                  </Table>
                </CardBody>
                <CardFooter className="pt-0">
                  <PaginationFooter
                    currentPage={page}
                    totalSearch={total}
                    onChangePagination={this.onChangePagination}
                  />
                </CardFooter>
              </Card>
            </FormGroup>
          </Container>
        </CardBody>
      </Card>
    );
  }
}

function mapStateToProps(state) {
  return {
    giftCardList: state.GiftCard.giftCardList,
    total: state.GiftCard.total,
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {
    ...giftCardAction,
  };
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(GiftCard);
