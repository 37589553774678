import React, { Component } from 'react';
import {
    FormGroup, Card, InputGroupText, CardBody, UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle,
    Col, Label, Input, Row, InputGroup, Form, InputGroupAddon, CardTitle, Button, CustomInput
} from 'reactstrap';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as settingAction from '../../actions';
import { isEqual, isEmpty } from 'lodash'
import PageTitle from '../../../../container/components/PageTitle'

class SettingMember extends Component {
    constructor(props) {
        super(props);
        this.state = {
            LOYALTY_ENROLLMENT: {},
            LOYALTY_REWARD: {},
            LOYALTY_REDEMPTION: {},
            LUCKY_SPIN: {},
            luckySpin: []
        };
    }

    componentDidMount() {
        const { actions } = this.props;
        this.getSettingMember()
        actions.getAllSettingMember()
    }

    componentDidUpdate(prevProps) {
        const { settingMember } = this.props;
        if (!isEqual(prevProps.settingMember, settingMember) && !isEmpty(settingMember)) {
            this.getSettingMember()
        }
    }

    getSettingMember = () => {
        const { settingMember } = this.props;
        console.log(settingMember)
        const { lstLoyalty, luckySpinSettings } = settingMember
        lstLoyalty.forEach(loyalty => this.setState({ [loyalty.type]: loyalty }))
        this.setState({ luckySpinSettings: luckySpinSettings.slice().sort((a, b) => a.spinValue - b.spinValue) })
    }

    onChange = ({ target: { value, id } }) => {
        const ids = id.split('-')
        const group = ids[0]
        const name = ids[1]
        this.setState(prevState => ({
            [group]: {
                ...prevState[group],
                [name]: value
            }
        }))
    }

    onChangeSpin = ({ target: { name, value } }) => {
        const { luckySpinSettings } = this.state
        let luckySpinSettingsData = luckySpinSettings.map(luckySpin => ({ ...luckySpin, odds: luckySpin.spinValue == name ? value : luckySpin.odds }))
        this.setState({ luckySpinSettings: luckySpinSettingsData })
    }

    onUpdateDataSettingMember = () => {
        const { LOYALTY_ENROLLMENT, LOYALTY_REWARD, LOYALTY_REDEMPTION, LUCKY_SPIN, luckySpinSettings } = this.state;
        const { actions } = this.props;
        const payload = {
            lstLoyalty: [
                LOYALTY_ENROLLMENT, LOYALTY_REWARD, LOYALTY_REDEMPTION, LUCKY_SPIN
            ],
            luckySpinSettings
        }
        actions.onUpdateSettingMember(payload)
    }

    changeStatus = ({ target: { id, checked } }) => {
        const ids = id.split('-')
        const group = ids[0]
        const name = ids[1]
        this.setState(prevState => ({
            [group]: {
                ...prevState[group],
                [name]: checked
            },
        }))
        if (group == 'LOYALTY_REWARD' && checked) {
            this.setState(prevState => ({
                LOYALTY_ENROLLMENT: {
                    ...prevState.LOYALTY_ENROLLMENT,
                    status: false
                }
            }))
        }
        if (group == 'LOYALTY_ENROLLMENT' && checked) {
            this.setState(prevState => ({
                LOYALTY_REWARD: {
                    ...prevState.LOYALTY_REWARD,
                    status: false
                }
            }))
        }
    };

    render() {
        const { LOYALTY_ENROLLMENT, LOYALTY_REWARD, LOYALTY_REDEMPTION, LUCKY_SPIN, luckySpinSettings } = this.state;
        return (
            <Card className="animated fadeIn card-home">
                <PageTitle >
                    <FormGroup className="mb-0 d-flex align-items-center justify-content-between w-100">
                        <h4>Setup Loyalty Point & Lucky Spin</h4>
                        <button className="btn-add" onClick={this.onUpdateDataSettingMember}>
                            Save change
                    </button>
                    </FormGroup>

                </PageTitle>
                <CardBody className="card-body-box d-flex flex-column">
                    <Row style={{ marginBottom: '20px' }}>
                        <Col md="6" className="mb-2 d-flex align-item-stretch">
                            <Card className="main-card mb-2 w-100">
                                <CardBody>
                                    <CardTitle>Loyalty Points Usage
                                       <div className="ml-auto" style={{ float: 'right' }}>
                                            <CustomInput 
                                                className="custom-input-large font-bold pl-0"
                                                checked={LOYALTY_REWARD.status}
                                                onChange={this.changeStatus}
                                                name="reward"
                                                type="checkbox"
                                                id="LOYALTY_REWARD-status"
                                                label=""
                                            />
                                        </div>
                                    </CardTitle>
                                    <Form inline>
                                        <FormGroup className="mb-1 mr-sm-1 mb-sm-0">
                                            <Label for="exampleEmail22" className="mr-sm-2">Dollar by spent $</Label>
                                            <Input type="text" name="minBill" id="LOYALTY_REWARD-amount" onChange={this.onChange} value={LOYALTY_REWARD.amount} placeholder="" style={{ width: '90px' }} />
                                        </FormGroup>
                                        <Label className="mr-sm-2">=</Label>
                                        <FormGroup className="mb-1 mr-sm-1 mb-sm-0">
                                            <Input type="text" name="points" id="LOYALTY_REWARD-points" value={LOYALTY_REWARD.points} onChange={this.onChange} placeholder="" style={{ width: '70px' }} />
                                            <Label for="LOYALTY_REWARD-points" className="mr-sm-2" value={LOYALTY_REWARD.points} onChange={this.onChange} style={{ paddingLeft: '5px' }}>Point</Label>
                                        </FormGroup>

                                    </Form>
                                    <p>Ex: <br></br> If $10 = 100 points then customer can redeem 100 points by $10 they have.</p>

                                </CardBody>
                            </Card>
                        </Col>

                        <Col md="6" className="mb-2 d-flex align-item-stretch">
                            <Card className="main-card mb-2 w-100">
                                <CardBody>
                                    <CardTitle>Loyalty Program Enrollment
                                    <div className="ml-auto" style={{ float: 'right' }}>
                                            <CustomInput
                                                className="custom-input-large font-bold pl-0"
                                                checked={LOYALTY_ENROLLMENT.status}
                                                onChange={this.changeStatus}
                                                name="enrollment"
                                                type="checkbox"
                                                id="LOYALTY_ENROLLMENT-status"
                                            />
                                        </div>
                                    </CardTitle>
                                    <Form inline>
                                        <FormGroup className="mb-1 mr-sm-1 mb-sm-0">
                                            <Label for="exampleEmail22" className="mr-sm-2">Each vist</Label>
                                        </FormGroup>
                                        <Label className="mr-sm-2">=</Label>
                                        <FormGroup className="mb-1 mr-sm-1 mb-sm-0">
                                            <Input type="text" id="LOYALTY_ENROLLMENT-points" value={LOYALTY_ENROLLMENT.points} onChange={this.onChange} placeholder="" style={{ width: '70px' }} />
                                            <Label for="LOYALTY_ENROLLMENT-points" className="mr-sm-2" style={{ paddingLeft: '5px' }}>Point</Label>
                                        </FormGroup>
                                    </Form>
                                    <Form inline style={{ marginTop: '10px' }}>
                                        <FormGroup className="mb-1 mr-sm-1 mb-sm-0">
                                            <Label for="exampleEmail22" className="mr-sm-2">Min Bill</Label>
                                        </FormGroup>
                                        <Label className="mr-sm-2">= $</Label>
                                        <FormGroup className="mb-1 mr-sm-1 mb-sm-0">
                                            <Input type="text" id="LOYALTY_ENROLLMENT-minBill" value={LOYALTY_ENROLLMENT.minBill} onChange={this.onChange} id="LOYALTY_ENROLLMENT-minBill" placeholder="" style={{ width: '70px' }} />
                                        </FormGroup>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col md="6" className="mb-2 d-flex align-item-stretch">
                            <Card className="main-card mb-2 w-100">
                                <CardBody>
                                    <CardTitle>Loyalty Points Redemption
                                    <div className="ml-auto" style={{ float: 'right' }}>
                                            <CustomInput
                                                className="custom-input-large font-bold pl-0"
                                                checked={LOYALTY_REDEMPTION.status}
                                                onChange={this.changeStatus}
                                                name="redemption"
                                                type="checkbox"
                                                id="LOYALTY_REDEMPTION-status"
                                            />
                                        </div>
                                    </CardTitle>
                                    <Form inline>
                                        <FormGroup className="mb-1 mr-sm-1 mb-sm-0">
                                            <Label for="exampleEmail22" className="mr-sm-2">Points</Label>
                                            <Input type="text" id="LOYALTY_REDEMPTION-points" value={LOYALTY_REDEMPTION.points} onChange={this.onChange} placeholder="" style={{ width: '90px' }} />
                                        </FormGroup>
                                        <Label className="mr-sm-2">= $</Label>
                                        <FormGroup className="mb-1 mr-sm-1 mb-sm-0">
                                            <Input type="text" id="LOYALTY_REDEMPTION-amount" onChange={this.onChange} value={LOYALTY_REDEMPTION.amount} placeholder="" style={{ width: '70px' }} />
                                        </FormGroup>
                                    </Form>
                                    <p>Ex: <br></br></p>
                                    <p>Points 10 = $1 : a customer can use 10 point to redeem $1.</p>
                                    <p>Points 50 = $5 : a customer needs to earn at least 10 points to be able to redeem those points.</p>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col md="6" className="mb-2 d-flex align-item-stretch">
                            <Card className="main-card mb-2 w-100">
                                <CardBody>
                                    <CardTitle>Lucky spin setting
                                    <div className="ml-auto" style={{ float: 'right' }}>
                                            <CustomInput
                                                className="custom-input-large font-bold pl-0"
                                                checked={LUCKY_SPIN.status}
                                                onChange={this.changeStatus}
                                                name="luckyspin"
                                                type="checkbox"
                                                id="LUCKY_SPIN-status"
                                            />
                                        </div>
                                    </CardTitle>
                                    <Form inline>
                                        <FormGroup className="mb-1 mr-sm-1 mb-sm-0">
                                            <Label for="exampleEmail22" className="mr-sm-2">Dollar by spent $</Label>
                                            <Input type="text" id="LUCKY_SPIN-amount" value={LUCKY_SPIN.amount} onChange={this.onChange} placeholder="" style={{ width: '90px' }} />
                                        </FormGroup>
                                        <Label className="mr-sm-2">= $</Label>
                                        <FormGroup className="mb-1 mr-sm-1 mb-sm-0">
                                            <Input type="text" id="LUCKY_SPIN-points" value={LUCKY_SPIN.points} onChange={this.onChange} placeholder="" style={{ width: '70px' }} />
                                            <Label for="examplePassword22" className="mr-sm-2" style={{ paddingLeft: '5px' }}>Spin</Label>
                                        </FormGroup>
                                    </Form>
                                    {
                                        luckySpinSettings && !!luckySpinSettings.length && luckySpinSettings.map(spin => (
                                            <InputGroup className="input-group-spin">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>Win ${spin.spinValue}!</InputGroupText>
                                                </InputGroupAddon>
                                                <Input placeholder="Total spin" name={spin.spinValue} value={spin.odds} onChange={this.onChangeSpin} />
                                                <InputGroupAddon addonType="append">
                                                    <InputGroupText>Spin!</InputGroupText>
                                                </InputGroupAddon>
                                            </InputGroup>
                                        ))
                                    }
                                    <p>Ex: <br></br></p>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <FormGroup className="text-center mt-auto">
                        <button className="btn-add" onClick={this.onUpdateDataSettingMember}>
                            Save change
                    </button>
                    </FormGroup>
                </CardBody>
            </Card>
        );
    }
}

function mapStateToProps(state) {
    return {
        settingMember: state.Setting.settingMember
    };
}

function mapDispatchToProps(dispatch) {
    const actions = {
        ...settingAction,
    };
    return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingMember);
