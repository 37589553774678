import React, { useState } from 'react';
import { Dropdown } from 'reactstrap';

export default function DropdownCustom({ children, className, isShow }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = ({ target: { tagName } }) => {
    console.log(tagName);
    setDropdownOpen((prevState) =>
      (tagName != 'INPUT' && tagName != 'A') || !prevState
        ? !prevState
        : prevState
    );
  };

  return (
    <Dropdown
      isOpen={dropdownOpen || isShow}
      className={className}
      toggle={toggle}
    >
      {children}
    </Dropdown>
  );
}
