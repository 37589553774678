import { all, put, takeEvery, call } from 'redux-saga/effects';
import * as action from '../actions';
import API from '../../../../../api/login';
import * as actionDefault from '../../../container/actions'

export function* handleLogin({ payload }) {
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.onLogin, payload);
    const status = response && response.data && response.data.code && response.data.code === 200
    yield put(action.onLoginSuccess({ status, shopData: response.data.payload, message: '' }));
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    yield put(actionDefault.isLoadingSuccess(true));
    yield put(action.onLoginSuccess({ status: error.errorCode == 200, message: error.message }));
  }
}

export function* handleSelectShop(payload) {
  try {
    yield put(action.selectShopSuccess(payload.payload));

  } catch (error) {
    console.log(error);
  }
}

export function* handleClearDataShop() {
  try {
    yield put(action.clearDataShopSuccess());

  } catch (error) {
    console.log(error);
  }
}

export default function* root() {
  yield all([
    takeEvery(action.onLogin, handleLogin),
    takeEvery(action.selectShop, handleSelectShop),
    takeEvery(action.clearDataShop, handleClearDataShop)
  ]);
}
