import freeze from 'deep-freeze';
import { handleActions } from 'redux-actions';
import * as actions from './actions';

export const name = 'Dashboard';

const initialState = {
  dashboardData: {
    reviews: {},
    coupon: {},
  },
  badReviews: {}
};

export default handleActions({
  [actions.getDataDashboardSuccess]: (state, action) => freeze({
    ...state,
    dashboardData: action.payload,
  }),
  [actions.getReviewSuccess]: (state, action) => freeze({
    ...state,
    badReviews: action.payload
})
}, initialState);
