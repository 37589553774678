import freeze from 'deep-freeze';
import { handleActions } from 'redux-actions';
import * as actions from './actions';

export const name = 'Setting';

const initialState = {
  feedbacks: {},
  socialList: [],
  createSocialSuccess: 0,
  updateSocialSuccess: 0,
  deleteSocialSuccess: 0,

  categoryFeedback: [],
  questionFeedback: [],
  notificationData: [],

  createQuestionFeedbacks: 0,
  deleteQuestionFeedbacks: 0,

  createCategoryFeedbacks: 0,
  deleteCategoryFeedbacks: 0,


  settingMember: {
    lstLoyalty:[],
    luckySpinSettings:[]
  },
  updateSettingMemberSuccess: 0,

  settingSms: {
    
  },
  settingAutoSms: {
    BIRTHDAY: {},
    NO_VISIT: {}
  },
  expressServices: {}
};

export default handleActions({
  [actions.getSocialChannelListSuccess]: (state, action) => freeze({
    ...state,
    socialList: action.payload,
  }),
  [actions.getCategoryFeedbacksSuccess]: (state, action) => freeze({
    ...state,
    categoryFeedback: action.payload,
  }),
  [actions.onCreateSocialChannelSuccess]: (state, action) => freeze({
    ...state,
    createSocialSuccess: action.payload ? state.createSocialSuccess + 1 : state.createSocialSuccess - 1,
  }),
  [actions.onUpdateSocialChannelSuccess]: (state, action) => freeze({
    ...state,
    updateSocialSuccess: action.payload ? state.updateSocialSuccess + 1 : state.updateSocialSuccess - 1,
  }),
  [actions.onDeleteSocialChannelSuccess]: (state, action) => freeze({
    ...state,
    deleteSocialSuccess: action.payload ? state.deleteSocialSuccess + 1 : state.deleteSocialSuccess - 1,
  }),
  [actions.getNotificationSuccess]: (state, action) => freeze({
    ...state,
    notificationData: action.payload ? action.payload : [],
  }),
  [actions.getQuestionFeedbacksSuccess]: (state, action) => freeze({
    ...state,
    questionFeedback: action.payload,
  }),
  [actions.onCreateQuestionFeedbacksSuccess]: (state, action) => freeze({
    ...state,
    createQuestionFeedbacks: action.payload ? state.createQuestionFeedbacks + 1 : state.createQuestionFeedbacks - 1,
  }),
  [actions.onDeleteQuestionFeedbacksSuccess]: (state, action) => freeze({
    ...state,
    deleteQuestionFeedbacks: action.payload ? state.deleteQuestionFeedbacks + 1 : state.deleteQuestionFeedbacks - 1,
  }),

  [actions.onCreateCategoryFeedbacksSuccess]: (state, action) => freeze({
    ...state,
    createCategoryFeedbacks: action.payload ? state.createCategoryFeedbacks + 1 : state.createCategoryFeedbacks - 1,
  }),
  [actions.onDeleteCategoryFeedbacksSuccess]: (state, action) => freeze({
    ...state,
    deleteCategoryFeedbacks: action.payload ? state.deleteCategoryFeedbacks + 1 : state.deleteCategoryFeedbacks - 1,
  }),
  [actions.getAllSettingMemberSuccess]: (state, action) => freeze({
    ...state,
    settingMember: action.payload
  }),
  [actions.onUpdateSettingMemberSuccess]: (state, action) => freeze({
    ...state,
    updateSettingMemberSuccess: action.payload ? state.updateSettingMemberSuccess + 1 : state.updateSettingMemberSuccess - 1,
  }),
  [actions.getSettingSmsSuccess]: (state, action) => freeze({
    ...state,
    settingSms: action.payload
  }),
  [actions.getSettingAutoSmsSuccess]: (state, action) => freeze({
    ...state,
    settingAutoSms: action.payload
  }),
  [actions.getExpressServicesSuccess]: (state, action) => freeze({
    ...state,
    expressServices: action.payload
  }),
}, initialState);
