import Dashboard from './modules/base/components/Dashboard/Dashboard';
import BadReviews from './modules/base/components/Dashboard/components/BadReviews';
import BadReviewsSocial from './modules/base/components/Dashboard/components/BadReviewsSocial';
import BadReviewsCoupon from './modules/base/components/Dashboard/components/BadReviewsCoupon';
import CouponHandle from './modules/base/components/Coupon/components/CouponHandle';
import CouponList from './modules/base/components/Coupon/components/CouponList';
import CouponCategory from './modules/base/components/Coupon/components/CouponCategory';
import Staff from './modules/base/components/Staff/components/Staff';
import StaffDetail from './modules/base/components/Staff/components/StaffDetail';
import StaffSchedule from './modules/base/components/Staff/components/StaffSchedule';
import Service from './modules/base/components/Service/components/Service';
import ServiceHandle from './modules/base/components/Service/components/ServiceHandle';
import AboutUs from './modules/base/components/Owner/components/AboutUs';
import OwnerHandle from './modules/base/components/Owner/components/OwnerHandle';
import OwnerSchedule from './modules/base/components/Owner/components/OwnerSchedule';
import Feedbacks from './modules/base/components/Setting/components/Feedbacks/Feedbacks';
import NotificationFeedbacks from './modules/base/components/Setting/components/NotificationFeedbacks/NotificationFeedbacks';
import SocialChannel from './modules/base/components/Setting/components/SocialChannel/SocialChannel';
import Marketing from './modules/base/components/Marketting/Marketting';
import Report from './modules/base/components/Report/Report';
import ReportSMSMarketing from './modules/base/components/Report/ReportSMSMarketing';
import Customer from './modules/base/components/Customer/Customer';
import CustomerDetail from './modules/base/components/Customer/CustomerDetail';
import AppoitmentList from './modules/base/components/Appoitment/components/AppoitmentList';
import AppoitmentSchedule from './modules/base/components/Appoitment/components/AppoitmentSchedule';
import LuckySpin from './modules/base/components/LuckySpin/LuckySpin';
import GiftCard from './modules/base/components/GiftCard/GiftCard';
import SettingMember from './modules/base/components/Setting/components/Member/SettingMember';
import SettingSms from './modules/base/components/Setting/components/Sms/SettingSms';
import SettingAutoSms from './modules/base/components/Setting/components/Sms/AutoSentSms';
import SettingExpressServices from './modules/base/components/Setting/components/ExpressServices/SettingExpressServices';

import { ROUTER_URL } from './constants';

const routes = [
  {
    path: ROUTER_URL.DASH_BOARD,
    exac: true,
    name: 'Dashboard',
    component: Dashboard,
  },
  {
    path: ROUTER_URL.BAD_REVIEW,
    exac: true,
    name: 'Review 1-3 Star',
    component: BadReviews,
  },
  {
    path: ROUTER_URL.BAD_REVIEW_FACEBOOK,
    exac: true,
    name: 'Review Facebook',
    component: BadReviewsSocial,
  },
  {
    path: ROUTER_URL.BAD_REVIEW_YELP,
    exac: true,
    name: 'Review Yelp',
    component: BadReviewsSocial,
  },
  {
    path: ROUTER_URL.BAD_REVIEW_GOOGLE,
    exac: true,
    name: 'Review Google',
    component: BadReviewsSocial,
  },
  {
    path: ROUTER_URL.BAD_REVIEW_COUPON_SENT,
    exac: true,
    name: 'Coupon Sent',
    component: BadReviewsCoupon,
  },
  {
    path: ROUTER_URL.BAD_REVIEW_COUPON_USED,
    exac: true,
    name: 'Coupon Used',
    component: BadReviewsCoupon,
  },
  {
    path: ROUTER_URL.COUPON_CREATE,
    exac: true,
    name: 'Coupon Create',
    component: CouponHandle,
  },
  {
    path: ROUTER_URL.COUPON_EDIT,
    exac: true,
    name: 'Coupon Edit',
    component: CouponHandle,
  },
  // {
  //   path: ROUTER_URL.COUPON, exac: true, name: 'Coupon', component: Coupon,
  // },
  {
    path: ROUTER_URL.COUPON_LIST,
    exac: true,
    name: 'Coupon List',
    component: CouponList,
  },
  {
    path: ROUTER_URL.COUPON_CATEGORY,
    exac: true,
    name: 'Coupon Category',
    component: CouponCategory,
  },
  {
    path: ROUTER_URL.STAFF_CREATE,
    exac: true,
    name: 'Staff Create',
    component: StaffDetail,
  },
  {
    path: ROUTER_URL.STAFF_EDIT,
    exac: true,
    name: 'Staff Edit',
    component: StaffDetail,
  },
  {
    path: ROUTER_URL.STAFF_EDIT_SCHEDULE,
    exac: true,
    name: 'Staff Schedule',
    component: StaffSchedule,
  },
  {
    path: ROUTER_URL.STAFF,
    exac: true,
    name: 'Staff',
    component: Staff,
  },
  {
    path: ROUTER_URL.SERVICE_CREATE,
    exac: true,
    name: 'Service Create',
    component: ServiceHandle,
  },
  {
    path: ROUTER_URL.SERVICE_EDIT,
    exac: true,
    name: 'Service Edit',
    component: ServiceHandle,
  },
  {
    path: ROUTER_URL.SERVICE,
    exac: true,
    name: 'Service',
    component: Service,
  },
  {
    path: ROUTER_URL.OWNER_EDIT,
    exac: true,
    name: 'Owner Edit',
    component: OwnerHandle,
  },
  {
    path: ROUTER_URL.OWNER_SCHEDULE,
    exac: true,
    name: 'Owner Schedule',
    component: OwnerSchedule,
  },
  // {
  //   path: ROUTER_URL.OWNER, exac: true, name: 'Owner', component: Owner,
  // },
  // {
  //   path: ROUTER_URL.SETTING, exac: true, name: 'Setting', component: Setting,
  // },
  {
    path: ROUTER_URL.SETTING_FEEDBACK,
    exac: true,
    name: 'Feedback',
    component: Feedbacks,
  },
  {
    path: ROUTER_URL.SETTING_NOTIFICATION,
    exac: true,
    name: 'Notification',
    component: NotificationFeedbacks,
  },
  {
    path: ROUTER_URL.SETTING_MEMBER,
    exac: true,
    name: 'Member',
    component: SettingMember,
  },
  {
    path: ROUTER_URL.SETTING_SMS,
    exac: true,
    name: 'Sms',
    component: SettingSms,
  },
  {
    path: ROUTER_URL.SETTING_SOCIAL,
    exac: true,
    name: 'Social Channel',
    component: SocialChannel,
  },
  {
    path: ROUTER_URL.MARKETTING,
    exac: true,
    name: 'Marketting',
    component: Marketing,
  },
  {
    path: ROUTER_URL.REPORT,
    exac: true,
    name: 'Report',
    component: Report,
  },
  {
    path: ROUTER_URL.REPORT_SMS_MARKETING,
    exac: true,
    name: 'Report SMS Marketing',
    component: ReportSMSMarketing,
  },
  {
    path: ROUTER_URL.CUSTOMER_DETAIL,
    exac: true,
    name: 'Customer Detail',
    component: CustomerDetail,
  },
  {
    path: ROUTER_URL.CUSTOMER,
    exac: true,
    name: 'Customer',
    component: Customer,
  },
  {
    path: ROUTER_URL.STAFF_EDIT,
    exac: true,
    name: 'Staff Edit',
    component: StaffDetail,
  },
  // {
  //   path: ROUTER_URL.APPOINTMENT, exac: true, name: 'Appointment', component: Appoitment,
  // },
  {
    path: ROUTER_URL.APPOINTMENT_LIST,
    exac: true,
    name: 'Appointment List',
    component: AppoitmentList,
  },
  {
    path: ROUTER_URL.APPOINTMENT_SCHEDULE,
    exac: true,
    name: 'Appointment Schedule',
    component: AppoitmentSchedule,
  },
  {
    path: ROUTER_URL.SETING_LUCKY_SPIN,
    exac: true,
    name: 'Setting Lucky Spin',
    component: LuckySpin,
  },
  {
    path: ROUTER_URL.ABOUT_US,
    exac: true,
    name: 'About Us',
    component: AboutUs,
  },
  {
    path: ROUTER_URL.GIFT_CARD,
    exac: true,
    name: 'GiftCard',
    component: GiftCard,
  },
  {
    path: ROUTER_URL.SETTING_AUTO_SMS,
    exac: true,
    name: 'AutoSms',
    component: SettingAutoSms,
  },
  {
    path: ROUTER_URL.SETTING_EXPRESS_SERVICES,
    exac: true,
    name: 'Express Services',
    component: SettingExpressServices,
  },
];

export default routes;
