import React, { Component } from 'react';
import {
  ModalFooter, ModalHeader, Modal, ModalBody, Row, Col, Container, FormGroup, UncontrolledDropdown, DropdownToggle, CustomInput
} from 'reactstrap';
import ImageUpload from './ImageUpload'

export default class ModaCoupon extends Component {
  constructor(props) {
    super(props)
    this.state = {
      category: '',
      type: '',
      callToAction: 'Get Coupon',
      files: []
    }
  }

  componentWillUnmount() {
    this.state.files.forEach(file => URL.revokeObjectURL(file.preview));
  }

  addFile = file => {
    console.log(file);
    this.setState({
      files: file.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      )
    });
  };

  submitCoupon = () => {
    
  }

  render() {
    const { files } = this.state;
    const { isOpen, } = this.props
    return (
      <Modal className="modal-new-coupon" isOpen={isOpen} centered>
        <ModalHeader>Add New Coupon</ModalHeader>
        <ModalBody className="py-0">
          <Container>
            <Row>
              <Col sm={5} style={{ borderRight: "1px solid #dee2e6" }}>
                <div className="form-campain" >
                  <h4>Time Setting</h4>

                  <FormGroup className="dropdown-full">
                    <UncontrolledDropdown>
                      <DropdownToggle caret>Category</DropdownToggle>
                    </UncontrolledDropdown>
                  </FormGroup>
                  <FormGroup className="dropdown-full">
                    <UncontrolledDropdown>
                      <DropdownToggle caret>Type</DropdownToggle>
                    </UncontrolledDropdown>
                  </FormGroup>
                  <FormGroup className="dropdown-full mb-0">
                    <UncontrolledDropdown>
                      <DropdownToggle caret>Call to action</DropdownToggle>
                    </UncontrolledDropdown>
                  </FormGroup>
                </div>
              </Col>
              <Col sm={7} className="d-flex">
                <ImageUpload addFile={this.addFile} files={files} />
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between align-items-center">
          <CustomInput type="checkbox" id="start-now" label="Save campain to run in next time" />
          <div className="d-flex align-items-center">
            <button className="btn-campain btn-campain--cancel" onClick={this.props.cancel}>Cancel</button>
            <button onClick={this.submitCoupon} className="btn-campain">Save</button>
          </div>
        </ModalFooter>
      </Modal>
    )
  }
}
