import React, { Component, useState, useEffect } from 'react';
import {
	Modal,
	ModalBody,
	ModalFooter,
	Button,
	Row,
	Col,
	Nav,
	NavItem,
	NavLink,
	TabContent, 
	TabPane
} from "reactstrap";
import classnames from 'classnames';
import {
getRandomColor,
} from "../../../../../libs/utils/utils";
export default function ModalChooseService(props) {
	const [searchService, setSearchService] = useState("");
	const [verticalServiceList, setVerticalServiceList] = useState(false);
	const { tags, isOpen, onClose, serviceList, categories_list } = props;
	const [ activeTabCategory, setActiveTabCategory ] = useState(0);
	function checkIsIncludes(serviceName) {
		return searchService
			? serviceName
				.toLocaleLowerCase()
				.includes(searchService.toString().toLocaleLowerCase())
			: true;
	}

	const handleService = (id) => () => {
		props.setTags(
			tags.includes(id) ? tags.filter((tag) => tag != id) : [...tags, id]
		);
	};
	const handleOnClose = () => {
		setSearchService("");
		onClose();
	}

	const handleVerticalServiceList = (isVertical) => {
		setVerticalServiceList(isVertical);
	}

	const category_toggle = (tab) => {
		if (activeTabCategory !== tab) {
			setActiveTabCategory(tab);
		}
	}
	
	const category_html = categories_list.map((cate, index) => {
		return (
			<NavItem>
				<NavLink
					className={classnames({ active: activeTabCategory === index })}
					onClick={() => category_toggle(index)}
				>
					{cate.categoryName}
				</NavLink>
			</NavItem>
		)
	})

	return (
		<Modal
			isOpen={isOpen}
			centered
			className="modal-gallery modal-add-service"
		>
			<div className="modal-header border-0">
				<h3 className="modal-title" style={{fontSize: "38.4px"}}>Add Service</h3>
				<button type="button" className="close" onClick={handleOnClose}>
					<span aria-hidden="true" style={{ fontSize: "50px"}}>×</span>
				</button>
			</div>
			<Row className="m-0">
				<Col sm={12} id="form-search-staff" className="d-flex pl-0 pr-0">
					<i className="fa fa-search"></i>
					<input
						className="form-control input-keyword pl-5" 
						id="form-search-name"
						id="keyWord" 
						placeholder="Search service..."
						onChange={({ target: { value } }) => setSearchService(value)}
					/>
					<Button className="ml-2" onClick={() => handleVerticalServiceList(false)} >
						<i className="fa fa-th staff-list-display" style={{ color: `${!verticalServiceList ? "#1e89df" : "#575757"}` }}></i>
					</Button>
					<Button className="ml-2" onClick={() => handleVerticalServiceList(true)}>
						<i className="fa fa-list-ul staff-list-display" style={{ color: `${verticalServiceList ? "#1e89df" : "#575757"}` }}></i>
					</Button>
				</Col>
			</Row>
			<ModalBody style={{
				height: '58vh',
				overflowY: 'auto'
			}}>
				<Row className="mt-3">
					<Col sm="12" id="category-tabs" className="p-0">
							{category_html ? <Nav tabs>{category_html}</Nav> : ''}
					</Col>
					<TabContent activeTab={activeTabCategory} className="service-list">
						{categories_list.map((cate, index) => {
								return (
									<TabPane tabId={index}>
										<Row className="m-0">
											{
												serviceList.filter(
													(service) => service.catId == cate.id && checkIsIncludes(service.serviceName)
												)
												.map((service) => {
													let selected_item_class = "";
													if (tags.includes(service.id)) {
														selected_item_class = "selected-item";
													}
													let price = `$${(service.price % 1) ? service.price : service.price + '.00'}`;
													return (
														<Col key={service.id} style={{ color: "#e7e7e7!important", padding: "5px" }} sm={verticalServiceList ? 12 : 4} onClick={handleService(service.id)}>
															<div className={`select-staff-item p-3 d-flex ${selected_item_class}`}>
																{service.urlImage ? 
																	<div className="logo-staff">
																		<img class="" src={service.urlImage} alt={service.serviceName} />
																	</div>
																	:
																	<div className="logo-staff" style={{ background: getRandomColor() }}>
																		<span style={{ fontSize: "20px"}}>{service.serviceName.substring(0, 1)}</span>
																	</div>

																}
																<div style={{fontSize: '20px', fontWeight: '600'}}>
																	{service.serviceName}
																	<br/>
																	{price}
																</div>
															</div>
														</Col>
													)
												})
											}
										</Row>
									</TabPane>
								)
							})
						}
					</TabContent>
				</Row>
			</ModalBody>
			<ModalFooter className="pt-3 border-0 justify-content-center">
				<Button
					color="primary"
					className="w-25"
					onClick={handleOnClose}
					style={{fontSize:"20px", background: '#2B94FB'}}
				>
					OK
				</Button>
			</ModalFooter>
		</Modal>
	)
}

