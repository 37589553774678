import React from 'react';
import {
  ModalFooter, ModalHeader, Modal, FormGroup, UncontrolledDropdown, Col, Label, Input, ModalBody,
  DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';


export default function ModalHandleQuestion(props) {
  const { isOpen, category, questionData } = props;
  return (
    <Modal className="modal-create" isOpen={!!isOpen} centered>
      <ModalHeader className="border-0">{isOpen == 1 ? 'Create ' : 'Edit '}Question</ModalHeader>
      <ModalBody className="border-0">
        <FormGroup row>
          <Label sm={3}>Question</Label>
          <Col sm={9}>
            <Input type="textarea" rows="3" name="question" value={questionData.question} onChange={props.onChange} />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>Star</Label>
          <Col sm={9}>
            <div className="d-flex justify-content-between">
              {
                [1, 2, 3, 4, 5].map(item => {
                  return (
                    <span key={item} value={item} onClick={props.changeStar(item)} className={`item-rating-coupon ${questionData.stars.indexOf(item) > -1 ? 'selected' : ''}`}> {item} </span>
                  )
                })
              }
            </div>
            {/* <UncontrolledDropdown direction="right">
              <DropdownToggle caret className="dashboard-dropdown-toggle background-main">
                {
                  questionData.star ? questionData.star : 1
                }
              </DropdownToggle>
              <DropdownMenu right className="header-dropdown-right-menu home-dropdown dashboard-dropdown">
                {
                  [1, 2, 3, 4, 5].map(star => <DropdownItem key={star} onClick={props.changeStar(star)}>{star}</DropdownItem>)
                }
              </DropdownMenu>
            </UncontrolledDropdown> */}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>Category</Label>
          <Col sm={3}>
            <UncontrolledDropdown direction="right">
              <DropdownToggle caret className="dashboard-dropdown-toggle background-main">
                {
                  category ? category.filter(cate => cate.id == questionData.categoryId).map(cate => cate.categoryName) : ''
                }
              </DropdownToggle>
              <DropdownMenu right className="header-dropdown-right-menu home-dropdown dashboard-dropdown">
                {
                  category && category.map(cate => <DropdownItem key={cate.id} onClick={props.changeCategory(cate.id)}>{cate.categoryName}</DropdownItem>)
                }
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
        </FormGroup>
      </ModalBody>
      <ModalFooter className="border-0">
        <button onClick={props.toggleModal(0)}>Cancel</button>
        <button onClick={props.onSave}>Save</button>
      </ModalFooter>
    </Modal>
  )
}

