import { createAction } from 'redux-actions';
import * as CONST from './constants';

export const getDataDashboard = createAction(CONST.GET_DATA_DASHBOARD);
export const getDataDashboardSuccess = createAction(CONST.GET_DATA_DASHBOARD_SUCCESS);

export const getReview = createAction(CONST.GET_REVIEWS);
export const getReviewSuccess = createAction(CONST.GET_REVIEWS_SUCCESS);

export const getReviewSocial = createAction(CONST.GET_REVIEWS_SOCIAL);

export const getReviewCoupon = createAction(CONST.GET_REVIEWS_COUPON);