import { apiBaseURL } from '../config';
import { request } from '../services/api';
import { isEmpty } from 'lodash'

const baseEndpoint = `${apiBaseURL}`;

function getStaffList(data) {
    let searchParams = ''
    if (!isEmpty(data)) {
        searchParams = new URLSearchParams();
        Object.keys(data.payload).forEach(key => searchParams.append(key, data.payload[key]))
    }
    const endpoint = `${baseEndpoint}/staff/listPage${searchParams ? '?' + searchParams.toString() : ''}`;
    return request(endpoint, {
        method: 'GET',
        data,
    });
}

function onCreateStaff(data) {
    const endpoint = `${baseEndpoint}/staff/sign_up`;
    return request(endpoint, {
        method: 'POST',
        data,
    });
}

function getDetailStaffById(id) {
    const endpoint = `${baseEndpoint}/staff/detail/${id}`;
    return request(endpoint, {
        method: 'GET',
    });
}

function onUpdateStaff(data) {
    const endpoint = `${baseEndpoint}/staff/update`;
    return request(endpoint, {
        method: 'PUT',
        data,
    });
}

function onDeleteStaff(id) {
    const endpoint = `${baseEndpoint}/staff/remove/${id}`;
    return request(endpoint, {
        method: 'DELETE'
    });
}

function getScheduleStaffById(id) {
    const endpoint = `${baseEndpoint}/staff/schedulerBooking?staffId=${id}`;
    return request(endpoint, {
        method: 'GET'
    });
}

function onUpdateScheduleStaff(data) {
    const endpoint = `${baseEndpoint}/staff/updateSchedulerBooking`;
    return request(endpoint, {
        method: 'PUT',
        data
    });
}

function getDetailReviewStaffById(id) {
    const endpoint = `${baseEndpoint}/reviews/getListReviewByStaff/?id=${id}`;
    return request(endpoint, {
        method: 'GET',
    });
}

export default {
    getStaffList,
    onCreateStaff,
    getDetailStaffById,
    getDetailReviewStaffById,
    onUpdateStaff,
    onDeleteStaff,
    getScheduleStaffById,
    onUpdateScheduleStaff
};
