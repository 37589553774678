import React, { Component, useState, useEffect, useRef } from 'react';
import {
	Modal,
	ModalBody,
	Button,
	Row,
	Col,
} from "reactstrap";
import classnames from "classnames";
import { format_phone_custom } from "../../../../../libs/utils/utils";
import {
	getRandomColor,
  } from "../../../../../libs/utils/utils";

const INCREASE_QUANTITY = 100;
export default function ModalChooseCustomer(props) {
	const scroll_bk = useRef(0);
	const listInnerRef = useRef(null);
	const inputSearch = useRef(null);
	const { isOpen, onClose, customerList, customer_selected, setCustomer, setCustomerEdit, searchName, setSearchName, openCreateCustomer, setNameCustomer, setIsOpenChooseStaff, setIsOpenChooseService } = props;
	const [chooseCustomer, setChooseCustomer] = useState(customer_selected);
	const [searchCustomer, setSearchCustomer] = useState(searchName);
	const [verticalCustomerList, setVerticalCustomerList] = useState(false);
	const [isFocusInputSearch, setIsFocusInputSearch] = useState(true);
	const [quantityShow, setQuantityShow] = useState(INCREASE_QUANTITY);

	function checkIsIncludes(name, phone) {
		var search = searchCustomer.toString().toLocaleLowerCase();
		return searchCustomer
			? ((name && name.toLocaleLowerCase().includes(search)) || (phone && phone.toLocaleLowerCase().includes(search))) : true;
	}

	const handleSelectCustomer = (e, customer) => {
		e.stopPropagation();
		setChooseCustomer(customer.name);
		setSearchName(customer.name);
		setCustomer(customer);
		chandleClosePopup();
		if (setIsOpenChooseStaff != null) {
			setIsOpenChooseStaff(true);
		}
		else if(setIsOpenChooseService != null){
			setIsOpenChooseService(true);
		}
		setQuantityShow(INCREASE_QUANTITY);
	};
	const handleSearch = (value) => {
		setChooseCustomer({});
		setSearchCustomer(value);
		setQuantityShow(INCREASE_QUANTITY);
	}

	const chandleClosePopup = () => {
		setSearchCustomer("");
		setIsFocusInputSearch(true);
		setQuantityShow(INCREASE_QUANTITY);
		onClose();
	}

	const create_customer = (e) => {
		openCreateCustomer(e);
		setIsFocusInputSearch(false);
	}

	useEffect(() => {
		setNameCustomer(searchCustomer);
	}, [searchCustomer])

	useEffect(() => {
		if (isFocusInputSearch) {
			if (inputSearch.current) {
				  inputSearch.current.focus()
			}
		}
	});

	const handleVerticalCustomerList = (isVertical) => {
		setVerticalCustomerList(isVertical);
	}

	const handleEditCustomer = (customer, e) => {
		e.preventDefault();
		e.stopPropagation();
		setIsFocusInputSearch(false);
		setCustomerEdit(customer);
	}

	const onScroll = () => {
		if (inputSearch.current) {
			inputSearch.current.blur();
	  	}
		if (listInnerRef.current) {
			const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
			if (scrollTop + clientHeight === scrollHeight && scroll_bk.current != scrollHeight) {
				setQuantityShow((quantityShow + INCREASE_QUANTITY));
				scroll_bk.current = scrollHeight;
			}
		}
	};

	return (
		<Modal
			isOpen={isOpen}
			centered
			className="modal-gallery modal-add-service"
		>
			<div className="modal-header border-0">
				<h3 className="modal-title" style={{ fontSize: "38.4px" }}>Choose Customer</h3>
				<button type="button" className="close" onClick={chandleClosePopup}>
					<span aria-hidden="true" style={{ fontSize: "50px"}}>×</span>
				</button>
			</div>
			<Row className="m-0">
				<Col className="d-flex flex-wrap align-items-center">
					{/* <div className={classnames("form-custom flex-fill", {
						"form-focus": (chooseCustomer && chooseCustomer.name) || searchCustomer,
					})}
					> */}
					<div className={classnames("form-custom flex-fill")}
					>
						<span>
							Name <b>*</b>
						</span>
						<input
							ref={isFocusInputSearch ? inputSearch : () => {}}
							name="inputSearch"
							value={searchCustomer}
							id="form-search-name"
							className="p-0"
							autoComplete="off"
							onChange={({ target: { value } }) => handleSearch(value)}
							style={{ marginLeft: "12px", height: "50px"}}
						/>
					</div>
					<button className="btn-plus" onClick={(e) => create_customer(e)}>
						<i className="fa fa-plus" aria-hidden="true"></i>
					</button>
					<Button className="ml-2" onClick={() => handleVerticalCustomerList(false)} >
						<i className="fa fa-th staff-list-display" style={{ color: `${!verticalCustomerList ? "#1e89df" : "#575757"}` }}></i>
					</Button>
					<Button className="ml-2" onClick={() => handleVerticalCustomerList(true)}>
						<i className="fa fa-list-ul staff-list-display" style={{ color: `${verticalCustomerList ? "#1e89df" : "#575757"}` }}></i>
					</Button>
				</Col>
			</Row>
			<ModalBody>
				<div 
					style={{
						height: '90vh',
						overflowY: 'auto'
					}}
					onScroll={() => onScroll()} 
					ref={listInnerRef}
				>
					<p id="other" >Other</p>
					<Row className="service-list" >
						{customerList
							.filter(
								(customer) => {
									return checkIsIncludes(customer.name, customer.phone);
									
								}
							)
							.map((customer, index) => {
								if (index > quantityShow) {
									return;
								}
								if (!customer.name || !customer.phone) {
									return null;
								}
								
								let selected_item_class = "";
								if (chooseCustomer) {
									if (chooseCustomer.id == customer.id) {
										selected_item_class = "selected-item";
									}
								}
								return (
									<Col className="mb-3" style={{ color: "#e7e7e7!important" }}  sm={verticalCustomerList ? 12 : 4} onClick={(e) => handleSelectCustomer(e, customer)}>
										<div className={`select-staff-item p-3 ${selected_item_class}`} style={{ display: "block" }}>
											<Row className="d-flex justify-content-center">
												<div>
													<div className="logo-staff" style={{ background: getRandomColor(), width: '50px', height: '50px' }}>
														<span style={{ fontSize: "20px"}}>{customer.name.substring(0, 1)}</span>
													</div>
												</div>
												<div style={{ display: 'flex', flexFlow: 'column', justifyContent: 'center' }}>
													<div style={{fontSize: '20px', fontWeight: '600'}}>
														{customer.name}
														<br/>
														{customer.phone && `${format_phone_custom(customer.phone)}`}
													</div>
												</div>
											</Row>
											<div className="edit-customer-btn">
												<Button className="ml-auto border-custom-modal" 
												onClick={(e) => handleEditCustomer(customer, e)}>
													<span
													className="staff-edit"
													>
														<i
															className="fa fa-pencil-square-o"
															aria-hidden="true"
														></i>
													</span>
												</Button>
											</div>
										</div>
									</Col>
								)
							})
						}
					</Row>
				</div>
			</ModalBody>
		</Modal>
	)
}

