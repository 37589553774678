import { apiBaseURL } from '../config';
import { request } from '../services/api';

const baseEndpoint = `${apiBaseURL}`;

function getMarkettingList(data) {
  const searchParams = new URLSearchParams();
  Object.keys(data.payload).forEach((key) => searchParams.append(key, data.payload[key]));
  const endpoint = `${baseEndpoint}/customer/getCustomerByFilter?${searchParams.toString()}`;
  return request(endpoint, {
    method: 'GET',
  });
}

function onCreateCampain(data) {
  const endpoint = `${baseEndpoint}/sms/sendSmsMarketingByCampaign`;
  return request(endpoint, {
    method: 'POST',
    data,
  });
}

function checkBalance(data) {
  const endpoint = `${baseEndpoint}/shop/checkBalance`;
  return request(endpoint, {
    method: 'POST',
    data,
  });
}

export default {
  getMarkettingList,
  onCreateCampain,
  checkBalance,
};
