import { all, put, takeEvery, call } from 'redux-saga/effects';
import * as action from '../actions';
import API from '../../../../../api/service';
import * as actionDefault from '../../../container/actions';

export function* handleGetServiceList(payload) {
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.getServiceList, payload);
    if (
      response &&
      response.data &&
      response.data.code &&
      response.data.code === 200
    ) {
      yield put(action.getServiceListSuccess(response.data.payload));
    }
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    console.log(error);
    yield put(actionDefault.isLoadingSuccess(true));
  }
}

export function* handleOnCreateService(payload) {
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.onCreateService, payload);
    const status =
      response &&
      response.data &&
      response.data.code &&
      response.data.code === 200;
    yield put(action.onCreateServiceSuccess(status));
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    console.log(error);
    yield put(action.onCreateServiceSuccess(false));
    yield put(actionDefault.isLoadingSuccess(true));
  }
}

export function* handleOnUpdateService(payload) {
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.onUpdateService, payload);
    const status =
      response &&
      response.data &&
      response.data.code &&
      response.data.code === 200;
    yield put(action.onUpdateServiceSuccess(status));
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    console.log(error);
    yield put(action.onUpdateServiceSuccess(false));
    yield put(actionDefault.isLoadingSuccess(true));
  }
}

export function* handleOnDeleteService({ payload }) {
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.onDeleteService, payload);
    const status =
      response &&
      response.data &&
      response.data.code &&
      response.data.code === 200;
    yield put(action.onDeleteServiceSuccess(status));
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    console.log(error);
    yield put(action.onDeleteServiceSuccess(false));
    yield put(actionDefault.isLoadingSuccess(true));
  }
}

export function* handleGetCategoryList(payload) {
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    
    const response = yield call(API.getCategoryList, payload);
    console.log(response)
    if (
      response &&
      response.data &&
      response.data.code &&
      response.data.code === 200
    ) {
      yield put(action.getCategoryListSuccess(response.data.payload));
    }
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    console.log(error);
    yield put(actionDefault.isLoadingSuccess(true));
  }
}

export function* handleOnCreateCategory(payload) {
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.onCreateCategory, payload);
    const status =
      response &&
      response.data &&
      response.data.code &&
      response.data.code === 200;
    yield put(action.onCreateCategorySuccess(status));
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    console.log(error);
    yield put(action.onCreateCategorySuccess(false));
    yield put(actionDefault.isLoadingSuccess(true));
  }
}
export function* handleGetCategoryById({ payload }) {
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.getDetailCategoryById, payload);
    if (
      response &&
      response.data &&
      response.data.code &&
      response.data.code === 200
    )
      yield put(action.getDetailCategorySuccess(response.data.payload));
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    console.log(error);
    yield put(actionDefault.isLoadingSuccess(true));
  }
}

export function* handleOnDeleteCategory({ payload }) {
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.onDeleteCategory, payload);
    const status =
      response &&
      response.data &&
      response.data.code &&
      response.data.code === 200;
    yield put(action.onDeleteCategorySuccess(status));
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    console.log(error);
    yield put(action.onDeleteCategorySuccess(false));
    yield put(actionDefault.isLoadingSuccess(true));
  }
}

export function* handleOnUpdateCategory(payload) {
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.onUpdateService, payload);
    const status =
      response &&
      response.data &&
      response.data.code &&
      response.data.code === 200;
    yield put(action.onUpdateCategorySuccess(status));
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    console.log(error);
    yield put(action.onUpdateCategorySuccess(false));
    yield put(actionDefault.isLoadingSuccess(true));
  }
}

export function* handleGetImageCategoryService() {
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.getImageCategoryService);
    if (
      response &&
      response.data &&
      response.data.code &&
      response.data.code === 200
    )
      yield put(action.getImageCategoryServiceSuccess(response.data.payload));
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    console.log(error);
    yield put(actionDefault.isLoadingSuccess(true));
  }
}

export function* handleGetGalleryByCategoryID(payload) {
  try {
    yield put(actionDefault.isLoadingSuccess(false));
    const response = yield call(API.getImageCategoryServiceById, payload);
    if (
      response &&
      response.data &&
      response.data.code &&
      response.data.code === 200
    ) {
      const { data, total } = response.data.payload
      yield put(action.getImageByCategoryIDSuccess({ data, total }));
    }
    yield put(actionDefault.isLoadingSuccess(true));
  } catch (error) {
    console.log(error);
    yield put(actionDefault.isLoadingSuccess(true));
  }
}

export default function* root() {
  yield all([
    takeEvery(action.getServiceList, handleGetServiceList),
    takeEvery(action.onCreateService, handleOnCreateService),
    takeEvery(action.onUpdateService, handleOnUpdateService),
    takeEvery(action.onDeleteService, handleOnDeleteService),
    takeEvery(action.getCategoryList, handleGetCategoryList),
    takeEvery(action.getDetailCategory, handleGetCategoryById),
    takeEvery(action.onCreateCategory, handleOnCreateCategory),
    takeEvery(action.onUpdateCategory, handleOnUpdateCategory),
    takeEvery(action.onDeleteCategory, handleOnDeleteCategory),
    takeEvery(action.getImageCategoryService, handleGetImageCategoryService),
    takeEvery(action.getImageByCategoryID, handleGetGalleryByCategoryID),
  ]);
}
