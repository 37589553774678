import React, { Component } from 'react';
import {
  FormGroup,
  Card,
  CardHeader,
  CardBody,
  Col,
  Row,
  Input,
  Container,
  Table,
  CardFooter,
  CustomInput,
} from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as reportAction from './actions';
import * as couponAction from '../Coupon/actions';
import moment from 'moment';
import Rating from 'react-rating';
import Star from '../../../../assets/img/star-review.svg';
import cx from 'classnames';
import PaginationFooter from '../../../../libs/components/PaginationFooter';
import DatePicker from 'react-datepicker';
import { formatPhone } from '../../../../libs/utils/utils'

const totalList = [
  {
    id: 'totalUser',
    name: 'Total user',
  },
  {
    id: 'totalCouponActive',
    name: 'Coupon active',
  },
  {
    id: 'totalUserUsed',
    name: 'User use Campaign',
  },
  {
    id: 'totalUserReject',
    name: 'User reject',
  },
  {
    id: 'totalExpireDate',
    name: 'Expire date',
  },
];

class ReportSMSMarketing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      marketingType: 0,
      page: 1,
      pageSize: 10,
      type: 0,
      keyword: '',
      startTime: '',
      endTime: '',
    };
  }

  componentDidMount() {
    const { actions, categoryCouponList } = this.props;
    this.onInit();
  }

  toggle = (tab) => {
    const { marketingType } = this.state;
    if (marketingType != tab)
      this.setState({ marketingType: tab }, () => this.onInit());
  };

  onInit = () => {

    const {  startTime, endTime,keyword,page, pageSize } = this.state;
    const { actions } = this.props;
    const payload = { page, pageSize, keyword, 
                    startTime: startTime ? moment(startTime).format('YYYY-MM-DD') : '',
                    endTime: endTime ? moment(endTime).format('YYYY-MM-DD') : '',
     };
    actions.getDataReportSMS(payload);
  };

  onChangePagination = (page) => () =>
    this.setState({ page }, () => this.onInit());

  submitCoupon = (payload) => {
    const { actions } = this.props;
    console.log(payload);
    actions.onCreateCampain(payload);
  };

  getListByCategoryId = (id) => {
    const { actions } = this.props;
    actions.getCouponByCategoryId(id);
  };

  getTotalByType = (type) => {
    const { marketingType } = this.state;
    const { reportSMSList } = this.props;
    if (reportSMSList && !!reportSMSList.length) {
      const campaignSelect = !!marketingType
        ? reportSMSList.find((report) => report.id === marketingType)
        : reportSMSList[0];
      return campaignSelect[type];
    }
    return 0;
  };


  handleChangeDate = (name, date) => {
    this.setState({
      [name]: date
    })
  }

  
  onChange = ({ target: { name, value } }) => this.setState({ [name]: value })

  onSearch = () => this.setState({ page: 1 }, () => this.onInit())

  render() {
    const { marketingType, page, type } = this.state;
    const { reportSMSList, total, totalSmsRemain } = this.props;

    const { startTime, endTime, keyword } = this.state

    return (
      <Card className="animated fadeIn card-home card-home--marketting">
        <CardBody className="d-flex p-0">
          <Container fluid className="d-flex w-100">
            <FormGroup className="table-marketting mt-0 w-100 d-flex">
              <Card className="table-list-marketting w-100">
                <CardHeader>
                
                  <h4>
                    <span>SMS Report</span>
                      <div className="total_sms">
    <span className="balance_amount">Total Sms Used : <span className="value">{total}</span> </span> || <span className="balance_amount">Total Sms Remaind : <span className="value">{totalSmsRemain}</span></span>
                    </div>
                  </h4>
                  
                  {/* <ul className="header-tabs">
                    {reportSMSList.map((tab) => (
                      <li
                        key={tab.id}
                        className={cx({
                          active:
                            tab.id == (marketingType || reportSMSList[0].id),
                        })}
                        onClick={() => this.toggle(tab.id)}
                      >
                        {tab.campaignName}
                      </li>
                    ))}
                  </ul> */}
                </CardHeader>
                <CardBody>
                <FormGroup className="d-flex flex-wrap mb-4">
                  <div className="form-search-reviews">
                    <Input className="w-15 search-phone" value={keyword} onChange={this.onChange} name="keyword" placeholder="Phone" />
                    <DatePicker
                      className="input-reviews-time"
                      name="startTime"
                      selected={startTime ? moment(startTime).toDate() : ''}
                      maxDate={endTime ? moment(endTime).toDate() : ''}
                      onChange={date => this.handleChangeDate('startTime', date)}
                      showYearDropdown
                      dateFormatCalendar="MMMM"
                      scrollableYearDropdown
                      yearDropdownItemNumber={15}
                      placeholderText="From"
                      dateFormat="MM/dd"
                      autoComplete="off"
                    />
                    <span className="mx-3">-</span>
                    <DatePicker
                      className="input-reviews-time"
                      name="endTime"
                      selected={endTime ? moment(endTime).toDate() : ''}
                      minDate={startTime ? moment(startTime).toDate() : ''}
                      onChange={date => this.handleChangeDate('endTime', date)}
                      showYearDropdown
                      dateFormatCalendar="MMMM"
                      scrollableYearDropdown
                      yearDropdownItemNumber={15}
                      placeholderText="To"
                      dateFormat="MM/dd"
                      autoComplete="off"
                    />
                    <button onClick={this.onSearch}>Search</button>
                  </div>
                </FormGroup>
                  {/* <div className="header-table-content">
                    {totalList.map((item, index) => (
                      <div
                        key={index}
                        className={cx('item-content-header', {
                          // selected: index == type,
                        })}
                        // onClick={() => this.setState({ type: index })}
                      >
                        <span>{this.getTotalByType(item.id) || 0}</span>
                        <p>{item.name}</p>
                      </div>
                    ))}
                  </div> */}
                  {/* <div className="table-marketting-content"> */}
                  <Table className="mb-0">
                    <thead>
                      <tr>
                        <th className="w-15">Customer Name</th>
                        <th className="w-15">Sms To</th>
                        <th className="w-40">Sms Content</th>
                        <th className="w-10">Sms Count</th>
                        <th className="w-15">Create Date</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {reportSMSList
                        .map((data) => (
                          <tr key={data.id}>
                           
                            <td>{data.customerName}</td>
                            <td>{formatPhone(data.smsTo) || data.smsTo}</td>
                            <td>{data.smsContent}</td>
                            <td style={{textAlign:'center'}}>{data.smsCount}</td>
                            <td>
                              {moment(new Date(data.createDate)).format(
                                'MM-DD-YYYY'
                              )}
                            </td>
                            <td>
                              {
                                data.status == 1 ? <div class="badge badge-success">Success</div> : data.status == 4 ? <div class="badge badge-danger">Error</div> : ''
                              }
                            </td>
                          </tr>
                        )
                        )}
                    </tbody>
                  </Table>
                </CardBody>
                <CardFooter className="pt-0">
                  <PaginationFooter
                    currentPage={page}
                    totalSearch={total}
                    onChangePagination={this.onChangePagination}
                  />
                </CardFooter>
              </Card>
            </FormGroup>
          </Container>
        </CardBody>
      </Card>
    );
  }
}

function mapStateToProps(state) {
  return {
    reportSMSList: state.Report.reportSMSList,
    total: state.Report.total,
    totalSmsRemain: state.Report.totalSmsRemain
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {
    ...reportAction,
  };
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportSMSMarketing);
